'use es6';

import { List, Set as ImmutableSet, Map as ImmutableMap } from 'immutable';
import Pages from '../models/Pages';
import { PagesActionTypes } from '../actions/PagesActions';
import RequestStatus from '../../shared/constants/RequestStatus';
import { getPagesActionName } from '../utils/pagesReduxUtils';
import { ColumnTypes } from '../../shared/constants/columns';
import { SortDirTypes } from '../../shared/constants/table';
import { FolderActionTypes } from '../actions/FolderActions';
import BulkUpdateTask from '../../shared/models/BulkUpdateTask';
import PagesDisplay from '../models/PagesDisplay';
import { getEntitiesData } from '../../shared/utils/multiLangUtils';
import { toggleSelectEntityAndExpandMultiLangGroup, toggleMultiLangGroupForEntityAndResetSelection, toggleSelectAllEntitiesAndExpandMultiLangGroups, toggleAllMultiLangGroupsAndResetSelectionsWhenFiltering } from '../../shared/utils/displaySelectedHelpers';
import { _getTranslatedPages } from '../selectors/pagesSelectors';
import { convertFilterQueryToMap } from '../../shared/utils/filters';
import SiteTreeActionTypes from '../../shared/actions/SiteTreeActionTypes';
const getIsFilteringOrSearching = pagesDisplay => pagesDisplay.search !== '' || pagesDisplay.filterQuery.size > 0;
export default (contentType => (state = new Pages(), action) => {
  const currentlyOpenNestedRows = state.getIn(['display', 'pagesWithOpenNestedRows']);
  const primaryEntities = getEntitiesData(state, currentlyOpenNestedRows);
  const translationEntities = _getTranslatedPages(primaryEntities);
  const currentlySelectedEntities = state.getIn(['display', 'selected']);
  const isFilteringOrSearching = getIsFilteringOrSearching(state.get('display'));
  switch (action.type) {
    case PagesActionTypes[getPagesActionName(contentType, 'RESET_PAGES')]:
      return new Pages({
        startStateFetchStatus: state.get('startStateFetchStatus'),
        startStateTotal: state.get('startStateTotal'),
        display: new PagesDisplay({
          selectedSort: state.getIn(['display', 'selectedSort']),
          selectedSortDir: state.getIn(['display', 'selectedSortDir'])
        })
      });
    case PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_PAGES')]:
      return state.merge({
        stale: false,
        status: RequestStatus.PENDING
      });
    case PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_PAGES_FOR_START_STATE')]:
      return state.merge({
        stale: false,
        startStateFetchStatus: RequestStatus.PENDING
      });
    case PagesActionTypes[getPagesActionName(contentType, 'RECEIVED_PAGES')]:
      {
        const pagesToMergeOnRefetch = state.get('pagesToMergeOnRefetch');
        let data = action.payload.pages;
        if (!pagesToMergeOnRefetch.isEmpty()) {
          data = pagesToMergeOnRefetch.reduce((acc, page) => acc.set(page.id, page), data);
          const {
            sort,
            sortDir,
            rowsPerPage
          } = state.get('display');
          if (sort === ColumnTypes.UPDATED_DATE) {
            if (sortDir === SortDirTypes.DESCENDING) {
              data = data.sortBy(page => -page.updated).slice(0, rowsPerPage);
            } else {
              data = data.sortBy(page => page.updated).slice(-rowsPerPage);
            }
          }
        }
        return state.merge({
          data,
          status: RequestStatus.SUCCEEDED,
          total: action.payload.total - action.payload.pages.size + data.size,
          startStateTotal: action.payload.total > 0 ? action.payload.total : state.get('startStateTotal'),
          pagesToMergeOnRefetch: new List(),
          stale: state.stale
        }).mergeIn(['display'], {
          excludedPageIds: new List(),
          selected: new ImmutableSet(),
          pagesWithOpenNestedRows: new ImmutableSet(),
          bulkUpdateTask: new BulkUpdateTask()
        });
      }
    case PagesActionTypes[getPagesActionName(contentType, 'RECEIVED_PAGES_FOR_START_STATE')]:
      return state.merge({
        startStateFetchStatus: RequestStatus.SUCCEEDED,
        startStateTotal: action.payload.total
      });
    case PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_PAGES_FAILED')]:
      return state.set('status', RequestStatus.FAILED);
    case PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_PAGES_FOR_START_STATE_FAILED')]:
      return state.set('startStateFetchStatus', RequestStatus.FAILED);
    case PagesActionTypes[getPagesActionName(contentType, 'SET_PAGES_TO_MERGE_ON_REFETCH')]:
      return state.set('pagesToMergeOnRefetch', action.payload.pagesToMergeOnRefetch);
    case PagesActionTypes[getPagesActionName(contentType, 'MARK_PAGES_STALE')]:
      return state.set('stale', true);
    case PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_AUDIENCE_ACCESS_PAGES_TO_BE_UNPUBLISHED_COUNT')]:
      return state.set('pagesToBeUnpublishedBecauseAudienceAccessCountStatus', RequestStatus.PENDING);
    case PagesActionTypes[getPagesActionName(contentType, 'RECEIVED_AUDIENCE_ACCESS_PAGES_TO_BE_UNPUBLISHED_COUNT')]:
      return state.merge({
        pagesToBeUnpublishedBecauseAudienceAccessCount: action.payload.total,
        pagesToBeUnpublishedBecauseAudienceAccessCountStatus: RequestStatus.SUCCEEDED
      });
    case PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_AUDIENCE_ACCESS_PAGES_TO_BE_UNPUBLISHED_COUNT_FAILED')]:
      return state.set('pagesToBeUnpublishedBecauseAudienceAccessCountStatus', RequestStatus.FAILED);

    // Pages display state
    case PagesActionTypes[getPagesActionName(contentType, 'TOGGLE_PAGE_SELECT')]:
      {
        const {
          selectedEntities,
          openNestedRows
        } = toggleSelectEntityAndExpandMultiLangGroup({
          id: action.payload.pageId,
          primaryEntities,
          translationEntities,
          currentlySelectedEntities,
          currentlyOpenNestedRows,
          isFilteringOrSearching
        });
        return state.mergeIn(['display'], {
          selected: selectedEntities,
          pagesWithOpenNestedRows: openNestedRows
        });
      }
    case PagesActionTypes[getPagesActionName(contentType, 'TOGGLE_ALL_PAGES_SELECT')]:
      {
        const {
          selectedEntities,
          openNestedRows
        } = toggleSelectAllEntitiesAndExpandMultiLangGroups({
          primaryEntities,
          translationEntities,
          currentlySelectedEntities,
          currentlyOpenNestedRows,
          isFilteringOrSearching
        });
        return state.mergeIn(['display'], {
          selected: selectedEntities,
          pagesWithOpenNestedRows: openNestedRows
        });
      }
    case PagesActionTypes[getPagesActionName(contentType, 'TOGGLE_ALL_PAGES_NESTED_ROWS_OPEN')]:
      {
        const {
          selectedEntities,
          openNestedRows
        } = toggleAllMultiLangGroupsAndResetSelectionsWhenFiltering({
          primaryEntities,
          currentlyOpenNestedRows
        });
        return state.mergeIn(['display'], {
          selected: selectedEntities,
          pagesWithOpenNestedRows: openNestedRows
        });
      }
    case PagesActionTypes[getPagesActionName(contentType, 'TOGGLE_MLANG_GROUP_OPEN')]:
      {
        const {
          selectedEntities,
          openNestedRows
        } = toggleMultiLangGroupForEntityAndResetSelection({
          id: action.payload.pageId,
          primaryEntities,
          translationEntities,
          currentlySelectedEntities,
          currentlyOpenNestedRows,
          isFilteringOrSearching
        });
        return state.mergeIn(['display'], {
          selected: selectedEntities,
          pagesWithOpenNestedRows: openNestedRows
        });
      }
    case PagesActionTypes[getPagesActionName(contentType, 'UPDATE_FILTER_QUERY')]:
      return state.setIn(['display', 'filterQuery'], convertFilterQueryToMap(action.payload.query));
    case PagesActionTypes.CLEAR_PAGES_FILTER_QUERY:
      return state.setIn(['display', 'filterQuery'], new ImmutableMap());
    case PagesActionTypes[getPagesActionName(contentType, 'UPDATE_FILTER_QUERY_STATE')]:
    case FolderActionTypes.UPDATE_FOLDERS_FILTER_QUERY_STATE:
      return state.mergeIn(['display'], Object.assign({
        currentPage: 1
      }, action.payload));
    case PagesActionTypes[getPagesActionName(contentType, 'SET_EXCLUDED_PAGE_IDS')]:
      return state.setIn(['display', 'excludedPageIds'], action.payload.excludedPageIds);
    case PagesActionTypes[getPagesActionName(contentType, 'SET_BULK_UPDATE_TASK')]:
      return state.setIn(['display', 'bulkUpdateTask'], new BulkUpdateTask(action.payload));
    // Special case to clear bulkUpdateTask in Site Tree since Site Tree is
    // borrowing "LANDING_PAGES" pagesDisplay state, but doesn't refetch pages
    // which would dispatch RECEIVED_PAGES
    case SiteTreeActionTypes.MARK_STALE:
      return state.setIn(['display', 'bulkUpdateTask'], new BulkUpdateTask());
    default:
      return state;
  }
});