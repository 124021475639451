import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'hub-http/clients/apiClient';
import RequestStatuses from '../../shared/constants/RequestStatus';
import { getShouldFetchBlogUsageLimits } from '../../shared/selectors/authSelectors';
import BlogUsageApi from '../api/BlogUsage';
export const fetchBlogsUsage = createAsyncThunk('blogUsage/fetchBlogsUsage', () => BlogUsageApi.fetchBlogsUsage(), {
  condition: (_, {
    getState
  }) => {
    return getShouldFetchBlogUsageLimits(getState());
  }
});
export const fetchBlogPostsUsage = createAsyncThunk('blogUsage/fetchBlogPostsUsage', () => BlogUsageApi.fetchBlogPostsUsage(), {
  condition: (_, {
    getState
  }) => {
    return getShouldFetchBlogUsageLimits(getState());
  }
});
export const fetchMultiLangBlogUsageLimit = createAsyncThunk('blogUsage/fetchMultiLangBlogUsageLimit', () => http.get('blogs/v3/blog-posts/multilang-limit').then(({
  limit
}) => limit));
const initialState = {
  blogLimit: null,
  blogsUsed: 0,
  blogUsageStatus: RequestStatuses.UNINITIALIZED,
  blogPostsLimit: null,
  blogPostsUsed: 0,
  blogPostsUsageStatus: RequestStatuses.UNINITIALIZED,
  mLangBlogPostLimit: null,
  mLangBlogPostLimitStatus: RequestStatuses.UNINITIALIZED
};
const blogUsageSlice = createSlice({
  name: 'blogUsage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchBlogsUsage.pending, state => {
      state.blogUsageStatus = RequestStatuses.PENDING;
    });
    builder.addCase(fetchBlogsUsage.fulfilled, (state, {
      payload
    }) => {
      Object.assign(state, {
        blogUsageStatus: RequestStatuses.SUCCEEDED,
        blogLimit: payload.limit,
        blogsUsed: payload.quantityUsed
      });
    });
    builder.addCase(fetchBlogsUsage.rejected, state => {
      state.blogUsageStatus = RequestStatuses.FAILED;
    });
    builder.addCase(fetchBlogPostsUsage.pending, state => {
      state.blogPostsUsageStatus = RequestStatuses.PENDING;
    });
    builder.addCase(fetchBlogPostsUsage.fulfilled, (state, {
      payload
    }) => {
      Object.assign(state, {
        blogPostsUsageStatus: RequestStatuses.SUCCEEDED,
        blogPostsLimit: payload.limit,
        blogPostsUsed: payload.quantityUsed
      });
    });
    builder.addCase(fetchBlogPostsUsage.rejected, state => {
      state.blogPostsUsageStatus = RequestStatuses.FAILED;
    });
    builder.addCase(fetchMultiLangBlogUsageLimit.pending, state => {
      state.mLangBlogPostLimitStatus = RequestStatuses.PENDING;
    });
    builder.addCase(fetchMultiLangBlogUsageLimit.fulfilled, (state, {
      payload: limit
    }) => {
      // TODO: remove extra logic to set the limit to null when the
      // backend returns 99999 for portals without an mLang limit
      // once the BE team starts sending null.
      Object.assign(state, {
        mLangBlogPostLimitStatus: RequestStatuses.SUCCEEDED,
        mLangBlogPostLimit: limit && limit < 99999 ? limit : null
      });
    });
    builder.addCase(fetchMultiLangBlogUsageLimit.rejected, state => {
      state.mLangBlogPostLimitStatus = RequestStatuses.FAILED;
    });
  }
});
export default blogUsageSlice.reducer;