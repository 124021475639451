'use es6';

import { Set as ImmutableSet } from 'immutable';
import { createAction } from 'redux-actions';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import keyMirror from 'react-utils/keyMirror';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
export const ModalActionTypes = keyMirror({
  OPEN_MODAL: null,
  CLOSE_MODAL: null,
  OPEN_SOCIAL_COMPOSER: null,
  CLOSE_SOCIAL_COMPOSER: null,
  UPDATE_SOCIAL_COMPOSER_PARAMS: null,
  OPEN_CONTENT_MODAL: null,
  OPEN_CONTENT_PREVIEW_PANEL: null,
  CLOSE_CONTENT_PREVIEW_PANEL: null
});
export const openModal = createAction(ModalActionTypes.OPEN_MODAL, (modalName, relevantEntityIds = new ImmutableSet(), extraData) => ({
  modalName,
  relevantEntityIds,
  extraData
}));
export const openContentModal = createAction(ModalActionTypes.OPEN_CONTENT_MODAL, ({
  modalName,
  relevantEntities,
  parentCrmObject,
  extraData
}) => ({
  modalName,
  relevantEntities,
  parentCrmObject,
  extraData
}));
export const openContentPreviewPanel = createAction(ModalActionTypes.OPEN_CONTENT_PREVIEW_PANEL, ({
  crmObject,
  parentCrmObject,
  contentModel
}) => ({
  crmObject,
  parentCrmObject,
  contentModel
}));
export const closeContentPreviewPanel = createAction(ModalActionTypes.CLOSE_CONTENT_PREVIEW_PANEL);
export const closeModal = props => dispatch => {
  const clearRowSelections = props ? props.clearRowSelections : false;
  dispatch({
    type: ModalActionTypes.CLOSE_MODAL
  });
  if (clearRowSelections) {
    listingLibObserver.setBulkActionSelectionMode('none');
  }
  Object.keys(FloatingAlertStore.getAlerts()).forEach(k => {
    if (k.includes('-error') && !k.includes('-bulk')) {
      // clear any alerts that were not resolved and the modal was closed
      FloatingAlertStore.removeAlert(k);
    }
  });
};
export const openSocialComposer = createAction(ModalActionTypes.OPEN_SOCIAL_COMPOSER, params => params);
export const updateSocialComposerParams = createAction(ModalActionTypes.UPDATE_SOCIAL_COMPOSER_PARAMS, params => params);
export const closeSocialComposer = createAction(ModalActionTypes.CLOSE_SOCIAL_COMPOSER);