import { combineReducers } from '@reduxjs/toolkit';

// @ts-expect-error Untyped module
import authorsReducer from './authors';
// @ts-expect-error Untyped module
import authorsOptionsReducer from './authorsOptions';
// @ts-expect-error Untyped module
import authorsWithEmailOptionsReducer from './authorsWithEmailOptions';
// @ts-expect-error Untyped module
import blogPostsReducer from './blogPosts';
import blogUsageReducer from './blogUsage';
// @ts-expect-error Untyped module
import blogsReducer from './blogs';
// @ts-expect-error Untyped module
import combineAuthorsOptionsReducer from './combineAuthorsOptions';
// @ts-expect-error Untyped module
import combineTagsOptionsReducer from './combineTagsOptions';
// @ts-expect-error Untyped module
import commentsReducer from './comments';
// @ts-expect-error Untyped module
import tagsOptionsReducer from './tagsOptions';
// @ts-expect-error Untyped module
import tagsReducer from './tags';
// @ts-expect-error Untyped module
import assignableAuthorOptionsReducer from './assignableAuthorOptions';
// @ts-expect-error Untyped module
import assignableTagOptionsReducer from './assignableTagOptions';
import blogZeroStateInitializerReducer from './blogZeroStateInitializer';
export default combineReducers({
  authors: authorsReducer,
  authorsOptions: authorsOptionsReducer,
  authorsWithEmailOptions: authorsWithEmailOptionsReducer,
  combineAuthorsOptions: combineAuthorsOptionsReducer,
  assignableAuthorOptions: assignableAuthorOptionsReducer,
  blogs: blogsReducer,
  blogPosts: blogPostsReducer,
  blogUsage: blogUsageReducer,
  comments: commentsReducer,
  tagsOptions: tagsOptionsReducer,
  combineTagsOptions: combineTagsOptionsReducer,
  assignableTagOptions: assignableTagOptionsReducer,
  tags: tagsReducer,
  blogZeroStateInitializer: blogZeroStateInitializerReducer
});