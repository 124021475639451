'use es6';

import ProvideReferenceResolvers from 'reference-resolvers/ProvideReferenceResolvers';
import { BLOG_AUTHOR, BLOG_TAG, BLOGS, TEAM, USER, CAMPAIGN, BUSINESS_UNIT_DROPDOWN_OPTION } from 'reference-resolvers/constants/ReferenceObjectTypes';
import BlogReferenceResolver from 'reference-resolvers/resolvers/BlogReferenceResolver';
import BlogAuthorReferenceResolver from 'reference-resolvers/resolvers/BlogAuthorReferenceResolver';
import BlogTagReferenceResolver from 'reference-resolvers/resolvers/BlogTagReferenceResolver';
import TeamReferenceResolver from 'reference-resolvers/resolvers/TeamReferenceResolver';
import UserReferenceResolver from 'reference-resolvers/resolvers/UserReferenceResolver';
import CampaignReferenceResolver from 'reference-resolvers/resolvers/CampaignReferenceResolver';
import BusinessUnitDropdownReferenceResolver from 'reference-resolvers/resolvers/BusinessUnitDropdownReferenceResolver';
import { reportingReferenceResolvers } from 'reporting-data/public/reference-resolvers';
const resolvers = {
  [TEAM]: TeamReferenceResolver,
  [USER]: UserReferenceResolver,
  [BLOGS]: BlogReferenceResolver,
  [BLOG_AUTHOR]: BlogAuthorReferenceResolver,
  [BLOG_TAG]: BlogTagReferenceResolver,
  [CAMPAIGN]: CampaignReferenceResolver,
  [BUSINESS_UNIT_DROPDOWN_OPTION]: BusinessUnitDropdownReferenceResolver
};
export default ProvideReferenceResolvers(Object.assign({}, reportingReferenceResolvers, resolvers), ({
  children
}) => children);