import { PublicAccessRuleTypes } from './PublicAccessRuleTypes';
import { AudienceAccessTypes } from './AudienceAccessTypes';
export const AccessControlTypes = {
  PUBLIC: 'public',
  PASSWORD: 'password',
  SSO: 'sso',
  SSO_WITH_LISTS: 'ssoWithLists',
  LIST_REGISTRATION: 'listRegistration',
  SELF_REGISTRATION: 'selfRegistration',
  ACCESS_GROUP_MEMBERSHIP: 'accessGroupMembership',
  SSO_WITH_ACCESS_GROUPS: 'ssoWithAccessGroups'
};
export const ListBasedAccessControlTypes = [AccessControlTypes.SSO_WITH_LISTS, AccessControlTypes.LIST_REGISTRATION];
export const RegistrationEmailAccessControlTypes = [AccessControlTypes.LIST_REGISTRATION, AccessControlTypes.ACCESS_GROUP_MEMBERSHIP];
export const AccessGroupsBasedAccessControlTypes = [AccessControlTypes.SSO_WITH_ACCESS_GROUPS, AccessControlTypes.ACCESS_GROUP_MEMBERSHIP];
export const IdsBasedAccessControlTypes = [...ListBasedAccessControlTypes, ...AccessGroupsBasedAccessControlTypes];
export const AccessControlToPublicAccessRuleType = {
  [AccessControlTypes.PUBLIC]: PublicAccessRuleTypes.PUBLIC,
  [AccessControlTypes.PASSWORD]: PublicAccessRuleTypes.PASSWORD,
  [AccessControlTypes.SSO]: PublicAccessRuleTypes.SSO_LOGIN,
  [AccessControlTypes.SSO_WITH_LISTS]: PublicAccessRuleTypes.LIST_MEMBERSHIP,
  [AccessControlTypes.LIST_REGISTRATION]: PublicAccessRuleTypes.LIST_MEMBERSHIP,
  [AccessControlTypes.SELF_REGISTRATION]: PublicAccessRuleTypes.MEMBERSHIP_LOGIN,
  [AccessControlTypes.ACCESS_GROUP_MEMBERSHIP]: PublicAccessRuleTypes.ACCESS_GROUP_MEMBERSHIP,
  // Note: intentional 2:1 mapping; rely on SSO enabled site setting to differentiate
  [AccessControlTypes.SSO_WITH_ACCESS_GROUPS]: PublicAccessRuleTypes.ACCESS_GROUP_MEMBERSHIP
};
export const PublicAccessRuleTypeToAccessControl = {
  [PublicAccessRuleTypes.PUBLIC]: AccessControlTypes.PUBLIC,
  [PublicAccessRuleTypes.PASSWORD]: AccessControlTypes.PASSWORD,
  [PublicAccessRuleTypes.SSO_LOGIN]: AccessControlTypes.SSO,
  [PublicAccessRuleTypes.SSO_WITH_LIST_MEMBERSHIP]: AccessControlTypes.SSO_WITH_LISTS,
  [PublicAccessRuleTypes.LIST_MEMBERSHIP]: AccessControlTypes.LIST_REGISTRATION,
  [PublicAccessRuleTypes.MEMBERSHIP_LOGIN]: AccessControlTypes.SELF_REGISTRATION,
  [PublicAccessRuleTypes.ACCESS_GROUP_MEMBERSHIP]: AccessControlTypes.ACCESS_GROUP_MEMBERSHIP
};

// TODO: Create new or reuse existing AudienceAccessType for Access Groups
export const AccessControlToAudienceAccessType = {
  [AccessControlTypes.PUBLIC]: AudienceAccessTypes.PUBLIC,
  [AccessControlTypes.PASSWORD]: AudienceAccessTypes.PRIVATE_PASSWORD_REQUIRED,
  [AccessControlTypes.SSO]: AudienceAccessTypes.PRIVATE_SSO_REQUIRED,
  [AccessControlTypes.LIST_REGISTRATION]: AudienceAccessTypes.PRIVATE_REGISTRATION_REQUIRED,
  [AccessControlTypes.SELF_REGISTRATION]: AudienceAccessTypes.PRIVATE_SELF_REGISTRATION_REQUIRED
};
export const AudienceAccessTypeToAccessControl = {
  [AudienceAccessTypes.PUBLIC]: AccessControlTypes.PUBLIC,
  [AudienceAccessTypes.PRIVATE_PASSWORD_REQUIRED]: AccessControlTypes.PASSWORD,
  [AudienceAccessTypes.PRIVATE_SSO_REQUIRED]: AccessControlTypes.SSO,
  [AudienceAccessTypes.PRIVATE_REGISTRATION_REQUIRED]: AccessControlTypes.ACCESS_GROUP_MEMBERSHIP,
  [AudienceAccessTypes.PRIVATE_SELF_REGISTRATION_REQUIRED]: AccessControlTypes.SELF_REGISTRATION
};