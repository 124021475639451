'use es6';

import keyMirror from 'react-utils/keyMirror';
export default keyMirror({
  BLOG_TAG_MODAL_EDIT: null,
  BLOG_TAG_MODAL_DELETE: null,
  BLOG_TAG_MODAL_COMBINE: null,
  BLOG_TAG_MODAL_CREATE: null,
  BLOG_TAG_MODAL_CREATE_MULTI_LANGUAGE_VARIATION: null,
  BLOG_TAG_MODAL_SET_TAG_LANGUAGE_BEFORE_CREATE_VARIATION: null,
  BLOG_TAG_MODAL_SET_TAG_LANGUAGE_BEFORE_ADD_TO_GROUP: null,
  BLOG_TAG_MODAL_CONVERT_TO_MASTER: null,
  BLOG_TAG_MODAL_REMOVE_FROM_LANGUAGE_GROUP: null,
  BLOG_TAG_MODAL_ADD_TO_LANGUAGE_GROUP: null,
  BLOG_TAG_MODAL_SWITCH_LANGUAGE_MASTER: null,
  BLOG_TAG_MODAL_DELETE_GROUP: null
});