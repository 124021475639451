import { createSlice } from '@reduxjs/toolkit';
import { EQUAL } from 'customer-data-filters/converters/contactSearch/FilterContactSearchOperatorTypes';
import AppDisplayModes from '../constants/AppDisplayModes';
import { ContentNameKeysToCrmPropertyNames } from '../constants/contentProperties';
const initialState = {
  currentFilterOperators: [],
  currentFiltersSource: null,
  quickFilters: [],
  query: ''
};
const appDisplayModeSyncedFiltersSlice = createSlice({
  name: 'appDisplayModeSyncedFilters',
  initialState,
  reducers: {
    saveCurrentFiltersStateForAppDisplayModeSync(state, {
      payload
    }) {
      const {
        filterGroups,
        quickFilters,
        query,
        filtersSource,
        contentType
      } = payload;
      const currentFilterOperators = [...(filterGroups.length > 0 ? filterGroups[0].filters : [])];
      if (filtersSource === AppDisplayModes.TABLE && contentType) {
        // This is a hidden filter in table mode, so needs to be manually added here
        currentFilterOperators.push({
          property: ContentNameKeysToCrmPropertyNames.CONTENT_TYPE,
          operator: EQUAL,
          value: contentType
        });
      }
      Object.assign(state, {
        currentFilterOperators,
        currentFiltersSource: filtersSource,
        quickFilters,
        query
      });
    }
  }
});
export default appDisplayModeSyncedFiltersSlice.reducer;
export const {
  saveCurrentFiltersStateForAppDisplayModeSync
} = appDisplayModeSyncedFiltersSlice.actions;