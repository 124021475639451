'use es6';

import { createSelector } from 'reselect';
import { ALL_FILTER } from '../../shared/constants/DefaultFilterParams';
const getTagsDisplayState = state => state.blog.tags.display;
export const getSelectedTags = createSelector([getTagsDisplayState], display => display.selected);
export const getTagsWithOpenNestedRows = createSelector(getTagsDisplayState, display => display.tagsWithOpenNestedRows);
export const getBlogTagFiltersData = createSelector([getTagsDisplayState], ({
  currentPage,
  selectedSort,
  selectedSortDir,
  tagStateFilter,
  blogFilter,
  search,
  rowsPerPage
}) => ({
  currentPage,
  selectedSort,
  selectedSortDir,
  tagStateFilter,
  blogFilter,
  search,
  rowsPerPage
}));
export const getBlogTagsFilterQueryFromState = createSelector([getBlogTagFiltersData], ({
  currentPage,
  selectedSort,
  selectedSortDir,
  tagStateFilter,
  blogFilter,
  search,
  rowsPerPage
}) => ({
  blogFilter,
  page: currentPage,
  sort: selectedSort,
  sortDir: selectedSortDir,
  tagState: tagStateFilter,
  search,
  rowsPerPage
}));
export const getIsFilteringOrSearchingTags = createSelector([getTagsDisplayState], ({
  search,
  tagStateFilter,
  blogFilter
}) => search !== '' || tagStateFilter !== ALL_FILTER || blogFilter !== ALL_FILTER);
export const getBlogTagsBlogFilter = createSelector([getBlogTagFiltersData], ({
  blogFilter
}) => blogFilter);