'use es6';

import { OrderedMap, Record } from 'immutable';
import RequestStatus from '../../shared/constants/RequestStatus';
import CommentsDisplay from './CommentsDisplay';
const defaults = {
  data: new OrderedMap(),
  stale: false,
  status: RequestStatus.UNINITIALIZED,
  total: null,
  postsStatus: RequestStatus.UNINITIALIZED,
  display: new CommentsDisplay()
};
export default class Comments extends Record(defaults) {
  setContentTitlesAndLanguages(posts) {
    const comments = this.data.map(comment => {
      const parentBlog = posts.getIn([comment.contentId, 'parentBlog']);
      const language = posts.getIn([comment.contentId, 'language']) || parentBlog && parentBlog.language;
      return comment.merge({
        contentTitle: posts.getIn([comment.contentId, 'name']),
        language
      });
    });
    return this.merge({
      postsStatus: RequestStatus.SUCCEEDED,
      data: comments
    });
  }
}