'use es6';

import { BlogPostsActionTypes } from '../actions/BlogPostsActions';
import BlogPosts from '../models/BlogPosts';
import RequestStatus from '../../shared/constants/RequestStatus';
export default ((state = new BlogPosts(), action) => {
  switch (action.type) {
    case BlogPostsActionTypes.REQUESTED_BLOG_POSTS_FOR_START_STATE:
      return state.merge({
        stale: false,
        startStateFetchStatus: RequestStatus.PENDING
      });
    case BlogPostsActionTypes.RECEIVED_BLOG_POSTS_FOR_START_STATE:
      return state.merge({
        startStateFetchStatus: RequestStatus.SUCCEEDED,
        startStateTotal: action.payload.total
      });
    case BlogPostsActionTypes.REQUESTED_BLOG_POSTS_FOR_START_STATE_FAILED:
      return state.set('startStateFetchStatus', RequestStatus.FAILED);
    default:
      return state;
  }
});