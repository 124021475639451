'use es6';

import { OrderedMap, Record } from 'immutable';
import RequestStatus from '../constants/RequestStatus';
const defaults = {
  domainsList: new OrderedMap(),
  domainsLanguages: new OrderedMap(),
  status: RequestStatus.UNINITIALIZED,
  total: null
};
export default class Domains extends Record(defaults) {}