'use es6';

import { Record } from 'immutable';
import Folder from './Folder';
import RequestStatus from '../../shared/constants/RequestStatus';
const defaults = {
  rootFolder: new Folder({}),
  stale: false,
  status: RequestStatus.UNINITIALIZED,
  total: 0
};
export default class Folders extends Record(defaults) {}