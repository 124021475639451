'use es6';

import { createAction } from 'redux-actions';
import keyMirror from 'react-utils/keyMirror';
import { findBlog, getBlogsData } from '../selectors/blogSelectors';
import { ALL_FILTER } from '../../shared/constants/DefaultFilterParams';
export const CommentActionTypes = keyMirror({
  COMMENTS_FILTERS_CHANGED: null,
  RESET_COMMENTS: null,
  TOGGLE_COMMENT_SELECT: null,
  TOGGLE_COMMENTS_SELECT_ALL: null,
  REQUESTED_COMMENTS: null,
  RECEIVED_COMMENTS: null,
  REQUESTED_COMMENTS_FAILED: null,
  MARK_COMMENTS_STALE: null,
  // Comment actions
  REQUESTED_APPROVE_COMMENTS: null,
  REQUESTED_DELETE_COMMENTS: null,
  REQUESTED_MARK_COMMENTS_AS_SPAM: null,
  REQUESTED_REPLY_TO_COMMENT: null,
  REQUESTED_REJECT_COMMENT: null,
  REQUESTED_BLOG_POSTS_FOR_COMMENTS: null,
  RECEIVED_BLOG_POSTS_FOR_COMMENTS: null,
  REQUESTED_BLOG_POSTS_FOR_COMMENTS_FAILED: null,
  // filters
  UPDATE_BLOG_COMMENTS_FILTER_QUERY_STATE: null,
  COMMENT_MODAL_CONTAINER_UNMOUNTED: null,
  COMMENTS_CONTAINER_UNMOUNTED: null
});
export const unmountCommentsContainer = createAction(CommentActionTypes.COMMENTS_CONTAINER_UNMOUNTED);
export const unmountCommentsModalsContainer = createAction(CommentActionTypes.COMMENT_MODAL_CONTAINER_UNMOUNTED);
export const resetComments = createAction(CommentActionTypes.RESET_COMMENTS);
const _commentsFiltersChanged = createAction(CommentActionTypes.COMMENTS_FILTERS_CHANGED, ({
  query
}) => ({
  query
}));
export const commentsFiltersChanged = ({
  query
}) => (dispatch, getState) => {
  const newQuery = Object.assign({}, query);
  const state = getState();
  const blogs = getBlogsData(state);
  const blogId = query.blog;
  if (blogId === ALL_FILTER) {
    delete newQuery.blog;
  }
  const blog = findBlog(blogs, blogId);
  if (blog) {
    if (blog.hasTranslations()) {
      newQuery.blog = [blogId, ...blog.get('translations').valueSeq().map(t => t.get('id')).toArray()];
    } else {
      newQuery.blog = [blogId];
    }
  }
  dispatch(_commentsFiltersChanged({
    query: newQuery
  }));
};
export const requestComments = createAction(CommentActionTypes.REQUESTED_COMMENTS);
export const receiveComments = createAction(CommentActionTypes.RECEIVED_COMMENTS, ({
  comments,
  total
}) => ({
  comments,
  total
}));
export const receiveCommentsFailure = createAction(CommentActionTypes.REQUESTED_COMMENTS_FAILED, error => ({
  error
}));
export const requestBlogPostsForComments = createAction(CommentActionTypes.REQUESTED_BLOG_POSTS_FOR_COMMENTS, comments => ({
  comments
}));
export const receiveBlogPostsForComments = createAction(CommentActionTypes.RECEIVED_BLOG_POSTS_FOR_COMMENTS, ({
  posts
}) => ({
  posts
}));
export const receiveBlogPostsForCommentsFailure = createAction(CommentActionTypes.REQUESTED_BLOG_POSTS_FOR_COMMENTS_FAILED, error => ({
  error
}));
export const toggleSelectComment = createAction(CommentActionTypes.TOGGLE_COMMENT_SELECT, commentId => ({
  commentId
}));
export const toggleSelectAllComments = createAction(CommentActionTypes.TOGGLE_COMMENTS_SELECT_ALL);
export const markCommentsStale = createAction(CommentActionTypes.MARK_COMMENTS_STALE);

// Comment actions

export const approveComments = createAction(CommentActionTypes.REQUESTED_APPROVE_COMMENTS, comments => ({
  comments
}));
export const deleteComments = createAction(CommentActionTypes.REQUESTED_DELETE_COMMENTS, comments => ({
  comments
}));
export const markCommentsAsSpam = createAction(CommentActionTypes.REQUESTED_MARK_COMMENTS_AS_SPAM, comments => ({
  comments
}));
export const replyToComment = createAction(CommentActionTypes.REQUESTED_REPLY_TO_COMMENT, data => ({
  data
}));
export const rejectComment = createAction(CommentActionTypes.REQUESTED_REJECT_COMMENT, comment => ({
  comment
}));
const _updateBlogCommentsFilterQueryState = createAction(CommentActionTypes.UPDATE_BLOG_COMMENTS_FILTER_QUERY_STATE, filterOptions => Object.assign({}, filterOptions));
export const updateBlogCommentsFilterQueryState = filterOptions => dispatch => {
  dispatch(_updateBlogCommentsFilterQueryState(filterOptions));
  dispatch(markCommentsStale());
};