'use es6';

import { OrderedMap } from 'immutable';
import { map } from 'rxjs/operators/map';
import http from 'hub-http/clients/apiClient';
import Domain from '../models/Domain';
import { getEarlyRequesterResultObservable } from '../utils/getEarlyRequesterResultPromise';
import { ALL_BUSINESS_UNITS_FILTER } from '../constants/DefaultFilterParams';
const URI = 'cos-domains/v1';
export const parse = domains => domains.reduce((acc, domainObject) => {
  const {
    domain,
    isStagingDomain,
    isUsedForAnyContentType
  } = domainObject;
  if (!isStagingDomain && isUsedForAnyContentType) {
    return acc.set(domain, new Domain(domainObject));
  }
  return acc;
}, new OrderedMap());
export default class DomainsApi {
  static fetchAllDomains(buId) {
    if (buId === undefined || buId === ALL_BUSINESS_UNITS_FILTER) {
      const request = getEarlyRequesterResultObservable({
        name: 'initial-domains-fetch',
        retryRequest: () => http.get(`${URI}/domains`)
      });
      return request.pipe(map(response => parse(response.objects)));
    } else {
      return http.get(`${URI}/domain-management/dashboard/bu/${buId}`, {
        query: {
          includeInternal: false
        }
      }).pipe(map(response => parse(response.domains)));
    }
  }
  static fetchPermittedDomains() {
    return http.get(`${URI}/permitted-domains/for-blog-settings`).then(response => response && response.objects);
  }
}