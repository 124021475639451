'use es6';

import { isOnSitePageRoute, isOnLandingPageRoute, isOnBlogPageRoute } from './urls';
import { getFullUrl } from 'hubspot-url-utils';

// Two seconds after window load, fetch a small script that in turn, appends
// abunch of <link rel="prefetch"> tags to the page. The idea is to use the
// idle time of the dashboard app to get a jump start on loading the heavy
// assets of the editor. The prefetches are given the lowest priority by the
// browser, so it hopefully doesn't interfere with the dashboard app.
// See https://developer.mozilla.org/en-US/docs/Web/HTTP/Link_prefetching_FAQ
export const setupDeferredPrefetchingOfEditorAssets = location => {
  window.onload = () => {
    setTimeout(() => {
      let prefetcherPath;
      if (isOnSitePageRoute(location) || isOnLandingPageRoute(location)) {
        prefetcherPath = 'content/editor/prefetcher.js';
      } else if (isOnBlogPageRoute(location)) {
        prefetcherPath = 'blog/editor/prefetcher.js';
      } else {
        return;
      }
      // Doing this to avoid relative domains, don't want to get 404s
      // when developing locally
      const domain = getFullUrl('app');
      const script = document.createElement('script');
      script.src = `${domain}/${prefetcherPath}`;
      document.head.appendChild(script);
    }, 2000);
  };
};