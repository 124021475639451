"use es6";

/**
 * An error thrown when an action is invalid because the object has been
 * unsubscribed.
 *
 * @see {@link Subject}
 * @see {@link BehaviorSubject}
 *
 * @class ObjectUnsubscribedError
 */
export class ObjectUnsubscribedError extends Error {
  constructor() {
    const err = super('object unsubscribed');
    this.name = err.name = 'ObjectUnsubscribedError';
    this.stack = err.stack;
    this.message = err.message;
  }
}