'use es6';

import { List, OrderedMap, Set as ImmutableSet } from 'immutable';
import { map } from 'rxjs/operators/map';
import httpRxjs from 'hub-http-rxjs/clients/apiClient';
import http from 'hub-http/clients/apiClient';
import Blog from '../models/Blog';
import { getEarlyRequesterResultObservable } from '../../shared/utils/getEarlyRequesterResultPromise';
import { ALL_BUSINESS_UNITS_FILTER, ALL_BUSINESS_UNITS_FILTER_FROM_GLOBAL_NAV } from '../../shared/constants/DefaultFilterParams';
const URI = 'blog-settings/v1/blogs';
function getURI(id) {
  let result = URI;
  if (id) {
    result = `${result}/${id}`;
  }
  return result;
}
export function parse(data) {
  return data.objects.reduce((acc, blogItem) => {
    return acc.push(Blog.from(blogItem));
  }, new List());
}
function parseIntoOrderedMap(data) {
  return data.objects.reduce((acc, blogItem) => acc.set(blogItem.id, Blog.from(blogItem)), new OrderedMap());
}
const DEFAULT_OPTIONS = {
  offset: 0,
  limit: 300,
  order: 'name',
  property: ['absoluteUrl', 'id', 'portalId', 'name', 'slug', 'updated', 'created', 'itemTemplateIsShared', 'itemLayoutId', 'itemTemplatePath', 'language', 'listingLayoutId', 'listingLayoutPath', 'listingPageId', 'listingTemplatePath', 'subscriptionListsByType', 'resolvedDomain', 'translations', 'publicAccessRules', 'publicAccessRulesEnabled'],
  translated_from_id__is_null: ' '
};
export default class BlogsApi {
  static fetchBlogs(opts = {}) {
    const query = Object.assign({}, DEFAULT_OPTIONS);
    const {
      businessUnitId
    } = opts;
    const request = businessUnitId === undefined || businessUnitId === ALL_BUSINESS_UNITS_FILTER || businessUnitId === ALL_BUSINESS_UNITS_FILTER_FROM_GLOBAL_NAV ? getEarlyRequesterResultObservable({
      name: 'initial-blogs-fetch',
      retryRequest: () => http.get(getURI(), {
        query
      })
    }) : httpRxjs.get(`${URI}/business-unit/${businessUnitId}`, {
      query
    });
    return request.pipe(map(parse));
  }
  static fetchBlogsForTags(tags) {
    let uniqueBlogIds = new ImmutableSet();
    tags.forEach(tag => {
      uniqueBlogIds = uniqueBlogIds.concat(tag.associatedBlogIds);
      tag.translations.forEach(translation => {
        uniqueBlogIds = uniqueBlogIds.concat(translation.associatedBlogIds);
      });
    });
    return httpRxjs.get(URI, {
      query: {
        id__in: uniqueBlogIds.reduce((acc, id) => {
          acc.push(id);
          return acc;
        }, []),
        limit: uniqueBlogIds.size,
        property: ['id', 'name', 'translations', 'language']
      }
    }).pipe(map(response => {
      return {
        blogs: parseIntoOrderedMap(response)
      };
    }));
  }
  static createBlog({
    name,
    slug,
    htmlTitle,
    domain,
    language,
    itemTemplatePath,
    listingTemplatePath
  }) {
    return http.post(URI, {
      data: {
        name,
        slug,
        htmlTitle,
        domain,
        language,
        itemTemplatePath,
        listingTemplatePath
      }
    });
  }
  static updateBlog(blogId, data) {
    return http.put(`${URI}/${blogId}`, {
      data
    });
  }
  static createLanguageVariation({
    masterLanguage,
    contentGroupId,
    language
  }) {
    return http.post(`blog-settings/v1/blogs/${contentGroupId}/create-language-variation`, {
      data: {
        masterLanguage,
        language
      }
    });
  }
}