'use es6';

import { Set as ImmutableSet } from 'immutable';
import { AuthorActionTypes } from '../actions/AuthorActions';
import Authors from '../models/Authors';
import RequestStatus from '../../shared/constants/RequestStatus';
import { ModalActionTypes } from '../../shared/actions/ModalActions';
import { toggleSelectEntityAndExpandMultiLangGroup, toggleSelectAllEntitiesAndExpandMultiLangGroups, toggleMultiLangGroupForEntityAndResetSelection } from '../../shared/utils/displaySelectedHelpers';
import { _getTranslatedAuthors } from '../selectors/authorSelectors';
export default ((state = new Authors(), action) => {
  const primaryEntities = state.data;
  const translationEntities = _getTranslatedAuthors(primaryEntities);
  const currentlyOpenNestedRows = state.getIn(['display', 'authorsWithOpenNestedRows']);
  const currentlySelectedEntities = state.getIn(['display', 'selected']);
  switch (action.type) {
    case AuthorActionTypes.REQUESTED_AUTHORS:
      return state.merge({
        stale: false,
        status: RequestStatus.PENDING
      });
    case AuthorActionTypes.RECEIVED_AUTHORS:
      {
        return state.merge({
          data: action.payload.authors,
          status: RequestStatus.SUCCEEDED,
          total: action.payload.total
        });
      }
    case AuthorActionTypes.REQUESTED_AUTHORS_FAILED:
      return state.set('status', RequestStatus.FAILED);
    case AuthorActionTypes.MARK_AUTHORS_STALE:
      return state.set('stale', true);
    case AuthorActionTypes.RESET_AUTHORS:
      return new Authors();

    // Authors Display cases
    case AuthorActionTypes.TOGGLE_AUTHOR_SELECT:
      {
        const {
          selectedEntities,
          openNestedRows
        } = toggleSelectEntityAndExpandMultiLangGroup({
          id: action.payload.authorId,
          primaryEntities,
          translationEntities,
          currentlySelectedEntities,
          currentlyOpenNestedRows
        });
        return state.mergeIn(['display'], {
          selected: selectedEntities,
          authorsWithOpenNestedRows: openNestedRows
        });
      }
    case AuthorActionTypes.TOGGLE_AUTHORS_SELECT_ALL:
      {
        const {
          selectedEntities,
          openNestedRows
        } = toggleSelectAllEntitiesAndExpandMultiLangGroups({
          primaryEntities,
          translationEntities,
          currentlySelectedEntities,
          currentlyOpenNestedRows
        });
        return state.mergeIn(['display'], {
          selected: selectedEntities,
          authorsWithOpenNestedRows: openNestedRows
        });
      }
    case AuthorActionTypes.TOGGLE_AUTHOR_MLANG_GROUP_OPEN:
      {
        const {
          selectedEntities,
          openNestedRows
        } = toggleMultiLangGroupForEntityAndResetSelection({
          id: action.payload.authorId,
          primaryEntities,
          translationEntities,
          currentlySelectedEntities,
          currentlyOpenNestedRows
        });
        return state.mergeIn(['display'], {
          selected: selectedEntities,
          authorsWithOpenNestedRows: openNestedRows
        });
      }
    case AuthorActionTypes.AUTHORS_FILTERS_CHANGED:
      return state.setIn(['display', 'selected'], new ImmutableSet());
    case AuthorActionTypes.UPDATE_BLOG_AUTHORS_FILTER_QUERY_STATE:
      return state.mergeIn(['display'], Object.assign({
        currentPage: 1
      }, action.payload));
    case AuthorActionTypes.REQUESTED_UPDATE_AUTHOR_FAILED:
    case AuthorActionTypes.REQUESTED_CREATE_AUTHOR_FAILED:
      return state.setIn(['display', 'blogAuthorModalErrorType'], action.payload.error.responseJSON.errorType);
    case ModalActionTypes.CLOSE_MODAL:
      return state.setIn(['display', 'blogAuthorModalErrorType'], null);
    default:
      return state;
  }
});