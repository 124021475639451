import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RequestStatuses from '../constants/RequestStatus';
import UsersApi from '../api/Users';
export const fetchUsers = createAsyncThunk('users/fetchUsers', () => UsersApi.fetchUsers());
const initialState = {
  users: [],
  status: RequestStatuses.UNINITIALIZED
};
const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchUsers.pending, state => {
      state.status = RequestStatuses.PENDING;
    });
    builder.addCase(fetchUsers.fulfilled, (state, {
      payload
    }) => {
      state.status = RequestStatuses.SUCCEEDED;
      state.users = payload;
    });
    builder.addCase(fetchUsers.rejected, state => {
      state.status = RequestStatuses.FAILED;
    });
  }
});
export default usersSlice.reducer;