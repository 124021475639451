'use es6';

import { Map as ImmutableMap, Record, Set as ImmutableSet } from 'immutable';
const DEFAULTS = {
  openModalName: null,
  // IDs of data needed for modal to perform an action (that of a page, post, tag, etc.)
  relevantEntityIds: ImmutableSet(),
  socialComposerOpen: false,
  socialComposerParams: ImmutableMap(),
  relevantEntities: ImmutableSet(),
  extraData: ImmutableMap(),
  // Required for preview panel
  previewPanelCrmObject: null,
  previewPanelParentCrmObject: null,
  previewPanelOpen: false,
  previewPanelContentModel: null
};
export default class ModalsDisplay extends Record(DEFAULTS) {}