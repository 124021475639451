import { createSelector } from 'reselect';
import { getPropertyFilterValueFromFilterOperators } from '../../frameworkListing/utils/filterPropertyUtils';
import { ContentNameKeysToCrmPropertyNames } from '../constants/contentProperties';
const getAppDisplayModeSyncedFiltersData = state => state.appDisplayModeSyncedFilters;
export const getAppDisplayModeSyncedFilterOperators = createSelector([getAppDisplayModeSyncedFiltersData], syncedfiltersData => syncedfiltersData.currentFilterOperators);
export const getAppDisplayModeSyncedQuickFilters = createSelector([getAppDisplayModeSyncedFiltersData], syncedfiltersData => syncedfiltersData.quickFilters);
export const getAppDisplayModeSearchQuery = createSelector([getAppDisplayModeSyncedFiltersData], syncedFiltersData => syncedFiltersData.query || '');
export const getAppDisplayModeSyncedAllFilters = createSelector([getAppDisplayModeSyncedFilterOperators, getAppDisplayModeSyncedQuickFilters], (filterOperators, quickFilters) => [...filterOperators, ...quickFilters]);
export const getAppDisplayModeSyncedFiltersSource = createSelector([getAppDisplayModeSyncedFiltersData], syncedfiltersData => syncedfiltersData.currentFiltersSource);
export const getAppDisplayModeSyncedContentGroupIdFilterValue = createSelector([getAppDisplayModeSyncedFilterOperators, getAppDisplayModeSyncedQuickFilters], (filterOperators, quickFilters) => filterOperators ? getPropertyFilterValueFromFilterOperators([...filterOperators, ...quickFilters], ContentNameKeysToCrmPropertyNames.CONTENT_GROUP_ID) : undefined);
export const getSiteTreeContentTypeFilterValue = createSelector([getAppDisplayModeSyncedFilterOperators], syncedFilters => {
  const contentTypeFilter = syncedFilters.find(syncedFilter => syncedFilter.property === ContentNameKeysToCrmPropertyNames.CONTENT_TYPE);
  return contentTypeFilter && contentTypeFilter.operator === 'IN' ? contentTypeFilter.values : null;
});
export const getBusinessUnitsFilterValue = createSelector([getAppDisplayModeSyncedAllFilters], syncedFilters => {
  const businessUnitsFilter = syncedFilters.find(syncedFilter => syncedFilter.property === ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT);
  return businessUnitsFilter && businessUnitsFilter.operator === 'IN' ? businessUnitsFilter.values : -1;
});