import http from 'hub-http/clients/apiClient';
const URI = 'app-users/v1/teams';
export default class TeamsApi {
  static fetchAllTeams() {
    return http.get(`${URI}/hierarchy`);
  }
  static fetchTeamsForUser() {
    return http.get(`${URI}/user/all`, {
      query: {
        includeChildTeamMembers: true,
        includeHierarchy: true
      }
    });
  }
}