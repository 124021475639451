'use es6';

import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { BLOG_AUTHOR, BLOG_TAG, TEAM, USER, CAMPAIGN, BUSINESS_UNIT_DROPDOWN_OPTION } from 'reference-resolvers/constants/ReferenceObjectTypes';
import Filter from '../models/Filter';
import { ContentNameKeysToCrmPropertyNames } from './contentProperties';
export const FilterTypes = {
  AUDIENCE_ACCESS: 'audienceAccess',
  CAMPAIGN: 'campaignFilter',
  TEAM: 'teamFilter',
  TEST_STATUS: 'testStatusFilter',
  VARIATION: 'variationFilter',
  AUTHOR: 'authorFilter',
  TAG: 'tagFilter',
  LANGUAGE: 'languageFilter',
  SEARCH: 'search',
  CONTENT_TYPE: 'contentTypeFilter',
  CREATED_BY: 'createdByFilter',
  CREATED_DATE: 'createdDateFilter',
  DOMAIN: 'domainFilter',
  PUBLISH_DATE: 'publishDateFilter',
  RESOLVED_LANGUAGE: 'resolvedLanguageFilter',
  UPDATED_BY: 'updatedByFilter',
  UPDATED_DATE: 'updatedDateFilter',
  STATE: 'publishStatusFilter',
  ARCHIVED: 'archivedFilter',
  CONTENT_ID: 'contentIdFilter',
  BLOG: 'blogFilter',
  THEME_PATH: 'themePathFilter',
  APPROVAL_STATUS: 'approvalStatusFilter',
  NAME: 'nameFilter',
  BUSINESS_UNIT: 'businessUnit'
};
export const FilterConfigurationsByProperty = ImmutableMap({
  [ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS]: new Filter({
    filterType: FilterTypes.AUDIENCE_ACCESS,
    propertyName: ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS
  }),
  [ContentNameKeysToCrmPropertyNames.BLOG_AUTHOR]: new Filter({
    filterType: FilterTypes.AUTHOR,
    propertyName: ContentNameKeysToCrmPropertyNames.BLOG_AUTHOR,
    referencedObjectType: BLOG_AUTHOR,
    usageTrackerActionName: 'author',
    needsDescriptorLabel: true
  }),
  [ContentNameKeysToCrmPropertyNames.CAMPAIGN]: new Filter({
    filterType: FilterTypes.CAMPAIGN,
    propertyName: ContentNameKeysToCrmPropertyNames.CAMPAIGN,
    referencedObjectType: CAMPAIGN,
    usageTrackerActionName: 'campaign'
  }),
  [ContentNameKeysToCrmPropertyNames.CONTENT_ID]: new Filter({
    filterType: FilterTypes.CONTENT_ID,
    propertyName: ContentNameKeysToCrmPropertyNames.CONTENT_ID,
    needsTranslatedLabel: false
  }),
  [ContentNameKeysToCrmPropertyNames.CONTENT_TYPE]: new Filter({
    filterType: FilterTypes.CONTENT_TYPE,
    propertyName: ContentNameKeysToCrmPropertyNames.CONTENT_TYPE
  }),
  [ContentNameKeysToCrmPropertyNames.CREATED_BY]: new Filter({
    filterType: FilterTypes.CREATED_BY,
    propertyName: ContentNameKeysToCrmPropertyNames.CREATED_BY,
    referencedObjectType: USER,
    needsDescriptorLabel: true
  }),
  [ContentNameKeysToCrmPropertyNames.CREATED_DATE]: new Filter({
    filterType: FilterTypes.CREATED_DATE,
    propertyName: ContentNameKeysToCrmPropertyNames.CREATED_DATE,
    needsDescriptorLabel: true
  }),
  [ContentNameKeysToCrmPropertyNames.DOMAIN]: new Filter({
    filterType: FilterTypes.DOMAIN,
    propertyName: ContentNameKeysToCrmPropertyNames.DOMAIN,
    isMultiSelect: false,
    usageTrackerActionName: 'domain'
  }),
  [ContentNameKeysToCrmPropertyNames.LANGUAGE]: new Filter({
    filterType: FilterTypes.LANGUAGE,
    propertyName: ContentNameKeysToCrmPropertyNames.LANGUAGE
  }),
  [ContentNameKeysToCrmPropertyNames.PUBLISH_DATE]: new Filter({
    filterType: FilterTypes.PUBLISH_DATE,
    propertyName: ContentNameKeysToCrmPropertyNames.PUBLISH_DATE,
    needsDescriptorLabel: true
  }),
  [ContentNameKeysToCrmPropertyNames.RESOLVED_LANGUAGE]: new Filter({
    filterType: FilterTypes.RESOLVED_LANGUAGE,
    propertyName: ContentNameKeysToCrmPropertyNames.RESOLVED_LANGUAGE
  }),
  [ContentNameKeysToCrmPropertyNames.STATE]: new Filter({
    filterType: FilterTypes.STATE,
    propertyName: ContentNameKeysToCrmPropertyNames.STATE,
    isMultiSelect: false,
    usageTrackerActionName: 'state'
  }),
  [ContentNameKeysToCrmPropertyNames.TAG]: new Filter({
    filterType: FilterTypes.TAG,
    propertyName: ContentNameKeysToCrmPropertyNames.TAG,
    referencedObjectType: BLOG_TAG,
    usageTrackerActionName: 'tag'
  }),
  [ContentNameKeysToCrmPropertyNames.TEAM]: new Filter({
    filterType: FilterTypes.TEAM,
    propertyName: ContentNameKeysToCrmPropertyNames.TEAM,
    referencedObjectType: TEAM,
    usageTrackerActionName: 'team'
  }),
  [ContentNameKeysToCrmPropertyNames.TEST_STATUS]: new Filter({
    filterType: FilterTypes.TEST_STATUS,
    propertyName: ContentNameKeysToCrmPropertyNames.TEST_STATUS
  }),
  [ContentNameKeysToCrmPropertyNames.UPDATED_BY]: new Filter({
    filterType: FilterTypes.UPDATED_BY,
    propertyName: ContentNameKeysToCrmPropertyNames.UPDATED_BY,
    referencedObjectType: USER,
    needsDescriptorLabel: true
  }),
  [ContentNameKeysToCrmPropertyNames.UPDATED_DATE]: new Filter({
    filterType: FilterTypes.UPDATED_DATE,
    propertyName: ContentNameKeysToCrmPropertyNames.UPDATED_DATE,
    needsDescriptorLabel: true
  }),
  [ContentNameKeysToCrmPropertyNames.VARIATION]: new Filter({
    filterType: FilterTypes.VARIATION,
    propertyName: ContentNameKeysToCrmPropertyNames.VARIATION,
    isMultiSelect: false,
    usageTrackerActionName: 'variation'
  }),
  [ContentNameKeysToCrmPropertyNames.CONTENT_GROUP_ID]: new Filter({
    filterType: FilterTypes.BLOG,
    propertyName: ContentNameKeysToCrmPropertyNames.CONTENT_GROUP_ID,
    isMultiSelect: true
  }),
  [ContentNameKeysToCrmPropertyNames.THEME_PATH]: new Filter({
    filterType: FilterTypes.THEME_PATH,
    propertyName: ContentNameKeysToCrmPropertyNames.THEME_PATH,
    isMultiSelect: true
  }),
  [ContentNameKeysToCrmPropertyNames.APPROVAL_STATUS]: new Filter({
    filterType: FilterTypes.APPROVAL_STATUS,
    propertyName: ContentNameKeysToCrmPropertyNames.APPROVAL_STATUS,
    isMultiSelect: false
  }),
  [ContentNameKeysToCrmPropertyNames.NAME]: new Filter({
    filterType: FilterTypes.NAME,
    propertyName: ContentNameKeysToCrmPropertyNames.NAME
  }),
  [ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT]: new Filter({
    filterType: FilterTypes.BUSINESS_UNIT,
    propertyName: ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT,
    referencedObjectType: BUSINESS_UNIT_DROPDOWN_OPTION
  })
});
export const BlogPostsFilterableProperties = [ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS, ContentNameKeysToCrmPropertyNames.BLOG_AUTHOR, ContentNameKeysToCrmPropertyNames.CAMPAIGN, ContentNameKeysToCrmPropertyNames.CONTENT_GROUP_ID, ContentNameKeysToCrmPropertyNames.CREATED_BY, ContentNameKeysToCrmPropertyNames.CREATED_DATE, ContentNameKeysToCrmPropertyNames.DOMAIN, ContentNameKeysToCrmPropertyNames.LANGUAGE, ContentNameKeysToCrmPropertyNames.PUBLISH_DATE, ContentNameKeysToCrmPropertyNames.TAG, ContentNameKeysToCrmPropertyNames.TEAM, ContentNameKeysToCrmPropertyNames.UPDATED_BY, ContentNameKeysToCrmPropertyNames.UPDATED_DATE];
export const PagesFilterableProperties = [ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS, ContentNameKeysToCrmPropertyNames.CAMPAIGN, ContentNameKeysToCrmPropertyNames.CREATED_BY, ContentNameKeysToCrmPropertyNames.CREATED_DATE, ContentNameKeysToCrmPropertyNames.LANGUAGE, ContentNameKeysToCrmPropertyNames.PUBLISH_DATE, ContentNameKeysToCrmPropertyNames.TEAM, ContentNameKeysToCrmPropertyNames.TEST_STATUS, ContentNameKeysToCrmPropertyNames.UPDATED_BY, ContentNameKeysToCrmPropertyNames.UPDATED_DATE, ContentNameKeysToCrmPropertyNames.VARIATION, ContentNameKeysToCrmPropertyNames.DOMAIN];
export const SiteTreeFilterableProperties = [ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS, ContentNameKeysToCrmPropertyNames.CAMPAIGN, ContentNameKeysToCrmPropertyNames.CONTENT_TYPE, ContentNameKeysToCrmPropertyNames.CREATED_BY, ContentNameKeysToCrmPropertyNames.CREATED_DATE, ContentNameKeysToCrmPropertyNames.DOMAIN, ContentNameKeysToCrmPropertyNames.RESOLVED_LANGUAGE, ContentNameKeysToCrmPropertyNames.PUBLISH_DATE, ContentNameKeysToCrmPropertyNames.TEST_STATUS, ContentNameKeysToCrmPropertyNames.UPDATED_BY, ContentNameKeysToCrmPropertyNames.UPDATED_DATE, ContentNameKeysToCrmPropertyNames.VARIATION, ContentNameKeysToCrmPropertyNames.STATE];
export const RequiredSiteTreeFilters = ImmutableSet([ContentNameKeysToCrmPropertyNames.RESOLVED_LANGUAGE, ContentNameKeysToCrmPropertyNames.CONTENT_TYPE, ContentNameKeysToCrmPropertyNames.DOMAIN]);

// These properties are "custom" because they are not returned
// by the properties API. They were created to support filters that map to
// several properties. e.g. audience access can be composed of both
// "hs_public_access_rule_type" and "hs_public_access_rules_enabled".
export const CustomProperties = [ContentNameKeysToCrmPropertyNames.VARIATION, ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS, ContentNameKeysToCrmPropertyNames.APPROVAL_STATUS];
export const BlogPostsDropdownFilters = [FilterTypes.BLOG, FilterTypes.STATE];
export const PagesDropdownFilters = [FilterTypes.DOMAIN, FilterTypes.STATE];