'use es6';

import { OrderedMap, Record } from 'immutable';
import RequestStatus from '../../shared/constants/RequestStatus';
import AuthorsDisplay from './AuthorsDisplay';
const defaults = {
  data: new OrderedMap(),
  stale: false,
  status: RequestStatus.UNINITIALIZED,
  total: 0,
  display: new AuthorsDisplay()
};
export default class Authors extends Record(defaults) {}