'use es6';

import { Map as ImmutableMap } from 'immutable';
import { createSelector } from 'reselect';
import PageModalNames from '../../pages/constants/PageModalNames';
import { getModalRelevantEntities, getModalRelevantEntityIds, getOpenModalName } from './modalsDisplaySelectors';
const getSiteTreeState = state => state.siteTree;
export const getSiteTreeData = createSelector([getSiteTreeState], siteTreeState => siteTreeState.treeData);
export const getSiteTreeLoadStatus = createSelector([getSiteTreeState], siteTreeState => siteTreeState.loadStatus);
export const getSiteTreeTreesLoadingMore = createSelector([getSiteTreeState], siteTreeState => siteTreeState.treesLoadingMore);
const nodeAccumulator = (acc, translatedAcc, node) => {
  if (node.content) {
    acc.push(node.content);
    if (node.content.translatedContent.size > 0) {
      node.content.translatedContent.valueSeq().forEach(t => translatedAcc.push(t));
    }
  }
  if (!node.children.isEmpty()) {
    node.children.forEach(n => nodeAccumulator(acc, translatedAcc, n));
  }
};
export const _getFlattenedSiteTreeContent = siteTree => {
  const allPrimaryContentModels = [];
  const allTranslatedContentModels = [];
  nodeAccumulator(allPrimaryContentModels, allTranslatedContentModels, siteTree);
  return {
    primarySiteTreeContent: allPrimaryContentModels.reduce((acc, page) => acc.set(page.get('id'), page), new ImmutableMap()),
    translatedSiteTreeContent: allTranslatedContentModels.reduce((acc, translation) => acc.set(translation.get('id'), translation), new ImmutableMap())
  };
};
export const getFlattenedSiteTreeContent = createSelector(getSiteTreeData, _getFlattenedSiteTreeContent);
const getModalRelevantPagesSelector = getModalRelevantEntities(PageModalNames);
export const getModalRelevantEntitiesForSiteTree = createSelector([getOpenModalName, getModalRelevantEntityIds, getFlattenedSiteTreeContent], (openModalName, modalRelevantIds, flattenedSiteTreeContent) => {
  const {
    primarySiteTreeContent,
    translatedSiteTreeContent
  } = flattenedSiteTreeContent;
  return getModalRelevantPagesSelector(openModalName, modalRelevantIds, primarySiteTreeContent, translatedSiteTreeContent);
});
export const getShouldAutoOpenNodesPastFourthLevel = createSelector([getSiteTreeData], siteTree => {
  if (siteTree && !siteTree.isEmpty()) {
    let shouldAutoOpenNodesPastFourthLevel = true;
    const nodesToVisit = [siteTree];
    while (nodesToVisit.length !== 0 && nodesToVisit[0].slug.split('/').length < 3) {
      const node = nodesToVisit[0];
      if (node.content) {
        shouldAutoOpenNodesPastFourthLevel = false;
        break;
      }
      const nodeChildren = node.get('children');
      nodeChildren.forEach(child => nodesToVisit.push(child));
      nodesToVisit.shift();
    }
    return shouldAutoOpenNodesPastFourthLevel;
  }
  return false;
});