import quickFetch from 'quick-fetch';
import { from } from 'rxjs/observable/from';
export function getEarlyRequest(requestKey) {
  return quickFetch.getRequestStateByName(requestKey);
}
// Returns spcified early request as a Promise or Observable, allowing retry using retryRequest
// if the early request failed. If early request doesn't exist, will automatically fallback
// on `retryRequest`.
//
// `retryRequest` must return a Promise
export function getEarlyRequesterResultPromise({
  name,
  retryRequest
}) {
  const earlyMetaRequest = getEarlyRequest(name);
  if (!earlyMetaRequest) {
    return retryRequest();
  }
  return new Promise((resolve, reject) => {
    earlyMetaRequest.whenFinished(result => {
      resolve(result);
      quickFetch.removeEarlyRequest(name);
    });
    earlyMetaRequest.onError(() => {
      retryRequest().then(resolve, reject).catch(reject);
      quickFetch.removeEarlyRequest(name);
    });
  });
}
export function getEarlyRequesterResultObservable(config) {
  return from(getEarlyRequesterResultPromise(config));
}