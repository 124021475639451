'use es6';

import { createAction } from 'redux-actions';
import keyMirror from 'react-utils/keyMirror';
import { getPagesActionName } from '../utils/pagesReduxUtils';
import { ContentTypes } from '../../shared/constants/ContentTypes';
import { TranslationErrorActionTypes } from '../../shared/actions/TranslationErrorActions';
import { getUserHasBulkPublishAccess } from '../../shared/selectors/authSelectors';
import { getPagesDisplaySelector } from '../selectors/pagesDisplaySelectors';
import { closeContentPreviewPanel } from '../../shared/actions/ModalActions';
import { getFoldersFiltersData } from '../selectors/foldersDisplaySelectors';
import { getManagementDataType } from '../../shared/utils/managementDataTypeHelpers';
import { FOLDERS } from '../../shared/constants/ManagementDataTypes';
import { BulkActionNames } from '../../shared/constants/BulkActions';
import { fetchSettings } from '../../shared/reducers/portal';
export const PagesActionTypes = keyMirror({
  // pages actions affecting separated state by contentType
  LANDING_PAGES_RESET_PAGES: null,
  LANDING_PAGES_REQUESTED_PAGES_FOR_START_STATE: null,
  LANDING_PAGES_RECEIVED_PAGES_FOR_START_STATE: null,
  LANDING_PAGES_REQUESTED_PAGES_FOR_START_STATE_FAILED: null,
  LANDING_PAGES_REQUESTED_PAGES: null,
  LANDING_PAGES_RECEIVED_PAGES: null,
  LANDING_PAGES_REQUESTED_PAGES_FAILED: null,
  LANDING_PAGES_TOGGLE_PAGE_SELECT: null,
  LANDING_PAGES_TOGGLE_ALL_PAGES_SELECT: null,
  LANDING_PAGES_TOGGLE_ALL_PAGES_NESTED_ROWS_OPEN: null,
  LANDING_PAGES_TOGGLE_MLANG_GROUP_OPEN: null,
  LANDING_PAGES_MARK_PAGES_STALE: null,
  LANDING_PAGES_SET_BULK_UPDATE_TASK: null,
  SITE_PAGES_RESET_PAGES: null,
  SITE_PAGES_REQUESTED_PAGES_FOR_START_STATE: null,
  SITE_PAGES_RECEIVED_PAGES_FOR_START_STATE: null,
  SITE_PAGES_REQUESTED_PAGES_FOR_START_STATE_FAILED: null,
  SITE_PAGES_REQUESTED_PAGES: null,
  SITE_PAGES_RECEIVED_PAGES: null,
  SITE_PAGES_REQUESTED_PAGES_FAILED: null,
  SITE_PAGES_TOGGLE_PAGE_SELECT: null,
  SITE_PAGES_TOGGLE_ALL_PAGES_SELECT: null,
  SITE_PAGES_TOGGLE_ALL_PAGES_NESTED_ROWS_OPEN: null,
  SITE_PAGES_TOGGLE_MLANG_GROUP_OPEN: null,
  SITE_PAGES_MARK_PAGES_STALE: null,
  SITE_PAGES_SET_BULK_UPDATE_TASK: null,
  // filters
  LANDING_PAGES_UPDATE_FILTER_QUERY_STATE: null,
  SITE_PAGES_UPDATE_FILTER_QUERY_STATE: null,
  LANDING_PAGES_UPDATE_FILTER_QUERY: null,
  SITE_PAGES_UPDATE_FILTER_QUERY: null,
  // shared pages actions
  FETCH_PAGES_FOR_START_STATE: null,
  FETCH_FILTERABLE_DOMAINS: null,
  PAGES_ADVANCED_FILTERS_CHANGED: null,
  CLEAR_PAGES_FILTER_QUERY: null,
  OPEN_AUDIENCE_ACCESS_MODAL: null,
  LANDING_PAGES_REQUESTED_FILTERABLE_DOMAINS: null,
  LANDING_PAGES_RECEIVED_FILTERABLE_DOMAINS: null,
  LANDING_PAGES_REQUESTED_FILTERABLE_DOMAINS_FAILED: null,
  SITE_PAGES_REQUESTED_FILTERABLE_DOMAINS: null,
  SITE_PAGES_RECEIVED_FILTERABLE_DOMAINS: null,
  SITE_PAGES_REQUESTED_FILTERABLE_DOMAINS_FAILED: null,
  // Page actions
  REQUESTED_ARCHIVE_PAGES: null,
  REQUESTED_ARCHIVE_AND_UNPUBLISH_PAGES: null,
  REQUESTED_ASSIGN_TEAM_PERMISSIONS_PAGES: null,
  RECEIVED_ASSIGN_TEAM_PERMISSIONS_PAGES: null,
  REQUESTED_ASSIGN_TEAM_PERMISSIONS_PAGES_FAILED: null,
  REQUESTED_CLONE_PAGE: null,
  REQUESTED_CONVERT_PAGE_TYPE: null,
  REQUESTED_DELETE_PAGES: null,
  REQUESTED_MOVE_PAGES_TO_FOLDER: null,
  REQUESTED_UNARCHIVE_PAGES: null,
  REQUESTED_UNPUBLISH_PAGE: null,
  REQUESTED_PUBLISH_PAGE: null,
  REQUESTED_START_PAGES_ASYNC_BULK_UPDATE_ACTION: null,
  REQUESTED_CREATE_PAGE_TRANSLATION: null,
  REQUESTED_ATTACH_TO_LANGUAGE_MASTER_PAGE: null,
  REQUESTED_REMOVE_FROM_LANGUAGE_GROUP_PAGE: null,
  REQUESTED_CONVERT_TO_VARIATION_MASTER_PAGE: null,
  SWITCHED_LANGUAGE_MASTER_PAGE: null,
  LANDING_PAGES_SET_EXCLUDED_PAGE_IDS: null,
  SITE_PAGES_SET_EXCLUDED_PAGE_IDS: null,
  LANDING_PAGES_SET_PAGES_TO_MERGE_ON_REFETCH: null,
  SITE_PAGES_SET_PAGES_TO_MERGE_ON_REFETCH: null,
  PAGES_MODAL_CONTAINER_UNMOUNTED: null
});
export const unmountPagesModalContainer = createAction(PagesActionTypes.PAGES_MODAL_CONTAINER_UNMOUNTED);

// pages actions affecting separated state by contentType
const makeContentTypeSpecificPagesActions = contentType => {
  const resetPages = createAction(PagesActionTypes[getPagesActionName(contentType, 'RESET_PAGES')]);
  const requestPagesForStartState = createAction(PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_PAGES_FOR_START_STATE')]);
  const receivePagesForStartState = createAction(PagesActionTypes[getPagesActionName(contentType, 'RECEIVED_PAGES_FOR_START_STATE')]);
  const receivePagesForStartStateFailure = createAction(PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_PAGES_FOR_START_STATE_FAILED')], error => ({
    error
  }));
  const requestPages = createAction(PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_PAGES')]);
  const receivePages = createAction(PagesActionTypes[getPagesActionName(contentType, 'RECEIVED_PAGES')]);
  const receivePagesFailure = createAction(PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_PAGES_FAILED')], error => ({
    error
  }));
  const markPagesStale = createAction(PagesActionTypes[getPagesActionName(contentType, 'MARK_PAGES_STALE')]);
  const setExcludedPageIds = createAction(PagesActionTypes[getPagesActionName(contentType, 'SET_EXCLUDED_PAGE_IDS')], pages => ({
    excludedPageIds: pages.map(page => page.get('id'))
  }));
  const setPagesToMergeOnRefetch = createAction(PagesActionTypes[getPagesActionName(contentType, 'SET_PAGES_TO_MERGE_ON_REFETCH')], pagesToMergeOnRefetch => ({
    pagesToMergeOnRefetch
  }));
  const toggleSelectPage = createAction(PagesActionTypes[getPagesActionName(contentType, 'TOGGLE_PAGE_SELECT')], pageId => ({
    pageId
  }));
  const toggleSelectAllPages = createAction(PagesActionTypes[getPagesActionName(contentType, 'TOGGLE_ALL_PAGES_SELECT')]);
  const toggleMultiLangGroupOpen = createAction(PagesActionTypes[getPagesActionName(contentType, 'TOGGLE_MLANG_GROUP_OPEN')], pageId => ({
    pageId
  }));
  const toggleAllPagesNestedRowsOpen = createAction(PagesActionTypes[getPagesActionName(contentType, 'TOGGLE_ALL_PAGES_NESTED_ROWS_OPEN')], pageIds => ({
    pageIds
  }));
  const requestDomains = createAction(PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_FILTERABLE_DOMAINS')]);
  const receiveDomains = createAction(PagesActionTypes[getPagesActionName(contentType, 'RECEIVED_FILTERABLE_DOMAINS')]);
  const receiveDomainsFailure = createAction(PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_FILTERABLE_DOMAINS_FAILED')], error => ({
    error
  }));
  const setPagesBulkUpdateTask = createAction(PagesActionTypes[getPagesActionName(contentType, 'SET_BULK_UPDATE_TASK')], bulkUpdateTask => bulkUpdateTask);
  const _updatePagesFilterQuery = createAction(PagesActionTypes[getPagesActionName(contentType, 'UPDATE_FILTER_QUERY')], query => ({
    query
  }));
  const updatePagesFilterQuery = (query, shouldRefetchPages) => dispatch => {
    dispatch(_updatePagesFilterQuery(query));
    if (shouldRefetchPages) {
      dispatch(markPagesStale());
    }
  };
  const _updatePagesFilterQueryState = createAction(PagesActionTypes[getPagesActionName(contentType, 'UPDATE_FILTER_QUERY_STATE')], filterOptions => filterOptions);
  const updatePagesFilterQueryState = filterOptions => dispatch => {
    dispatch(_updatePagesFilterQueryState(filterOptions));
    dispatch(markPagesStale());
  };
  return {
    resetPages,
    requestPagesForStartState,
    receivePagesForStartState,
    receivePagesForStartStateFailure,
    requestPages,
    receivePages,
    receivePagesFailure,
    requestDomains,
    receiveDomains,
    receiveDomainsFailure,
    markPagesStale,
    toggleSelectPage,
    toggleSelectAllPages,
    toggleAllPagesNestedRowsOpen,
    toggleMultiLangGroupOpen,
    updatePagesFilterQueryState,
    updatePagesFilterQuery,
    setExcludedPageIds,
    setPagesToMergeOnRefetch,
    setPagesBulkUpdateTask
  };
};
export const landingPagesActions = makeContentTypeSpecificPagesActions(ContentTypes.LANDING_PAGE);
const sitePagesActions = makeContentTypeSpecificPagesActions(ContentTypes.SITE_PAGE);
export const getPagesAction = (contentType, actionFunctionName) => {
  if (contentType === ContentTypes.SITE_PAGE) {
    return sitePagesActions[actionFunctionName];
  }
  return landingPagesActions[actionFunctionName];
};

// shared pages actions
export const fetchPagesForStartState = createAction(PagesActionTypes.FETCH_PAGES_FOR_START_STATE);
export const fetchFilterableDomains = createAction(PagesActionTypes.FETCH_FILTERABLE_DOMAINS, (contentType, businessUnitId) => ({
  contentType,
  businessUnitId
}));
const pagesFiltersUpdated = createAction(PagesActionTypes.PAGES_ADVANCED_FILTERS_CHANGED, (contentType, hasFiltersApplied, query) => ({
  contentType,
  hasFiltersApplied,
  query
}));
export const clearPagesFiltersFilterQuery = createAction(PagesActionTypes.CLEAR_PAGES_FILTER_QUERY);
export const pagesFiltersChanged = contentType => (dispatch, getState) => {
  const state = getState();
  const requestOptions = getPagesDisplaySelector(contentType, 'getPagesCurrentlyAppliedFilters')(state);
  const hasFiltersApplied = getPagesDisplaySelector(contentType, 'getIsFilteringPages')(state);
  const managementDataType = getManagementDataType();
  const folderFiltersData = getFoldersFiltersData(state);
  dispatch(pagesFiltersUpdated(contentType, hasFiltersApplied, Object.assign({}, requestOptions, {
    folderId: managementDataType === FOLDERS ? folderFiltersData.folderId : undefined
  })));
  dispatch(closeContentPreviewPanel());
};

// Page actions
const getPageCountWithTranslations = pages => pages.reduce((count, page) => {
  if (page.hasTranslations()) {
    return count + page.getTranslatedContent().size + 1;
  }
  return count + 1;
}, 0);
const archivePage = createAction(PagesActionTypes.REQUESTED_ARCHIVE_PAGES, pages => ({
  count: getPageCountWithTranslations(pages),
  pages
}));
const bulkArchivePages = createAction(PagesActionTypes.REQUESTED_START_PAGES_ASYNC_BULK_UPDATE_ACTION, pages => ({
  pages,
  actionName: BulkActionNames.ARCHIVE,
  payload: {
    archived: true
  }
}));
export const archivePages = pages => dispatch => {
  if (pages.size > 1) {
    dispatch(bulkArchivePages(pages));
  } else {
    dispatch(archivePage(pages));
  }
};
const archiveAndUnpublishPage = createAction(PagesActionTypes.REQUESTED_ARCHIVE_AND_UNPUBLISH_PAGES, pages => ({
  count: getPageCountWithTranslations(pages),
  pages
}));
const bulkArchiveAndUnpublishPages = createAction(PagesActionTypes.REQUESTED_START_PAGES_ASYNC_BULK_UPDATE_ACTION, pages => ({
  pages,
  actionName: BulkActionNames.ARCHIVE_AND_UNPUBLISH,
  payload: {
    archived: true
  }
}));
export const archiveAndUnpublishPages = pages => dispatch => {
  if (pages.size > 1) {
    dispatch(bulkArchiveAndUnpublishPages(pages));
  } else {
    dispatch(archiveAndUnpublishPage(pages));
  }
};
export const assignTeamPermissions = createAction(PagesActionTypes.REQUESTED_ASSIGN_TEAM_PERMISSIONS_PAGES, (pages, {
  teams,
  users
}) => ({
  pages,
  teams,
  users
}));
export const receiveAssignTeamPermissions = createAction(PagesActionTypes.RECEIVED_ASSIGN_TEAM_PERMISSIONS_PAGES);
export const receiveAssignTeamPermissionsFailure = createAction(PagesActionTypes.REQUESTED_ASSIGN_TEAM_PERMISSIONS_PAGES_FAILED);
export const clonePage = createAction(PagesActionTypes.REQUESTED_CLONE_PAGE, (pages, {
  name
}) => ({
  name,
  page: pages.first()
}));
export const convertPage = createAction(PagesActionTypes.REQUESTED_CONVERT_PAGE_TYPE, pages => ({
  count: getPageCountWithTranslations(pages),
  page: pages.first()
}));
const deletePage = createAction(PagesActionTypes.REQUESTED_DELETE_PAGES, pages => ({
  count: getPageCountWithTranslations(pages),
  pages
}));
const bulkDeletePages = createAction(PagesActionTypes.REQUESTED_START_PAGES_ASYNC_BULK_UPDATE_ACTION, pages => ({
  pages,
  actionName: BulkActionNames.DELETE
}));
export const deletePages = pages => dispatch => {
  if (pages.size > 1) {
    dispatch(bulkDeletePages(pages));
  } else {
    dispatch(deletePage(pages));
  }
};
export const moveToFolderPages = createAction(PagesActionTypes.REQUESTED_MOVE_PAGES_TO_FOLDER, (pages, folderId) => ({
  folderId,
  pages,
  count: getPageCountWithTranslations(pages)
}));
const unarchivePage = createAction(PagesActionTypes.REQUESTED_UNARCHIVE_PAGES, pages => ({
  count: getPageCountWithTranslations(pages),
  pages
}));
const bulkUnarchivePages = createAction(PagesActionTypes.REQUESTED_START_PAGES_ASYNC_BULK_UPDATE_ACTION, pages => ({
  pages,
  actionName: BulkActionNames.UNARCHIVE,
  payload: {
    archived: false
  }
}));
export const unarchivePages = pages => dispatch => {
  if (pages.size > 1) {
    dispatch(bulkUnarchivePages(pages));
  } else {
    dispatch(unarchivePage(pages));
  }
};
export const receivePagesAsyncBulkUpdateActionFailure = createAction(TranslationErrorActionTypes.REQUESTED_ASYNC_BULK_UPDATE_ACTION_FAILED, ({
  error
}) => ({
  error
}));
export const receivePagesArchiveActionFailure = createAction(TranslationErrorActionTypes.REQUESTED_ARCHIVE_CONTENT_FAILED, ({
  error
}) => ({
  error
}));
const publishPage = createAction(PagesActionTypes.REQUESTED_PUBLISH_PAGE, pages => ({
  page: pages.first()
}));
const bulkPublishPages = createAction(PagesActionTypes.REQUESTED_START_PAGES_ASYNC_BULK_UPDATE_ACTION, pages => ({
  pages,
  actionName: BulkActionNames.PUBLISH
}));
export const publishPages = pages => (dispatch, getState) => {
  const state = getState();
  const contentType = pages.first().contentType;
  const canUserBulkPublish = getUserHasBulkPublishAccess(contentType)(state);
  if (canUserBulkPublish) {
    dispatch(bulkPublishPages(pages));
  } else {
    dispatch(publishPage(pages));
  }
};
const unpublishPage = createAction(PagesActionTypes.REQUESTED_UNPUBLISH_PAGE, pages => ({
  page: pages.first()
}));
const bulkUnpublishPages = createAction(PagesActionTypes.REQUESTED_START_PAGES_ASYNC_BULK_UPDATE_ACTION, pages => ({
  pages,
  actionName: BulkActionNames.UNPUBLISH
}));
export const unpublishPages = pages => dispatch => {
  if (pages.size > 1) {
    dispatch(bulkUnpublishPages(pages));
  } else {
    dispatch(unpublishPage(pages));
  }
};
export const createTranslationVariationPage = createAction(PagesActionTypes.REQUESTED_CREATE_PAGE_TRANSLATION, (pages, {
  language,
  masterLanguage
}) => ({
  language,
  masterLanguage,
  page: pages.first()
}));
export const receiveCreateTranslationVariationPageFailure = createAction(TranslationErrorActionTypes.REQUESTED_CREATE_TRANSLATION_FAILED, ({
  error
}) => ({
  error
}));
const bulkAttachToLanguageMasterPage = createAction(PagesActionTypes.REQUESTED_START_PAGES_ASYNC_BULK_UPDATE_ACTION, (pages, {
  translatedFromId,
  selectedPrimaryContentLanguage,
  selectedPrimaryContentModel
}) => ({
  pages,
  actionName: BulkActionNames.ADD_TO_MLANG_GROUP,
  payload: {
    translatedFromId,
    masterLanguage: selectedPrimaryContentLanguage
  },
  selectedPrimaryContentModel
}));
const attachToLanguageMasterPage = createAction(PagesActionTypes.REQUESTED_ATTACH_TO_LANGUAGE_MASTER_PAGE, (pages, {
  language,
  translatedFromId,
  selectedPrimaryContentModel,
  selectedPrimaryContentLanguage
}) => ({
  language,
  page: pages.first(),
  translatedFromId,
  selectedPrimaryContentModel,
  selectedPrimaryContentLanguage
}));
export const attachToLanguageMasterPages = (pages, primaryPage) => dispatch => {
  if (pages.size > 1) {
    dispatch(bulkAttachToLanguageMasterPage(pages, primaryPage));
  } else {
    dispatch(attachToLanguageMasterPage(pages, primaryPage));
  }
};
export const receiveAttachToLanguageMasterPageFailure = createAction(TranslationErrorActionTypes.REQUESTED_ATTACH_TO_LANGUAGE_MASTER_FAILED, ({
  error
}) => ({
  error
}));
export const switchedLanguageMasterPage = createAction(PagesActionTypes.SWITCHED_LANGUAGE_MASTER_PAGE, ({
  content: page,
  newMasterId
}) => ({
  page,
  newMasterId
}));
export const setDomainLanguagePage = () => dispatch => {
  dispatch(fetchSettings());
};
export const removeFromLanguageGroupPage = createAction(PagesActionTypes.REQUESTED_REMOVE_FROM_LANGUAGE_GROUP_PAGE, page => ({
  page
}));
export const convertToVariationMasterPage = createAction(PagesActionTypes.REQUESTED_CONVERT_TO_VARIATION_MASTER_PAGE, page => ({
  page
}));