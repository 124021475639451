'use es6';

import { createSelector } from 'reselect';
import { getAuthorsWithEmailOptionsData } from './authorSelectors';
import { ALL_FILTER } from '../../shared/constants/DefaultFilterParams';
const getCommentsDisplayState = state => state.blog.comments.display;
export const getSelectedComments = createSelector([getCommentsDisplayState], display => display.selected);
export const getBlogCommentsFiltersData = createSelector([getCommentsDisplayState], ({
  currentPage,
  authorFilter,
  blogFilter,
  commentStateFilter,
  selectedSort,
  selectedSortDir,
  search,
  rowsPerPage
}) => ({
  currentPage,
  authorFilter,
  blogFilter,
  commentStateFilter,
  selectedSort,
  selectedSortDir,
  search,
  rowsPerPage
}));
export const getIsFilteringOrSearchingComments = createSelector([getCommentsDisplayState], ({
  search,
  commentStateFilter,
  authorFilter,
  blogFilter
}) => search !== '' || blogFilter !== ALL_FILTER || commentStateFilter !== ALL_FILTER || authorFilter !== ALL_FILTER);
const getBlogCommentAuthorEmail = (authors, selectedAuthorId) => {
  if (selectedAuthorId && selectedAuthorId !== ALL_FILTER) {
    const author = authors.get(parseInt(selectedAuthorId, 10));
    return author ? author.get('email') : '';
  }
  return '';
};
export const getBlogCommentsFilterQueryFromState = createSelector([getBlogCommentsFiltersData, getAuthorsWithEmailOptionsData], ({
  authorFilter,
  blogFilter,
  currentPage,
  selectedSort,
  selectedSortDir,
  commentStateFilter,
  search,
  rowsPerPage
}, authorsWithEmailOptions) => ({
  blog: blogFilter,
  commentedContentAuthor: getBlogCommentAuthorEmail(authorsWithEmailOptions, authorFilter),
  page: currentPage,
  sort: selectedSort,
  sortDir: selectedSortDir,
  state: commentStateFilter,
  search,
  rowsPerPage
}));
export const getBlogCommentsBlogFilter = createSelector([getBlogCommentsFiltersData], ({
  blogFilter
}) => blogFilter);