'use es6';

export const LANDING_PAGES = 'landingPages';
export const SITE_PAGES = 'sitePages';
export const FOLDERS = 'folders';
export const BLOG_POSTS = 'blogPosts';
export const BLOG_LISTING_PAGE = 'listing';
export const TAGS = 'tags';
export const COMMENTS = 'comments';
export const AUTHORS = 'authors';
export const PageManagementDataTypes = [LANDING_PAGES, SITE_PAGES, FOLDERS];
export const BlogManagementDataTypes = [BLOG_POSTS, BLOG_LISTING_PAGE, TAGS, COMMENTS, AUTHORS];