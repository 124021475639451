'use es6';

import { Map as ImmutableMap, fromJS } from 'immutable';
import TranslationError from '../models/TranslationError';
import { ModalActionTypes } from '../actions/ModalActions';
import { TranslationErrorActionTypes } from '../actions/TranslationErrorActions';
export default ((state = new TranslationError(), action) => {
  switch (action.type) {
    case TranslationErrorActionTypes.REQUESTED_CREATE_TRANSLATION_FAILED:
      return state.set('data', new ImmutableMap({
        createTranslationVariation: new ImmutableMap(action.payload.error.responseJSON)
      }));
    case TranslationErrorActionTypes.REQUESTED_PUBLISH_CONTENT_FAILED:
      return state.set('data', new ImmutableMap({
        publishContent: new ImmutableMap(action.payload.error.responseJSON)
      }));
    case TranslationErrorActionTypes.REQUESTED_ARCHIVE_CONTENT_FAILED:
      return state.set('data', new ImmutableMap({
        archiveContent: new ImmutableMap(action.payload.error.responseJSON)
      }));
    case TranslationErrorActionTypes.REQUESTED_ATTACH_TO_LANGUAGE_MASTER_FAILED:
      return state.set('data', new ImmutableMap({
        attachToLanguageMaster: new ImmutableMap(action.payload.error.responseJSON)
      }));
    case TranslationErrorActionTypes.REQUESTED_ASYNC_BULK_UPDATE_ACTION_FAILED:
      return state.set('data', fromJS({
        asyncBulkAction: action.payload.error.responseJSON
      }));
    case ModalActionTypes.CLOSE_MODAL:
      return new TranslationError();
    default:
      return state;
  }
});