import keyMirror from 'react-utils/keyMirror';
export default keyMirror({
  CREATE_BLOG_MODAL: null,
  CREATE_BLOG_POST_MODAL: null,
  CREATE_BLOG_LISTING_PAGE_MODAL: null,
  SITE_EXPORT_MODAL: null,
  BLOG_POSTS_EXPORT_MODAL: null,
  CLONE_MODAL: null,
  ARCHIVE_MODAL: null,
  UNARCHIVE_MODAL: null,
  EDIT_COLUMNS_MODAL: null,
  CREATE_PAGE_MODAL: null,
  CREATE_TRANSLATION_MODAL: null,
  ADD_TO_LANGUAGE_GROUP_MODAL: null,
  SWITCH_LANGUAGE_PRIMARY_MODAL: null,
  REMOVE_FROM_LANGUAGE_GROUP_MODAL: null,
  CONVERT_TO_LANGUAGE_GROUP_PRIMARY_MODAL: null,
  DELETE_MODAL: null,
  DELETE_GROUP_MODAL: null,
  TOPIC_SUGGESTIONS_WIZARD: null
});