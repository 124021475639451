'use es6';

import { Set as ImmutableSet, Map as ImmutableMap } from 'immutable';
import { ModalActionTypes } from '../actions/ModalActions';
import ModalsDisplay from '../models/ModalsDisplay';
export default ((state = new ModalsDisplay(), action) => {
  switch (action.type) {
    case ModalActionTypes.OPEN_MODAL:
      return state.merge({
        openModalName: action.payload.modalName,
        relevantEntityIds: action.payload.relevantEntityIds,
        extraData: ImmutableMap(action.payload.extraData)
      });
    case ModalActionTypes.OPEN_CONTENT_MODAL:
      return state.merge({
        openModalName: action.payload.modalName,
        relevantEntities: ImmutableSet.of(...action.payload.relevantEntities),
        extraData: ImmutableMap(action.payload.extraData)
      });
    case ModalActionTypes.OPEN_CONTENT_PREVIEW_PANEL:
      return state.merge({
        previewPanelOpen: true,
        previewPanelCrmObject: action.payload.crmObject,
        previewPanelParentCrmObject: action.payload.parentCrmObject,
        previewPanelContentModel: action.payload.contentModel
      });
    case ModalActionTypes.CLOSE_CONTENT_PREVIEW_PANEL:
      return state.merge({
        previewPanelOpen: false,
        previewPanelCrmObject: null,
        previewPanelParentCrmObject: null,
        previewPanelContentModel: null
      });
    case ModalActionTypes.CLOSE_MODAL:
      return state.merge({
        openModalName: null,
        relevantEntityIds: ImmutableSet(),
        relevantEntities: ImmutableSet(),
        extraData: ImmutableMap()
      });
    case ModalActionTypes.OPEN_SOCIAL_COMPOSER:
      return state.merge({
        socialComposerOpen: true,
        socialComposerParams: action.payload
      });
    case ModalActionTypes.CLOSE_SOCIAL_COMPOSER:
      return state.merge({
        socialComposerOpen: false
      });
    case ModalActionTypes.UPDATE_SOCIAL_COMPOSER_PARAMS:
      return state.mergeIn(['socialComposerParams'], action.payload);
    default:
      return state;
  }
});