import http from 'hub-http/clients/apiClient';
import { getEarlyRequesterResultPromise } from '../utils/getEarlyRequesterResultPromise';
export default class ContentCreatorInfoApi {
  static fetchThemes() {
    return getEarlyRequesterResultPromise({
      name: 'themes-data-fetch',
      retryRequest: () => http.get('designmanager/v1/themes/list', {
        query: {
          templateTypeId: 4
        }
      })
    }).then(data => data);
  }
}