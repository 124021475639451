// TODO: rename to "ContentTypes.ts" once old ContentTypes file is no longer used
export const ContentTypes = {
  LANDING_PAGE: 1,
  SITE_PAGE: 4,
  BLOG_POST: 3,
  EXTERNAL: 0,
  BLOG_LISTING_PAGE: 7
};
export const CONTENT_TYPES_ARRAY = [ContentTypes.LANDING_PAGE, ContentTypes.SITE_PAGE, ContentTypes.BLOG_POST, ContentTypes.EXTERNAL, ContentTypes.BLOG_LISTING_PAGE];
export const PAGE_CONTENT_TYPES = [ContentTypes.LANDING_PAGE, ContentTypes.SITE_PAGE];
export const ContentTypesToLegacyContentTypeStrings = {
  [ContentTypes.LANDING_PAGE]: 'landing',
  [ContentTypes.SITE_PAGE]: 'site',
  [ContentTypes.BLOG_POST]: 'blog',
  [ContentTypes.EXTERNAL]: 'external',
  [ContentTypes.BLOG_LISTING_PAGE]: 'blogListingPage'
};