'use es6';

import { createAction } from 'redux-actions';
import keyMirror from 'react-utils/keyMirror';
export const BlogActionTypes = keyMirror({
  FETCH_BLOGS: null,
  REQUESTED_BLOGS: null,
  RECEIVED_BLOGS: null,
  REQUESTED_BLOGS_FAILED: null,
  NAVIGATED_TO_BLOG: null,
  SET_SELECTED_BLOG_ID: null
});
export const fetchBlogs = createAction(BlogActionTypes.FETCH_BLOGS, businessUnitId => ({
  businessUnitId
}));
export const requestBlogs = createAction(BlogActionTypes.REQUESTED_BLOGS);
export const receiveBlogs = createAction(BlogActionTypes.RECEIVED_BLOGS, blogs => ({
  blogs
}));
export const receiveBlogsFailure = createAction(BlogActionTypes.REQUESTED_BLOGS_FAILED, error => ({
  error
}));