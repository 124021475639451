'use es6';

import { createSelector } from 'reselect';
import I18n from 'I18n';
import { requestStatusFinished } from '../../shared/utils/requestStatuses';
import { getHasBlogAccess, getHasCmsHub } from '../../shared/selectors/authSelectors';
import { getBlogsData, getBlogsStatus, getCurrentlySelectedBlogs, getHasBlogsOtherThanDefaultHubspotBlog } from './blogSelectors';
import { getBlogPostsStartStateFetchStatus, getStartStateBlogPostsTotal } from './blogPostsSelectors';
import { BLOG_POSTS, BLOG_LISTING_PAGE, COMMENTS, TAGS, AUTHORS } from '../../shared/constants/ManagementDataTypes';
import { getAppDisplayModeSyncedContentGroupIdFilterValue } from '../../shared/selectors/appDisplayModeSyncedFiltersSelectors';
import { getManagementDataType } from '../../shared/utils/managementDataTypeHelpers';
import { ALL_FILTER } from '../../shared/constants/DefaultFilterParams';
import { getBlogTagsBlogFilter } from './tagsDisplaySelectors';
import { getBlogCommentsBlogFilter } from './commentsDisplaySelectors';
import { getBlogAuthorsBlogFilter } from './authorsDisplaySelectors';

// For showing start state with "set up your blog" CTA in framework table
export const getShouldShowFrameworkTableBlogEmptyState = createSelector([getHasBlogAccess, getBlogsStatus, getBlogsData], (hasBlogAccess, blogsRequestStatus, blogs) => {
  return hasBlogAccess && requestStatusFinished(blogsRequestStatus) && blogs.size === 0;
});
export const getShouldShowBlogPostsZeroState = createSelector([getStartStateBlogPostsTotal, getBlogPostsStartStateFetchStatus], (postsCount, blogPostsStartStateFetchStatus) => {
  return requestStatusFinished(blogPostsStartStateFetchStatus) && postsCount === 0;
});

// For showing the start state with "create new blog post" CTA
export const getShouldShowCmsHubOnboardingZeroState = createSelector([getBlogsStatus, getHasBlogsOtherThanDefaultHubspotBlog, getHasCmsHub, getBlogPostsStartStateFetchStatus, getStartStateBlogPostsTotal], (blogsRequestStatus, hasBlogsOtherThanDefaultHubspotBlog, hasCmsHub, blogPostsStartStateFetchStatus, startStateBlogPostsTotal) => {
  return hasCmsHub && requestStatusFinished(blogsRequestStatus) && requestStatusFinished(blogPostsStartStateFetchStatus) && startStateBlogPostsTotal === 0 && hasBlogsOtherThanDefaultHubspotBlog;
});
export const getBlogNamesWithAudienceAccessEnabled = createSelector([getCurrentlySelectedBlogs], selectedBlogs => selectedBlogs.reduce((acc, blog) => {
  if (blog.publicAccessRulesEnabled) {
    acc.push(blog.name);
  }
  return acc;
}, []));
export const getBlogManagementDataTypeSelectOptions = createSelector([getHasBlogAccess], hasBlogAccess => {
  if (hasBlogAccess) {
    return [{
      text: I18n.text('content-management-ui.managementDataTypes.blogPosts'),
      value: BLOG_POSTS
    }, {
      text: I18n.text('content-management-ui.managementDataTypes.blogListingPages'),
      value: BLOG_LISTING_PAGE
    }, {
      text: I18n.text('content-management-ui.managementDataTypes.tags'),
      value: TAGS
    }, {
      text: I18n.text('content-management-ui.managementDataTypes.comments'),
      value: COMMENTS
    }, {
      text: I18n.text('content-management-ui.managementDataTypes.authors'),
      value: AUTHORS
    }];
  }
  return [];
});

// For use in Large Sites 1.5 app only
export const getBlogIdToLinkTo = createSelector([getManagementDataType, getAppDisplayModeSyncedContentGroupIdFilterValue, getBlogTagsBlogFilter, getBlogCommentsBlogFilter, getBlogAuthorsBlogFilter], (managementDataType, blogPostsBlogFilter, tagsBlogFilter, commentsBlogFilter, authorsBlogFilter) => {
  let blogId;
  switch (managementDataType) {
    case BLOG_POSTS:
    case BLOG_LISTING_PAGE:
      {
        if (blogPostsBlogFilter && blogPostsBlogFilter.length === 1) {
          blogId = blogPostsBlogFilter[0];
        }
        break;
      }
    case TAGS:
      {
        if (tagsBlogFilter && tagsBlogFilter !== ALL_FILTER) {
          blogId = tagsBlogFilter;
        }
        break;
      }
    case COMMENTS:
      {
        if (commentsBlogFilter && commentsBlogFilter !== ALL_FILTER) {
          blogId = commentsBlogFilter;
        }
        break;
      }
    case AUTHORS:
      {
        if (authorsBlogFilter && authorsBlogFilter !== ALL_FILTER) {
          blogId = authorsBlogFilter;
        }
        break;
      }
    default:
  }
  return blogId;
});