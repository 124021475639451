'use es6';

import { combineEpics } from 'redux-observable';
import fetchBlogs from './blog/epics/fetchBlogs';
import fetchBlogPostsForStartState from './blog/epics/fetchBlogPostsForStartState';
import fetchDomains from './shared/epics/fetchDomains';
import fetchPagesForStartState from './pages/epics/fetchPagesForStartState';
import getProperties from './shared/epics/getProperties';
import navigatedToApp from './shared/epics/navigatedToApp';
import pagesFiltersUpdated from './pages/epics/pagesFiltersUpdated';
import fetchFilterableDomains from './pages/epics/fetchFilterableDomains';
import fetchFolders from './pages/epics/fetchFolders';
export default combineEpics(
// App
navigatedToApp,
// Blogs
fetchBlogs,
// Blog posts
fetchBlogPostsForStartState,
// Pages
fetchPagesForStartState, pagesFiltersUpdated,
// Properties
getProperties,
// Domains
fetchFilterableDomains, fetchDomains,
// Folders
fetchFolders);