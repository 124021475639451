'use es6';

import { OrderedMap } from 'immutable';
import { map } from 'rxjs/operators/map';
import { from } from 'rxjs/observable/from';
import { forkJoin } from 'rxjs/observable/forkJoin';
import httpRxjs from 'hub-http-rxjs/clients/apiClient';
import http from 'hub-http/clients/apiClient';
import { ContentTypes } from '../../shared/constants/ContentTypes';
import Page from '../models/Page';
import { PageStates, VARIANT_STATES } from '../constants/PageStates';
import { getEarlyRequesterResultObservable } from '../../shared/utils/getEarlyRequesterResultPromise';
import { MAB_AB_STATUS } from '../constants/Variations';
import { BulkActionNamesToRequestValue } from '../../shared/constants/BulkActions';
const URI = 'cospages/v2';
const ActionURIs = {
  AB_TEST: 'create-variation',
  ARCHIVE: 'archive',
  ARCHIVE_AND_UNPUBLISH: 'archive-and-unpublish',
  CLONE: 'clone',
  CONVERT: 'subcategory-move',
  DELETE: 'bulk'
};
const DEFAULT_OPTIONS = {
  abStatus__distinct: [MAB_AB_STATUS.variant],
  archived: false,
  campaign: null,
  folderId: null,
  isDraft: null,
  name__icontains: null,
  offset: 0,
  order: '-updated',
  publish_date__lte: null,
  publish_date__gt: null,
  state__in: null,
  state__nin: VARIANT_STATES.toArray(),
  domain__nlike: ['%sandbox%hs-sites.com', '%sandbox%hs-sitesqa.com', 'classic-migration-sandbox%'],
  property: ['ab', 'abTestId', 'absoluteUrl', 'archived', 'authorName', 'campaign', 'campaignName', 'categoryId', 'clonedFrom', 'contentTypeCategoryId', 'created', 'createdById', 'currentState', 'domain', 'folderId', 'htmlTitle', 'id', 'isPublished', 'label', 'language', 'mab', 'mabExperimentId', 'name', 'password', 'previewKey', 'publicAccessRules', 'publicAccessRulesEnabled', 'publishDate', 'resolvedDomain', 'screenshotPreviewUrl', 'screenshotPreviewTakenAt', 'securityState', 'slug', 'state', 'subcategory', 'teamPerms', 'templateType', 'templatePath', 'testStatus', 'title', 'translatedContent', 'translatedFromId', 'updated', 'updatedById', 'url', 'userPerms']
};
const getUri = (contentType, opts = {
  requestType: ''
}) => {
  const resource = contentType === ContentTypes.LANDING_PAGE ? 'landing-pages' : 'site-pages';
  const uri = opts.requestType === 'GET' ? `${URI}/dashboard` : URI;
  if (opts.domain && opts.domain !== 'all') {
    return `${uri}/${resource}/list/${opts.domain}`;
  }
  return `${uri}/${resource}`;
};
export const parsePages = ({
  objects,
  total
}) => {
  const pages = objects.reduce((acc, pageItem) => acc.set(pageItem.id, Page.from(pageItem)), new OrderedMap());
  return {
    pages,
    total
  };
};
const getPageIdArray = pages => pages.map(page => page.get('id')).toArray();
const splitPagesIntoContentTypes = pages => pages.reduce((acc, p) => {
  acc[p.contentType].push(p.id);
  return acc;
}, {
  [ContentTypes.LANDING_PAGE]: [],
  [ContentTypes.SITE_PAGE]: []
});
const makeMultipleContentTypeRequests = ({
  uri,
  requestMethod,
  pages,
  data,
  query
}) => {
  const {
    [ContentTypes.SITE_PAGE]: sitePages,
    [ContentTypes.LANDING_PAGE]: landingPages
  } = splitPagesIntoContentTypes(pages);
  return forkJoin([sitePages.length > 0 ? httpRxjs[requestMethod](`${getUri(ContentTypes.SITE_PAGE)}/${uri}`, {
    data,
    query: Object.assign({
      id: sitePages,
      failIfNotAllSucceed: true
    }, query)
  }) : from(Promise.resolve()), landingPages.length > 0 ? httpRxjs[requestMethod](`${getUri(ContentTypes.LANDING_PAGE)}/${uri}`, {
    data,
    query: Object.assign({
      id: landingPages,
      failIfNotAllSucceed: true
    }, query)
  }) : from(Promise.resolve())]);
};
export default class PagesApi {
  static archive(pages) {
    return makeMultipleContentTypeRequests({
      uri: ActionURIs.ARCHIVE,
      requestMethod: 'put',
      pages,
      data: {
        isArchived: true
      }
    });
  }
  static archiveAndUnpublish(pages) {
    return makeMultipleContentTypeRequests({
      uri: ActionURIs.ARCHIVE_AND_UNPUBLISH,
      requestMethod: 'put',
      pages
    });
  }
  static clone(page, name) {
    const {
      id,
      contentType
    } = page;
    return httpRxjs.post(`${getUri(contentType)}/${id}/${ActionURIs.CLONE}`, {
      data: {
        name,
        state: PageStates.DRAFT,
        isVariantOnly: false
      }
    });
  }
  static convert({
    id,
    contentType
  }) {
    const toType = contentType === ContentTypes.LANDING_PAGE ? 'site' : 'landing';
    return httpRxjs.post(`${getUri(contentType)}/${id}/${ActionURIs.CONVERT}`, {
      data: `${toType}_page`
    });
  }
  static createABTest(contentType, page, name) {
    return httpRxjs.post(`${getUri(contentType)}/${page.id}/${ActionURIs.AB_TEST}`, {
      data: name
    });
  }
  static delete(pages) {
    return makeMultipleContentTypeRequests({
      uri: ActionURIs.DELETE,
      requestMethod: 'delete',
      pages
    });
  }
  static unarchive(pages) {
    return makeMultipleContentTypeRequests({
      uri: ActionURIs.ARCHIVE,
      requestMethod: 'put',
      pages,
      data: {
        isArchived: false
      }
    });
  }
  static unpublish({
    contentType,
    id
  }) {
    return httpRxjs.post(`${getUri(contentType)}/${id}/publish-action`, {
      data: {
        action: 'cancel-publish'
      }
    });
  }
  static publish({
    contentType,
    id
  }) {
    return httpRxjs.post(`${getUri(contentType)}/${id}/publish-action`, {
      data: {
        action: 'schedule-publish'
      }
    });
  }
  static startBulkUpdate({
    contentType,
    pages,
    actionName,
    payload = {}
  }) {
    const data = {
      objectIds: getPageIdArray(pages),
      action: BulkActionNamesToRequestValue[actionName],
      payload
    };
    return httpRxjs.post(`${contentType ? getUri(contentType) : 'cospages/v2/pages'}/bulk-action/async`, {
      data
    });
  }
  static fetchPagesForStartState(contentType) {
    const query = Object.assign({}, DEFAULT_OPTIONS, {
      limit: 2
    });
    // Count archived pages when considering if we have any pages in portal for start state
    delete query.archived;
    const opts = {
      requestType: 'GET'
    };
    const request = getEarlyRequesterResultObservable({
      name: contentType === ContentTypes.SITE_PAGE ? 'fetch-site-pages-for-start-state' : 'fetch-landing-pages-for-start-state',
      retryRequest: () => http.get(getUri(contentType, opts), {
        query
      })
    });
    return request.pipe(map(parsePages));
  }
  static moveToFolder(pages, folderId) {
    return httpRxjs.put(`${getUri(ContentTypes.LANDING_PAGE)}/move-folder`, {
      data: folderId === 0 ? undefined : folderId,
      query: {
        id: getPageIdArray(pages),
        failIfNotAllSucceed: true
      }
    });
  }
  static createTranslationVariation({
    id,
    name,
    contentType
  }, language, masterLanguage) {
    return httpRxjs.post(`${getUri(contentType)}/${id}/create-language-variation`, {
      data: {
        name,
        language,
        masterLanguage
      }
    });
  }
  static assignPermissions(pages, teamIds, userIds) {
    const {
      [ContentTypes.SITE_PAGE]: sitePages,
      [ContentTypes.LANDING_PAGE]: landingPages
    } = splitPagesIntoContentTypes(pages);
    return forkJoin([sitePages.length > 0 ? httpRxjs.put(`${getUri(ContentTypes.SITE_PAGE)}/assign-permissions`, {
      data: {
        objectIds: sitePages,
        permissionRefIdsByType: {
          TEAM: teamIds,
          USER: userIds
        }
      }
    }) : from(Promise.resolve()), landingPages.length > 0 ? httpRxjs.put(`${getUri(ContentTypes.LANDING_PAGE)}/assign-permissions`, {
      data: {
        objectIds: landingPages,
        permissionRefIdsByType: {
          TEAM: teamIds,
          USER: userIds
        }
      }
    }) : from(Promise.resolve())]);
  }
  static attachToLanguageMaster({
    id,
    contentType
  }, language, translatedFromId) {
    return httpRxjs.put(`${getUri(contentType)}/${id}/attach-to-lang-master`, {
      data: {
        language,
        translatedFromId
      }
    });
  }
  static removeFromLanguageGroup({
    id,
    contentType
  }) {
    return httpRxjs.put(`${getUri(contentType)}/${id}/detach-from-lang-group`);
  }
  static convertToVariationMaster({
    contentType,
    id
  }) {
    return httpRxjs.put(`${getUri(contentType)}/${id}/set-new-lang-master`);
  }
}