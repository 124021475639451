'use es6';

import { createAction } from 'redux-actions';
import keyMirror from 'react-utils/keyMirror';
import { ContentTypes } from '../../shared/constants/ContentTypes';
import { getPagesAction } from './PagesActions';
export const FolderActionTypes = keyMirror({
  MARK_FOLDERS_STALE: null,
  FETCH_FOLDERS: null,
  REQUESTED_FOLDERS: null,
  RECEIVED_FOLDERS: null,
  REQUESTED_FOLDERS_FAILED: null,
  REQUESTED_CREATE_FOLDER: null,
  REQUESTED_DELETE_FOLDER: null,
  REQUESTED_RENAME_FOLDER: null,
  // filters
  UPDATE_FOLDERS_FILTER_QUERY_STATE: null,
  FOLDERS_CONTAINER_UNMOUNTED: null
});
export const unmountFoldersContainer = createAction(FolderActionTypes.FOLDERS_CONTAINER_UNMOUNTED);
export const markFoldersStale = createAction(FolderActionTypes.MARK_FOLDERS_STALE);
export const fetchFolders = createAction(FolderActionTypes.FETCH_FOLDERS);
export const requestFolders = createAction(FolderActionTypes.REQUESTED_FOLDERS);
export const receiveFolders = createAction(FolderActionTypes.RECEIVED_FOLDERS, folders => ({
  folders
}));
export const receiveFoldersFailure = createAction(FolderActionTypes.REQUESTED_FOLDERS_FAILED, error => ({
  error
}));
export const createFolder = createAction(FolderActionTypes.REQUESTED_CREATE_FOLDER, (name, parentFolderId) => ({
  name,
  parentFolderId
}));
export const deleteFolder = createAction(FolderActionTypes.REQUESTED_DELETE_FOLDER, folderId => ({
  folderId
}));
export const renameFolder = createAction(FolderActionTypes.REQUESTED_RENAME_FOLDER, (folderId, name) => ({
  folderId,
  name
}));
const _updateFoldersFilterQueryState = createAction(FolderActionTypes.UPDATE_FOLDERS_FILTER_QUERY_STATE, filterOptions => filterOptions);
export const updateFoldersFilterQueryState = filterOptions => dispatch => {
  dispatch(_updateFoldersFilterQueryState(filterOptions));
  dispatch(getPagesAction(ContentTypes.LANDING_PAGE, 'markPagesStale')());
};