'use es6';

import { createSelector } from 'reselect';
const getUserSettings = state => state.userSettings;
export const getUserSettingsData = createSelector(getUserSettings, userSettings => userSettings.data);
export const getUserSettingsStatus = createSelector(getUserSettings, userSettings => userSettings.status);
export const getUserSettingsFetchStatus = createSelector(getUserSettings, ({
  status
}) => status);
export const getUserSettingsSaveStatus = createSelector(getUserSettings, ({
  saveStatus
}) => saveStatus);