import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ContentCreatorInfoApi from '../api/ContentCreatorInfo';
export const fetchThemes = createAsyncThunk('contentCreatorInfo/fetchThemes', () => ContentCreatorInfoApi.fetchThemes());
const initialState = {
  createPageModalContentType: null,
  themes: []
};
const contentCreatorInfoSlice = createSlice({
  name: 'contentCreatorInfo',
  initialState,
  reducers: {
    setCreatePageModalContentType(state, {
      payload
    }) {
      state.createPageModalContentType = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchThemes.fulfilled, (state, {
      payload
    }) => {
      state.themes = payload;
    });
  }
});
export default contentCreatorInfoSlice.reducer;
export const {
  setCreatePageModalContentType
} = contentCreatorInfoSlice.actions;