import PortalIdParser from 'PortalIdParser';
// @ts-expect-error Untyped module
import { locationContainsPath } from './urls';
import { LANDING_PAGES, SITE_PAGES, BLOG_POSTS, BLOG_LISTING_PAGE
// @ts-expect-error Untyped module
} from '../constants/UrlPathParams';
import { ContentTypes, ContentTypesToLegacyContentTypeStrings } from '../constants/ContentTypeConstants';
// @ts-expect-error Untyped module
import SafeStorage from 'SafeStorage';
export const isTemporarySlug = url => url.includes('-temporary-slug-');
export const getTableViewContentTypeId = () => {
  if (locationContainsPath(window.location, LANDING_PAGES)) {
    return ContentTypes.LANDING_PAGE;
  } else if (locationContainsPath(window.location, SITE_PAGES)) {
    return ContentTypes.SITE_PAGE;
  } else if (locationContainsPath(window.location, BLOG_POSTS)) {
    return ContentTypes.BLOG_POST;
  } else if (locationContainsPath(window.location, BLOG_LISTING_PAGE)) {
    return ContentTypes.BLOG_LISTING_PAGE;
  }
  return undefined;
};
export const convertContentTypeIdToLegacyContentTypeString = contentTypeId => contentTypeId ? ContentTypesToLegacyContentTypeStrings[contentTypeId] : undefined;
export const unionOfSets = (set1, set2) => {
  let combinedSet = new Set();
  set2.forEach(item => {
    combinedSet = set1.add(item);
  });
  return combinedSet;
};
export const getSelectedBusinessUnitIdFromGlobalNav = () => {
  let selectedBusinessUnitStorageValue;
  try {
    selectedBusinessUnitStorageValue = SafeStorage.getItem('SELECTED_BUSINESS_UNIT');
    if (selectedBusinessUnitStorageValue) {
      selectedBusinessUnitStorageValue = JSON.parse(selectedBusinessUnitStorageValue);
    }
  } catch (e) {
    selectedBusinessUnitStorageValue = undefined;
  }
  const portalId = PortalIdParser.get();
  if (selectedBusinessUnitStorageValue && portalId) {
    return selectedBusinessUnitStorageValue[portalId];
  }
  return '0';
};