'use es6';

import { TagActionTypes } from '../actions/TagActions';
import Tags from '../models/Tags';
import RequestStatus from '../../shared/constants/RequestStatus';
import { ModalActionTypes } from '../../shared/actions/ModalActions';
export default ((state = new Tags(), action) => {
  switch (action.type) {
    case TagActionTypes.REQUESTED_COMBINE_TAGS_OPTIONS:
      return state.set('status', RequestStatus.PENDING);
    case TagActionTypes.RECEIVED_COMBINE_TAGS_OPTIONS:
      return state.merge({
        data: state.data.merge(action.payload.tags),
        status: RequestStatus.SUCCEEDED,
        total: action.payload.total
      });
    case TagActionTypes.REQUESTED_COMBINE_TAGS_OPTIONS_FAILED:
      return state.set('status', RequestStatus.FAILED);
    case ModalActionTypes.CLOSE_MODAL:
      return new Tags();
    default:
      return state;
  }
});