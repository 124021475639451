'use es6';

import { ALL_FILTER } from '../../shared/constants/DefaultFilterParams';
export const Variations = {
  [ALL_FILTER]: ALL_FILTER,
  AB: 'ab',
  MAB: 'mab',
  LANG: 'lang'
};
export const MAB_AB_STATUS = {
  master: 'MAB_MASTER',
  variant: 'MAB_VARIANT'
};
export const TEST_STATUS_FOR_CRM_SEARCH = {
  mabMaster: 'mab_master',
  mabVariant: 'mab_variant',
  abMaster: 'master',
  abVariant: 'variant',
  loserVariant: 'loser_variant'
};