import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { createEpicMiddleware } from 'redux-observable';

//@ts-expect-error Untyped module
import NotificationMiddleware from 'ReduxMessenger/middleware/NotificationMiddleware';
import rootReducer from './rootReducer';
//@ts-expect-error Untyped module
import { getRootEpic } from './asyncEpicsLoader';
export default function (initialState) {
  const epicMiddleware = createEpicMiddleware(getRootEpic());
  return configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: getDefaultMiddleware =>
    // TODO: enable serializableCheck once Immutable usage
    // is removed from redux state
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat(NotificationMiddleware, epicMiddleware)
  });
}
export const useAppDispatch = useDispatch;