import keyMirror from 'react-utils/keyMirror';
const SiteTreeActionTypes = keyMirror({
  SET_ZOOM_LEVEL: null,
  TOGGLE_TREE_OPEN: null,
  TOGGLE_SELECTED_CONTENT: null,
  TOGGLE_SHOULD_SHOW_ALL_URLS: null,
  TOGGLE_SHOULD_SHOW_STATUSES: null,
  TOGGLE_SHOULD_SHOW_TRANSLATIONS: null,
  REQUEST_INITIAL_SITE_TREE: null,
  RECEIVED_INITIAL_SITE_TREE: null,
  RECEIVED_INITIAL_SITE_TREE_FAILURE: null,
  LOAD_MORE_SITE_TREE_NODES: null,
  REQUEST_LOAD_MORE_SITE_TREE_NODES: null,
  RECEIVED_LOAD_MORE_SITE_TREE_NODES: null,
  RECEIVED_LOAD_MORE_SITE_TREE_NODES_FAILURE: null,
  SET_NODES_TO_BE_REMOVED: null,
  SET_NODES_TO_BE_MERGED: null,
  MARK_STALE: null,
  // Filters
  SITE_TREE_FILTERS_CHANGED: null,
  UPDATE_DEFAULT_RESOLVED_LANGUAGE: null,
  SITE_TREE_CONTAINER_UNMOUNTED: null
});
export default SiteTreeActionTypes;