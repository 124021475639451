'use es6';

import { Map as ImmutableMap } from 'immutable';
import { map } from 'rxjs/operators/map';
import I18n from 'I18n';
import http from 'hub-http-rxjs/clients/apiClient';
import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
import { FilterConfigurationsByProperty } from '../constants/filters';
import { ContentNameKeysToCrmPropertyNames } from '../constants/contentProperties';
const baseKey = 'content-management-ui.shared.properties.labels';
const parseProperties = properties => {
  const filterFields = Object.values(ContentNameKeysToCrmPropertyNames);
  return properties.reduce((acc, {
    property
  }) => {
    if (filterFields.includes(property.name)) {
      if (FilterConfigurationsByProperty.getIn([property.name, 'needsTranslatedLabel'])) {
        property.label = I18n.text(`${baseKey}.${property.name}`);
      }
      return acc.set(property.name, PropertyRecord.fromJS(property));
    }
    return acc;
  }, new ImmutableMap());
};
export default class PropertiesApi {
  static fetchProperties() {
    return http.get('properties/v4/default-properties/0-10/current').pipe(map(parseProperties));
  }
}