'use es6';

import { Record, Set as ImmutableSet } from 'immutable';
import { ALL_FILTER } from '../../shared/constants/DefaultFilterParams';
import { BlogTagsColumns, BlogTagsSortableColumnPreferredSortDirs } from '../constants/BlogTableColumns';
import { ITEMS_PER_PAGE } from '../../shared/constants/table';
const defaults = {
  tagsWithOpenNestedRows: new ImmutableSet(),
  selected: new ImmutableSet(),
  // filters
  currentPage: 1,
  selectedSort: BlogTagsColumns.NAME,
  selectedSortDir: BlogTagsSortableColumnPreferredSortDirs[BlogTagsColumns.NAME],
  tagStateFilter: ALL_FILTER,
  blogFilter: ALL_FILTER,
  search: '',
  rowsPerPage: ITEMS_PER_PAGE
};
export default class TagsDisplay extends Record(defaults) {}