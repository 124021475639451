import { gate as v3Gate } from 'hub-http/gates';

// @ts-expect-error Untyped module
import { ContentTypes, ContentTypeIds } from '../constants/ContentTypes';
import { ContentTypes as ContentTypesAsNumbers } from '../constants/ContentTypeConstants';
function getHasTeamsAccessForContentTypeScope(type) {
  switch (type) {
    case ContentTypes.BLOG:
    case ContentTypesAsNumbers.BLOG_POST:
    case ContentTypes.BLOG_LISTING_PAGE:
    case ContentTypesAsNumbers.BLOG_LISTING_PAGE:
      return 'blog-posts-teams-partitioning';
    case ContentTypes.LANDING_PAGE:
    case ContentTypesAsNumbers.LANDING_PAGE:
      return 'landingpages-teams-partitioning';
    case ContentTypes.SITE_PAGE:
    case ContentTypesAsNumbers.SITE_PAGE:
      return 'sitepages-teams-partitioning';
    default:
      return undefined;
  }
}
function getScopePrefix(contentType) {
  let prefix;

  // TODO: Switch to using the content type id
  // once we finish cutting over to using the
  // framework table fully
  switch (contentType) {
    case 'landing_page':
    case ContentTypeIds.landing:
    case ContentTypes.LANDING_PAGE:
      prefix = 'landingpages';
      break;
    case ContentTypeIds.blog:
    case ContentTypes.BLOG:
      prefix = 'blog';
      break;
    case ContentTypes.EXTERNAL_PAGE:
      break;
    default:
      prefix = 'pages';
  }
  return prefix;
}
export default class User {
  constructor(userProps, gates) {
    this.user_id = -1;
    this.first_name = '';
    this.last_name = '';
    this.email = '';
    this.locale = 'en';
    this.roles = [];
    this.scopes = [];
    this.lang_enabled = false;
    this.gates = [];
    this.user_id = userProps.user_id;
    this.first_name = userProps.first_name;
    this.last_name = userProps.last_name;
    this.email = userProps.email;
    this.locale = userProps.locale;
    this.roles = userProps.roles;
    this.scopes = userProps.scopes;
    this.lang_enabled = userProps.lang_enabled;
    this.gates = gates;
  }
  hasScope(scope) {
    return this.scopes.includes(scope);
  }
  hasGate(gate) {
    return this.gates.includes(gate);
  }
  isSuperAdmin() {
    return this.hasScope('super-admin');
  }
  hasAccessToCmsApprovals(contentType) {
    switch (contentType) {
      case ContentTypes.LANDING_PAGE:
      case ContentTypeIds.landing:
        return this.hasScope('landingpages-approval-read');
      case ContentTypes.SITE_PAGE:
      case ContentTypeIds.site:
        return this.hasScope('pages-approval-read');
      case ContentTypes.BLOG:
      case ContentTypeIds.blog:
        return this.hasScope('blog-approval-read');
      default:
        return false;
    }
  }
  hasLandingPagesAccess() {
    return this.hasScope('landingpages-access');
  }
  hasAdvancedLandingPagesAccess() {
    return this.hasScope('landingpages-advanced-write');
  }
  hasWebsiteAccess() {
    return this.hasScope('sitepages-access');
  }
  hasPageSubcategoryMoveAccess(subcategoryToMoveTo) {
    return this.hasScope('page-subcategory-move') && this.hasContentAccess(subcategoryToMoveTo);
  }
  hasBlogAccess() {
    return this.hasScope('blog-access');
  }
  hasBlogSettingsWriteAccess() {
    return this.hasScope('blog-settings-write');
  }
  hasCampaignsAccess() {
    return this.hasScope('campaigns-access');
  }
  hasMultiLanguageAccess(contentType) {
    switch (contentType) {
      case ContentTypes.LANDING_PAGE:
      case ContentTypeIds.landing:
        return this.hasScope('landingpages-multilanguage');
      case ContentTypes.SITE_PAGE:
      case ContentTypeIds.site:
        return this.hasScope('sitepages-multilanguage');
      case ContentTypes.BLOG:
      case ContentTypeIds.blog:
        return this.hasScope('blog-multilanguage');
      default:
        return false;
    }
  }
  hasAccountTrust() {
    return this.hasScope('landingpages-trusted');
  }
  hasMultiDomainPublishAccess() {
    return this.hasScope('multi-domain-publishing');
  }
  canWrite(contentType) {
    const prefix = getScopePrefix(contentType);
    return prefix ? this.hasContentAccess(contentType) && this.hasScope(`${prefix}-write`) : false;
  }
  canPublish(contentType) {
    const prefix = getScopePrefix(contentType);
    return prefix ? this.hasAccountTrust() && this.hasScope(`${prefix}-publish`) : false;
  }

  // This scope is for the new CMS Enterprise-only bulk actions: publish,
  // unpublish, and add to multi-language group.
  canBulkPublish(contentType) {
    const prefix = getScopePrefix(contentType);
    return prefix ? this.hasAccountTrust() && this.hasScope(`${prefix}-bulk-publish`) : false;
  }
  canCreateAbTest() {
    return this.hasScope('content-ab-testing');
  }
  canCreateMABTest() {
    return this.hasScope('content-mab-testing');
  }
  hasSocialAccess() {
    return this.hasScope('social-access');
  }
  hasEmailAccess() {
    return this.hasScope('email-access');
  }
  hasSiteExportAccess() {
    return this.hasScope('cms-site-export-access');
  }
  hasPagesAnalyticsAccess() {
    return this.hasScope('pages-analytics');
  }
  hasWebsiteAnalyticsAccess() {
    return this.hasScope('website-analytics');
  }
  hasGenericTeamsAccess() {
    return this.hasScope('teams-content-partitioning');
  }
  hasContentRemixAccess() {
    return this.hasScope('content-remix-write') && this.gates.includes('ContentRemix:QuickMix');
  }
  hasTeamsAccess(contentType) {
    const hasGenericTeamsPartitioning = this.hasGenericTeamsAccess();
    const hasTeamsAccessForContentTypeScope = getHasTeamsAccessForContentTypeScope(contentType);
    return hasTeamsAccessForContentTypeScope ? hasGenericTeamsPartitioning && this.hasScope(hasTeamsAccessForContentTypeScope) : false;
  }
  hasTeamAssignmentAccess() {
    return this.hasScope('content-team-assignment');
  }
  hasMembershipsAccess() {
    return this.hasScope('marketing-content-restricted-write');
  }
  hasDomainsAccess() {
    return this.hasScope('content-custom-domain-write');
  }
  hasBusinessUnitsAccess() {
    return this.hasScope('business-units-access');
  }
  hasContentAccess(type) {
    switch (type) {
      case ContentTypes.BLOG:
      case ContentTypesAsNumbers.BLOG_POST:
      case ContentTypesAsNumbers.BLOG_LISTING_PAGE:
        return this.hasBlogAccess();
      case ContentTypes.LANDING_PAGE:
      case ContentTypesAsNumbers.LANDING_PAGE:
        return this.hasLandingPagesAccess();
      case ContentTypes.SITE_PAGE:
      case ContentTypesAsNumbers.SITE_PAGE:
        return this.hasWebsiteAccess();
      default:
        return true;
    }
  }
  hasContentSettingsAccess() {
    return this.hasScope('content-settings-access');
  }
  hasThemedContentCreatorAccess() {
    return this.hasScope('themed-content-creator-access');
  }
  canBulkUpdateDomain(contentType) {
    switch (contentType) {
      case ContentTypes.LANDING_PAGE:
      case ContentTypesAsNumbers.LANDING_PAGE:
        return this.hasScope('landingpages-bulk-domain-change');
      case ContentTypes.SITE_PAGE:
      case ContentTypesAsNumbers.SITE_PAGE:
        return this.hasScope('sitepages-bulk-domain-change');
      default:
        return false;
    }
  }
  hasManagePrivateContentPermissions(contentType) {
    let hasManagePrivateContentScopeForContentType = false;
    let portalIsUngatedForManagePrivateContentPermissions = false;
    switch (contentType) {
      case ContentTypes.LANDING_PAGE:
      case ContentTypesAsNumbers.LANDING_PAGE:
        hasManagePrivateContentScopeForContentType = this.hasScope('landingpages-private-content-manage');
        portalIsUngatedForManagePrivateContentPermissions = this.hasGate(v3Gate('Permissions:LandingpagesPrivateContent'));
        break;
      case ContentTypes.SITE_PAGE:
      case ContentTypesAsNumbers.SITE_PAGE:
        hasManagePrivateContentScopeForContentType = this.hasScope('pages-private-content-manage');
        portalIsUngatedForManagePrivateContentPermissions = this.hasGate(v3Gate('Permissions:PagesPrivateContent'));
        break;
      case ContentTypes.BLOG:
      case ContentTypesAsNumbers.BLOG_POST:
        hasManagePrivateContentScopeForContentType = this.hasScope('blog-private-content-manage');
        portalIsUngatedForManagePrivateContentPermissions = this.hasGate(v3Gate('Permissions:BlogPrivateContent'));
        break;
      default:
        break;
    }
    return portalIsUngatedForManagePrivateContentPermissions ? hasManagePrivateContentScopeForContentType : this.canPublish(contentType);
  }
}