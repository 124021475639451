'use es6';

import { createSelector } from 'reselect';
import I18n from 'I18n';
import { getIsMarketingStarterPortal, getHasFreeLandingPages, getPortalHasSiteTreeAccess, getUserHasSitePagesAccess } from '../../shared/selectors/authSelectors';
import { getPagesSelector } from './pagesSelectors';
import { ContentTypes } from '../../shared/constants/ContentTypes';
import { getManagementDataGroup, getManagementDataType } from '../../shared/utils/managementDataTypeHelpers';
import { LANDING_PAGES, SITE_PAGES } from '../../shared/constants/ManagementDataTypes';
import { PAGES_MANAGEMENT_DATA_GROUP } from '../../shared/constants/ManagementDataGroups';
import { isAppViewSiteTree } from '../../shared/utils/urls';
import { getPagesUsageFetchSucceeded } from './pagesUsageSelectors';
export const getHasMarketingHubStarterLandingPagesEmptyState = createSelector([getIsMarketingStarterPortal, getHasFreeLandingPages, getPagesSelector(ContentTypes.LANDING_PAGE, 'getHasNoPagesInPortal')], (isMarketingStarterPortal, hasFreeLandingPages, hasNoPagesInPortal) => {
  return isMarketingStarterPortal && !hasFreeLandingPages && hasNoPagesInPortal;
});
export const getPagesManagementDataTypeSelectOptions = createSelector([getUserHasSitePagesAccess], hasSitePagesAccess => {
  const options = [{
    text: I18n.text('content-management-ui.managementDataTypes.landingPages'),
    value: LANDING_PAGES
  }];
  if (hasSitePagesAccess) {
    options.push({
      text: I18n.text('content-management-ui.managementDataTypes.sitePages'),
      value: SITE_PAGES
    });
  }
  return options;
});
export const getShouldShowSiteTreeToggle = createSelector([getPortalHasSiteTreeAccess, getManagementDataType, isAppViewSiteTree, getPagesUsageFetchSucceeded], (hasSiteTreeAccess, managementDataType, currentlyInSiteTree, pagesUsageFetchSucceeded) => {
  const managementDataGroup = getManagementDataGroup(managementDataType);
  return hasSiteTreeAccess && (currentlyInSiteTree || managementDataGroup === PAGES_MANAGEMENT_DATA_GROUP && pagesUsageFetchSucceeded);
});