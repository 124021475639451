module.exports = {
  "editTitle": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "namespace": "content-specialists-lib",
    "version": "1",
    "properties": {
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    }
  },
  "showMoreTitles": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "namespace": "content-specialists-lib",
    "version": "1",
    "properties": {
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    }
  },
  "clickGenerateTopic": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "namespace": "content-specialists-lib",
    "version": "1",
    "properties": {
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    }
  },
  "addSectionHeader": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "namespace": "content-specialists-lib",
    "version": "1",
    "properties": {
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    }
  },
  "removeSectionHeader": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "namespace": "content-specialists-lib",
    "version": "1",
    "properties": {
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    }
  },
  "changeHeaderOrder": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "namespace": "content-specialists-lib",
    "version": "1",
    "properties": {
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    }
  },
  "addTalkingPoint": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "namespace": "content-specialists-lib",
    "version": "1",
    "properties": {
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    }
  },
  "removeTalkingPoint": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "namespace": "content-specialists-lib",
    "version": "1",
    "properties": {
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    }
  },
  "regenerateHeader": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "namespace": "content-specialists-lib",
    "version": "1",
    "properties": {
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    }
  },
  "expandCustomizeDetails": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "namespace": "content-specialists-lib",
    "version": "1",
    "properties": {
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    }
  },
  "changeContentLanguage": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "properties": {
      "language": "string",
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    },
    "namespace": "content-specialists-lib",
    "version": "1"
  },
  "changeContentDomain": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "namespace": "content-specialists-lib",
    "version": "1",
    "properties": {
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    }
  },
  "icpInteraction": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "change-icp",
        "click-icp-settings-link",
        "click-refresh"
      ],
      "linkText": {
        "type": "string",
        "isOptional": true
      },
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    },
    "namespace": "content-specialists-lib",
    "version": "1"
  },
  "industryInteraction": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "namespace": "content-specialists-lib",
    "version": "1",
    "properties": {
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    }
  },
  "refetchedBrandVoice": {
    "name": "content-specialist-interaction",
    "class": "interaction",
    "namespace": "content-specialists-lib",
    "version": "1",
    "properties": {
      "contentType": [
        "BLOG_POST",
        "CASE_STUDY",
        "LANDING_PAGE",
        "PODCAST"
      ]
    }
  },
  "aiButtonInteraction": {
    "name": "aiButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "featureId": {
        "type": "string",
        "isOptional": true
      },
      "featureCategoryId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonInteraction": {
    "name": "copilotActionButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "prompt": {
        "type": "string",
        "isOptional": true
      },
      "hasPrefillValues": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copyMessageInteraction": {
    "name": "copyMessageInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_copied"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "feedbackInteraction": {
    "name": "inlineFeedbackInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_upvoted",
          "prompt_upvote_removed",
          "prompt_downvoted",
          "prompt_downvote_removed"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonPageView": {
    "name": "aiComponentsView",
    "class": "view",
    "properties": {
      "action": [
        "page_view"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  }
};