'use es6';

import { catchError } from 'rxjs/operators/catchError';
import { mergeMap } from 'rxjs/operators/mergeMap';
import { of } from 'rxjs/observable/of';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import { requestDomains, receiveDomains, receiveDomainsFailure, DomainActionTypes } from '../actions/DomainActions';
import Domains from '../api/Domains';
export default (action$ => action$.ofType(DomainActionTypes.FETCH_DOMAINS).pipe(mergeMap(({
  payload: {
    businessUnitId
  }
}) => Domains.fetchAllDomains(businessUnitId).pipe(map(receiveDomains), catchError(error => of(receiveDomainsFailure(error))), startWith(requestDomains())))));