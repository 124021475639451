import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["translations", "translatedFromId"];
import { Map as ImmutableMap, Record } from 'immutable';
import ContentRoutes from 'ContentUtils/Routes';
const defaults = {
  absoluteUrl: '',
  id: '',
  portalId: 0,
  name: '',
  slug: '',
  updated: 0,
  created: 0,
  itemTemplateIsShared: true,
  itemLayoutId: 0,
  itemTemplatePath: '',
  language: '',
  listingLayoutId: 0,
  listingLayoutPath: '',
  listingPageId: '',
  listingTemplatePath: '',
  translatedFromId: null,
  subscriptionListsByType: new Map(),
  publicAccessRules: [],
  publicAccessRulesEnabled: false,
  resolvedDomain: '',
  translations: ImmutableMap()
};
class Blog extends Record(defaults) {
  constructor(blog) {
    super(blog);
  }
  static from(json) {
    const {
        translations: rawTranslations
      } = json,
      rest = _objectWithoutPropertiesLoose(json, _excluded);
    const receivedJson = Object.assign({}, defaults, rest, {
      id: String(json.id)
    });
    const translations = {};
    if (rawTranslations) {
      Object.keys(rawTranslations).forEach(lang => {
        const translation = rawTranslations[lang];
        if (translation) {
          translations[lang] = new Blog(Object.assign({}, translation, {
            id: String(translation.id),
            translatedFromId: translation.masterId || translation.translatedFromId
          }));
        }
      });
      receivedJson.translations = ImmutableMap(translations);
    }
    return new Blog(receivedJson);
  }
  getPublicUrl() {
    let publicUrl = this.absoluteUrl;
    if (!publicUrl) {
      publicUrl = '';
    }
    return publicUrl.replace('https://', '').replace('http://', '');
  }
  getListingPageUrl() {
    return this.listingPageId ? ContentRoutes.edit(this.listingPageId, 'blog_listing_page') : '';
  }
  hasTranslations() {
    return !!this.translations && this.translations.size > 0;
  }
  isTranslation() {
    return !!this.translatedFromId;
  }
}
export default Blog;