import { createAsyncThunk } from '@reduxjs/toolkit';
import { createAction } from 'redux-actions';
import keyMirror from 'react-utils/keyMirror';
import { CREATE_CONTENT_ACTIONS, trackFailedAction, trackSuccessfulAction
// @ts-expect-error Untyped module
} from '../../shared/utils/usageTrackerHelpers';
// @ts-expect-error Untyped module
import { ContentTypes } from '../../shared/constants/ContentTypes';
// @ts-expect-error Untyped module
import BlogPosts from '../api/BlogPosts';
import ContentRoutes from 'ContentUtils/Routes';
import { navigateTo } from '../../shared/utils/navigationUtils';
// @ts-expect-error Untyped module
import { showFailureNotification } from '../../shared/utils/alertUtils';
// @ts-expect-error Untyped module
import { getShouldShowCmsHubOnboardingZeroState } from '../selectors/blogUISelectors';
import { CMS_ONBOARDING_GET_STARTED_ACTIONS } from '../../shared/constants/usageTrackerEvents';
export const BlogPostsActionTypes = keyMirror({
  REQUESTED_BLOG_POSTS_FOR_START_STATE: null,
  RECEIVED_BLOG_POSTS_FOR_START_STATE: null,
  REQUESTED_BLOG_POSTS_FOR_START_STATE_FAILED: null,
  FETCH_BLOG_POSTS_FOR_START_STATE: null,
  REQUESTED_CREATE_BLOG_POST: null
});
export const requestBlogPostsForStartState = createAction(BlogPostsActionTypes.REQUESTED_BLOG_POSTS_FOR_START_STATE);
export const receiveBlogPostsForStartState = createAction(BlogPostsActionTypes.RECEIVED_BLOG_POSTS_FOR_START_STATE);
export const receiveBlogPostsForStartStateFailure = createAction(BlogPostsActionTypes.REQUESTED_BLOG_POSTS_FOR_START_STATE_FAILED, error => ({
  error
}));
export const fetchBlogPostsForStartState = createAction(BlogPostsActionTypes.FETCH_BLOG_POSTS_FOR_START_STATE);
export const createBlogPost = createAsyncThunk(BlogPostsActionTypes.REQUESTED_CREATE_BLOG_POST, ({
  blogId,
  language
}, {
  getState
}) => {
  const shouldShowCmsHubOnboardingZeroState = getShouldShowCmsHubOnboardingZeroState(getState());
  const action = shouldShowCmsHubOnboardingZeroState ? CMS_ONBOARDING_GET_STARTED_ACTIONS.BLOG_POST_INLINE : CREATE_CONTENT_ACTIONS.BLOG_POST_INLINE;
  return BlogPosts.createBlogPost(blogId, language).toPromise().then(blogPost => {
    trackSuccessfulAction('contentActionsInteractionCreateButton', {
      action,
      contentType: ContentTypes.BLOG
    }, true);
    navigateTo(ContentRoutes.edit(blogPost.id, blogPost.categoryId));
  }).catch(error => {
    showFailureNotification({
      contentType: ContentTypes.BLOG,
      featureType: 'blogPosts',
      action: 'create',
      response: error
    });
    trackFailedAction('contentActionsInteractionCreateButton', {
      action,
      contentType: ContentTypes.BLOG
    });
  });
});