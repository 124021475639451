import { createSelector } from 'reselect';
import RequestStatus from '../../shared/constants/RequestStatus';
import { getHasFreeBlog } from '../../shared/selectors/authSelectors';
import { requestStatusSucceeded } from '../../shared/utils/requestStatuses';
const getBlogUsageState = state => state.blog.blogUsage;

// Blog usage selectors

const getBlogsUsageStatus = createSelector([getBlogUsageState], ({
  blogUsageStatus
}) => blogUsageStatus);
export const getBlogLimit = createSelector([getBlogUsageState], ({
  blogLimit
}) => blogLimit);
export const getBlogsUsed = createSelector([getBlogUsageState], ({
  blogsUsed
}) => blogsUsed);
export const getHasMoreBlogsThanLimit = createSelector([getBlogLimit, getBlogsUsed, getBlogsUsageStatus], (blogLimit, blogsUsed, blogsUsageStatus) => blogsUsageStatus === RequestStatus.SUCCEEDED && blogLimit !== null && blogsUsed > blogLimit);
export const getHasReachedBlogsLimit = createSelector([getBlogLimit, getBlogsUsed, getBlogsUsageStatus], (blogLimit, blogsUsed, blogsUsageStatus) => blogsUsageStatus === RequestStatus.SUCCEEDED && blogLimit !== null && blogsUsed >= blogLimit);
export const getHasSingleBlog = createSelector([getBlogLimit, getBlogsUsed, getBlogsUsageStatus], (blogLimit, blogsUsed, blogUsageStatus) => blogUsageStatus === RequestStatus.SUCCEEDED && (blogLimit === 1 || blogsUsed === 1));

// Blog posts usage selectors

export const getBlogPostsUsageStatus = createSelector([getBlogUsageState], ({
  blogPostsUsageStatus
}) => blogPostsUsageStatus);
export const getBlogPostsLimit = createSelector([getBlogUsageState], ({
  blogPostsLimit
}) => blogPostsLimit);
export const getBlogPostsUsed = createSelector([getBlogUsageState], ({
  blogPostsUsed
}) => blogPostsUsed);
export const getHasMoreBlogPostsThanLimit = createSelector([getBlogPostsLimit, getBlogPostsUsed, getBlogPostsUsageStatus], (blogPostsLimit, blogPostsUsed, blogPostsUsageStatus) => blogPostsUsageStatus === RequestStatus.SUCCEEDED && blogPostsLimit !== null && blogPostsUsed > blogPostsLimit);
export const getHasReachedBlogPostsLimit = createSelector([getBlogPostsLimit, getBlogPostsUsed, getBlogPostsUsageStatus], (blogPostsLimit, blogPostsUsed, blogPostsUsageStatus) => blogPostsUsageStatus === RequestStatus.SUCCEEDED && blogPostsLimit !== null && blogPostsUsed >= blogPostsLimit);
const getHasMoreBlogsOrBlogPostsThanLimit = createSelector([getHasMoreBlogsThanLimit, getHasMoreBlogPostsThanLimit], (hasMoreBlogsThanLimit, hasMoreBlogPostsThanLimit) => hasMoreBlogsThanLimit || hasMoreBlogPostsThanLimit);
export const getCannotCreateNewBlogPosts = createSelector([getHasMoreBlogsThanLimit, getHasReachedBlogPostsLimit], (hasMoreBlogsThanLimit, hasReachedBlogPostsLimit) => hasMoreBlogsThanLimit || hasReachedBlogPostsLimit);
export const getHasOnlyReachedBlogPostsLimit = createSelector([getHasMoreBlogsThanLimit, getHasReachedBlogPostsLimit], (hasMoreBlogsThanLimit, hasReachedBlogPostsLimit) => !hasMoreBlogsThanLimit && hasReachedBlogPostsLimit);
export const getShouldShowFreeBlogUsageLimitBanner = createSelector([getHasFreeBlog, getBlogPostsLimit, getBlogPostsUsed, getHasMoreBlogsOrBlogPostsThanLimit], (hasFreeBlog, blogPostsLimit, blogPostsUsed, hasMoreBlogsOrBlogPostsThanLimit) => hasFreeBlog && !hasMoreBlogsOrBlogPostsThanLimit && blogPostsLimit && blogPostsLimit - blogPostsUsed <= 10);

// mLang limit usage selectors
export const getMlangBlogPostLimit = createSelector([getBlogUsageState], ({
  mLangBlogPostLimit
}) => mLangBlogPostLimit);
export const getMlangBlogPostLimitRequestStatus = createSelector([getBlogUsageState], ({
  mLangBlogPostLimitStatus
}) => mLangBlogPostLimitStatus);
export const getHasMlangBlogPostLimit = createSelector([getMlangBlogPostLimitRequestStatus, getMlangBlogPostLimit], (mlangBlogPostLimitRequestStatus, mlangBlogPostLimit) => requestStatusSucceeded(mlangBlogPostLimitRequestStatus) && !!mlangBlogPostLimit);