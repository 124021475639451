'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["hasMore", "slug", "children", "absoluteUrl", "resolvedDomain"];
import { List, Record } from 'immutable';
import Page from '../../pages/models/Page';
const defaults = {
  hasMore: false,
  slug: '',
  absoluteUrl: '',
  resolvedDomain: '',
  children: new List(),
  // save `Page` model here if there is a page at this node
  content: null
};
class SiteTreeNode extends Record(defaults) {
  static from(json) {
    const {
        hasMore,
        slug,
        children,
        absoluteUrl,
        resolvedDomain
      } = json,
      content = _objectWithoutPropertiesLoose(json, _excluded);
    const receivedJson = {
      hasMore,
      slug,
      absoluteUrl,
      resolvedDomain,
      children: List(children.map(child => SiteTreeNode.from(child)))
    };
    if (content.id) {
      receivedJson.content = Page.from(Object.assign({}, content, {
        slug,
        absoluteUrl,
        resolvedDomain
      }));
    }
    return new SiteTreeNode(receivedJson);
  }
}
export default SiteTreeNode;