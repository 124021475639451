'use es6';

import Folders from '../models/Folders';
import { FolderActionTypes } from '../actions/FolderActions';
import RequestStatus from '../../shared/constants/RequestStatus';
export default ((state = new Folders(), action) => {
  switch (action.type) {
    case FolderActionTypes.REQUESTED_FOLDERS:
      return state.merge({
        stale: false,
        status: RequestStatus.PENDING
      });
    case FolderActionTypes.RECEIVED_FOLDERS:
      return state.merge({
        rootFolder: action.payload.folders.rootFolder,
        status: RequestStatus.SUCCEEDED,
        total: action.payload.folders.rootFolder.childFolders.size
      });
    case FolderActionTypes.REQUESTED_FOLDERS_FAILED:
      return state.set('status', RequestStatus.FAILED);
    case FolderActionTypes.MARK_FOLDERS_STALE:
      return state.set('stale', true);
    default:
      return state;
  }
});