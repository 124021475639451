'use es6';

export const FOLDERS = 'folders';
export const PAGES = 'pages';
export const LANDING_PAGES = 'landing';
export const SITE_PAGES = 'site';
export const BLOG = 'blog';
export const BLOG_POSTS = 'posts';
export const BLOG_LISTING_PAGE = 'listing';
export const BLOG_TAGS = 'tags';
export const BLOG_COMMENTS = 'comments';
export const BLOG_AUTHORS = 'authors';
export const SITE_TREE = 'tree';