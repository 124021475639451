'use es6';

import { Subject } from 'rxjs/Subject';
import { map } from 'rxjs/operator/map';
import { switchMap } from 'rxjs/operator/switchMap';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { filter } from 'rxjs/operator/filter';
import { merge } from 'rxjs/observable/merge';
const keyHasType = (type, key) => {
  return type === key || typeof key === 'function' && type === key.toString();
};
function ofType(...keys) {
  return function ofTypeOperatorFunction(source) {
    return filter.call(source, ({
      type
    }) => {
      const len = keys.length;
      if (len === 1) {
        return keyHasType(type, keys[0]);
      } else {
        for (let i = 0; i < len; i++) {
          if (keyHasType(type, keys[i])) {
            return true;
          }
        }
      }
      return false;
    });
  };
}
class ActionsObservable extends Observable {
  static of(...actions) {
    return new this(of(...actions));
  }
  static from(actions, scheduler) {
    return new this(from(actions, scheduler));
  }
  constructor(actionsSubject) {
    super();
    this.source = actionsSubject;
  }
  lift(operator) {
    const observable = new ActionsObservable(this);
    observable.operator = operator;
    return observable;
  }
  ofType(...keys) {
    return ofType(...keys)(this);
  }
}
const EPIC_END = '@@redux-observable/EPIC_END';
const deprecationsSeen = {};
const deprecate = typeof console === 'object' && typeof console.warn === 'function' ? msg => {
  if (!deprecationsSeen[msg]) {
    deprecationsSeen[msg] = true;
    console.warn(`redux-observable | DEPRECATION: ${msg}`);
  }
} : () => {};
const defaultAdapter = {
  input: action$ => action$,
  output: action$ => action$
};
const defaultOptions = {
  adapter: defaultAdapter
};
function createEpicMiddleware(rootEpic, options = defaultOptions) {
  if (typeof rootEpic !== 'function') {
    throw new TypeError('You must provide a root Epic to createEpicMiddleware');
  } // even though we used default param, we need to merge the defaults
  // inside the options object as well in case they declare only some

  options = Object.assign({}, defaultOptions, options);
  const input$ = new Subject();
  const action$ = options.adapter.input(new ActionsObservable(input$));
  const epic$ = new Subject();
  let store;
  const epicMiddleware = _store => {
    store = _store;
    return next => {
      var _context;
      (_context = map.call(epic$, epic => {
        const vault = process.env.NODE_ENV === 'production' ? store : {
          getState: store.getState,
          dispatch: action => {
            deprecate('calling store.dispatch() directly in your Epics is deprecated and will be removed. Instead, emit actions through the Observable your Epic returns.\n\n  https://goo.gl/WWNYSP');
            return store.dispatch(action);
          }
        };
        const output$ = 'dependencies' in options ? epic(action$, vault, options.dependencies) : epic(action$, vault);
        if (!output$) {
          throw new TypeError(`Your root Epic "${epic.name || '<anonymous>'}" does not return a stream. Double check you\'re not missing a return statement!`);
        }
        return output$;
      }), switchMap).call(_context, output$ => options.adapter.output(output$)).subscribe(action => {
        try {
          store.dispatch(action);
        } catch (err) {
          console.error(err);
        }
      }); // Setup initial root epic

      epic$.next(rootEpic);
      return action => {
        const result = next(action);
        input$.next(action);
        return result;
      };
    };
  };
  epicMiddleware.replaceEpic = rootEpic => {
    // gives the previous root Epic a last chance
    // to do some clean up
    store.dispatch({
      type: EPIC_END
    }); // switches to the new root Epic, synchronously terminating
    // the previous one

    epic$.next(rootEpic);
  };
  return epicMiddleware;
}

/**
  Merges all epics into a single one.
 */

const combineEpics = (...epics) => (...args) => merge(...epics.map(epic => {
  const output$ = epic(...args);
  if (!output$) {
    throw new TypeError(`combineEpics: one of the provided Epics "${epic.name || '<anonymous>'}" does not return a stream. Double check you\'re not missing a return statement!`);
  }
  return output$;
}));
export { ActionsObservable, EPIC_END, combineEpics, createEpicMiddleware, ofType };