'use es6';

import { createSelector } from 'reselect';
import { OrderedMap, Set as ImmutableSet } from 'immutable';
import I18n from 'I18n';
import { createSelectOptions } from '../../shared/utils/selectorHelpers';
const getAuthorsState = state => state.blog.authors;
export const getAuthorsData = createSelector([getAuthorsState], authors => authors.data);
export const getAuthorsStale = createSelector([getAuthorsState], authors => authors.stale);
export const getAuthorsStatus = createSelector([getAuthorsState], authors => authors.status);
export const getAuthorsTotal = createSelector([getAuthorsState], authors => authors.total);
const getAuthorsOptionsState = state => state.blog.authorsOptions;
const getAuthorsWithEmailOptionsState = state => state.blog.authorsWithEmailOptions;
export const getAuthorsOptionsData = createSelector([getAuthorsOptionsState], authors => authors.data);
export const getAuthorsOptionsStatus = createSelector([getAuthorsOptionsState], authors => authors.status);
export const getAuthorsOptionsTotal = excludeAuthorIds => createSelector([getAuthorsOptionsState], authors => excludeAuthorIds ? authors.total - excludeAuthorIds.size : authors.total);
export const getAuthorsWithEmailOptionsData = createSelector([getAuthorsWithEmailOptionsState], authors => authors.data);
export const getAuthorsWithEmailOptionsStatus = createSelector([getAuthorsWithEmailOptionsState], authors => authors.status);
export const getAuthorsWithEmailOptionsTotal = excludeAuthorIds => createSelector([getAuthorsWithEmailOptionsState], authors => excludeAuthorIds ? authors.total - excludeAuthorIds.size : authors.total);
const makeGetAuthorsOptions = authorsOptionsDataSelector => (includeAllOption, excludeAuthorIds = new ImmutableSet()) => createSelector([authorsOptionsDataSelector], authors => {
  const args = [authors.filter(author => !excludeAuthorIds.has(author.id)), 'id', 'name'];
  if (includeAllOption) {
    args.push('content-management-ui.header.filters.allAuthors');
  }
  return createSelectOptions(...args);
});
export const getAuthorsAsSelectOptions = makeGetAuthorsOptions(getAuthorsOptionsData);
export const getAuthorsWithEmailAsSelectOptions = makeGetAuthorsOptions(getAuthorsWithEmailOptionsData);
export const _getTranslatedAuthors = authors => authors.reduce((translatedAuthorsMap, author) => author.get('translations').reduce((acc, translation) => acc.set(translation.id, translation), translatedAuthorsMap), OrderedMap());
export const getTranslatedAuthors = createSelector(getAuthorsData, _getTranslatedAuthors);
const getCombineAuthorsOptionsState = state => state.blog.combineAuthorsOptions;
const getCombineAuthorsOptionsData = createSelector([getCombineAuthorsOptionsState], authors => authors.data);
export const getCombineAuthorsOptionsStatus = createSelector([getCombineAuthorsOptionsState], authors => authors.status);
export const getCombineAuthorsOptionsTotal = (excludeAuthorIds = new ImmutableSet()) => createSelector([getCombineAuthorsOptionsState], authors => authors.total - excludeAuthorIds.size);
export const getCombineAuthorsAsSelectOptions = (excludeAuthorIds = new ImmutableSet()) => createSelector([getCombineAuthorsOptionsData], authors => {
  const authorsWithoutExcluded = authors.filterNot(author => excludeAuthorIds.has(author.id));
  return createSelectOptions(authorsWithoutExcluded, 'id', 'name');
});
const getAssignableAuthorOptions = state => state.blog.assignableAuthorOptions;
export const getAssignableAuthorOptionsData = createSelector([getAssignableAuthorOptions], assignableAuthorOptions => {
  return assignableAuthorOptions.get('assignableOptions');
});
export const getAssignableAuthorOptionsFetchStatuses = createSelector([getAssignableAuthorOptions], assignableAuthorOptions => assignableAuthorOptions.get('assignableOptionsFetchStatuses'));
export const getInitialAssignableAuthorOptionsFetchStatus = createSelector([getAssignableAuthorOptions], assignableAuthorOptions => assignableAuthorOptions.get('initialAssignableOptionsFetchStatus'));
export const getAssignableAuthorOptionsTotals = createSelector([getAssignableAuthorOptions], assignableAuthorOptions => assignableAuthorOptions.get('assignableOptionsTotals'));
const getSelectAssignableOptionsForAuthor = (optionsForEntity, excludeLanguage = false) => optionsForEntity.reduce((selectOptions, option) => {
  const languageText = !excludeLanguage && option.language ? I18n.text(`SharedI18nStrings.languageNames.${option.language}`) : null;
  selectOptions.push({
    text: languageText ? `${option.name} (${languageText})` : option.name,
    value: option.id
  });
  return selectOptions;
}, []);
export const getAssignableAuthorOptionsForSelects = createSelector([getAssignableAuthorOptionsData, getAuthorsData], (assignableAuthorOptionsData, authors) => {
  return assignableAuthorOptionsData.map((optionsForAuthor, authorId) => {
    const author = authors.get(authorId);
    const excludeLanguage = author ? author.hasTranslations() : false;
    return getSelectAssignableOptionsForAuthor(optionsForAuthor, excludeLanguage);
  });
});