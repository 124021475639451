'use es6';

import { Map as ImmutableMap } from 'immutable';
import { AuthorActionTypes } from '../actions/AuthorActions';
import RequestStatus from '../../shared/constants/RequestStatus';
const AssignableAuthorOptions = new ImmutableMap({
  assignableOptions: new ImmutableMap(),
  // Map of OrderedMap of Author id -> Author
  assignableOptionsFetchStatuses: new ImmutableMap(),
  // Map of Strings
  assignableOptionsTotals: new ImmutableMap(),
  // Map of Numbers
  initialAssignableOptionsFetchStatus: RequestStatus.UNINITIALIZED
});
const setAssignableOptionsForAuthorInState = (state, authorId, authors, total) => {
  const currentAssignableOptions = state.getIn(['assignableOptions', authorId]);
  return state.setIn(['assignableOptions', authorId], currentAssignableOptions ? currentAssignableOptions.merge(authors) : authors).setIn(['assignableOptionsFetchStatuses', authorId], RequestStatus.SUCCEEDED).setIn(['assignableOptionsTotals', authorId], total);
};
export default ((state = AssignableAuthorOptions, {
  payload,
  type
}) => {
  switch (type) {
    case AuthorActionTypes.REQUESTED_ASSIGNABLE_AUTHOR_OPTIONS:
      return state.setIn(['assignableOptionsFetchStatuses', payload.id], RequestStatus.PENDING);
    case AuthorActionTypes.REQUESTED_INITIAL_ASSIGNABLE_AUTHOR_OPTIONS:
      return state.set('initialAssignableOptionsFetchStatus', RequestStatus.PENDING);
    case AuthorActionTypes.RECEIVED_ASSIGNABLE_AUTHOR_OPTIONS:
      return setAssignableOptionsForAuthorInState(state, payload.id, payload.authors, payload.total);
    case AuthorActionTypes.RECEIVED_INITIAL_ASSIGNABLE_AUTHOR_OPTIONS:
      return payload.responses.reduce((updatedState, {
        id,
        authors,
        total
      }) => setAssignableOptionsForAuthorInState(updatedState, id, authors, total), state.set('initialAssignableOptionsFetchStatus', RequestStatus.SUCCEEDED));
    case AuthorActionTypes.REQUESTED_ASSIGNABLE_AUTHOR_OPTIONS_FAILED:
      return state.setIn(['assignableOptionsFetchStatuses', payload.id], RequestStatus.FAILED);
    case AuthorActionTypes.REQUESTED_INITIAL_ASSIGNABLE_AUTHOR_OPTIONS_FAILED:
      return state.set('initialAssignableOptionsFetchStatus', RequestStatus.FAILED);
    case AuthorActionTypes.CLEAR_ASSIGNABLE_AUTHOR_OPTIONS:
      return AssignableAuthorOptions;
    default:
      return state;
  }
});