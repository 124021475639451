'use es6';

import { Record, Set as ImmutableSet } from 'immutable';
import { ALL_FILTER } from '../../shared/constants/DefaultFilterParams';
import { BlogCommentsColumns, BlogCommentsSortableColumnPreferredSortDirs } from '../constants/BlogTableColumns';
import { ITEMS_PER_PAGE } from '../../shared/constants/table';
const defaults = {
  selected: new ImmutableSet(),
  // filters
  currentPage: 1,
  authorFilter: ALL_FILTER,
  blogFilter: ALL_FILTER,
  commentStateFilter: ALL_FILTER,
  selectedSort: BlogCommentsColumns.POSTED,
  selectedSortDir: BlogCommentsSortableColumnPreferredSortDirs[BlogCommentsColumns.POSTED],
  search: '',
  rowsPerPage: ITEMS_PER_PAGE
};
export default class CommentsDisplay extends Record(defaults) {}