import http from 'hub-http/clients/apiClient';
import { getEarlyRequesterResultPromise } from '../../shared/utils/getEarlyRequesterResultPromise';
const URI = 'cospages/v2';
export default class PagesUsage {
  static fetchSharedPageUsage() {
    return getEarlyRequesterResultPromise({
      name: 'fetch-shared-pages-usage',
      retryRequest: () => http.get(`${URI}/pages/usage`)
    });
  }
}