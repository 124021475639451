'use es6';

import keyMirror from 'react-utils/keyMirror';
import { createAction } from 'redux-actions';
export const PropertiesActionTypes = keyMirror({
  REQUESTED_PROPERTIES: null,
  RECEIVED_PROPERTIES: null,
  REQUESTED_PROPERTIES_FAILED: null,
  UPDATE_PROPERTY_OPTIONS: null,
  UPDATE_PROPERTY: null
});
export const requestProperties = createAction(PropertiesActionTypes.REQUESTED_PROPERTIES);
export const receiveProperties = createAction(PropertiesActionTypes.RECEIVED_PROPERTIES, properties => ({
  properties
}));
export const receivePropertiesFailure = createAction(PropertiesActionTypes.REQUESTED_PROPERTIES_FAILED, error => ({
  error
}));
export const updatePropertyOptions = createAction(PropertiesActionTypes.UPDATE_PROPERTY_OPTIONS, (propertyName, options) => ({
  propertyName,
  options
}));
export const updateProperty = createAction(PropertiesActionTypes.UPDATE_PROPERTY, (propertyName, propertyData) => ({
  propertyName,
  propertyData
}));