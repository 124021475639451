'use es6';

import { Record, Set as ImmutableSet } from 'immutable';
import { FOLDERS } from '../../shared/constants/ManagementDataTypes';
const defaults = {
  id: null,
  name: '',
  childFolders: ImmutableSet(),
  parentFolderId: null,
  items: ImmutableSet(),
  managementDataType: FOLDERS
};
class Folder extends Record(defaults) {
  static from(json) {
    const childFolders = ImmutableSet(json.childFolders.map(folder => Folder.from(Object.assign({}, folder, {
      parentFolderId: json.id
    }))));
    const items = ImmutableSet(json.items);
    return new Folder(Object.assign({}, json, {
      childFolders,
      items
    }));
  }
  getName() {
    return this.name;
  }
}
export default Folder;