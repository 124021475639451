import intersection from 'hs-lodash/intersection';
import RequestStatus from '../constants/RequestStatus';

// "Loading" here refers to when a loading indicator is shown, not if a request is in flight
const LOADING_STATUSES = [RequestStatus.UNINITIALIZED, RequestStatus.PENDING];
const FINISHED_STATUSES = [RequestStatus.SUCCEEDED, RequestStatus.FAILED];
const FAILED_STATUSES = [RequestStatus.FAILED];
const SUCCEEDED_STATUSES = [RequestStatus.SUCCEEDED];
export const requestStatusFinished = status => FINISHED_STATUSES.includes(status);
export const requestStatusUninitialized = status => status === RequestStatus.UNINITIALIZED;
export const requestStatusPending = status => status === RequestStatus.PENDING;
export const requestStatusSucceeded = status => status === RequestStatus.SUCCEEDED;
export const requestStatusUpdatedToSucceeded = (first, second) => first === RequestStatus.PENDING && second === RequestStatus.SUCCEEDED;
export const requestStatusUpdatedToFinish = (first, second) => first === RequestStatus.PENDING && requestStatusFinished(second);
export const anyRequestStatusesLoading = statuses => intersection(LOADING_STATUSES, statuses).length > 0;
export const anyRequestStatusesFailed = statuses => intersection(FAILED_STATUSES, statuses).length > 0;
export const anyRequestStatusesSucceeded = statuses => intersection(SUCCEEDED_STATUSES, statuses).length > 0;
export const allRequestStatusesSucceeded = statuses => statuses.every(status => SUCCEEDED_STATUSES.includes(status));
export const allRequestStatusesFinished = statuses => statuses.filter(requestStatusFinished).length === statuses.length;
export const requestStatusFailedOrUninitialized = status => requestStatusUninitialized(status) || anyRequestStatusesFailed([status]);