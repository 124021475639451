import debounce from 'react-utils/debounce';
import events from '../../../events.yaml';
const TRACKER_DEBOUNCE_DELAY_MS = 500;
const makeUsageTrackerContainer = () => {
  let usageTracker;
  /*
  Used for testing purposes
  */
  const setLibraryTracker = tracker => {
    usageTracker = tracker;
  };
  const initialize = (tracker, contentType) => {
    const libraryTracker = tracker.clone({
      preserveTrackerProperties: false,
      trackerName: 'content-specialists-lib',
      events,
      lastKnownEventProperties: ['contentType']
    });
    libraryTracker.setProperties({
      contentType
    });
    setLibraryTracker(Object.assign({}, libraryTracker, {
      trackDebounced: debounce(libraryTracker.track, TRACKER_DEBOUNCE_DELAY_MS)
    }));
  };
  const getLibraryTracker = () => {
    if (!usageTracker) {
      throw Error(`Failed to load library tracker. Make sure the consuming app calls initialize on the container;`);
    }
    return usageTracker;
  };
  return {
    setLibraryTracker,
    initialize,
    getLibraryTracker
  };
};
export const usageTrackerContainer = makeUsageTrackerContainer();