import { combineReducers } from '@reduxjs/toolkit';

// @ts-expect-error Untyped module
import filterableDomainsReducer from './filterableDomains';
// @ts-expect-error Untyped module
import foldersReducer from './folders';
// @ts-expect-error Untyped module
import pagesReducer from './pages';
// @ts-expect-error Untyped module
import { ContentTypes } from '../../shared/constants/ContentTypes';
import pagesUsageReducer from './pagesUsage';
const landingPagesReducer = pagesReducer(ContentTypes.LANDING_PAGE);
const sitePagesReducer = pagesReducer(ContentTypes.SITE_PAGE);
const landingPageDomainsReducer = filterableDomainsReducer(ContentTypes.LANDING_PAGE);
const sitePageDomainsReducer = filterableDomainsReducer(ContentTypes.SITE_PAGE);
export default combineReducers({
  landingPageDomains: landingPageDomainsReducer,
  sitePageDomains: sitePageDomainsReducer,
  folders: foldersReducer,
  landingPages: landingPagesReducer,
  sitePages: sitePagesReducer,
  pagesUsage: pagesUsageReducer
});