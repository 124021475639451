'use es6';

import { BlogActionTypes } from '../actions/BlogActions';
import Blogs from '../models/Blogs';
import RequestStatus from '../../shared/constants/RequestStatus';
import LocalStorage from '../../shared/api/LocalStorage';
import { blogZeroStateInitializerActions } from './blogZeroStateInitializer';
import Blog from '../models/Blog';
export default ((state = new Blogs(), action) => {
  switch (action.type) {
    case BlogActionTypes.REQUESTED_BLOGS:
      return state.set('status', RequestStatus.PENDING);
    case BlogActionTypes.RECEIVED_BLOGS:
      {
        LocalStorage.stashFirstBlog(action.payload.blogs);
        return state.merge({
          data: action.payload.blogs,
          status: RequestStatus.SUCCEEDED
        });
      }
    case BlogActionTypes.REQUESTED_BLOGS_FAILED:
      return state.set('status', RequestStatus.FAILED);
    case `${blogZeroStateInitializerActions.createDefaultBlog.fulfilled}`:
      {
        const newBlog = Blog.from(action.payload);
        return state.update('data', data => {
          const updatedData = data.push(newBlog);
          return updatedData;
        });
      }
    default:
      return state;
  }
});