import { ContentTypes as ContentTypeIds } from './ContentTypeConstants';
export const BLOG_PAGE_VIEW = 'blogPageView';
export const BLOG_FILTER_INTERACTION = 'blogFilterInteraction';
export const PREVIEW_PANEL_INTERACTION = 'contentActionsInteractionPreviewPanel';
export const PAGES_PAGE_VIEW = 'pagesPageView';
export const PAGES_FILTER_INTERACTION = 'pagesFilterInteraction';
export const VIEW_OPTIONS_INTERACTION = 'viewOptionsInteraction';
export const ACTIONS_USAGE = 'contentUpdateActionUsage';
export const ACTIONS_INTERACTION_NO_VOLUME_NO_STATUS = 'contentActionsInteractionNoVolumeNoStatus';
export const TABLE_INTERACTION = 'tableInteraction';
export const CONTENT_ACTIONS_INTERACTION = 'contentActionsInteraction';
export const FRAMEWORK_TABLE_INTERACTION = 'frameworkTableInteraction';
export const MANAGEMENT_DATA_GROUP_NAVIGATION_INTERACTION = 'managementDataGroupNavigationInteraction';
export const USER_SETTINGS_CHANGES = 'userAttributesChanges';
export const CMS_ONBOARDING_GET_STARTED_ACTIONS = {
  BLOG_POST_DRAFT: 'getting-started-with-blog-post-draft',
  BLOG_POST_INLINE: 'getting-started-with-blog-post-inline',
  [ContentTypeIds.LANDING_PAGE]: 'getting-started-with-landing-page',
  [ContentTypeIds.SITE_PAGE]: 'getting-started-with-site-page'
};