'use es6';

import { catchError } from 'rxjs/operators/catchError';
import { map } from 'rxjs/operators/map';
import { switchMap } from 'rxjs/operators/switchMap';
import { startWith } from 'rxjs/operators/startWith';
import { merge } from 'rxjs/observable/merge';
import { of } from 'rxjs/observable/of';
import { FolderActionTypes, receiveFolders, receiveFoldersFailure, requestFolders } from '../actions/FolderActions';
import { showFailureNotification } from '../../shared/utils/alertUtils';
import Folders from '../api/Folders';
export default (action$ => action$.ofType(FolderActionTypes.FETCH_FOLDERS).pipe(switchMap(() => merge(Folders.fetchFolderTree().pipe(map(receiveFolders), catchError(error => {
  showFailureNotification({
    contentType: 'folders',
    featureType: 'folders',
    action: 'fetch',
    response: error
  }); // TODO: add an error state in the table instead of a notification

  return of(receiveFoldersFailure(error));
}), startWith(requestFolders()))))));