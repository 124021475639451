import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RequestStatuses from '../../shared/constants/RequestStatus';
import { getShouldFetchFromSharedPageLimitApi } from '../../shared/selectors/authSelectors';
import PagesUsageApi from '../api/PagesUsage';
export const fetchSharedPageUsage = createAsyncThunk('pagesUsage/fetchSharedPageUsage', () => PagesUsageApi.fetchSharedPageUsage(), {
  condition: (_, {
    getState
  }) => {
    return getShouldFetchFromSharedPageLimitApi(getState());
  }
});
const initialState = {
  freeLPLimit: null,
  freeLPUsed: 0,
  freeLPStatus: RequestStatuses.UNINITIALIZED,
  sitePageLimit: null,
  sitePagesUsed: 0,
  sitePageStatus: RequestStatuses.UNINITIALIZED,
  mLangSitePageLimit: null,
  mLangSitePageLimitStatus: RequestStatuses.UNINITIALIZED,
  mLangLandingPageLimit: null,
  mLangLandingPageLimitStatus: RequestStatuses.UNINITIALIZED
};
function getLimitValue(limitsData) {
  let limitValue = undefined;
  if (limitsData) {
    if (limitsData.limit) {
      limitValue = limitsData.limit;
    } else if (limitsData.pageLimit) {
      limitValue = limitsData.pageLimit;
    }
  }
  return limitValue;
}
const pagesUsageSlice = createSlice({
  name: 'pagesUsage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSharedPageUsage.pending, state => {
      Object.assign(state, {
        freeLPStatus: RequestStatuses.PENDING,
        sitePageStatus: RequestStatuses.PENDING
      });
    });
    builder.addCase(fetchSharedPageUsage.fulfilled, (state, {
      payload
    }) => {
      const lpLimitsData = payload.find(limitsData => limitsData.limitName === 'cms-landing-pages');
      const sitePageLimitsData = payload.find(limitsData => limitsData.limitName === 'cms-site-pages');
      const sitePagesMLangGroupSizeLimitData = payload.find(limitsData => limitsData.limitName === 'cms-site-pages-multi-language');
      const landingPagesMLangGroupSizeLimitData = payload.find(limitsData => limitsData.limitName === 'cms-landing-pages-multi-language');
      Object.assign(state, {
        freeLPStatus: RequestStatuses.SUCCEEDED,
        freeLPLimit: getLimitValue(lpLimitsData),
        freeLPUsed: lpLimitsData ? lpLimitsData.quantityUsed : undefined,
        sitePageStatus: RequestStatuses.SUCCEEDED,
        sitePageLimit: getLimitValue(sitePageLimitsData),
        sitePagesUsed: sitePageLimitsData ? sitePageLimitsData.quantityUsed : undefined,
        mLangSitePageLimitStatus: RequestStatuses.SUCCEEDED,
        mLangSitePageLimit: sitePagesMLangGroupSizeLimitData ? sitePagesMLangGroupSizeLimitData.limit : null,
        mLangLandingPageLimitStatus: RequestStatuses.SUCCEEDED,
        mLangLandingPageLimit: landingPagesMLangGroupSizeLimitData ? landingPagesMLangGroupSizeLimitData.limit : null
      });
    });
    builder.addCase(fetchSharedPageUsage.rejected, state => {
      Object.assign(state, {
        freeLPStatus: RequestStatuses.FAILED,
        sitePageStatus: RequestStatuses.FAILED
      });
    });
  }
});
export default pagesUsageSlice.reducer;