'use es6';

import { map } from 'rxjs/operators/map';
import http from 'hub-http/clients/apiClient';
import { getEarlyRequesterResultObservable } from '../../shared/utils/getEarlyRequesterResultPromise';
const URI = 'blogs/v3/blog-posts/crm-search/search';
export default class BlogPostsCrmSearchApi {
  static fetchBlogPostsForStartState() {
    const request = getEarlyRequesterResultObservable({
      name: 'fetch-blog-posts-for-start-state',
      retryRequest: () => http.post(URI, {
        data: {
          filterGroups: [{
            filters: []
          }],
          objectTypeId: '0-10',
          sorts: [{
            property: 'hs_updated_at',
            order: 'DESC'
          }],
          count: 1,
          query: '',
          offset: 0
        }
      })
    });
    return request.pipe(map(response => {
      return {
        total: response.total
      };
    }));
  }
}