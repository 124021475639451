"use es6";

import { Subscriber } from './Subscriber';
/**
 * We need this JSDoc comment for affecting ESDoc.
 * @ignore
 * @extends {Ignored}
 */
export class OuterSubscriber extends Subscriber {
  notifyNext(outerValue, innerValue, outerIndex, innerIndex, innerSub) {
    this.destination.next(innerValue);
  }
  notifyError(error, innerSub) {
    this.destination.error(error);
  }
  notifyComplete(innerSub) {
    this.destination.complete();
  }
}