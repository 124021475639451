'use es6';

import { PropertiesActionTypes } from '../actions/PropertiesActions';
import RequestStatus from '../constants/RequestStatus';
import Properties from '../models/Properties';
export default ((state = new Properties(), action) => {
  switch (action.type) {
    case PropertiesActionTypes.RECEIVED_PROPERTIES:
      return state.merge({
        status: RequestStatus.SUCCEEDED,
        data: action.payload.properties
      });
    case PropertiesActionTypes.UPDATE_PROPERTY_OPTIONS:
      return state.setIn(['data', action.payload.propertyName, 'options'], action.payload.options);
    case PropertiesActionTypes.UPDATE_PROPERTY:
      return state.setIn(['data', action.payload.propertyName], action.payload.propertyData);
    case PropertiesActionTypes.REQUESTED_PROPERTIES:
      return state.set('status', RequestStatus.PENDING);
    case PropertiesActionTypes.REQUESTED_PROPERTIES_FAILED:
      return state.set('status', RequestStatus.FAILED);
    default:
      return state;
  }
});