import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
import { getEarlyRequesterResultPromise } from '../../shared/utils/getEarlyRequesterResultPromise';
export default class BlogUsage {
  static fetchBlogsUsage() {
    return getEarlyRequesterResultPromise({
      name: 'fetch-blogs-usage',
      retryRequest: () => http.get(`blog-settings/v1/blogs/${PortalIdParser.get()}/usage`)
    });
  }
  static fetchBlogPostsUsage() {
    return getEarlyRequesterResultPromise({
      name: 'fetch-blog-posts-usage',
      retryRequest: () => http.get(`blogs/v3/blog-posts/${PortalIdParser.get()}/usage`)
    });
  }
}