import { createSlice } from '@reduxjs/toolkit';
const DefaultBulkUpdateTaskState = {
  actionName: '',
  taskId: null,
  data: {}
};
const initialState = {
  bulkUpdateTask: {
    actionName: '',
    taskId: null,
    data: {}
  },
  contentIdsAlreadyPolledForPrerenderingStatus: new Set()
};
const contentDisplay = createSlice({
  name: 'contentDisplay',
  initialState,
  reducers: {
    setContentBulkUpdateTask(state, {
      payload
    }) {
      state.bulkUpdateTask = payload;
    },
    resetContentBulkUpdateTask(state) {
      state.bulkUpdateTask = DefaultBulkUpdateTaskState;
    },
    finishedPollingForContentPrerenderingStatus(state, {
      payload
    }) {
      state.contentIdsAlreadyPolledForPrerenderingStatus = state.contentIdsAlreadyPolledForPrerenderingStatus.add(payload);
    },
    resetPrerendingStatus(state) {
      state.contentIdsAlreadyPolledForPrerenderingStatus = new Set();
    }
  }
});
export default contentDisplay.reducer;
export const {
  setContentBulkUpdateTask,
  resetContentBulkUpdateTask,
  finishedPollingForContentPrerenderingStatus,
  resetPrerendingStatus
} = contentDisplay.actions;