import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { getEarlyRequesterResultPromise } from '../utils/getEarlyRequesterResultPromise';
const getURI = portalId => `content-settings/v1/site-settings/by-portal-id/${portalId}`;
const getScopesURI = portalId => `hubs2/v1/scopes/hub/${portalId}/filter`;
export default class PortalApi {
  static fetchSettings() {
    return getEarlyRequesterResultPromise({
      name: 'initial-portal-settings-fetch',
      retryRequest: () => http.get(getURI(PortalIdParser.get()))
    });
  }
  static fetchPortalScopes() {
    return getEarlyRequesterResultPromise({
      name: 'initial-portal-scopes-fetch',
      retryRequest: () => http.get(getScopesURI(PortalIdParser.get()), {
        query: {
          scopes: ['landingpages-access', 'sitepages-access', 'blog-access', 'landingpages-custom-domain-access', 'content-site-tree', 'website-pages-no-branding', 'landingpages-no-branding', 'blog-pages-no-branding', 'blog-posts-auto-translate', 'landingpages-auto-translate', 'sitepages-auto-translate']
        }
      })
    });
  }
}