'use es6';

import { createAction } from 'redux-actions';
import keyMirror from 'react-utils/keyMirror';
export const DomainActionTypes = keyMirror({
  FETCH_DOMAINS: null,
  REQUESTED_DOMAINS: null,
  RECEIVED_DOMAINS: null,
  REQUESTED_DOMAINS_FAILED: null
});
export const fetchDomains = createAction(DomainActionTypes.FETCH_DOMAINS, businessUnitId => ({
  businessUnitId
}));
export const requestDomains = createAction(DomainActionTypes.REQUESTED_DOMAINS);
export const receiveDomains = createAction(DomainActionTypes.RECEIVED_DOMAINS, domains => domains);
export const receiveDomainsFailure = createAction(DomainActionTypes.REQUESTED_DOMAINS_FAILED, error => ({
  error
}));