module.exports = {
  "pagesPageView": {
    "name": "Pageview",
    "class": "view",
    "properties": {
      "screen": [
        "landing-pages",
        "site-pages",
        "site-tree"
      ],
      "subscreen": [
        "manage",
        "folders",
        "analyze"
      ],
      "subscreen2": {
        "type": [
          "all",
          "published",
          "scheduled",
          "draft",
          "ab",
          "archived"
        ],
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "blogPageView": {
    "name": "Pageview",
    "class": "view",
    "properties": {
      "screen": [
        "blog"
      ],
      "subscreen": [
        "posts",
        "authors",
        "tags",
        "comments",
        "listing"
      ],
      "subscreen2": {
        "type": [
          "all",
          "published",
          "scheduled",
          "draft",
          "archived"
        ],
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "foldersPageView": {
    "name": "Pageview",
    "class": "view",
    "properties": {
      "screen": [
        "landing-pages"
      ],
      "subscreen": [
        "folders"
      ]
    },
    "namespace": "Website"
  },
  "previewPanelPageView": {
    "name": "Pageview",
    "class": "view",
    "properties": {
      "screen": [
        "landing-pages",
        "site-pages",
        "blog",
        "site-tree"
      ],
      "subscreen": {
        "type": [
          "posts-preview",
          "manage-preview",
          "folders-preview"
        ],
        "isOptional": true
      },
      "subscreen2": {
        "type": [
          "all",
          "published",
          "scheduled",
          "draft",
          "archived"
        ],
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "advancedFilterPanelPageView": {
    "name": "Pageview",
    "class": "view",
    "properties": {
      "screen": [
        "landing-pages",
        "site-pages",
        "blog",
        "site-tree"
      ],
      "subscreen": [
        "manage",
        "posts"
      ],
      "subscreen2": {
        "type": [
          "all",
          "published",
          "scheduled",
          "draft",
          "archived"
        ],
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "analyzeView": {
    "name": "Pageview",
    "class": "view",
    "properties": {
      "screen": [
        "landing-pages",
        "site-pages"
      ],
      "subscreen": [
        "analyze"
      ]
    },
    "namespace": "Website"
  },
  "exploreAnalyticsSuite": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "screen": [
        "landing-pages",
        "site-pages"
      ],
      "subscreen": [
        "analyze"
      ]
    },
    "namespace": "Website"
  },
  "contentActionsInteraction": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "attach-to-language-master",
        "attach-to-language-master-for-test",
        "convert-to-variation-master",
        "archive",
        "archive-and-unpublish",
        "assign-permissions",
        "bulk-assign-permissions",
        "bulk-change-audience-access",
        "bulk-convert-type",
        "bulk-move-to-folder",
        "change-audience-access",
        "clone",
        "convert-type",
        "create-blog-post-translation-variation-experiment",
        "create-translation-variation",
        "create-translation-variation-for-test",
        "move-to-folder",
        "unarchive",
        "unpublish",
        "publish",
        "delete",
        "create",
        "rename",
        "approve",
        "remove-from-language-group",
        "reply",
        "reject",
        "run-a-test",
        "run-a-test-for-multi-lang",
        "spam",
        "combine",
        "update",
        "switched-language-master",
        "create-tag-translation",
        "set-tag-language",
        "convert-tag-to-master",
        "remove-tag-from-language-group",
        "add-tag-to-language-group",
        "switch-tag-master",
        "create-author-translation",
        "convert-author-to-master",
        "set-author-language",
        "add-author-to-language-group",
        "remove-author-from-language-group",
        "switch-author-master",
        "create-child-page",
        "toggle-multi-language-group",
        "autoCreateDefaultBlog"
      ],
      "status": [
        "success",
        "fail"
      ],
      "volume": {
        "type": [
          "bulk",
          "single"
        ],
        "isOptional": true
      },
      "screen": [
        "blog",
        "landing-pages",
        "site-pages",
        "site-tree",
        "blog-posts",
        "blog-listing-pages",
        "folders"
      ],
      "subscreen": {
        "type": [
          "folders",
          "manage",
          "posts",
          "comments",
          "tags",
          "authors"
        ],
        "isOptional": true
      },
      "searching": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "contentActionsInteractionNoVolumeNoStatus": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-link-edit",
        "clicked-link-details",
        "edit",
        "view",
        "details",
        "viewDetailsPanel",
        "performance",
        "clicked-bulk-publish",
        "clicked-bulk-unpublish",
        "clicked-bulk-add-to-mLang-group",
        "clicked-bulk-archive",
        "clicked-bulk-unarchive",
        "clicked-bulk-delete",
        "clicked-bulk-assign-permissions",
        "clicked-bulk-move-to-folder",
        "clicked-bulk-change-audience-access",
        "clicked-bulk-update-domain",
        "clicked-create-mLang-variation"
      ],
      "screen": [
        "blog",
        "landing-pages",
        "site-pages",
        "site-tree",
        "blog-posts",
        "folders",
        "blog-listing-pages"
      ],
      "subscreen": {
        "type": [
          "folders",
          "manage",
          "posts",
          "listing"
        ],
        "isOptional": true
      },
      "subscreen2": {
        "type": [
          "all",
          "published",
          "scheduled",
          "draft",
          "archived"
        ],
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "tableInteraction": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "selected-multiple-content",
        "clicked-toggle-show-full-url",
        "clicked-toggle-show-multi-lang-variations",
        "clicked-toggle-show-publish-status"
      ],
      "screen": [
        "blog",
        "landing-pages",
        "site-pages",
        "site-tree"
      ],
      "subscreen": [
        "folders",
        "manage",
        "posts"
      ]
    },
    "namespace": "Website"
  },
  "contentUpdateActionUsage": {
    "name": "content-update-actions-usage",
    "class": "usage",
    "properties": {
      "action": [
        "publish",
        "unpublish",
        "attach-to-language-master",
        "archive",
        "archive-and-unpublish",
        "delete",
        "unarchive",
        "schedule-publish",
        "schedule-unpublish",
        "domain-update"
      ],
      "screen": [
        "blog",
        "landing-pages",
        "site-pages",
        "blog-posts",
        "site-tree"
      ],
      "volume": [
        "bulk",
        "single"
      ],
      "status": [
        "success",
        "fail"
      ]
    },
    "namespace": "Website"
  },
  "bulkUpdateActivation": {
    "name": "bulk-update-activation",
    "class": "activation",
    "properties": {
      "screen": [
        "blog",
        "landing-pages",
        "site-pages",
        "blog-posts",
        "site-tree"
      ]
    },
    "namespace": "Website"
  },
  "analyicsInteraction": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-link-view-analytics",
        "clicked-link-view-dashboard-reports"
      ],
      "screen": [
        "blog",
        "landing-pages",
        "site-pages"
      ],
      "subscreen": [
        "folders",
        "manage",
        "posts",
        "authors",
        "tags",
        "comments",
        "listing"
      ],
      "subscreen2": {
        "type": [
          "all",
          "published",
          "scheduled",
          "draft",
          "archived"
        ],
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "contentActionsInteractionCreateButton": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "create-blog-post-inline",
        "create-blog-post-draft",
        "create-blog-listing-page",
        "create-landing-page",
        "create-site-page",
        "create-landing-page-beta",
        "getting-started-with-site-page",
        "getting-started-with-landing-page",
        "getting-started-with-blog-post-draft",
        "getting-started-with-blog-post-inline",
        "create-ai-landing-page"
      ],
      "screen": [
        "blog",
        "landing-pages",
        "site-pages",
        "site-tree"
      ],
      "subscreen": [
        "folders",
        "manage",
        "posts",
        "authors",
        "tags",
        "comments",
        "listing"
      ],
      "subscreen2": {
        "type": [
          "all",
          "published",
          "scheduled",
          "draft",
          "archived"
        ],
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "contentActionsInteractionPreviewPanel": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-preview-image",
        "clicked-preview-url"
      ],
      "screen": [
        "blog",
        "landing-pages",
        "site-pages",
        "site-tree"
      ],
      "subscreen": [
        "folders-preview",
        "manage-preview",
        "posts-preview"
      ],
      "subscreen2": {
        "type": [
          "all",
          "published",
          "scheduled",
          "draft",
          "archived"
        ],
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "advancedFilterPanelInteraction": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "condition-draft-saved",
        "condition-added",
        "condition-deleted"
      ],
      "screen": [
        "blog",
        "landing-pages",
        "site-pages",
        "site-tree"
      ],
      "condition": {
        "type": [
          "In",
          "NotIn",
          "Equal",
          "Less",
          "Greater",
          "InRange"
        ],
        "isOptional": true
      },
      "filterType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "viewOptionsInteraction": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-edit-columns"
      ],
      "screen": [
        "blog",
        "landing-pages",
        "site-pages",
        "site-tree"
      ]
    },
    "namespace": "Website"
  },
  "blogFilterInteraction": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-campaign-filter",
        "clicked-author-filter",
        "clicked-commentedContentAuthor-filter",
        "clicked-tag-filter",
        "clicked-blog-filter",
        "clicked-state-filter",
        "clicked-tagState-filter",
        "clicked-search-filter",
        "clicked-blogFilter-filter",
        "clicked-authorState-filter",
        "clicked-team-filter",
        "clicked-language-filter",
        "clicked-clear-filter",
        "clicked-clearAll-filter",
        "clicked-businessUnits-filter"
      ],
      "screen": [
        "blog",
        "blog-posts",
        "blog-tags",
        "blog-comments",
        "blog-authors",
        "blog-listing-page"
      ],
      "subscreen": {
        "type": [
          "posts",
          "authors",
          "tags",
          "comments",
          "listing"
        ],
        "isOptional": true
      },
      "subscreen2": {
        "type": [
          "all",
          "published",
          "scheduled",
          "draft",
          "archived",
          "APPROVED",
          "PENDING_MODERATION",
          "REJECTED",
          "SPAM"
        ],
        "isOptional": true
      },
      "volume": {
        "type": [
          "bulk",
          "single",
          "all"
        ],
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "pagesFilterInteraction": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-businessUnits-filter",
        "clicked-campaign-filter",
        "clicked-domain-filter",
        "clicked-search-filter",
        "clicked-team-filter",
        "clicked-variation-filter",
        "clicked-language-filter",
        "clicked-state-filter",
        "clicked-clear-filter",
        "clicked-clearAll-filter"
      ],
      "screen": [
        "landing-pages",
        "site-pages",
        "site-tree",
        "folders"
      ],
      "subscreen2": {
        "type": [
          "all",
          "published",
          "scheduled",
          "draft",
          "ab",
          "archived"
        ],
        "isOptional": true
      },
      "volume": {
        "type": [
          "bulk",
          "single",
          "all"
        ],
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "foldersFilterInteraction": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-search-filter"
      ],
      "screen": [
        "landing-pages",
        "folders"
      ],
      "subscreen": {
        "type": [
          "folders"
        ],
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "userAttributesChanges": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "mark-favorite-blog",
        "unmark-favorite-blog",
        "edit-columns"
      ],
      "status": [
        "success",
        "fail"
      ],
      "screen": [
        "blog",
        "landing-pages",
        "site-pages"
      ],
      "subscreen": [
        "posts",
        "comments",
        "manage",
        "folders",
        "tags",
        "authors",
        "listing"
      ],
      "subscreen2": {
        "type": [
          "all",
          "published",
          "scheduled",
          "draft",
          "archived"
        ],
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "blogMoreToolsInteraction": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-manage-post-template-link",
        "clicked-manage-listing-template-link",
        "clicked-manage-shared-template-link",
        "clicked-subscribers-list-link",
        "clicked-notification-emails-link",
        "clicked-page-performance-link",
        "clicked-blog-settings-link",
        "clicked-rss-feed-link",
        "export-content"
      ],
      "status": {
        "type": [
          "success",
          "fail"
        ],
        "isOptional": true
      },
      "exportType": {
        "type": [
          "CSV",
          "HTML"
        ],
        "isOptional": true
      },
      "screen": [
        "blog"
      ]
    },
    "namespace": "Website"
  },
  "pagesMoreToolsInteraction": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-hub-db-link",
        "clicked-page-performance-link",
        "clicked-advanced-menus-link",
        "clicked-content-staging-link",
        "export-content"
      ],
      "status": {
        "type": [
          "success",
          "fail"
        ],
        "isOptional": true
      },
      "screen": [
        "landing-pages",
        "site-pages"
      ]
    },
    "namespace": "Website"
  },
  "clickGettingStartedCmsOnboardingActivation": {
    "name": "cms-onboarding-activation",
    "class": "activation",
    "properties": {
      "contentType": [
        "blog",
        "landing-pages",
        "site-pages"
      ]
    },
    "namespace": "Website"
  },
  "blogPostsZeroStateInteraction": {
    "name": "blog-posts-zero-state-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-create-new-post-ai-wizard-cta",
        "clicked-create-new-post-cta",
        "clicked-import-blog-cta"
      ],
      "screen": [
        "blog"
      ]
    },
    "namespace": "Website"
  },
  "blogListingZeroStateInteraction": {
    "name": "blog-listing-zero-state-interaction",
    "class": "interaction",
    "properties": {
      "volume": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "clicked-continue-with-blp",
        "create-blp-and-update-blog"
      ],
      "status": {
        "type": [
          "success",
          "fail"
        ],
        "isOptional": true
      },
      "screen": [
        "blog"
      ]
    },
    "namespace": "Website"
  },
  "zeroStatePageView": {
    "name": "Pageview",
    "class": "view",
    "properties": {
      "screen": [
        "landing-pages",
        "site-pages",
        "blog"
      ]
    },
    "namespace": "Website"
  },
  "zeroStateInteraction": {
    "name": "zero-state-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-start-now-cta",
        "clicked-get-started-cta",
        "clicked-view-marketplace-link",
        "clicked-find-partner-link",
        "clicked-getting-started-guide-link",
        "clicked-import-site-to-hubspot-link",
        "clicked-start-with-aio",
        "clicked-start-with-theme",
        "clicked-start-with-ai"
      ],
      "screen": [
        "blog",
        "landing-pages",
        "site-pages"
      ]
    },
    "namespace": "Website"
  },
  "setupGuideProgressInteraction": {
    "name": "setup-guide-progress-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-view-guide",
        "clicked-remove-progress-bar",
        "confirmed-remove-progress-bar"
      ],
      "screen": [
        "blog",
        "landing-pages",
        "site-pages"
      ]
    },
    "namespace": "Website"
  },
  "frameworkTableInteraction": {
    "name": "framework-table-interaction",
    "class": "interaction",
    "properties": {
      "page": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string"
      },
      "screen": [
        "blog-posts",
        "blog-listing-pages",
        "landing-pages",
        "site-pages",
        "site-tree"
      ],
      "fieldName": {
        "type": "string",
        "isOptional": true
      },
      "condition": {
        "type": "string",
        "isOptional": true
      },
      "sortDirection": {
        "type": [
          "ASC",
          "DESC"
        ],
        "isOptional": true
      },
      "pageNumber": {
        "type": "number",
        "isOptional": true
      },
      "pageSize": {
        "type": "number",
        "isOptional": true
      },
      "isDefaultView": {
        "type": "boolean",
        "isOptional": true
      },
      "column": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "Website"
  },
  "managementDataGroupNavigationInteraction": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-blog-posts",
        "clicked-blog-listing-pages",
        "clicked-blog-authors",
        "clicked-blog-tags",
        "clicked-blog-comments",
        "clicked-landing-pages",
        "clicked-site-pages"
      ],
      "screen": [
        "blog",
        "landing-pages",
        "site-pages"
      ],
      "subscreen": [
        "folders",
        "manage",
        "posts",
        "authors",
        "tags",
        "comments",
        "listing"
      ]
    },
    "namespace": "Website"
  },
  "aiButtonInteraction": {
    "name": "aiButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "featureId": {
        "type": "string",
        "isOptional": true
      },
      "featureCategoryId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonInteraction": {
    "name": "copilotActionButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "prompt": {
        "type": "string",
        "isOptional": true
      },
      "hasPrefillValues": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copyMessageInteraction": {
    "name": "copyMessageInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_copied"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "feedbackInteraction": {
    "name": "inlineFeedbackInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_upvoted",
          "prompt_upvote_removed",
          "prompt_downvoted",
          "prompt_downvote_removed"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonPageView": {
    "name": "aiComponentsView",
    "class": "view",
    "properties": {
      "action": [
        "page_view"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "contentLabelLibInteraction": {
    "name": "content label lib interaction",
    "class": "interaction",
    "properties": {
      "wordpress_plugin": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": [
          "warning-tag-loaded",
          "open-close-modal-click",
          "info-modal-loaded",
          "details-banner-loaded"
        ]
      }
    },
    "namespace": "content-label-lib"
  },
  "contentLabelLibInteractionPages": {
    "name": "content label lib interaction",
    "class": "interaction",
    "properties": {
      "wordpress_plugin": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": [
          "warning-tag-loaded",
          "open-close-modal-click",
          "info-modal-loaded",
          "details-banner-loaded"
        ]
      }
    },
    "namespace": "content-label-lib"
  },
  "blogQuickCreateModalOpened": {
    "name": "blog quick create modal action",
    "class": "interaction",
    "namespace": "content-components"
  },
  "blogQuickCreateModalClickedCreate": {
    "name": "blog quick create modal action",
    "class": "interaction",
    "namespace": "content-components"
  },
  "audienceAccessInteraction": {
    "name": "audience access interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "click-radio-all",
          "click-radio-password",
          "click-radio-sso",
          "click-radio-specificContacts",
          "click-radio-ssoWithSpecificContacts",
          "show-password-help",
          "show-sso-help",
          "show-specificContacts-help",
          "show-ssoWithSpecificContacts-help",
          "change-lists",
          "click-kb-article",
          "show-sso-disabled-tooltip",
          "show-registration-disabled-tooltip"
        ]
      }
    },
    "namespace": "content-components"
  },
  "partnerMatchingAppInteraction": {
    "name": "partner matching app interaction",
    "namespace": "partner-matching-app",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "app": {
        "type": "string"
      }
    }
  },
  "colorImportInteraction": {
    "name": "color import modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "submit-url",
          "website-colors-retrieved",
          "toggle-color-selection",
          "import-colors"
        ]
      }
    },
    "namespace": "content-components"
  },
  "setupCustomDomainModalInteraction": {
    "name": "setup custom domain modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "view",
          "clicked-add-custom-domain-button",
          "clicked-close-modal",
          "clicked-remind-me-tomorrow-button",
          "clicked-do-not-show-me-again-button"
        ]
      },
      "screen": [
        "detail"
      ]
    },
    "namespace": "content-components"
  },
  "postPublishContentRemixModalInteraction": {
    "name": "ContentComponents interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "view-modal",
          "close-modal-temporary",
          "close-modal-permanent",
          "click-remix"
        ]
      },
      "contentId": {
        "type": "number"
      }
    },
    "namespace": "content-components"
  },
  "ContentComponentsInteraction": {
    "name": "ContentComponents interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "custom-fonts-upload-modal-opened",
          "custom-fonts-upload-modal-closed",
          "custom-fonts-create-font",
          "custom-fonts-edit-font",
          "custom-fonts-delete-font"
        ]
      }
    },
    "namespace": "content-components"
  },
  "contentCreateAutoTranslationActionUsage": {
    "name": "content-create-autotranslation-action",
    "class": "usage",
    "properties": {
      "action": [
        "create-multi-language-variation",
        "direct-translate"
      ],
      "status": [
        "success",
        "fail"
      ],
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "content-components"
  },
  "contentCreateAutoTranslationActionActivation": {
    "name": "content-create-autotranslation-action",
    "class": "activation",
    "properties": {
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "content-components"
  },
  "contentCreateAutoTranslationActionInteraction": {
    "name": "content-create-autotranslation-action",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-submit-create-multi-language-variation",
        "clicked-ai-translate-toggle"
      ],
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "isAutoTranslateEnabled": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "content-components"
  }
};