'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["action", "count", "contentType"],
  _excluded2 = ["action", "count", "contentType"];
import AppDisplayModes from '../constants/AppDisplayModes';
import { ContentTypes } from '../constants/ContentTypes';
import { ContentTypes as ContentTypeIds } from '../constants/ContentTypeConstants';
import { BLOG_POSTS, TAGS, COMMENTS, AUTHORS, FOLDERS, BLOG_LISTING_PAGE } from '../constants/ManagementDataTypes';
import { TABLE_INTERACTION } from '../constants/usageTrackerEvents';
import { getManagementDataType } from './managementDataTypeHelpers';
import { isAppViewSiteTree } from './urls';
import { usageTracker, usageTrackerAsBeacon } from './usageTracker';
const SCREENS = {
  [ContentTypes.BLOG]: 'blog',
  [ContentTypeIds.BLOG_POST]: 'blog',
  [ContentTypeIds.BLOG_LISTING_PAGE]: 'blog',
  [ContentTypes.LANDING_PAGE]: 'landing-pages',
  [ContentTypeIds.LANDING_PAGE]: 'landing-pages',
  [ContentTypes.SITE_PAGE]: 'site-pages',
  [ContentTypeIds.SITE_PAGE]: 'site-pages',
  [AppDisplayModes.SITE_TREE]: 'site-tree'
};
const SUB_SCREENS = {
  [BLOG_POSTS]: 'posts',
  [TAGS]: 'tags',
  [COMMENTS]: 'comments',
  [AUTHORS]: 'authors',
  [BLOG_LISTING_PAGE]: 'listing',
  [FOLDERS]: 'folders'
};
export const CREATE_CONTENT_ACTIONS = {
  BLOG_POST_DRAFT: 'create-blog-post-draft',
  BLOG_POST_INLINE: 'create-blog-post-inline',
  LANDING_PAGE: 'create-landing-page',
  LANDING_PAGE_BETA: 'create-landing-page-beta',
  SITE_PAGE: 'create-site-page'
};
export const getScreenType = contentType => isAppViewSiteTree() ? 'site-tree' : SCREENS[contentType];
export const getScreens = contentType => {
  const managementDataType = getManagementDataType();
  return {
    screen: getScreenType(contentType),
    subscreen: SUB_SCREENS[managementDataType] || 'manage'
  };
};
const getVolume = count => count > 1 ? 'bulk' : 'single';
export const getVolumeAndScreens = (count, contentType) => Object.assign({}, getScreens(contentType), {
  volume: getVolume(count)
});
export const getScreensForPreviewPanel = contentType => {
  const {
    screen,
    subscreen
  } = getScreens(contentType);
  return {
    screen,
    subscreen: `${subscreen}-preview`
  };
};
export const trackSuccessfulAction = (event, _ref, isBeacon = false) => {
  let {
      action,
      count = 1,
      contentType
    } = _ref,
    otherProperties = _objectWithoutPropertiesLoose(_ref, _excluded);
  const eventProperties = Object.assign({
    action,
    status: 'success'
  }, getVolumeAndScreens(count, contentType), otherProperties);
  if (isBeacon) {
    usageTrackerAsBeacon.track(event, eventProperties);
  } else {
    usageTracker.track(event, eventProperties);
  }
};
export const trackFailedAction = (event, _ref2) => {
  let {
      action,
      count = 1,
      contentType
    } = _ref2,
    otherProperties = _objectWithoutPropertiesLoose(_ref2, _excluded2);
  return usageTracker.track(event, Object.assign({
    action,
    status: 'fail'
  }, getVolumeAndScreens(count, contentType), otherProperties));
};
export const trackSiteTreeViewOptionInteraction = actionType => {
  usageTracker.track(TABLE_INTERACTION, Object.assign({
    action: `clicked-toggle-show-${actionType}`
  }, getScreens()));
};