'use es6';

import { createSelector } from 'reselect';
import { ALL_FILTER } from '../../shared/constants/DefaultFilterParams';
const getAuthorsDisplayState = state => {
  return state.blog.authors.display;
};
export const getSelectedAuthors = createSelector([getAuthorsDisplayState], display => display.selected);
export const getAuthorsWithOpenNestedRows = createSelector(getAuthorsDisplayState, display => display.authorsWithOpenNestedRows);
export const getBlogAuthorModalErrorType = createSelector([getAuthorsDisplayState], display => display.blogAuthorModalErrorType);
export const getBlogAuthorFiltersData = createSelector([getAuthorsDisplayState], ({
  currentPage,
  blogFilter,
  authorStateFilter,
  selectedSort,
  selectedSortDir,
  search,
  rowsPerPage
}) => ({
  currentPage,
  blogFilter,
  authorStateFilter,
  selectedSort,
  selectedSortDir,
  search,
  rowsPerPage
}));
export const getBlogAuthorsFilterQueryState = createSelector([getBlogAuthorFiltersData], ({
  currentPage,
  blogFilter,
  authorStateFilter,
  selectedSort,
  selectedSortDir,
  search,
  rowsPerPage
}) => ({
  authorState: authorStateFilter,
  blogFilter,
  page: currentPage,
  sort: selectedSort,
  sortDir: selectedSortDir,
  search,
  rowsPerPage
}));
export const getIsFilteringOrSearchingAuthors = createSelector([getAuthorsDisplayState], ({
  search,
  authorStateFilter,
  blogFilter
}) => search !== '' || authorStateFilter !== ALL_FILTER || blogFilter !== ALL_FILTER);
export const getBlogAuthorsBlogFilter = createSelector([getBlogAuthorFiltersData], ({
  blogFilter
}) => blogFilter);