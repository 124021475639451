import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RequestStatuses from '../constants/RequestStatus';
import PortalApi from '../api/Portal';
export const fetchSettings = createAsyncThunk('portal/fetchSettings', () => PortalApi.fetchSettings());
const initialState = {
  settingsData: {},
  settingsStatus: RequestStatuses.UNINITIALIZED
};
const portalSlice = createSlice({
  name: 'portal',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSettings.pending, state => {
      state.settingsStatus = RequestStatuses.PENDING;
    });
    builder.addCase(fetchSettings.fulfilled, (state, {
      payload
    }) => {
      state.settingsStatus = RequestStatuses.SUCCEEDED;
      state.settingsData = payload;
    });
    builder.addCase(fetchSettings.rejected, state => {
      state.settingsStatus = RequestStatuses.FAILED;
    });
  }
});
export default portalSlice.reducer;