'use es6';

import { OrderedMap } from 'immutable';
import { map } from 'rxjs/operators/map';
import http from 'hub-http-rxjs/clients/apiClient';
import FilterableDomain from '../models/FilterableDomain';
import { ContentTypes } from '../../shared/constants/ContentTypes';
import { ALL_BUSINESS_UNITS_FILTER } from '../../shared/constants/DefaultFilterParams';
const getUri = contentType => `cospages/v2/dashboard/${contentType === ContentTypes.LANDING_PAGE ? 'landing-pages' : 'site-pages'}/filterable-domains`;
const parse = domains => domains.reduce((acc, {
  domainName,
  domain
}) => acc.set(domainName, new FilterableDomain(domain || {
  domain: domainName // needed for creating filter options when the domain doesn't exist
})), new OrderedMap());
export default {
  fetchDomains(contentType, businessUnitId) {
    const query = businessUnitId !== undefined && businessUnitId !== ALL_BUSINESS_UNITS_FILTER ? {
      businessUnitId
    } : {};
    return http.get(getUri(contentType), {
      query
    }).pipe(map(parse));
  }
};