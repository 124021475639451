'use es6';

import { Set as ImmutableSet } from 'immutable';
import { CommentActionTypes } from '../actions/CommentActions';
import Comments from '../models/Comments';
import RequestStatus from '../../shared/constants/RequestStatus';
import { toggleSelectAllEntitiesAndExpandMultiLangGroups, toggleSelectEntityAndExpandMultiLangGroup } from '../../shared/utils/displaySelectedHelpers';
export default ((state = new Comments(), action) => {
  const primaryEntities = state.data;
  const currentlySelectedEntities = state.getIn(['display', 'selected']);
  switch (action.type) {
    case CommentActionTypes.REQUESTED_COMMENTS:
      return state.merge({
        stale: false,
        status: RequestStatus.PENDING
      });
    case CommentActionTypes.RECEIVED_COMMENTS:
      return state.merge({
        data: action.payload.comments,
        status: RequestStatus.SUCCEEDED,
        total: action.payload.total
      });
    case CommentActionTypes.REQUESTED_COMMENTS_FAILED:
      return state.set('status', RequestStatus.FAILED);
    case CommentActionTypes.MARK_COMMENTS_STALE:
      return state.set('stale', true);
    case CommentActionTypes.REQUESTED_BLOG_POSTS_FOR_COMMENTS:
      return state.set('postsStatus', RequestStatus.PENDING);
    case CommentActionTypes.RECEIVED_BLOG_POSTS_FOR_COMMENTS:
      return state.setContentTitlesAndLanguages(action.payload.posts);
    case CommentActionTypes.REQUESTED_BLOG_POSTS_FOR_COMMENTS_FAILED:
      return state.set('postsStatus', RequestStatus.FAILED);
    case CommentActionTypes.RESET_COMMENTS:
      return new Comments();

    // Comments Display cases
    case CommentActionTypes.TOGGLE_COMMENT_SELECT:
      {
        const {
          selectedEntities
        } = toggleSelectEntityAndExpandMultiLangGroup({
          id: action.payload.commentId,
          primaryEntities,
          currentlySelectedEntities
        });
        return state.setIn(['display', 'selected'], selectedEntities);
      }
    case CommentActionTypes.TOGGLE_COMMENTS_SELECT_ALL:
      {
        const {
          selectedEntities
        } = toggleSelectAllEntitiesAndExpandMultiLangGroups({
          primaryEntities,
          currentlySelectedEntities
        });
        return state.setIn(['display', 'selected'], selectedEntities);
      }
    case CommentActionTypes.COMMENTS_FILTERS_CHANGED:
      return state.setIn(['display', 'selected'], new ImmutableSet());
    case CommentActionTypes.UPDATE_BLOG_COMMENTS_FILTER_QUERY_STATE:
      return state.mergeIn(['display'], Object.assign({
        currentPage: 1
      }, action.payload));
    default:
      return state;
  }
});