'use es6';

import { OrderedMap } from 'immutable';
import { createSelector } from 'reselect';
import { getModalRelevantEntityIds } from '../../shared/selectors/modalsDisplaySelectors';
import { getTableViewContentType } from '../../shared/utils/urls';
import { ContentTypes } from '../../shared/constants/ContentTypes';
import { requestStatusSucceeded } from '../../shared/utils/requestStatuses';
import { getEntitiesData, getNumMultiLangGroups } from '../../shared/utils/multiLangUtils';
export const _getTranslatedPages = pages => {
  const result = pages.reduce((translatedPagesMap, page) => {
    const inResult = page.get('translatedContent').reduce((acc, translation) => acc.set(translation.id, translation), translatedPagesMap);
    return inResult;
  }, OrderedMap());
  return result;
};
const makePageSelectors = contentType => {
  const getPagesState = state => state.pages[contentType === ContentTypes.SITE_PAGE ? 'sitePages' : 'landingPages'];
  const getPagesWithOpenNestedRows = createSelector([getPagesState], pages => pages.getIn(['display', 'pagesWithOpenNestedRows']));
  const getPagesData = createSelector([getPagesState, getPagesWithOpenNestedRows], getEntitiesData);
  const getPagesIdsSet = createSelector([getPagesData], pages => pages.keySeq().toSet());
  const getPagesTotal = createSelector([getPagesState], pages => pages.total);
  const getNumPagesCurrentlyDisplayed = createSelector([getPagesData], pages => pages.size);
  const getPagesStatus = createSelector([getPagesState], pages => pages.status);
  const getPagesStartStateFetchStatus = createSelector([getPagesState], pages => pages.startStateFetchStatus);
  const getPagesStale = createSelector([getPagesState], pages => pages.stale);
  const getStartStatePagesTotal = createSelector([getPagesState], pages => pages.startStateTotal);
  const getTranslatedPages = createSelector([getPagesData], _getTranslatedPages);
  const getNumMultiLangGroupsForPages = createSelector([getPagesData], getNumMultiLangGroups);
  const getShouldShowAllMLangGroups = createSelector([getPagesIdsSet, getPagesWithOpenNestedRows, getNumMultiLangGroupsForPages], (pagesIds, pagesWithOpenNestedRows, numMultiLangGroups) => numMultiLangGroups > pagesIds.intersect(pagesWithOpenNestedRows).size);
  const getHasNoPagesInPortal = createSelector([getStartStatePagesTotal, getPagesStartStateFetchStatus], (startStatePagesTotal, pagesStartStateFetchStatus) => {
    if (requestStatusSucceeded(pagesStartStateFetchStatus)) {
      return startStatePagesTotal === 0;
    }
    return false;
  });
  return {
    getPagesState,
    getPagesData,
    getPagesIdsSet,
    getPagesTotal,
    getPagesStatus,
    getPagesStartStateFetchStatus,
    getPagesStale,
    getStartStatePagesTotal,
    getTranslatedPages,
    getShouldShowAllMLangGroups,
    getNumMultiLangGroupsForPages,
    getNumPagesCurrentlyDisplayed,
    getHasNoPagesInPortal
  };
};
export const landingPagesSelectors = makePageSelectors(ContentTypes.LANDING_PAGE);
export const sitePagesSelectors = makePageSelectors(ContentTypes.SITE_PAGE);
export const getPagesSelector = (contentType, selectorName) => {
  if (contentType === ContentTypes.SITE_PAGE) {
    return sitePagesSelectors[selectorName];
  }
  return landingPagesSelectors[selectorName];
};
export const getSelectedPageResolvedDomain = () => {
  const selectedPageContentType = getTableViewContentType();
  return createSelector([getModalRelevantEntityIds, getPagesSelector(selectedPageContentType, 'getPagesData')], (modalRelevantEntityIds, pages) => {
    const selectedPageId = modalRelevantEntityIds.size === 1 ? modalRelevantEntityIds.first() : null;
    const selectedPage = pages && pages.get(selectedPageId);
    return selectedPage ? selectedPage.getDomain() : null;
  });
};