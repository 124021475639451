'use es6';

import { OrderedMap, Record, List } from 'immutable';
import RequestStatus from '../../shared/constants/RequestStatus';
import PagesDisplay from './PagesDisplay';
const defaults = {
  data: new OrderedMap(),
  pagesToMergeOnRefetch: new List(),
  stale: false,
  startStateData: new OrderedMap(),
  startStateFetchStatus: RequestStatus.UNINITIALIZED,
  startStateTotal: 0,
  status: RequestStatus.UNINITIALIZED,
  total: 0,
  display: new PagesDisplay()
};
export default class Pages extends Record(defaults) {}