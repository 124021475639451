'use es6';

import { ContentTypes } from '../../shared/constants/ContentTypes';
export const getPagesActionName = (contentType, actionName) => {
  let prefix;
  if (contentType === ContentTypes.SITE_PAGE) {
    prefix = 'SITE';
  } else {
    prefix = 'LANDING';
  }
  return `${prefix}_PAGES_${actionName}`;
};