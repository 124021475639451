import I18n from 'I18n';
import { AccessControlTypes, AudienceAccessTypeToAccessControl, PublicAccessRuleTypeToAccessControl } from '../constants/AccessControlTypes';
import { PublicAccessRuleTypes } from '../constants/PublicAccessRuleTypes';
export const getContentAccessTypeTagI18nKeyFromAccessControlType = accessControlType => {
  const i18nPrivateAccessKey = accessControlType !== AccessControlTypes.PUBLIC ? 'private.' : '';
  return `contentAccessControl.accessOptions.${i18nPrivateAccessKey}${accessControlType}.tag`;
};
export const getContentAccessTypeTagI18nKeyFromContent = ({
  password,
  publicAccessRules,
  publicAccessRulesEnabled,
  isSSOEnabled
}) => {
  const publicAccessRuleType = publicAccessRules[0] && publicAccessRules[0].type || PublicAccessRuleTypes.PUBLIC;
  let accessControlType = PublicAccessRuleTypeToAccessControl[publicAccessRuleType];
  // Backwards compatible setup for SSO with lists
  if (publicAccessRuleType === PublicAccessRuleTypes.LIST_MEMBERSHIP && isSSOEnabled) {
    accessControlType = AccessControlTypes.SSO_WITH_LISTS;
  }
  // Backwards compatible setup for password access
  if (password && !publicAccessRulesEnabled) {
    accessControlType = AccessControlTypes.PASSWORD;
  }
  // SSO and normal AG map to same public access rule depending on SSO enabled
  if (publicAccessRuleType === PublicAccessRuleTypes.ACCESS_GROUP_MEMBERSHIP && isSSOEnabled) {
    accessControlType = AccessControlTypes.SSO_WITH_ACCESS_GROUPS;
  }
  return {
    i18nKey: getContentAccessTypeTagI18nKeyFromAccessControlType(accessControlType),
    accessControlType
  };
};
export const getContentAccessTypeTableFilterOptions = audienceAccessTypes => {
  return audienceAccessTypes.map(audienceAccessType => ({
    text: I18n.text(getContentAccessTypeTagI18nKeyFromAccessControlType(AudienceAccessTypeToAccessControl[audienceAccessType])),
    value: audienceAccessType
  }));
};