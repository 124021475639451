'use es6';

import keyMirror from 'react-utils/keyMirror';
export default keyMirror({
  // Page actions
  PAGE_MODAL_CLONE: null,
  PAGE_MODAL_ASSIGN_TO_MASTER: null,
  PAGE_MODAL_CREATE_TRANSLATION: null,
  PAGE_MODAL_CONVERT: null,
  PAGE_MODAL_SWITCH_LANGUAGE_MASTER: null,
  PAGE_MODAL_DELETE: null,
  PAGE_MODAL_DELETE_GROUP: null,
  PAGE_MODAL_ARCHIVE: null,
  PAGE_MODAL_UNARCHIVE: null,
  PAGE_MODAL_PUBLISH: null,
  PAGE_MODAL_UNPUBLISH: null,
  PAGE_MODAL_MOVE_TO_FOLDER: null,
  PAGE_MODAL_ASSIGN_PERMISSIONS: null,
  PAGE_MODAL_REMOVE_FROM_LANGUAGE_GROUP: null,
  PAGE_MODAL_CONVERT_TO_VARIATION_MASTER: null,
  PAGE_MODAL_BULK_PUBLISH_ERROR_LIST: null,
  PAGE_MODAL_CONTENT_REMIX: null
});