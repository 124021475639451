import http from 'hub-http/clients/apiClient';
const URI = 'users/v2/app/hub-users';
export default class UsersApi {
  static fetchUsers() {
    return http.get(URI, {
      query: {
        active: true,
        visible: true
      }
    });
  }
}