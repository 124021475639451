'use es6';

export const BulkActionNames = {
  UNPUBLISH: 'bulk-unpublish',
  PUBLISH: 'bulk-publish',
  ADD_TO_MLANG_GROUP: 'bulk-attachToLanguageMaster',
  ARCHIVE: 'bulk-archive',
  ARCHIVE_AND_UNPUBLISH: 'bulk-archiveAndUnpublish',
  DELETE: 'bulk-delete',
  UNARCHIVE: 'bulk-unarchive',
  SCHEDULE_PUBLISH: 'bulk-schedule-publish',
  SCHEDULE_UNPUBLISH: 'bulk-schedule-unpublish',
  DOMAIN_UPDATE: 'bulk-domain-update'
};
export const BulkActionNamesToTrackingKeys = {
  [BulkActionNames.UNPUBLISH]: 'unpublish',
  [BulkActionNames.PUBLISH]: 'publish',
  [BulkActionNames.ADD_TO_MLANG_GROUP]: 'attach-to-language-master',
  [BulkActionNames.ARCHIVE]: 'archive',
  [BulkActionNames.ARCHIVE_AND_UNPUBLISH]: 'archive-and-unpublish',
  [BulkActionNames.DELETE]: 'delete',
  [BulkActionNames.UNARCHIVE]: 'unarchive',
  [BulkActionNames.SCHEDULE_PUBLISH]: 'schedule-publish',
  [BulkActionNames.SCHEDULE_UNPUBLISH]: 'schedule-unpublish',
  [BulkActionNames.DOMAIN_UPDATE]: 'domain-update'
};
export const BulkActionNamesToRequestValue = {
  [BulkActionNames.UNPUBLISH]: 'UNPUBLISH',
  [BulkActionNames.PUBLISH]: 'PUBLISH',
  [BulkActionNames.ADD_TO_MLANG_GROUP]: 'ADD_TO_MULTILANG_GROUP',
  [BulkActionNames.ARCHIVE]: 'ARCHIVE',
  [BulkActionNames.ARCHIVE_AND_UNPUBLISH]: 'ARCHIVE_AND_UNPUBLISH',
  [BulkActionNames.DELETE]: 'DELETE',
  [BulkActionNames.UNARCHIVE]: 'ARCHIVE',
  [BulkActionNames.SCHEDULE_PUBLISH]: 'SCHEDULE_PUBLISH',
  [BulkActionNames.SCHEDULE_UNPUBLISH]: 'SCHEDULE_UNPUBLISH',
  [BulkActionNames.DOMAIN_UPDATE]: 'DOMAIN_UPDATE'
};