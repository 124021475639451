'use es6';

import { Record, Set as ImmutableSet, List } from 'immutable';
import SiteTreeNode from '../models/SiteTreeNode';
import RequestStatus from '../constants/RequestStatus';
import SiteTreeDisplay from './SiteTreeDisplay';
const defaults = {
  treeData: new SiteTreeNode(),
  loadStatus: RequestStatus.UNINITIALIZED,
  total: 0,
  treesLoadingMore: new ImmutableSet(),
  nodesToBeMerged: new List(),
  nodesToBeRemoved: new List(),
  display: new SiteTreeDisplay()
};
export default class SiteTree extends Record(defaults) {}