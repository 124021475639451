'use es6';

import { Set as ImmutableSet } from 'immutable';
export const getContentGroupLanguagesFromMaster = content => {
  let languages = [];
  if (content.hasTranslations()) {
    languages = [content.language, ...content.translations.keySeq().toArray()];
  } else if (content.language) {
    languages = [content.language];
  }
  return new ImmutableSet(languages);
};
const getContentGroupLanguagesFromTranslation = (content, contents) => {
  const translatedFromId = content.get('translatedFromId');
  const primaryContent = contents.find(c => c.get('id') === translatedFromId);
  if (primaryContent) {
    return getContentGroupLanguagesFromMaster(primaryContent);
  }
  return new ImmutableSet();
};
export const getContentGroupLanguages = (content, contents) => {
  if (content.isTranslation() && contents) {
    return getContentGroupLanguagesFromTranslation(content, contents);
  }
  return getContentGroupLanguagesFromMaster(content);
};