'use es6';

import { List } from 'immutable';
import { createSelector } from 'reselect';
import memoize from 'react-utils/memoize';
import { getUserData } from '../../shared/selectors/authSelectors';
import { ALL_FILTER } from '../../shared/constants/DefaultFilterParams';
import { getModalRelevantPages } from '../../shared/selectors/modalsDisplaySelectors';
import { requestStatusFinished } from '../../shared/utils/requestStatuses';
import { ContentTypes } from '../../shared/constants/ContentTypes';
import PropertyOptionRecord from 'customer-data-objects/property/PropertyOptionRecord';
const DOMAIN_KEY = 'domain';
const makeDomainSelectors = contentType => {
  const getDomainsState = state => state.pages[`${contentType}PageDomains`];
  const getDomainsData = createSelector([getDomainsState], domains => domains.data);
  const getDomainsStatus = createSelector([getDomainsState], domains => domains.status);
  const getPagesDisplayState = state => state.pages[contentType === ContentTypes.SITE_PAGE ? 'sitePages' : 'landingPages'].display;
  const getDomainFilter = createSelector([getPagesDisplayState], ({
    domainFilter
  }) => domainFilter);

  // If the user does not have access to domains, and the portal only has internal domains,
  // than don't show them the domain filter
  const getCanFilterByDomain = createSelector([getDomainsData, getUserData], (domains, user) => {
    if (user.hasDomainsAccess()) {
      return true;
    }
    return domains.some(domain => domain.get('isInternalDomain') === false) || domains.size > 2;
  });

  // Excludes domains that aren't connected to HubSpot - internal hs-sites or hubspotpagebuilder domains
  const getConnectedDomains = createSelector([getDomainsData], domains => domains.filter(domain => domain.get('isInternalDomain') === false));
  const getHasMultipleConnectedDomains = createSelector([getConnectedDomains], domains => domains.size > 1);
  const getHasAtLeastOneConnectedDomain = createSelector([getConnectedDomains], domains => domains.size > 0);
  const getDomainsAsSelectOptions = createSelector([getDomainsState], domains => domains.dataAsSelectOptions);
  const getDomainsAsPropertyOptions = createSelector([getDomainsData], domains => {
    return domains.reduce((acc, {
      domain
    }) => {
      return acc.push(PropertyOptionRecord({
        label: domain,
        value: domain
      }));
    }, new List());
  });
  const getSelectedDomain = createSelector([getDomainFilter, getDomainsData, getDomainsStatus], (domainFilter, domains, status) => {
    if (!domainFilter || !requestStatusFinished(status) || domainFilter === ALL_FILTER) {
      return ALL_FILTER;
    }
    return domains.getIn([domainFilter, DOMAIN_KEY]);
  });
  const getIsSelectedDomainValid = createSelector([getSelectedDomain], selectedDomain => !!selectedDomain);
  const getIdForDomain = createSelector([getDomainFilter, getDomainsData], (domainFilter, domains) => {
    const found = domains.find(currentDomain => currentDomain.domain === domainFilter);
    if (found) {
      return found.id;
    }
    return found;
  });
  const getIsPagesDomainsConnected = memoize(pagesContentType => createSelector([getModalRelevantPages(pagesContentType), getDomainsData], (pages, domains) => !pages.some(page => {
    const pageDomain = domains.get(page.get('resolvedDomain'));
    if (pageDomain) {
      return !pageDomain.get('isResolving') || !pageDomain.get('isDnsCorrect');
    }
    return true;
  })));
  return {
    getDomainsData,
    getCanFilterByDomain,
    getDomainsAsSelectOptions,
    getDomainsAsPropertyOptions,
    getSelectedDomain,
    getIsSelectedDomainValid,
    getIdForDomain,
    getIsPagesDomainsConnected,
    getHasMultipleConnectedDomains,
    getHasAtLeastOneConnectedDomain,
    getDomainsStatus,
    getDomainFilter
  };
};
const landingPageDomainSelectors = makeDomainSelectors(ContentTypes.LANDING_PAGE);
const sitePageDomainSelectors = makeDomainSelectors(ContentTypes.SITE_PAGE);
export const getDomainsSelector = (contentType, selectorName) => {
  if (contentType === ContentTypes.SITE_PAGE) {
    return sitePageDomainSelectors[selectorName];
  }
  return landingPageDomainSelectors[selectorName];
};