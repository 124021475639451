'use es6';

import { Map as ImmutableMap, Record, Set as ImmutableSet, List } from 'immutable';
import ContentRoutes from 'ContentUtils/Routes';
import { ApprovalStatus } from 'ui-product-approvals/types';
import { DRAFT_STATES, PUBLISHED_STATES, SCHEDULED_STATES } from '../constants/BlogPostStates';
import ContentSecurityStates, { VIOLATION_STATES } from '../../shared/constants/ContentSecurityStates';
import { ContentTypes, ContentTypeIds } from '../../shared/constants/ContentTypes';
import { BLOG_POSTS } from '../../shared/constants/ManagementDataTypes';
import { ApprovalValueToStatus } from '../../frameworkListing/utils/filterPropertyUtils';
const defaults = {
  contentType: ContentTypes.BLOG,
  contentTypeCategoryId: ContentTypeIds.BLOG,
  managementDataType: BLOG_POSTS,
  // From api call
  absoluteUrl: '',
  id: '',
  portalId: 0,
  businessUnitId: -1,
  contentGroupId: 0,
  campaign: '',
  campaignName: '',
  categoryId: -1,
  clonedFrom: -1,
  domain: '',
  translatedFromId: null,
  name: '',
  htmlTitle: '',
  title: '',
  translatedContent: ImmutableMap(),
  slug: '',
  state: 'DRAFT',
  publishDate: 0,
  updated: 0,
  updatedById: -1,
  created: 0,
  createdById: -1,
  archived: false,
  blueprintTypeId: 0,
  previewKey: '',
  blogAuthorId: 0,
  tagIds: [],
  tags: [],
  authorName: '',
  authorUsername: '',
  authorUserId: 0,
  blogAuthor: {},
  authorEmail: '',
  isPublished: false,
  analytics: {
    ctaClickRate: 0,
    ctaClicks: 0,
    ctaViews: 0,
    pageViews: 0
  },
  meta: {
    campaignName: ''
  },
  url: '',
  parentBlog: {
    enableGoogleAmpOutput: null,
    language: '',
    translations: {}
  },
  enableGoogleAmpOutputOverride: null,
  teamPerms: new List(),
  templatePathForRender: null,
  templatePath: null,
  analyticsPageId: null,
  userPerms: new List(),
  scheduledUpdateDate: null,
  screenshotPreviewUrl: '',
  screenshotPreviewTakenAt: null,
  securityState: ContentSecurityStates.NONE,
  resolvedDomain: '',
  postTemplate: '',
  // properties derived from parentBlog
  language: null,
  password: null,
  publicAccessRulesEnabled: false,
  publicAccessRules: new List(),
  approvalStatus: null
};
const getDerivedPropertiesFromParentBlog = parentBlog => ({
  language: parentBlog.language,
  password: parentBlog.password,
  publicAccessRulesEnabled: parentBlog.publicAccessRulesEnabled,
  publicAccessRules: new List(parentBlog.publicAccessRules)
});
class BlogPost extends Record(defaults) {
  getName() {
    return this.name || this.title;
  }
  isPublished() {
    return PUBLISHED_STATES.has(this.state);
  }
  isPublishedWithScheduledUpdate() {
    return this.isPublished() && !!this.scheduledUpdateDate;
  }
  isScheduled() {
    return SCHEDULED_STATES.has(this.state);
  }
  isDraft() {
    return DRAFT_STATES.has(this.state);
  }
  isArchived() {
    return this.archived;
  }
  hasTranslations() {
    return this.translatedContent.size > 0;
  }
  isTranslation() {
    return !!this.translatedFromId;
  }
  getGroupPrimaryId() {
    return this.isTranslation() ? this.translatedFromId : this.id;
  }
  getTranslatedContent() {
    return this.translatedContent;
  }
  getMlangGroupSize() {
    return this.getTranslatedContent().size + 1;
  }
  getEditUrl() {
    return ContentRoutes.edit(this.id, this.categoryId);
  }
  getDetailsUrl() {
    return ContentRoutes.details(this.id, this.categoryId);
  }
  getTranslatedContentIds() {
    return this.translatedContent.reduce((acc, translation) => acc.add(translation.get('id')), new ImmutableSet());
  }
  isInViolation() {
    return VIOLATION_STATES.includes(this.securityState);
  }
  isPartOfMultiLanguageGroup() {
    return this.hasTranslations() || this.isTranslation();
  }
  needsApproval(user) {
    if (user.isSuperAdmin()) {
      return ApprovalValueToStatus[this.approvalStatus] === ApprovalStatus.APPROVAL_PENDING;
    }
    return ApprovalValueToStatus[this.approvalStatus] !== ApprovalStatus.APPROVED;
  }
  static from(json) {
    const receivedJson = Object.assign({}, json);
    if (receivedJson.translatedContent) {
      const translatedContent = {};
      Object.keys(receivedJson.translatedContent).forEach(key => {
        const translationParentBlog = receivedJson.parentBlog.translations[key];
        if (translationParentBlog) {
          translatedContent[key] = new BlogPost(Object.assign({}, receivedJson.translatedContent[key], {
            categoryId: receivedJson.categoryId,
            /**
             * To set the correct `translatedFromId`, we want to use
             * `masterId` that is part of the api response first.
             *
             * Since we have mostly eliminated use of `masterId` in all places
             * in this app, we can use passed value of `translatedFromId` for that
             * language translation.
             */
            translatedFromId: receivedJson.translatedContent[key].masterId || receivedJson.translatedContent[key].translatedFromId,
            contentGroupId: translationParentBlog.id,
            teamPerms: new List(receivedJson.translatedContent[key].teamPerms),
            userPerms: new List(receivedJson.translatedContent[key].userPerms)
          }, getDerivedPropertiesFromParentBlog(translationParentBlog)));
        }
      });
      receivedJson.translatedContent = new ImmutableMap(translatedContent);
    }
    receivedJson.teamPerms = new List(receivedJson.teamPerms);
    receivedJson.userPerms = new List(receivedJson.userPerms);
    return new BlogPost(Object.assign({}, receivedJson, getDerivedPropertiesFromParentBlog(receivedJson.parentBlog)));
  }
}
export default BlogPost;