'use es6';

import { Set as ImmutableSet } from 'immutable';
import { toggleMultiLangGroupForEntityAndResetSelection, toggleSelectAllEntitiesAndExpandMultiLangGroups, toggleSelectEntityAndExpandMultiLangGroup } from '../../shared/utils/displaySelectedHelpers';
import { TagActionTypes } from '../actions/TagActions';
import Tags from '../models/Tags';
import RequestStatus from '../../shared/constants/RequestStatus';
import TagsDisplay from '../models/TagsDisplay';
import { _getTranslatedTags } from '../selectors/tagSelectors';
export default ((state = new Tags(), action) => {
  const primaryEntities = state.data;
  const translationEntities = _getTranslatedTags(primaryEntities);
  const currentlySelectedEntities = state.getIn(['display', 'selected']);
  const currentlyOpenNestedRows = state.getIn(['display', 'tagsWithOpenNestedRows']);
  switch (action.type) {
    case TagActionTypes.REQUESTED_TAGS:
      return state.merge({
        stale: false,
        status: RequestStatus.PENDING
      });
    case TagActionTypes.RECEIVED_TAGS:
      {
        return state.merge({
          data: action.payload.tags,
          status: RequestStatus.SUCCEEDED,
          total: action.payload.total
        });
      }
    case TagActionTypes.REQUESTED_TAGS_FAILED:
      return state.set('status', RequestStatus.FAILED);
    case TagActionTypes.MARK_TAGS_STALE:
      return state.set('stale', true);

    // Tags display state
    case TagActionTypes.TOGGLE_TAG_SELECT:
      {
        const {
          selectedEntities,
          openNestedRows
        } = toggleSelectEntityAndExpandMultiLangGroup({
          id: action.payload.tagId,
          primaryEntities,
          translationEntities,
          currentlySelectedEntities,
          currentlyOpenNestedRows
        });
        return state.mergeIn(['display'], {
          selected: selectedEntities,
          tagsWithOpenNestedRows: openNestedRows
        });
      }
    case TagActionTypes.TOGGLE_TAGS_SELECT_ALL:
      {
        const {
          selectedEntities,
          openNestedRows
        } = toggleSelectAllEntitiesAndExpandMultiLangGroups({
          primaryEntities,
          translationEntities,
          currentlySelectedEntities,
          currentlyOpenNestedRows
        });
        return state.mergeIn(['display'], {
          selected: selectedEntities,
          tagsWithOpenNestedRows: openNestedRows
        });
      }
    case TagActionTypes.TOGGLE_TAG_MLANG_GROUP_OPEN:
      {
        const {
          selectedEntities,
          openNestedRows
        } = toggleMultiLangGroupForEntityAndResetSelection({
          id: action.payload.tagId,
          primaryEntities,
          translationEntities,
          currentlySelectedEntities,
          currentlyOpenNestedRows
        });
        return state.mergeIn(['display'], {
          selected: selectedEntities,
          tagsWithOpenNestedRows: openNestedRows
        });
      }
    case TagActionTypes.TAGS_FILTERS_CHANGED:
      return state.setIn(['display', 'selected'], new ImmutableSet());
    case TagActionTypes.UPDATE_BLOG_TAGS_FILTER_QUERY_STATE:
      return state.mergeIn(['display'], Object.assign({
        currentPage: 1
      }, action.payload));
    case TagActionTypes.RESET_TAGS:
      return state.setIn(['display'], new TagsDisplay());
    default:
      return state;
  }
});