'use es6';

export const replaceNode = (siteTree, updatedPageModel) => {
  if (siteTree.content && siteTree.slug === updatedPageModel.slug) {
    return siteTree.set('content', updatedPageModel);
  } else if (!siteTree.children.isEmpty() && (!siteTree.slug || updatedPageModel.slug.indexOf(siteTree.slug) === 0)) {
    let isChildrenListUpdated = false;
    const updatedChildren = siteTree.children.map(childSiteTree => {
      const updatedNode = replaceNode(childSiteTree, updatedPageModel);
      if (childSiteTree !== updatedNode) {
        isChildrenListUpdated = true;
        return updatedNode;
      }
      return childSiteTree;
    });
    if (isChildrenListUpdated) {
      return siteTree.set('children', updatedChildren);
    }
  }
  return siteTree;
};
export const removeNode = (siteTree, pageToBeRemoved) => {
  if (siteTree.content && siteTree.slug === pageToBeRemoved.slug) {
    return siteTree.set('content', null);
  } else if (!siteTree.children.isEmpty() && (!siteTree.slug || pageToBeRemoved.slug.indexOf(siteTree.slug) === 0)) {
    let isChildrenListUpdated = false;
    const updatedChildren = siteTree.children.map(childSiteTree => {
      const updatedNode = removeNode(childSiteTree, pageToBeRemoved);
      if (childSiteTree !== updatedNode) {
        isChildrenListUpdated = true;
        return updatedNode;
      }
      return childSiteTree;
    });
    if (isChildrenListUpdated) {
      return siteTree.set('children', updatedChildren.filter(c => c.content || !c.children.isEmpty()));
    }
  }
  return siteTree;
};