'use es6';

import { catchError } from 'rxjs/operators/catchError';
import { mergeMap } from 'rxjs/operators/mergeMap';
import { of } from 'rxjs/observable/of';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import { requestBlogs, receiveBlogs, receiveBlogsFailure, BlogActionTypes } from '../actions/BlogActions';
import Blogs from '../api/Blogs';
export default (action$ => action$.ofType(BlogActionTypes.FETCH_BLOGS).pipe(mergeMap(({
  payload: {
    businessUnitId
  }
}) => Blogs.fetchBlogs({
  businessUnitId
}).pipe(map(receiveBlogs), catchError(error => of(receiveBlogsFailure(error))), startWith(requestBlogs())))));