'use es6';

import { map } from 'rxjs/operators/map';
import http from 'hub-http-rxjs/clients/apiClient';
import Folder from '../models/Folder';
import { FilterablePageStates, PageStates } from '../constants/PageStates';
import { SubcategoryTypes } from '../../shared/constants/ContentTypes';
const FOLDER_TREE_URI = 'content/api/v4/folder-relationships/tree';
const URI = 'content/api/v4/folders';
function parseFolderIds(folder, ids) {
  if (!ids.includes(folder.id)) {
    ids.push(folder.id);
  }
  folder.childFolders.forEach(f => {
    ids.push(f.id);
    parseFolderIds(f, ids);
  });
}
function parse(folder) {
  const allFolderIds = [];
  parseFolderIds(folder, allFolderIds);
  return {
    rootFolder: Folder.from(folder),
    allFolderIds
  };
}
const DEFAULT_OPTIONS = {
  archived: false,
  campaign: null,
  folderCategory: 4,
  name__icontains: null,
  subcategory__neq: SubcategoryTypes.SITE_PAGE,
  state__in: null,
  state__nin: [PageStates.DRAFT_AB_VARIANT, PageStates.PUBLISHED_AB_VARIANT, PageStates.LOSER_AB_VARIANT, PageStates.SCHEDULED_AB]
};
function sanitizeQuery(query = {}) {
  const sanitizedQuery = {};
  const {
    search,
    campaign,
    state
  } = query;
  if (search) {
    sanitizedQuery['name__icontains'] = search;
  }
  if (campaign) {
    sanitizedQuery.campaign = campaign === FilterablePageStates.ALL ? null : campaign;
  }
  switch (state) {
    case FilterablePageStates.DRAFT:
      sanitizedQuery['state__in'] = [PageStates.DRAFT, PageStates.DRAFT_AB];
      sanitizedQuery['state__nin'] = [];
      break;
    case FilterablePageStates.PUBLISHED:
      sanitizedQuery['publish_date__lte'] = Date.now();
      sanitizedQuery['state__in'] = [PageStates.PUBLISHED, PageStates.PUBLISHED_AB, PageStates.PUBLISHED_OR_SCHEDULED];
      sanitizedQuery['state__nin'] = [];
      break;
    case FilterablePageStates.SCHEDULED:
      sanitizedQuery['publish_date__gt'] = Date.now();
      break;
    case FilterablePageStates.ARCHIVED:
      sanitizedQuery.archived = true;
      break;
    case FilterablePageStates.AB:
      sanitizedQuery['state__in'] = [PageStates.PUBLISHED_AB, PageStates.DRAFT_AB, PageStates.SCHEDULED_AB];
      sanitizedQuery['state__nin'] = [];
      break;
    default:
      break;
  }
  return sanitizedQuery;
}
export default {
  fetchFolderTree(query = {}) {
    const data = Object.assign({}, DEFAULT_OPTIONS, sanitizeQuery(query));
    return http.get(FOLDER_TREE_URI, {
      query: data
    }).pipe(map(parse));
  },
  create(name, parentFolderId) {
    return http.post(URI, {
      data: {
        name,
        parentFolderId
      }
    });
  },
  destroy(folderId) {
    return http.delete(`${URI}/${folderId}`);
  },
  rename(folderId, name) {
    return http.put(`${URI}/${folderId}`, {
      data: {
        name
      }
    });
  }
};