'use es6';

import SafeStorage from 'SafeStorage';
import PortalIdParser from 'PortalIdParser';
import { handleStandardPromiseError } from '../utils/errorUtils';
import { UserSettingKeyToLocalStorageKey } from '../constants/UserSettings';
const CONTROL_CENTER_KEY = 'cms-control-center';
const getUserSettingsKey = (name, userId) => `${name}_user_settings_${PortalIdParser.get()}_${userId}`;
const localStorageKeyFormatterForUserSettings = key => {
  if (UserSettingKeyToLocalStorageKey[key]) {
    return `${CONTROL_CENTER_KEY}-${UserSettingKeyToLocalStorageKey[key]}_${PortalIdParser.get()}`;
  }
  return null;
};
export default class LocalStorage {
  static getAttribute(key) {
    try {
      return SafeStorage.getItem(key);
    } catch (err) {
      return undefined;
    }
  }
  static getAttributeParsed(key) {
    const value = LocalStorage.getAttribute(key);
    if (value) {
      try {
        return JSON.parse(value);
      } catch (err) {
        return undefined;
      }
    }
    return undefined;
  }
  static setAttribute(key, value) {
    try {
      SafeStorage.setItem(key, value);
    } catch (err) {
      return;
    }
  }
  static getLocalUserSettings(userId) {
    const settings = this.getAttribute(getUserSettingsKey(CONTROL_CENTER_KEY, userId));
    if (settings) {
      try {
        const settingsJson = JSON.parse(settings);
        return Promise.resolve(settingsJson);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return Promise.reject(new Error('unable to find local storage user settings'));
  }
  static stashContentTypeColumnsFromUserSettings(userSettings) {
    // saving custom columns here to keep localStorage in sync
    // in case user settings quick fetch is slow or fails
    // this will take care of it.
    Object.keys(userSettings).forEach(key => {
      const localStorageKey = localStorageKeyFormatterForUserSettings(key);
      if (localStorageKey) {
        this.setAttribute(localStorageKey, JSON.stringify(userSettings[key]));
      }
    });
  }
  static replaceUserSettings(userId, userSettings) {
    this.stashContentTypeColumnsFromUserSettings(userSettings);
    return this.setAttribute(getUserSettingsKey(CONTROL_CENTER_KEY, userId), JSON.stringify(userSettings));
  }
  static updateUserSetting(userId, updatedUserSettings) {
    this.getLocalUserSettings(userId).then(currentUserSettings => {
      if (currentUserSettings) {
        this.replaceUserSettings(userId, Object.assign({}, currentUserSettings, updatedUserSettings));
      }
    }).catch(handleStandardPromiseError);
  }
  static stashFirstBlog(blogs) {
    if (blogs.size > 0) {
      this.setAttribute(`cms-control-center-first-blog_${PortalIdParser.get()}`, blogs.first().get('id'));
    }
  }
}