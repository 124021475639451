'use es6';

import { Map as ImmutableMap } from 'immutable';
import Domains from '../models/Domains';
import { DomainActionTypes } from '../actions/DomainActions';
import { fetchSettings } from './portal';
import RequestStatus from '../constants/RequestStatus';
export default ((state = new Domains(), action) => {
  switch (action.type) {
    case DomainActionTypes.REQUESTED_DOMAINS:
      return state.set('status', RequestStatus.PENDING);
    case DomainActionTypes.RECEIVED_DOMAINS:
      return state.merge({
        domainsList: action.payload,
        status: RequestStatus.SUCCEEDED,
        total: action.payload.size
      });
    case DomainActionTypes.REQUESTED_DOMAINS_FAILED:
      return state.set('status', RequestStatus.FAILED);
    case fetchSettings.fulfilled.type:
      {
        const languages = Object.keys(action.payload.bodyOverrideByDomain).reduce((acc, domain) => acc.set(domain, action.payload.bodyOverrideByDomain[domain].contentLanguage || 'en'), new ImmutableMap());
        return state.set('domainsLanguages', languages);
      }
    default:
      return state;
  }
});