'use es6';

import { ColumnConfigurations, ColumnTypes } from '../constants/columns';
import { ContentNameKeysToCrmPropertyNames } from '../constants/contentProperties';
import { ContentTypes } from '../constants/ContentTypes';
export const getDefaultColumnSortInfo = columns => {
  if (!columns || columns.includes(ColumnTypes.UPDATED_DATE)) {
    return {
      selectedSort: ColumnTypes.UPDATED_DATE,
      selectedSortDir: ColumnConfigurations.getIn([ColumnTypes.UPDATED_DATE, 'preferredSortDirection'])
    };
  } else if (columns.includes(ColumnTypes.CREATED_DATE)) {
    return {
      selectedSort: ColumnTypes.CREATED_DATE,
      selectedSortDir: ColumnConfigurations.getIn([ColumnTypes.CREATED_DATE, 'preferredSortDirection'])
    };
  } else if (columns.includes(ColumnTypes.PUBLISH_DATE)) {
    return {
      selectedSort: ColumnTypes.PUBLISH_DATE,
      selectedSortDir: ColumnConfigurations.getIn([ColumnTypes.PUBLISH_DATE, 'preferredSortDirection'])
    };
  } else {
    return {
      selectedSort: ColumnTypes.NAME,
      selectedSortDir: ColumnConfigurations.getIn([ColumnTypes.NAME, 'preferredSortDirection'])
    };
  }
};
export const filterColumnsForScopes = (columns, user, contentType, hasBusinessUnitsAccess = false) => columns.filter(column => {
  switch (column) {
    case ColumnTypes.AUDIENCE_ACCESS:
    case ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS:
      return user.canPublish(contentType) && user.hasMembershipsAccess();
    case ColumnTypes.TEST_STATUS:
    case ContentNameKeysToCrmPropertyNames.TEST_STATUS:
      return contentType !== ContentTypes.BLOG && (user.canCreateAbTest() || user.canCreateMABTest());
    case ColumnTypes.CAMPAIGN:
    case ContentNameKeysToCrmPropertyNames.CAMPAIGN:
      return user.hasCampaignsAccess();
    case ColumnTypes.LANGUAGE:
    case ContentNameKeysToCrmPropertyNames.LANGUAGE:
      return user.hasMultiLanguageAccess(contentType);
    case ColumnTypes.TEAM:
    case ContentNameKeysToCrmPropertyNames.TEAM:
      return user.hasTeamsAccess() && user.hasTeamAssignmentAccess();
    case ColumnTypes.BUSINESS_UNIT:
    case ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT:
      return hasBusinessUnitsAccess;
    case ColumnTypes.APPROVAL_STATUS:
    case ContentNameKeysToCrmPropertyNames.APPROVAL_STATUS:
      return user.hasAccessToCmsApprovals(contentType);
    default:
      return true;
  }
});