import { createSelector } from 'reselect';
// @ts-expect-error Untyped module
import { ContentTypes } from '../../shared/constants/ContentTypes';
import RequestStatus from '../../shared/constants/RequestStatus';
import { getHasFreeLandingPages, getHasFreeSitePages, getPortalHasSitePagesAccess, getPortalHasWebsitePagesNoBranding, getShouldFetchSitePageLimit, getShouldFetchLandingPageLimit, getShouldShowRemoveBrandingBanner, getShouldFetchFromSharedPageLimitApi } from '../../shared/selectors/authSelectors';
import { anyRequestStatusesFailed, requestStatusSucceeded } from '../../shared/utils/requestStatuses';
// @ts-expect-error Untyped module
import { getTableViewContentType } from '../../shared/utils/urls';
const MIN_THRESHOLD_TO_DISPLAY_FREE_BANNER = 5;
const MIN_THRESHOLD_TO_DISPLAY_STARTER_BANNER = 10;
const getPagesUsageState = state => state.pages.pagesUsage;
export const getFreeLPLimit = createSelector(getPagesUsageState, ({
  freeLPLimit
}) => freeLPLimit);
export const getFreeLPUsed = createSelector(getPagesUsageState, ({
  freeLPUsed
}) => freeLPUsed);
export const getFreeLPFetchStatus = createSelector(getPagesUsageState, ({
  freeLPStatus
}) => freeLPStatus);
export const getShouldShowFreeLPBanner = createSelector([getHasFreeLandingPages, getFreeLPLimit, getFreeLPUsed, getFreeLPFetchStatus], (hasFreeLandingPages, freeLPLimit, freeLPUsed, freeLPStatus) => freeLPStatus === RequestStatus.SUCCEEDED && hasFreeLandingPages && freeLPLimit !== null && freeLPLimit - freeLPUsed <= 5);
export const getHasFreeLPLimitReached = createSelector([getFreeLPLimit, getFreeLPUsed, getFreeLPFetchStatus], (freeLPLimit, freeLPUsed, freeLPStatus) => {
  return freeLPStatus === RequestStatus.SUCCEEDED && freeLPLimit !== null && freeLPUsed >= freeLPLimit;
});

// downgrade selectors

// starter site pages
export const getSitePageLimit = createSelector(getPagesUsageState, ({
  sitePageLimit
}) => sitePageLimit);
export const getSitePagesUsed = createSelector(getPagesUsageState, ({
  sitePagesUsed
}) => sitePagesUsed);
export const getSitePageUsageFetchStatus = createSelector(getPagesUsageState, ({
  sitePageStatus
}) => sitePageStatus);
export const getShouldShowFreeSitePageUpgradeBanner = createSelector([getHasFreeSitePages, getSitePageLimit, getSitePagesUsed], (hasFreeSitePages, sitePageLimit, sitePagesUsed) => {
  if (!hasFreeSitePages || sitePageLimit === null) {
    return false;
  }
  const numPagesBelowLimit = sitePageLimit - sitePagesUsed;
  return numPagesBelowLimit <= MIN_THRESHOLD_TO_DISPLAY_FREE_BANNER && numPagesBelowLimit >= 0;
});
export const getShouldShowStarterSitePageUpgradeBanner = createSelector([getPortalHasSitePagesAccess, getPortalHasWebsitePagesNoBranding, getSitePageLimit, getSitePagesUsed], (portalHasSitePagesAccess, portalHasSitePagesNoBranding, sitePageLimit, sitePagesUsed) => {
  if (!portalHasSitePagesAccess || !portalHasSitePagesNoBranding || sitePageLimit === null) {
    return false;
  }
  const numPagesBelowLimit = sitePageLimit - sitePagesUsed;
  return numPagesBelowLimit <= MIN_THRESHOLD_TO_DISPLAY_STARTER_BANNER && numPagesBelowLimit >= 0;
});
export const getShouldUseCmsStarterSitePagesUpgradePoint = createSelector([getSitePagesUsed, getSitePageLimit], (sitePagesUsed, sitePageLimit) => sitePageLimit !== null && sitePagesUsed < 40);
export const getHasReachedSitePageLimit = createSelector([getSitePageLimit, getSitePagesUsed], (sitePageLimit, sitePagesUsed) => {
  return sitePageLimit !== null && sitePageLimit <= sitePagesUsed;
});
export const getSitePagesMoreThanLimitDifference = createSelector([getSitePageLimit, getSitePagesUsed, getSitePageUsageFetchStatus], (sitePageLimit, sitePagesUsed, sitePageUsageFetchStatus) => {
  return sitePageUsageFetchStatus === RequestStatus.SUCCEEDED && sitePageLimit !== null && sitePagesUsed > sitePageLimit ? sitePagesUsed - sitePageLimit : 0;
});
export const getHasPagesLimit = createSelector([getSitePageLimit, getFreeLPLimit], (sitePageLimit, landingPageLimit) => sitePageLimit && sitePageLimit > 0 || landingPageLimit && landingPageLimit > 0);
const getPagesUsageFetchStatus = createSelector([getTableViewContentType, getFreeLPFetchStatus, getSitePageUsageFetchStatus], (contentType, landingPagesUsageStatus, sitePagesUsageStatus) => {
  switch (contentType) {
    case ContentTypes.LANDING_PAGE:
      {
        return landingPagesUsageStatus;
      }
    case ContentTypes.SITE_PAGE:
      {
        return sitePagesUsageStatus;
      }
    default:
      return undefined;
  }
});
export const getPagesUsageFetchFailed = createSelector([getPagesUsageFetchStatus], pagesUsageFetchStatus => pagesUsageFetchStatus ? anyRequestStatusesFailed([pagesUsageFetchStatus]) : false);
export const getPagesUsageFetchSucceeded = createSelector([getTableViewContentType, getFreeLPFetchStatus, getSitePageUsageFetchStatus, getShouldFetchLandingPageLimit, getShouldFetchSitePageLimit], (contentType, landingPagesUsageStatus, sitePagesUsageStatus, shouldFetchLandingPageLimit, shouldFetchSitePageLimit) => {
  switch (contentType) {
    case ContentTypes.LANDING_PAGE:
      {
        return !shouldFetchLandingPageLimit || requestStatusSucceeded(landingPagesUsageStatus);
      }
    case ContentTypes.SITE_PAGE:
      {
        return !shouldFetchSitePageLimit || requestStatusSucceeded(sitePagesUsageStatus);
      }
    default:
      return false;
  }
});

// mLang limit usage selectors
export const getMlangSitePageLimit = createSelector([getPagesUsageState], ({
  mLangSitePageLimit
}) => mLangSitePageLimit);
export const getMlangSitePageLimitRequestStatus = createSelector([getPagesUsageState], ({
  mLangSitePageLimitStatus
}) => mLangSitePageLimitStatus);
export const getHasMlangSitePagesLimit = createSelector([getMlangSitePageLimitRequestStatus, getMlangSitePageLimit], (mLangSitePageLimitRequestStatus, mLangSitePageLimit) => requestStatusSucceeded(mLangSitePageLimitRequestStatus) && !!mLangSitePageLimit);
export const getMlangLandingPageLimit = createSelector([getPagesUsageState], ({
  mLangLandingPageLimit
}) => mLangLandingPageLimit);
export const getMlangLandingPageLimitRequestStatus = createSelector([getPagesUsageState], ({
  mLangLandingPageLimitStatus
}) => mLangLandingPageLimitStatus);
export const getHasMlangLandingPagesLimit = createSelector([getMlangLandingPageLimitRequestStatus, getMlangLandingPageLimit], (mLangLandingPageLimitRequestStatus, mLangLandingPageLimit) => requestStatusSucceeded(mLangLandingPageLimitRequestStatus) && !!mLangLandingPageLimit);
export const getShouldShowRemoveBrandingForPages = createSelector([getTableViewContentType, getShouldShowFreeLPBanner, getShouldShowFreeSitePageUpgradeBanner, getShouldShowRemoveBrandingBanner], (contentType, shouldShowLPLimitBanner, shouldShowSitePageLimitBanner, shouldShowRemoveBrandingBanner) => contentType === ContentTypes.LANDING_PAGE && !shouldShowLPLimitBanner && shouldShowRemoveBrandingBanner || contentType === ContentTypes.SITE_PAGE && !shouldShowSitePageLimitBanner && shouldShowRemoveBrandingBanner);
export const getShouldApplyPageMlangGroupSizeLimit = createSelector([getShouldFetchFromSharedPageLimitApi, getHasMlangLandingPagesLimit, getHasMlangSitePagesLimit, getTableViewContentType], (shouldFetchFromSharedPageLimitApi, hasMlangLandingPagesLimit, hasMlangSitePagesLimit, contentType) => {
  if (shouldFetchFromSharedPageLimitApi && (contentType === ContentTypes.LANDING_PAGE || contentType === ContentTypes.SITE_PAGE)) {
    return contentType === ContentTypes.LANDING_PAGE ? hasMlangLandingPagesLimit : hasMlangSitePagesLimit;
  }
  return false;
});