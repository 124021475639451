'use es6';

import FilterableDomains from '../models/FilterableDomains';
import { PagesActionTypes } from '../actions/PagesActions';
import RequestStatus from '../../shared/constants/RequestStatus';
import { createSelectOptions } from '../../shared/utils/selectorHelpers';
import { getPagesActionName } from '../utils/pagesReduxUtils';
const DOMAIN_KEY = 'domain';
const ALL_DOMAINS_LANG_KEY = 'content-management-ui.header.filters.all';
export default (contentType => (state = new FilterableDomains(), action) => {
  switch (action.type) {
    case PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_FILTERABLE_DOMAINS')]:
      return state.set('status', RequestStatus.PENDING);
    case PagesActionTypes[getPagesActionName(contentType, 'RECEIVED_FILTERABLE_DOMAINS')]:
      return new FilterableDomains({
        data: action.payload,
        status: RequestStatus.SUCCEEDED,
        total: action.payload.size,
        dataAsSelectOptions: createSelectOptions(action.payload, DOMAIN_KEY, DOMAIN_KEY, ALL_DOMAINS_LANG_KEY)
      });
    case PagesActionTypes[getPagesActionName(contentType, 'REQUESTED_FILTERABLE_DOMAINS_FAILED')]:
      return state.set('status', RequestStatus.FAILED);
    default:
      return state;
  }
});