'use es6';

import { catchError } from 'rxjs/operators/catchError';
import { map } from 'rxjs/operators/map';
import { switchMap } from 'rxjs/operators/switchMap';
import { startWith } from 'rxjs/operators/startWith';
import { of } from 'rxjs/observable/of';
import { getPagesAction, PagesActionTypes } from '../actions/PagesActions';
import { getHasWebsiteAccess } from '../../shared/selectors/authSelectors';
import { ContentTypes } from '../../shared/constants/ContentTypes';
import PagesCrmSearch from '../api/PagesCrmSearch';
export default ((action$, state$) => action$.ofType(PagesActionTypes.PAGES_ADVANCED_FILTERS_CHANGED).pipe(switchMap(({
  payload: {
    contentType,
    hasFiltersApplied,
    query
  }
}) => {
  const requestPages = getPagesAction(contentType, 'requestPages');
  const receivePages = getPagesAction(contentType, 'receivePages');
  const receivePagesFailure = getPagesAction(contentType, 'receivePagesFailure');
  const hasAccess = contentType === ContentTypes.SITE_PAGE ? getHasWebsiteAccess(state$.getState()) : true;
  return hasAccess ? PagesCrmSearch.fetchPages(contentType, hasFiltersApplied, query).pipe(map(receivePages), catchError(error => of(receivePagesFailure(error))), startWith(requestPages())) : of();
})));