import { useSelector } from 'react-redux';
import { combineReducers } from '@reduxjs/toolkit';
// @ts-expect-error Untyped module
import notifications from 'ReduxMessenger/reducers/Notifications';
import auth from './shared/reducers/auth';
import blog from './blog/reducers/rootBlogReducer';
import contentCreatorInfo from './shared/reducers/contentCreatorInfo';
import contentDisplay from './shared/reducers/contentDisplay';
// @ts-expect-error Untyped module
import domains from './shared/reducers/domains';
import appDisplayModeSyncedFilters from './shared/reducers/appDisplayModeSyncedFilters';
// @ts-expect-error Untyped module
import modalsDisplay from './shared/reducers/modalsDisplay';
import pages from './pages/reducers/rootPagesReducer';
import portal from './shared/reducers/portal';
// @ts-expect-error Untyped module
import properties from './shared/reducers/properties';
import users from './shared/reducers/users';
import teams from './shared/reducers/teams';
// @ts-expect-error Untyped module
import translationErrors from './shared/reducers/translationErrors';
// @ts-expect-error Untyped module
import siteTree from './shared/reducers/siteTree';
import onboardingTaskProgressReducer from 'setup-guide-api/reducers/taskProgress';
import onboardingTaskReducer from 'setup-guide-api/reducers/task';
import userSettings from './shared/reducers/userSettings';
const rootReducer = combineReducers({
  auth,
  blog,
  contentDisplay,
  contentCreatorInfo,
  domains,
  modalsDisplay,
  notifications,
  pages,
  portal,
  properties,
  userSettings,
  users,
  teams,
  translationErrors,
  siteTree,
  task: onboardingTaskReducer,
  taskProgress: onboardingTaskProgressReducer,
  appDisplayModeSyncedFilters
});
export default rootReducer;
export function useAppSelector(selector) {
  return useSelector(selector);
}