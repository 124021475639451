'use es6';

import { LANDING_PAGES, SITE_PAGES, BLOG_POSTS, FOLDERS } from './ManagementDataTypes';
export const ITEMS_PER_PAGE = 25;
export const SortDirTypes = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  NONE: 'none'
};
export const SEARCH_DEBOUNCE_MS = 500;
export const MANAGEMENT_DATA_TYPES_WITH_PERMISSIONING = [LANDING_PAGES, SITE_PAGES, BLOG_POSTS, FOLDERS];