'use es6';

import { OrderedMap } from 'immutable';
import { map } from 'rxjs/operators/map';
import http from 'hub-http/clients/apiClient';
import { EQUAL, NOT_KNOWN, NOT_IN, IN } from 'customer-data-filters/converters/contactSearch/FilterContactSearchOperatorTypes';
import ContentStateFilters from '../../shared/constants/ContentStateFilters';
import TableSortDirToCrmSearchSortDir from '../../shared/constants/sorts';
import { ITEMS_PER_PAGE } from '../../shared/constants/table';
import { ContentTypes } from '../../shared/constants/ContentTypes';
import { TEST_STATUS_FOR_CRM_SEARCH } from '../constants/Variations';
import Page from '../models/Page';
import { getEarlyRequesterResultObservable } from '../../shared/utils/getEarlyRequesterResultPromise';
import { ColumnConfigurations } from '../../shared/constants/columns';
import { ALL_BUSINESS_UNITS_FILTER, ALL_BUSINESS_UNITS_FILTER_FROM_GLOBAL_NAV } from '../../shared/constants/DefaultFilterParams';
import { ContentNameKeysToCrmPropertyNames } from '../../shared/constants/contentProperties';
import { getSelectedBusinessUnitIdFromGlobalNav } from '../../shared/utils/utils';
const URI = 'cospages/v2/dashboard';
const DEFAULT_OPTIONS = {
  objectTypeId: 'content',
  offset: 0
};
const buildRequestBody = (hasFiltersApplied, options = {}) => {
  const requestBody = {};
  const {
    state,
    search,
    page,
    sort,
    sortDir,
    excludedPageIds,
    rowsPerPage,
    folderId,
    businessUnit,
    includeArchived = false
  } = options;
  const filters = [...options.filters];
  if (!hasFiltersApplied && !search) {
    filters.push({
      property: ContentNameKeysToCrmPropertyNames.TRANSLATED_FROM_ID,
      operator: NOT_KNOWN
    });
  }
  if (folderId === 0 && !search) {
    filters.push({
      property: ContentNameKeysToCrmPropertyNames.FOLDER_ID,
      operator: NOT_KNOWN
    });
  } else if (folderId) {
    filters.push({
      property: ContentNameKeysToCrmPropertyNames.FOLDER_ID,
      operator: EQUAL,
      value: folderId
    });
  }
  if (!includeArchived) {
    const archivedValue = state === ContentStateFilters.ARCHIVED;
    filters.push({
      property: ContentNameKeysToCrmPropertyNames.ARCHIVED,
      operator: EQUAL,
      value: archivedValue
    });
  }
  filters.push({
    property: ContentNameKeysToCrmPropertyNames.AB_STATUS,
    operator: NOT_IN,
    values: [TEST_STATUS_FOR_CRM_SEARCH.mabVariant, TEST_STATUS_FOR_CRM_SEARCH.abVariant, TEST_STATUS_FOR_CRM_SEARCH.loserVariant]
  });
  if (excludedPageIds && !excludedPageIds.isEmpty()) {
    filters.push({
      property: ContentNameKeysToCrmPropertyNames.CONTENT_ID,
      operator: NOT_IN,
      values: excludedPageIds.toArray()
    });
  }
  if (businessUnit !== undefined && businessUnit !== ALL_BUSINESS_UNITS_FILTER) {
    filters.push({
      property: ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT,
      operator: IN,
      values: [businessUnit]
    });
  }
  requestBody.filterGroups = [{
    filters
  }];
  if (search) {
    requestBody.query = search;
  }
  const count = rowsPerPage || ITEMS_PER_PAGE;
  requestBody.count = count;
  if (page) {
    requestBody.offset = count * (page - 1);
  }
  const sortProperty = ColumnConfigurations.getIn([sort, 'apiSortProperty']);
  if (sortProperty && sortDir) {
    requestBody.sorts = [{
      property: sortProperty,
      order: TableSortDirToCrmSearchSortDir[sortDir]
    }];
  }
  return requestBody;
};
const parsePages = ({
  objects,
  total
}) => {
  const pages = objects.reduce((acc, pageItem) => acc.set(pageItem.id, Page.from(pageItem)), new OrderedMap());
  return {
    pages,
    total
  };
};
const getUri = contentType => {
  const resource = contentType === ContentTypes.LANDING_PAGE ? 'landing-pages' : 'site-pages';
  return `${URI}/${resource}/crm-search/search`;
};
export default class PagesCrmSearchApi {
  static _fetchPages(earlyRequest, contentType, hasFiltersApplied, opts = {}) {
    const options = Object.assign({}, DEFAULT_OPTIONS, buildRequestBody(hasFiltersApplied, opts));
    const request = getEarlyRequesterResultObservable({
      name: earlyRequest,
      retryRequest: () => http.post(getUri(contentType), {
        data: options
      })
    });
    return request.pipe(map(parsePages));
  }
  static fetchPages(contentType, hasFiltersApplied, opts) {
    return this._fetchPages(`default-large-sites-${contentType}-pages-fetch`, contentType, hasFiltersApplied, opts);
  }
  static fetchPagesForStartState(contentType) {
    const request = getEarlyRequesterResultObservable({
      name: `fetch-${contentType}-pages-for-start-state`,
      retryRequest: () => {
        const selectedBusinessUnitIdFromGlobalNav = getSelectedBusinessUnitIdFromGlobalNav();
        return http.post(getUri(contentType), {
          data: {
            filterGroups: [{
              filters: [{
                operator: 'EQ',
                property: 'hs_all_assigned_business_unit_ids',
                value: selectedBusinessUnitIdFromGlobalNav === ALL_BUSINESS_UNITS_FILTER_FROM_GLOBAL_NAV ? undefined : parseInt(selectedBusinessUnitIdFromGlobalNav, 10)
              }]
            }],
            objectTypeId: '0-10',
            sorts: [{
              property: 'hs_updated_at',
              order: 'DESC'
            }],
            count: 1,
            query: '',
            offset: 0
          }
        });
      }
    });
    return request.pipe(map(parsePages));
  }
}