'use es6';

import { Record, Set as ImmutableSet } from 'immutable';
const defaults = {
  zoomLevel: 1,
  openTrees: new ImmutableSet(),
  selectedContent: new ImmutableSet(),
  shouldShowFullUrls: false,
  shouldShowStatuses: false,
  shouldShowTranslations: false,
  defaultResolvedLanguage: ''
};
export default class SiteTreeDisplay extends Record(defaults) {}