import { FINISHED, NOT_STARTED } from 'content-assistance-lib/hooks/constants';
import { useContentAssistantSettings } from 'content-assistance-lib/hooks/useContentAssistantSettings';
import { useEffect } from 'react';
import { handleAndReportException } from '../utils/errorUtils';
export const useFetchContentAssistantSettings = () => {
  const {
    fetch: fetchContentAssistantSettings,
    getRequest: contentAssistantSettingsRequestStatus,
    isContentAssistanceEnabled
  } = useContentAssistantSettings({
    onError: err => handleAndReportException('Unable to fetch CA settings', err)
  });
  useEffect(() => {
    if (contentAssistantSettingsRequestStatus === NOT_STARTED) {
      fetchContentAssistantSettings();
    }
  }, [contentAssistantSettingsRequestStatus, fetchContentAssistantSettings]);
  return {
    isContentAssistanceOn: contentAssistantSettingsRequestStatus === FINISHED && isContentAssistanceEnabled,
    contentAssistantSettingsRequestStatus
  };
};