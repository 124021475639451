'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import Column from '../models/Column';
import { ContentNameKeysToCrmPropertyNames } from './contentProperties';
import { SortDirTypes } from './table';
export const ColumnTypes = {
  NAME: 'name',
  PUBLISH_STATUS: 'state',
  UPDATED_DATE: 'updated',
  UPDATED_BY: 'updatedBy',
  CREATED_DATE: 'created',
  AUTHOR: 'author',
  TAG: 'tag',
  TEST_STATUS: 'testStatus',
  DOMAIN: 'domain',
  LANGUAGE: 'language',
  CREATED_BY: 'createdBy',
  CAMPAIGN: 'campaign',
  TEAM: 'team',
  TITLE: 'title',
  PUBLISH_DATE: 'published',
  AUDIENCE_ACCESS: 'audienceAccess',
  TAG_NAME: 'tagName',
  LIVE_POSTS: 'livePosts',
  TOTAL_POSTS: 'totalPosts',
  LAST_USED: 'lastUsed',
  BLOGS: 'totalBlogs',
  COMMENT: 'comment',
  COMMENTER: 'userName',
  POSTED: 'createdAt',
  POST: 'post',
  COMMENT_STATUS: 'commentStatus',
  POST_AUTHOR: 'postAuthor',
  POST_LANGUAGE: 'postLanguage',
  INTERNAL_NAME: 'internalName',
  PUBLIC_NAME: 'fullName',
  EMAIL: 'email',
  AVATAR: 'avatar',
  APPROVAL_STATUS: 'approvalStatus'
};
export const DefaultPagesColumns = List([ColumnTypes.NAME, ColumnTypes.PUBLISH_STATUS, ColumnTypes.AUDIENCE_ACCESS, ColumnTypes.TEST_STATUS, ColumnTypes.UPDATED_DATE, ColumnTypes.CREATED_DATE]);
export const DefaultPagesFrameworkColumns = [ContentNameKeysToCrmPropertyNames.NAME, ContentNameKeysToCrmPropertyNames.STATE, ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS, ContentNameKeysToCrmPropertyNames.TEST_STATUS, ContentNameKeysToCrmPropertyNames.UPDATED_DATE, ContentNameKeysToCrmPropertyNames.CREATED_DATE];
export const DefaultBlogColumns = List([ColumnTypes.NAME, ColumnTypes.PUBLISH_STATUS, ColumnTypes.AUDIENCE_ACCESS, ColumnTypes.UPDATED_DATE, ColumnTypes.CREATED_DATE]);
export const DefaultBlogFrameworkColumns = [ContentNameKeysToCrmPropertyNames.NAME, ContentNameKeysToCrmPropertyNames.STATE, ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS, ContentNameKeysToCrmPropertyNames.UPDATED_DATE, ContentNameKeysToCrmPropertyNames.CREATED_DATE];
export const DefaultBlogListingPageFrameworkColumns = [ContentNameKeysToCrmPropertyNames.NAME, ContentNameKeysToCrmPropertyNames.STATE, ContentNameKeysToCrmPropertyNames.CONTENT_GROUP_ID, ContentNameKeysToCrmPropertyNames.UPDATED_DATE, ContentNameKeysToCrmPropertyNames.CREATED_DATE];
export const PagesCustomizableColumns = [ColumnTypes.AUDIENCE_ACCESS, ColumnTypes.APPROVAL_STATUS, ColumnTypes.CAMPAIGN, ColumnTypes.CREATED_BY, ColumnTypes.CREATED_DATE, ColumnTypes.DOMAIN, ColumnTypes.LANGUAGE, ColumnTypes.PUBLISH_DATE, ColumnTypes.PUBLISH_STATUS, ColumnTypes.TEAM, ColumnTypes.TEST_STATUS, ColumnTypes.TITLE, ColumnTypes.UPDATED_BY, ColumnTypes.UPDATED_DATE];
export const BlogTagsCustomizableColumns = [ColumnTypes.LIVE_POSTS, ColumnTypes.TOTAL_POSTS, ColumnTypes.BLOGS, ColumnTypes.LAST_USED, ColumnTypes.LANGUAGE];
export const DefaultTagsColumns = List([ColumnTypes.TAG_NAME, ColumnTypes.LIVE_POSTS, ColumnTypes.TOTAL_POSTS, ColumnTypes.LAST_USED]);
export const BlogAuthorsCustomizableColumns = [ColumnTypes.AVATAR, ColumnTypes.LIVE_POSTS, ColumnTypes.TOTAL_POSTS, ColumnTypes.BLOGS, ColumnTypes.EMAIL, ColumnTypes.PUBLIC_NAME, ColumnTypes.LANGUAGE];
export const DefaultAuthorsColumns = List([ColumnTypes.INTERNAL_NAME, ColumnTypes.AVATAR, ColumnTypes.BLOGS, ColumnTypes.PUBLIC_NAME, ColumnTypes.EMAIL, ColumnTypes.LIVE_POSTS, ColumnTypes.TOTAL_POSTS]);
export const BlogCommentsCustomizableColumns = [ColumnTypes.COMMENT_STATUS, ColumnTypes.POST, ColumnTypes.POSTED, ColumnTypes.POST_AUTHOR, ColumnTypes.POST_LANGUAGE];
export const DefaultCommentsColumns = List([ColumnTypes.COMMENTER, ColumnTypes.COMMENT, ColumnTypes.COMMENT_STATUS, ColumnTypes.POSTED]);
export const RequiredColumns = List([ColumnTypes.NAME]);
export const RequiredColumnsForTags = List([ColumnTypes.TAG_NAME]);
export const RequiredColumnsForAuthors = List([ColumnTypes.INTERNAL_NAME]);
export const RequiredColumnsForComments = List([ColumnTypes.COMMENTER, ColumnTypes.COMMENT]);
export const ContentModelColumnToCrmPropertyNames = {
  [ColumnTypes.NAME]: ContentNameKeysToCrmPropertyNames.NAME,
  [ColumnTypes.PUBLISH_STATUS]: ContentNameKeysToCrmPropertyNames.STATE,
  [ColumnTypes.UPDATED_DATE]: ContentNameKeysToCrmPropertyNames.UPDATED_DATE,
  [ColumnTypes.UPDATED_BY]: ContentNameKeysToCrmPropertyNames.UPDATED_BY,
  [ColumnTypes.CREATED_DATE]: ContentNameKeysToCrmPropertyNames.CREATED_DATE,
  [ColumnTypes.AUTHOR]: ContentNameKeysToCrmPropertyNames.BLOG_AUTHOR,
  [ColumnTypes.TAG]: ContentNameKeysToCrmPropertyNames.TAG,
  [ColumnTypes.TEST_STATUS]: ContentNameKeysToCrmPropertyNames.TEST_STATUS,
  [ColumnTypes.DOMAIN]: ContentNameKeysToCrmPropertyNames.DOMAIN,
  [ColumnTypes.LANGUAGE]: ContentNameKeysToCrmPropertyNames.LANGUAGE,
  [ColumnTypes.CREATED_BY]: ContentNameKeysToCrmPropertyNames.CREATED_BY,
  [ColumnTypes.CAMPAIGN]: ContentNameKeysToCrmPropertyNames.CAMPAIGN,
  [ColumnTypes.TEAM]: ContentNameKeysToCrmPropertyNames.TEAM,
  [ColumnTypes.TITLE]: ContentNameKeysToCrmPropertyNames.TITLE,
  [ColumnTypes.PUBLISH_DATE]: ContentNameKeysToCrmPropertyNames.PUBLISH_DATE,
  [ColumnTypes.AUDIENCE_ACCESS]: ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS,
  [ColumnTypes.BUSINESS_UNIT]: ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT,
  [ColumnTypes.APPROVAL_STATUS]: ContentNameKeysToCrmPropertyNames.APPROVAL_STATUS
  // [ColumnTypes.TAG_NAME]: ,
  // [ColumnTypes.LIVE_POSTS]: ,
  // [ColumnTypes.TOTAL_POSTS]: ,
  // [ColumnTypes.LAST_USED]: ,
  // [ColumnTypes.BLOGS]: ,
  // [ColumnTypes.COMMENT]: ,
  // [ColumnTypes.COMMENTER]: ,
  // [ColumnTypes.POSTED]: ,
  // [ColumnTypes.POST]: ,
  // [ColumnTypes.COMMENT_STATUS]: ,
  // [ColumnTypes.POST_AUTHOR]: ,
  // [ColumnTypes.POST_LANGUAGE]: ,
  // [ColumnTypes.INTERNAL_NAME]: ,
  // [ColumnTypes.PUBLIC_NAME]: ,
  // [ColumnTypes.EMAIL]: ,
  // [ColumnTypes.AVATAR]: ,
};
export const ColumnConfigurations = ImmutableMap({
  [ColumnTypes.NAME]: new Column({
    columnName: ColumnTypes.NAME,
    sortable: true,
    preferredSortDirection: SortDirTypes.ASCENDING,
    width: 500,
    apiSortProperty: ContentNameKeysToCrmPropertyNames.NAME
  }),
  [ColumnTypes.PUBLISH_STATUS]: new Column({
    columnName: ColumnTypes.PUBLISH_STATUS,
    width: 150
  }),
  [ColumnTypes.UPDATED_DATE]: new Column({
    columnName: ColumnTypes.UPDATED_DATE,
    sortable: true,
    preferredSortDirection: SortDirTypes.DESCENDING,
    width: 175,
    apiSortProperty: ContentNameKeysToCrmPropertyNames.UPDATED_DATE
  }),
  [ColumnTypes.UPDATED_BY]: new Column({
    columnName: ColumnTypes.UPDATED_BY,
    sortable: false,
    // TODO: change it to `true` once we have sorting available for this column
    preferredSortDirection: SortDirTypes.ASCENDING,
    width: 150
  }),
  [ColumnTypes.CREATED_DATE]: new Column({
    columnName: ColumnTypes.CREATED_DATE,
    sortable: true,
    preferredSortDirection: SortDirTypes.DESCENDING,
    width: 175,
    apiSortProperty: ContentNameKeysToCrmPropertyNames.CREATED_DATE
  }),
  [ColumnTypes.TEST_STATUS]: new Column({
    columnName: ColumnTypes.TEST_STATUS,
    width: 200
  }),
  [ColumnTypes.DOMAIN]: new Column({
    columnName: ColumnTypes.DOMAIN,
    sortable: true,
    preferredSortDirection: SortDirTypes.ASCENDING,
    width: 300,
    apiSortProperty: ContentNameKeysToCrmPropertyNames.DOMAIN
  }),
  [ColumnTypes.BUSINESS_UNIT]: new Column({
    columnName: ColumnTypes.BUSINESS_UNIT,
    sortable: false,
    // TODO: change it to `true` once we have sorting available for this column
    width: 250
  }),
  [ColumnTypes.LANGUAGE]: new Column({
    columnName: ColumnTypes.LANGUAGE,
    sortable: false,
    // TODO: change it to `true` once we have sorting available for this column
    preferredSortDirection: SortDirTypes.ASCENDING,
    width: 200
  }),
  [ColumnTypes.CREATED_BY]: new Column({
    columnName: ColumnTypes.CREATED_BY,
    sortable: false,
    // TODO: change it to `true` once we have sorting available for this column
    preferredSortDirection: SortDirTypes.ASCENDING,
    width: 150
  }),
  [ColumnTypes.CAMPAIGN]: new Column({
    columnName: ColumnTypes.CAMPAIGN,
    sortable: false,
    // TODO: change it to `true` once we have sorting available for this column
    preferredSortDirection: SortDirTypes.ASCENDING,
    width: 150
  }),
  [ColumnTypes.TEAM]: new Column({
    columnName: ColumnTypes.TEAM,
    width: 150
  }),
  [ColumnTypes.TITLE]: new Column({
    columnName: ColumnTypes.TITLE,
    sortable: true,
    preferredSortDirection: SortDirTypes.ASCENDING,
    width: 300,
    apiSortProperty: ContentNameKeysToCrmPropertyNames.TITLE
  }),
  [ColumnTypes.PUBLISH_DATE]: new Column({
    columnName: ColumnTypes.PUBLISH_DATE,
    sortable: true,
    preferredSortDirection: SortDirTypes.DESCENDING,
    width: 175,
    apiSortProperty: ContentNameKeysToCrmPropertyNames.PUBLISH_DATE
  }),
  [ColumnTypes.AUDIENCE_ACCESS]: new Column({
    columnName: ColumnTypes.AUDIENCE_ACCESS,
    width: 200
  }),
  [ColumnTypes.AUTHOR]: new Column({
    columnName: ColumnTypes.AUTHOR,
    width: 200
  }),
  [ColumnTypes.TAG]: new Column({
    columnName: ColumnTypes.TAG,
    width: 150
  }),
  [ColumnTypes.TAG_NAME]: new Column({
    columnName: ColumnTypes.TAG_NAME,
    width: 350,
    sortable: true,
    preferredSortDirection: SortDirTypes.DESCENDING
  }),
  [ColumnTypes.COMMENTER]: new Column({
    columnName: ColumnTypes.COMMENTER,
    width: 400,
    sortable: true,
    preferredSortDirection: SortDirTypes.ASCENDING
  }),
  [ColumnTypes.COMMENT]: new Column({
    columnName: ColumnTypes.COMMENT,
    width: 430
  }),
  [ColumnTypes.POSTED]: new Column({
    columnName: ColumnTypes.POSTED,
    width: 150,
    sortable: true,
    preferredSortDirection: SortDirTypes.DESCENDING
  }),
  [ColumnTypes.COMMENT_STATUS]: new Column({
    columnName: ColumnTypes.COMMENT_STATUS,
    width: 150
  }),
  [ColumnTypes.POST]: new Column({
    columnName: ColumnTypes.POST,
    width: 150,
    sortable: true,
    preferredSortDirection: SortDirTypes.ASCENDING
  }),
  [ColumnTypes.POST_AUTHOR]: new Column({
    columnName: ColumnTypes.POST_AUTHOR,
    width: 200,
    sortable: true,
    preferredSortDirection: SortDirTypes.ASCENDING
  }),
  [ColumnTypes.POST_LANGUAGE]: new Column({
    columnName: ColumnTypes.POST_LANGUAGE,
    width: 200,
    sortable: false // TODO: change it to `true` once we have sorting available for this column
  }),
  [ColumnTypes.LIVE_POSTS]: new Column({
    columnName: ColumnTypes.LIVE_POSTS,
    width: 100,
    sortable: true,
    preferredSortDirection: SortDirTypes.DESCENDING
  }),
  [ColumnTypes.TOTAL_POSTS]: new Column({
    columnName: ColumnTypes.TOTAL_POSTS,
    width: 100,
    sortable: true,
    preferredSortDirection: SortDirTypes.DESCENDING
  }),
  [ColumnTypes.INTERNAL_NAME]: new Column({
    columnName: ColumnTypes.INTERNAL_NAME,
    width: 350,
    sortable: true,
    preferredSortDirection: SortDirTypes.DESCENDING
  }),
  [ColumnTypes.PUBLIC_NAME]: new Column({
    columnName: ColumnTypes.PUBLIC_NAME,
    width: 250,
    sortable: true,
    preferredSortDirection: SortDirTypes.ASCENDING
  }),
  [ColumnTypes.AVATAR]: new Column({
    columnName: ColumnTypes.AVATAR,
    width: 200
  }),
  [ColumnTypes.EMAIL]: new Column({
    columnName: ColumnTypes.EMAIL,
    width: 250,
    sortable: true,
    preferredSortDirection: SortDirTypes.ASCENDING
  }),
  [ColumnTypes.BLOGS]: new Column({
    columnName: ColumnTypes.BLOGS,
    width: 100,
    sortable: false,
    // TODO: change it to `true` once we have sorting available for this column
    preferredSortDirection: SortDirTypes.DESCENDING
  }),
  [ColumnTypes.LAST_USED]: new Column({
    columnName: ColumnTypes.LAST_USED,
    width: 200,
    sortable: true,
    preferredSortDirection: SortDirTypes.DESCENDING
  }),
  [ColumnTypes.APPROVAL_STATUS]: new Column({
    columnName: ColumnTypes.APPROVAL_STATUS,
    width: 200
  })
});