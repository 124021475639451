export default class Portal {
  constructor(props) {
    this.scopes = [];
    this.timezone = null;
    this.createdAt = null;
    this.scopes = props.scopes;
    this.timezone = props.timezone;
    this.createdAt = props.createdAt;
  }
  hasScope(scope) {
    return this.scopes.includes(scope);
  }
  hasSitePagesAccess() {
    return this.scopes.includes('sitepages-access');
  }
  hasLandingPagesAccess() {
    return this.scopes.includes('landingpages-access');
  }
}