import { createTracker } from 'usage-tracker';
import { setPrimaryTracker } from 'usage-tracker-container';
import { REPORTING_TRACKER } from 'reporting-data/constants/usageTracker';
import { usageTrackerContainer as contentSpecialistUsageTrackerContainer } from 'content-specialists-lib/utils/usageTracker';
import { ContentTypes } from 'content-specialists-lib/constants/contentTypes';
import events from '../../../events.yaml';
export const usageTracker = createTracker({
  events,
  lastKnownEventProperties: ['screen', 'subscreen'],
  properties: {
    namespace: 'Website'
  },
  onError: err => {
    throw err;
  }
});
setPrimaryTracker(usageTracker, 'ContentUILib');
contentSpecialistUsageTrackerContainer.initialize(usageTracker, ContentTypes.BLOG_POST);
setPrimaryTracker(usageTracker, REPORTING_TRACKER);
export const usageTrackerAsBeacon = usageTracker.clone({});
export const usageTrackerForFrameworkListingLib = usageTracker.clone({
  lastKnownEventProperties: ['screen']
});