import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["userId"],
  _excluded2 = ["userId"];
import { createSlice } from '@reduxjs/toolkit';
import RequestStatuses from '../constants/RequestStatus';
// @ts-expect-error Untyped module
import LocalStorage from '../api/LocalStorage';
const initialState = {
  status: RequestStatuses.UNINITIALIZED,
  saveStatus: RequestStatuses.UNINITIALIZED,
  data: {
    authorsCustomColumns: [],
    tagsCustomColumns: [],
    commentsCustomColumns: [],
    blogPostsCustomColumns: [],
    landingPagesCustomColumns: [],
    sitePagesCustomColumns: [],
    blogPostsFrameworkCustomColumns: [],
    blogListingPagesFrameworkCustomColumns: [],
    landingPagesFrameworkCustomColumns: [],
    sitePagesFrameworkCustomColumns: [],
    hasSeenLargeSitesOnboardingTour: false,
    hasSeenLpApprovalStatusColumnTour: false,
    hasSeenWpApprovalStatusColumnTour: false,
    hasSeenBpApprovalStatusColumnTour: false
  }
};
const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    // Fetching user settings
    requestedUserSettings(state) {
      state.status = 'PENDING';
    },
    receivedUserSettings(state, _ref) {
      let {
          payload: {
            userId
          }
        } = _ref,
        userSettings = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
      state.data = userSettings;
      state.status = RequestStatuses.SUCCEEDED;
      LocalStorage.replaceUserSettings(userId, userSettings);
    },
    receivedUserSettingsFailure(state) {
      state.status = RequestStatuses.FAILED;
    },
    // Saving user settings
    requestedSaveUserSettings(state) {
      state.saveStatus = RequestStatuses.PENDING;
    },
    receivedSavedUserSettings(state, _ref2) {
      let {
          payload: {
            userId
          }
        } = _ref2,
        userSettings = _objectWithoutPropertiesLoose(_ref2.payload, _excluded2);
      state.data = Object.assign({}, state.data, userSettings);
      state.saveStatus = RequestStatuses.SUCCEEDED;
      LocalStorage.updateUserSetting(userId, userSettings);
    },
    receivedSaveUserSettingsFailure(state) {
      state.saveStatus = RequestStatuses.FAILED;
    }
  }
});
export const {
  requestedUserSettings,
  receivedUserSettings,
  receivedUserSettingsFailure,
  requestedSaveUserSettings,
  receivedSavedUserSettings,
  receivedSaveUserSettingsFailure
} = userSettingsSlice.actions;
export default userSettingsSlice.reducer;