'use es6';

import { catchError } from 'rxjs/operators/catchError';
import { map } from 'rxjs/operators/map';
import { mergeMap } from 'rxjs/operators/mergeMap';
import { startWith } from 'rxjs/operators/startWith';
import { of } from 'rxjs/observable/of';
import { getPagesAction, PagesActionTypes } from '../actions/PagesActions';
import { getHasWebsiteAccess } from '../../shared/selectors/authSelectors';
import { ContentTypes } from '../../shared/constants/ContentTypes';
import PagesCrmSearch from '../api/PagesCrmSearch';
export default ((action$, state$) => action$.ofType(PagesActionTypes.FETCH_PAGES_FOR_START_STATE).pipe(mergeMap(({
  payload: contentType
}) => {
  const requestPagesForStartState = getPagesAction(contentType, 'requestPagesForStartState');
  const receivePagesForStartState = getPagesAction(contentType, 'receivePagesForStartState');
  const receivePagesForStartStateFailure = getPagesAction(contentType, 'receivePagesForStartStateFailure');
  const hasAccess = contentType === ContentTypes.SITE_PAGE ? getHasWebsiteAccess(state$.getState()) : true;
  return hasAccess ? PagesCrmSearch.fetchPagesForStartState(contentType).pipe(map(receivePagesForStartState), catchError(error => of(receivePagesForStartStateFailure(error))), startWith(requestPagesForStartState())) : of();
})));