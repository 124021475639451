'use es6';

import { Map as ImmutableMap } from 'immutable';
import I18n from 'I18n';
import { IN, GREATER, LESS, KNOWN, NOT_KNOWN, EQUAL } from 'customer-data-filters/converters/contactSearch/FilterContactSearchOperatorTypes';
import { PublicAccessRuleTypes } from 'ui-memberships-settings-lib/constants/PublicAccessRuleTypes';
import AudienceAccessTypes from 'ContentUtils/constants/AudienceAccessTypes';
import { ALL_FILTER } from '../constants/DefaultFilterParams';
import { CustomProperties, FilterConfigurationsByProperty, FilterTypes } from '../constants/filters';
import ContentStateFilters from '../constants/ContentStateFilters';
import { FilterableStatesToPublishStates } from '../../pages/constants/PageStates';
import { TEST_STATUS_FOR_CRM_SEARCH, Variations } from '../../pages/constants/Variations';
import { SELF_USER_IDENTIFIER } from '../../frameworkListing/constants';
import { ApprovalStatusToFilterValue } from '../../frameworkListing/utils/filterPropertyUtils';
import { ContentNameKeysToCrmPropertyNames } from '../constants/contentProperties';
export const getSelectedFilterTagText = (selectedValue, options) => {
  // TODO: cleanup `options.size` once we move off old code wit immutables
  if (options && (options.size > 0 || options.length > 0)) {
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      return selectedOption.label;
    }
  } else if (selectedValue === SELF_USER_IDENTIFIER) {
    return I18n.text(`content-management-ui.shared.filters.labels.${SELF_USER_IDENTIFIER}`);
  }
  return selectedValue;
};
export const buildDropdownFiltersDataOptions = (filterQuery, properties, filterContentNameKeysToCrmPropertyNames) => filterContentNameKeysToCrmPropertyNames.reduce((acc, propertyName) => {
  const {
    filterType,
    referencedObjectType
  } = FilterConfigurationsByProperty.get(propertyName);
  acc[filterType] = {
    filter: filterQuery.get(filterType),
    property: properties.get(propertyName),
    referencedObjectType
  };
  return acc;
}, {});
export const buildFilterTagsData = (filterQuery, properties) => filterQuery.reduce((acc, filter) => {
  if (FilterConfigurationsByProperty.has(filter.property)) {
    const property = properties.get(filter.property);
    const {
      referencedObjectType
    } = FilterConfigurationsByProperty.get(filter.property);
    acc.push(Object.assign({}, filter, {
      propertyType: property ? property.get('type') : null,
      options: property ? property.get('options') : null,
      referencedObjectType
    }));
  }
  return acc;
}, []);
export const convertFilterQueryToMap = query => query.reduce((acc, filter) => acc.set(FilterConfigurationsByProperty.getIn([filter.property, 'filterType']), filter), new ImmutableMap());
export const getPublishedStateFilter = () => ({
  property: ContentNameKeysToCrmPropertyNames.PUBLISH_DATE,
  operator: LESS,
  value: I18n.moment().format('x')
});
export const getScheduledStateFilter = () => ({
  property: ContentNameKeysToCrmPropertyNames.PUBLISH_DATE,
  operator: GREATER,
  value: I18n.moment().format('x')
});
export const correctAudienceAccessFilter = audienceAccessFilter => {
  const audienceAccessFilterValue = audienceAccessFilter.value;
  if (audienceAccessFilterValue === AudienceAccessTypes.ALL) {
    return [{
      property: ContentNameKeysToCrmPropertyNames.PASSWORD,
      operator: NOT_KNOWN
    }, {
      property: ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS_RULES_ENABLED,
      operator: EQUAL,
      value: false
    }];
  } else if (audienceAccessFilterValue === AudienceAccessTypes.PASSWORD) {
    return [{
      property: ContentNameKeysToCrmPropertyNames.PASSWORD,
      operator: KNOWN
    }];
  }
  const audienceAccessType = audienceAccessFilterValue === AudienceAccessTypes.SPECIFIC_CONTACTS ? PublicAccessRuleTypes.ACCESS_GROUP_MEMBERSHIP : PublicAccessRuleTypes.SSO_LOGIN;
  return [{
    property: ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS_RULES_ENABLED,
    operator: EQUAL,
    value: true
  }, {
    property: ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS_TYPE,
    operator: EQUAL,
    value: audienceAccessType
  }];
};
export const correctVariationFilter = variationFilter => {
  if (variationFilter.value === Variations.LANG) {
    return {
      property: ContentNameKeysToCrmPropertyNames.LANGUAGE,
      operator: KNOWN
    };
  }
  const abStatusValue = variationFilter.value === Variations.MAB ? TEST_STATUS_FOR_CRM_SEARCH.mabMaster : TEST_STATUS_FOR_CRM_SEARCH.abMaster;
  return {
    operator: IN,
    property: ContentNameKeysToCrmPropertyNames.AB_STATUS,
    values: [abStatusValue]
  };
};
export const correctFilterQueryForPagesRequest = (currentlyAppliedFilters, query) => {
  const contentStateFilter = currentlyAppliedFilters.state;
  const domainFilter = currentlyAppliedFilters.domain;
  const correctedFilters = query.filter(({
    property
  }) => !CustomProperties.includes(property)).valueSeq().toArray();
  const variationFilter = query.get(FilterTypes.VARIATION);
  if (variationFilter && Object.keys(variationFilter).length > 0) {
    const correctedVariationFilter = correctVariationFilter(variationFilter);
    correctedFilters.push(correctedVariationFilter);
  }
  const audienceAccessFilter = query.get(FilterTypes.AUDIENCE_ACCESS);
  if (audienceAccessFilter && Object.keys(audienceAccessFilter).length > 0) {
    const correctedAudienceAccessFilter = correctAudienceAccessFilter(audienceAccessFilter);
    correctedAudienceAccessFilter.forEach(filter => correctedFilters.push(filter));
  }
  const approvalStatusFilter = query.get(FilterTypes.APPROVAL_STATUS);
  if (approvalStatusFilter && Object.keys(approvalStatusFilter).length > 0) {
    correctedFilters.push(Object.assign({}, approvalStatusFilter, {
      value: ApprovalStatusToFilterValue[approvalStatusFilter.value]
    }));
  }
  if (contentStateFilter !== ContentStateFilters.ALL && contentStateFilter !== ContentStateFilters.ARCHIVED) {
    const states = FilterableStatesToPublishStates[contentStateFilter];
    correctedFilters.push({
      property: ContentNameKeysToCrmPropertyNames.STATE,
      operator: IN,
      values: states.toArray()
    });
  }
  if (domainFilter !== ALL_FILTER) {
    correctedFilters.push({
      property: ContentNameKeysToCrmPropertyNames.DOMAIN,
      operator: IN,
      values: [domainFilter]
    });
  }
  if (!query.has(FilterTypes.PUBLISH_DATE)) {
    if (contentStateFilter === ContentStateFilters.PUBLISHED) {
      correctedFilters.push(getPublishedStateFilter());
    } else if (contentStateFilter === ContentStateFilters.SCHEDULED) {
      correctedFilters.push(getScheduledStateFilter());
    }
  }
  return correctedFilters;
};