'use es6';

import { mergeMap } from 'rxjs/operators/mergeMap';
import { merge } from 'rxjs/observable/merge';
import { of } from 'rxjs/observable/of';
import { AppActionTypes } from '../actions/AppActions';
import { fetchSharedPageUsage } from '../../pages/reducers/pagesUsage';
import { fetchBlogPostsUsage, fetchBlogsUsage } from '../../blog/reducers/blogUsage';
import { fetchSettings } from '../reducers/portal';
import { fetchThemes } from '../reducers/contentCreatorInfo';
export default (action$ => action$.ofType(AppActionTypes.NAVIGATED_TO_APP).pipe(mergeMap(() => merge(of(fetchSettings()), of(fetchThemes()), of(fetchSharedPageUsage()), of(fetchBlogsUsage()), of(fetchBlogPostsUsage())))));