export const ContentNameKeysToCrmPropertyNames = {
  AB_STATUS: 'hs_ab_status',
  AB_TEST_ID: 'hs_ab_test_id',
  ARCHIVED: 'hs_archived',
  AUDIENCE_ACCESS: 'hs_audience_access',
  AUDIENCE_ACCESS_TYPE: 'hs_public_access_rule_type',
  AUDIENCE_ACCESS_RULES_ENABLED: 'hs_public_access_rules_enabled',
  BLOG_AUTHOR: 'hs_blog_author_id',
  BUSINESS_UNIT: 'hs_all_assigned_business_unit_ids',
  CAMPAIGN: 'hs_campaign',
  CATEGORY_ID: 'hs_content_type_category_id',
  CONTENT_ID: 'hs_content_id',
  CONTENT_GROUP_ID: 'hs_content_group_id',
  CONTENT_TYPE: 'hs_content_type_category_id',
  CREATED_BY: 'hs_created_by_id',
  CREATED_DATE: 'hs_createdate',
  DOMAIN: 'hs_resolved_domain',
  IS_PUBLISHED: 'hs_is_published',
  LANGUAGE: 'hs_language',
  MAB_TEST_ID: 'hs_mab_experiment_id',
  MASTER_ID: 'hs_master_id',
  NAME: 'hs_name',
  PASSWORD: 'hs_password',
  PUBLISH_DATE: 'hs_publish_date',
  RESOLVED_LANGUAGE: 'hs_resolved_language',
  SLUG: 'hs_slug',
  STATE: 'hs_state',
  TAG: 'hs_tag_ids',
  TEAM: 'hs_team_perms',
  TEMPLATE_PATH: 'hs_template_path',
  TEST_STATUS: 'hs_test_status',
  THEME_PATH: 'hs_theme_path',
  TRANSLATED_FROM_ID: 'hs_translated_from_id',
  UPDATED_BY: 'hs_updated_by_id',
  UPDATED_DATE: 'hs_updated_at',
  URL: 'hs_url',
  USER: 'hs_user_perms',
  VARIATION: 'hs_variation',
  TITLE: 'hs_html_title',
  FOLDER_ID: 'hs_folder_id',
  DYNAMIC_PAGE_DATA_SOURCE_TYPE: 'hs_dynamic_page_data_source_type',
  DYNAMIC_PAGE_DATA_SOURCE_ID: 'hs_dynamic_page_data_source_id',
  TEMPLATE_TYPE: 'hs_template_type',
  OBJECT_ID: 'hs_object_id',
  APPROVAL_STATUS: 'hs_approval_publish_status',
  PAGE_EXPIRY_DATE: 'hs_page_expiry_date',
  PAGE_EXPIRY_REDIRECT_URL: 'hs_page_expiry_redirect_url',
  PAGE_EXPIRY_ENABLED: 'hs_page_expiry_enabled',
  POST_BODY: 'hs_post_body',
  // fake properties
  CLONED_FROM: 'hs_cloned_from',
  CURRENT_STATE: 'hs_current_state',
  PREVIEW_KEY: 'hs_preview_key',
  PARENT_BLOG: 'hs_parent_blog',
  POST_TEMPLATE: 'hs_post_template',
  TRANSLATED_CONTENT: 'hs_translated_content',
  SCHEDULED_UPDATE_DATE: 'hs_scheduleddate',
  SECURITY_STATE: 'hs_security_state',
  SUBCATEGORY: 'hs_subcategory'
};

// TODO: Reevaluate whether some of these need to be here
// Some properties have no Content model equivalent and are only used to filter
// eg. hs_audience_access, hs_variation
// Also some that are in Content model, but not needed for CRM object, eg. categoryId, subcategory

// Lookup for internal ContentPropertyNameKey to either Content Model or CRM Object property names
export const ContentPropertiesMap = {
  AB_STATUS: ['abStatus', ContentNameKeysToCrmPropertyNames.AB_STATUS],
  AB_TEST_ID: ['abTestId', ContentNameKeysToCrmPropertyNames.AB_TEST_ID],
  ARCHIVED: ['archived', ContentNameKeysToCrmPropertyNames.ARCHIVED],
  AUDIENCE_ACCESS_TYPE: ['publicAccessRules', ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS_TYPE],
  AUDIENCE_ACCESS: ['audienceAccess', ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS],
  AUDIENCE_ACCESS_RULES_ENABLED: ['publicAccessRulesEnabled', ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS_RULES_ENABLED],
  BLOG_AUTHOR: ['blogAuthor', ContentNameKeysToCrmPropertyNames.BLOG_AUTHOR],
  BUSINESS_UNIT: ['businessUnitId', ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT],
  CAMPAIGN: ['campaignName', ContentNameKeysToCrmPropertyNames.CAMPAIGN],
  CATEGORY_ID: ['categoryId', ContentNameKeysToCrmPropertyNames.CATEGORY_ID],
  CLONED_FROM: ['clonedFrom', ContentNameKeysToCrmPropertyNames.CLONED_FROM],
  CONTENT_ID: ['id', ContentNameKeysToCrmPropertyNames.CONTENT_ID],
  CONTENT_GROUP_ID: ['contentGroupId', ContentNameKeysToCrmPropertyNames.CONTENT_GROUP_ID],
  CONTENT_TYPE: ['contentTypeCategoryId', ContentNameKeysToCrmPropertyNames.CONTENT_TYPE],
  CREATED_BY: ['createdById', ContentNameKeysToCrmPropertyNames.CREATED_BY],
  CREATED_DATE: ['created', ContentNameKeysToCrmPropertyNames.CREATED_DATE],
  CURRENT_STATE: ['currentState', ContentNameKeysToCrmPropertyNames.CURRENT_STATE],
  DOMAIN: ['resolvedDomain', ContentNameKeysToCrmPropertyNames.DOMAIN],
  LANGUAGE: ['language', ContentNameKeysToCrmPropertyNames.LANGUAGE],
  MAB_TEST_ID: ['mabExperimentId', ContentNameKeysToCrmPropertyNames.MAB_TEST_ID],
  MASTER_ID: ['masterId', ContentNameKeysToCrmPropertyNames.MASTER_ID],
  NAME: ['name', ContentNameKeysToCrmPropertyNames.NAME],
  PARENT_BLOG: ['parentBlog', ContentNameKeysToCrmPropertyNames.PARENT_BLOG],
  PASSWORD: ['password', ContentNameKeysToCrmPropertyNames.PASSWORD],
  POST_BODY: ['postBody', ContentNameKeysToCrmPropertyNames.POST_BODY],
  POST_TEMPLATE: ['postTemplate', ContentNameKeysToCrmPropertyNames.POST_TEMPLATE],
  PREVIEW_KEY: ['previewKey', ContentNameKeysToCrmPropertyNames.PREVIEW_KEY],
  PUBLISH_DATE: ['publishDate', ContentNameKeysToCrmPropertyNames.PUBLISH_DATE],
  RESOLVED_LANGUAGE: [null, ContentNameKeysToCrmPropertyNames.RESOLVED_LANGUAGE],
  SCHEDULED_UPDATE_DATE: ['scheduledUpdateDate', ContentNameKeysToCrmPropertyNames.SCHEDULED_UPDATE_DATE],
  SECURITY_STATE: ['securityState', ContentNameKeysToCrmPropertyNames.SECURITY_STATE],
  SLUG: ['slug', ContentNameKeysToCrmPropertyNames.SLUG],
  STATE: ['state', ContentNameKeysToCrmPropertyNames.STATE],
  SUBCATEGORY: ['subcategory', ContentNameKeysToCrmPropertyNames.SUBCATEGORY],
  TAG: ['tagIds', ContentNameKeysToCrmPropertyNames.TAG],
  TEAM: ['teamPerms', ContentNameKeysToCrmPropertyNames.TEAM],
  TEMPLATE_PATH: ['templatePath', ContentNameKeysToCrmPropertyNames.TEMPLATE_PATH],
  TEST_STATUS: ['testStatus', ContentNameKeysToCrmPropertyNames.TEST_STATUS],
  TRANSLATED_CONTENT: ['translatedContent', null],
  TRANSLATED_FROM_ID: ['translatedFromId', ContentNameKeysToCrmPropertyNames.TRANSLATED_FROM_ID],
  UPDATED_BY: ['updatedById', ContentNameKeysToCrmPropertyNames.UPDATED_BY],
  UPDATED_DATE: ['updated', ContentNameKeysToCrmPropertyNames.UPDATED_DATE],
  USER: ['userPerms', ContentNameKeysToCrmPropertyNames.USER],
  VARIATION: [null, ContentNameKeysToCrmPropertyNames.VARIATION],
  TITLE: ['htmlTitle', ContentNameKeysToCrmPropertyNames.TITLE],
  FOLDER_ID: ['folderId', ContentNameKeysToCrmPropertyNames.FOLDER_ID],
  DYNAMIC_PAGE_DATA_SOURCE_TYPE: [null, ContentNameKeysToCrmPropertyNames.DYNAMIC_PAGE_DATA_SOURCE_TYPE],
  DYNAMIC_PAGE_DATA_SOURCE_ID: [null, ContentNameKeysToCrmPropertyNames.DYNAMIC_PAGE_DATA_SOURCE_ID],
  TEMPLATE_TYPE: ['templateType', ContentNameKeysToCrmPropertyNames.TEMPLATE_TYPE],
  OBJECT_ID: ['objectId', ContentNameKeysToCrmPropertyNames.OBJECT_ID],
  URL: ['absoluteUrl', ContentNameKeysToCrmPropertyNames.URL],
  IS_PUBLISHED: ['isPublished', ContentNameKeysToCrmPropertyNames.IS_PUBLISHED],
  THEME_PATH: ['themePath', ContentNameKeysToCrmPropertyNames.THEME_PATH],
  APPROVAL_STATUS: ['approvalStatus', ContentNameKeysToCrmPropertyNames.APPROVAL_STATUS],
  PAGE_EXPIRY_ENABLED: ['pageExpiryEnabled', ContentNameKeysToCrmPropertyNames.PAGE_EXPIRY_ENABLED],
  PAGE_EXPIRY_DATE: ['pageExpiryDate', ContentNameKeysToCrmPropertyNames.PAGE_EXPIRY_DATE],
  PAGE_EXPIRY_REDIRECT_URL: ['pageExpiryRedirectUrl', ContentNameKeysToCrmPropertyNames.PAGE_EXPIRY_REDIRECT_URL]
};