'use es6';

import PortalIdParser from 'PortalIdParser';
import ContentRoutes from 'ContentUtils/Routes';
import { ContentTypes } from '../constants/ContentTypes';
import * as ManagementDataTypes from '../constants/ManagementDataTypes';
import { BLOG_POSTS, LANDING_PAGES, SITE_PAGES, SITE_TREE } from '../constants/UrlPathParams';
import { BLOG_MANAGEMENT_DATA_GROUP, PAGES_MANAGEMENT_DATA_GROUP } from '../constants/ManagementDataGroups';
const PORTAL_ID = PortalIdParser.get();
export const stripProtocol = url => url.replace(/^(http:\/\/)|(https:\/\/)/, '');
export const getAppRoot = () => `/website/${PortalIdParser.get()}`;
export const getComposeUrl = id => `/compose/${PortalIdParser.get()}/blog-post/${id}`;
export const pathnameContainsPath = (pathname, path) => pathname.includes(`/${path}`);
export const pathnameEndsWith = (pathname, path) => new RegExp(`/${path}$`).test(pathname);

// Expects entire location object to be passed as first argument
export const locationContainsPath = ({
  pathname
}, path) => pathnameContainsPath(pathname, path);
export const getShareablePreviewURLForContent = content => {
  const {
    absoluteUrl,
    id,
    previewKey
  } = content;
  if (!previewKey || content.isPublished()) {
    return absoluteUrl;
  }
  return `https://${stripProtocol(absoluteUrl)}?hs_preview=${previewKey}-${id}`;
};
export const getAmpPreviewUrlForBlogPosts = post => {
  const {
    previewKey,
    id
  } = post;
  return ContentRoutes.getPreviewUrl(id, {
    preview_key: previewKey,
    hsLoginVerifyDisabled: true,
    hs_amp: true
  });
};
export const isOnLandingPageRoute = location => location.pathname.match(/\/pages\/landing(\/all)?$/);
export const isOnSitePageRoute = location => location.pathname.match(/\/pages\/site(\/all)?$/);
export const isOnBlogPageRoute = location => location.pathname.match(/\/blog\/posts$/);
export const isAppViewSiteTree = () => locationContainsPath(window.location, SITE_TREE);
export const getTableViewContentType = () => {
  if (locationContainsPath(window.location, LANDING_PAGES)) {
    return ContentTypes.LANDING_PAGE;
  } else if (locationContainsPath(window.location, SITE_PAGES)) {
    return ContentTypes.SITE_PAGE;
  } else if (locationContainsPath(window.location, BLOG_POSTS)) {
    return ContentTypes.BLOG;
  }
  return undefined;
};
export const MARKETPLACE_SOLUTIONS_URL = `/ecosystem/${PORTAL_ID}/marketplace/solutions`;
export const MARKETPLACE_WEBSITE_DEV = `${MARKETPLACE_SOLUTIONS_URL}/website-development`;

// ONLY FOR USE FOR LARGE SITES 1.5 UNGATED CASES
export const getRouteFromManagementDataType = managementDataType => {
  switch (managementDataType) {
    case ManagementDataTypes.LANDING_PAGES:
      return '/pages/landing';
    case ManagementDataTypes.FOLDERS:
      return '/pages/landing/folders';
    case ManagementDataTypes.SITE_PAGES:
      return '/pages/site';
    case ManagementDataTypes.BLOG_POSTS:
      return '/blog/posts';
    case ManagementDataTypes.BLOG_LISTING_PAGE:
      return '/blog/listing';
    case ManagementDataTypes.COMMENTS:
      return '/blog/comments';
    case ManagementDataTypes.AUTHORS:
      return '/blog/authors';
    case ManagementDataTypes.TAGS:
      return '/blog/tags';
    default:
      return '';
  }
};
export const getRouteFromManagementDataGroup = managementDataType => {
  switch (managementDataType) {
    case PAGES_MANAGEMENT_DATA_GROUP:
      return 'pages';
    case BLOG_MANAGEMENT_DATA_GROUP:
      return 'blog';
    default:
      return '';
  }
};
export const getEditorSettingsUrl = (contentId, categoryId) => {
  return `${ContentRoutes.edit(contentId, categoryId)}/settings`;
};
export const getEditorUrlWithQueryParamsAfterCreatingTranslation = ({
  contentId,
  contentCategoryId,
  hasNewBlogVariationJustBeenCreated
}) => {
  const redirectUrl = ContentRoutes.edit(contentId, contentCategoryId);
  const queryParams = ['redirectFromMultiLangVariationCreation=true'];
  if (hasNewBlogVariationJustBeenCreated) {
    queryParams.push('newBlogVariation=true');
  }
  return `${redirectUrl}${queryParams.length > 0 ? '?' : ''}${queryParams.join('&')}`;
};
export const getOnboardingTaskParams = () => {
  const searchParams = new URLSearchParams(document.location.search);
  return {
    isOnboardingTask: searchParams.get('isOnboardingTask') === 'true'
  };
};