import AppDisplayModes from '../constants/AppDisplayModes';
import { ContentTypes as ContentTypeIds } from '../constants/ContentTypeConstants';
import { BLOG_POSTS, TAGS, COMMENTS, AUTHORS, FOLDERS, BLOG_LISTING_PAGE
// @ts-expect-error Untyped module
} from '../constants/ManagementDataTypes';
// @ts-expect-error Untyped module
import { getManagementDataType } from './managementDataTypeHelpers';
// @ts-expect-error Untyped module
import { isAppViewSiteTree } from './urls';
// @ts-expect-error Untyped module
import { BulkActionNamesToTrackingKeys } from '../constants/BulkActions';
import { ACTIONS_USAGE } from '../constants/usageTrackerEvents';
import { usageTracker, usageTrackerAsBeacon } from './usageTracker';
import { getTableViewContentTypeId } from './utils';
const SCREENS = {
  [ContentTypeIds.BLOG_POST]: 'blog',
  [ContentTypeIds.BLOG_LISTING_PAGE]: 'blog',
  [ContentTypeIds.LANDING_PAGE]: 'landing-pages',
  [ContentTypeIds.SITE_PAGE]: 'site-pages',
  [AppDisplayModes.SITE_TREE]: 'site-tree'
};
const SUB_SCREENS = {
  [BLOG_POSTS]: 'posts',
  [TAGS]: 'tags',
  [COMMENTS]: 'comments',
  [AUTHORS]: 'authors',
  [BLOG_LISTING_PAGE]: 'listing',
  [FOLDERS]: 'folders'
};
const getScreenType = contentType => isAppViewSiteTree() ? 'site-tree' : contentType ? SCREENS[contentType] : 'pages';
export const getScreens = (contentType, managementDataType) => {
  const contentTypeFromUrl = getTableViewContentTypeId();
  const managementDataTypeFromUrl = getManagementDataType();
  let screen;
  const managementDataTypeForScreens = managementDataType || managementDataTypeFromUrl;
  if (managementDataTypeForScreens === COMMENTS || managementDataTypeForScreens === TAGS || managementDataTypeForScreens === AUTHORS) {
    screen = SCREENS[ContentTypeIds.BLOG_POST];
  }
  return {
    screen: screen || getScreenType(contentType || contentTypeFromUrl),
    subscreen: SUB_SCREENS[managementDataTypeForScreens] || 'manage'
  };
};
export const getScreensForPreviewPanel = () => {
  const {
    screen,
    subscreen
  } = getScreens();
  return {
    screen,
    subscreen: `${subscreen}-preview`
  };
};
export const trackSuccessfulAction = (event, action, isBeacon = false) => {
  const eventProperties = Object.assign({
    action,
    status: 'success'
  }, getScreens());
  if (isBeacon) {
    usageTrackerAsBeacon.track(event, eventProperties);
  } else {
    usageTracker.track(event, eventProperties);
  }
};
export const trackFailedAction = (event, action) => usageTracker.track(event, Object.assign({
  action,
  status: 'fail'
}, getScreens()));
export const trackBulkActionStartSuccess = actionName => {
  const screens = getScreens();
  usageTracker.track(ACTIONS_USAGE, Object.assign({}, screens, {
    action: BulkActionNamesToTrackingKeys[actionName],
    volume: 'bulk',
    status: 'success'
  }));
  usageTracker.track('bulkUpdateActivation', screens);
};
export const trackBulkActionStartFailure = actionName => {
  usageTracker.track(ACTIONS_USAGE, Object.assign({}, getScreens(), {
    action: BulkActionNamesToTrackingKeys[actionName],
    volume: 'bulk',
    status: 'fail'
  }));
};