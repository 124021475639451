import { createSelector } from 'reselect';
import { gate } from 'hub-http/gates';
import { getTableViewContentTypeId } from '../utils/utils';
// @ts-expect-error Untyped module
import { ContentTypes as LegacyContentTypes } from '../constants/ContentTypes';
import { ContentTypes as ContentTypeIds } from '../constants/ContentTypeConstants';
// @ts-expect-error Untyped module
import { getTableViewContentType } from '../utils/urls';
export const getAuth = state => state.auth;
export const getGates = createSelector([getAuth], auth => auth.gates);
export const getUserData = createSelector([getAuth], auth => auth.user);
const getPortalData = createSelector([getAuth], auth => auth.portal);
export const getUserScopes = createSelector([getUserData], ({
  scopes
}) => scopes);
const getPortalHasScope = scope => createSelector([getPortalData], portal => portal.hasScope(scope));
export const getUserId = createSelector([getUserData], data => data.user_id);
export const getHasTeamsAccess = createSelector([getGates, getUserData], (gates, user) => gates.includes('content-partitioning-teams-v2') && user.hasGenericTeamsAccess());
export const getHasTeamsAccessForContentType = contentType => createSelector([getGates, getUserData], (gates, user) => gates.includes('content-partitioning-teams-v2') && user.hasTeamsAccess(contentType));
export const getHasTeamAssignmentAccess = createSelector([getHasTeamsAccess, getUserData], (teamsAccess, user) => teamsAccess && user.hasTeamAssignmentAccess());
export const getHasTeamsAdvancedModelingAccess = createSelector([getUserScopes], scopes => scopes.includes('teams-advanced-modeling-access'));
export const getHasMembershipsAccess = createSelector([getUserData], user => user.hasMembershipsAccess());
export const hasGoalBasedTestAccess = createSelector([getUserData], user => user.canCreateMABTest());
export const hasAbTestAccess = createSelector([getUserData], user => user.canCreateAbTest());
export const getHasContentTypeAccess = contentType => createSelector([getUserData], user => user.hasContentAccess(contentType));
export const getHasBlogAccess = createSelector([getUserData], user => user.hasBlogAccess());
export const getHasWebsiteAnalyticsAccess = createSelector([getUserData], user => user.hasWebsiteAnalyticsAccess());
export const getHasLandingPagesAccess = createSelector([getUserData], user => user.hasLandingPagesAccess());
export const getHasWebsiteAccess = createSelector([getUserData], user => user.hasWebsiteAccess());
const getPortalHasBlogAccess = getPortalHasScope('blog-access');
export const getPortalHasLandingPagesAccess = getPortalHasScope('landingpages-access');
const getPortalHasLPCustomDomainAccess = getPortalHasScope('landingpages-custom-domain-access');
export const getPortalHasWebsitePagesNoBranding = getPortalHasScope('website-pages-no-branding');
const getPortalHasLandingPagesNoBranding = getPortalHasScope('landingpages-no-branding');
const getPortalHasBlogPagesNoBranding = getPortalHasScope('blog-pages-no-branding');
export const getPortalHasSiteTreeAccess = getPortalHasScope('content-site-tree');
export const getPortalHasSitePagesAccess = createSelector([getPortalData], portal => portal.hasSitePagesAccess());
export const getHasLandingPagesAutoTranslateAccess = getPortalHasScope('landingpages-auto-translate');
export const getHasSitePagesAutoTranslateAccess = getPortalHasScope('sitepages-auto-translate');
export const getHasBlogPostsAutoTranslateAccess = getPortalHasScope('blog-posts-auto-translate');
export const getUserHasAdvancedLPAccess = createSelector([getUserScopes], scopes => scopes.includes('landingpages-advanced-write'));
export const getUserHasSitePagesAccess = createSelector([getUserScopes], scopes => scopes.includes('sitepages-access'));
const getUserHasMarketplaceAssetProvider = createSelector([getUserScopes], scopes => scopes.includes('marketplace-asset-provider'));
export const getUserHasPublishAccess = contentType => createSelector([getUserData], user => user.canPublish(contentType));
export const getUserHasBulkPublishAccess = contentType => createSelector([getUserData], user => user.canBulkPublish(contentType));
export const getHasCmsHub = createSelector([getUserData], user => {
  return user.hasWebsiteAccess() && user.hasThemedContentCreatorAccess();
});
export const getUserHasEitherTestAccess = createSelector([getUserData], user => user.canCreateMABTest() || user.canCreateAbTest());
export const getHasBusinessUnitsAccess = createSelector([getGates, getUserData], (gates, user) => gates.includes('business-units:enforce-access-scope') && user.hasBusinessUnitsAccess());
export const getHasBlogListingWriteScope = createSelector([getUserScopes], scopes => scopes.includes('blog-listing-page-write'));
export const getHasCustomViewsAccess = createSelector([getUserScopes, getTableViewContentTypeId], (scopes, contentTypeId) => scopes.includes('content-management-saved-views') && scopes.includes('contacts-read') && contentTypeId && [ContentTypeIds.BLOG_POST, ContentTypeIds.LANDING_PAGE, ContentTypeIds.SITE_PAGE].includes(contentTypeId));
export const getHasAnalyzeTabGate = createSelector([getGates], gates => gates.includes('RA:landingPageAnalyzeTab'));

// Used for (only) MH starter specific states - where you only have LPs
export const getIsMarketingStarterPortal = createSelector([getPortalHasLandingPagesAccess, getUserHasAdvancedLPAccess, getPortalHasLPCustomDomainAccess, getPortalHasSitePagesAccess, getPortalHasBlogAccess], (hasLandingPagesAccess, hasAdvancedLPAccess, hasLPCustomDomainAccess, hasSitePagesAccess, hasBlogAccess) => hasLandingPagesAccess && !hasAdvancedLPAccess && hasLPCustomDomainAccess && !hasSitePagesAccess && !hasBlogAccess);

// Ensure portal has access to CMS Hub and is not a free/pro/enterprise portal or marketplace provider account
export const getIsCmsStarterPortal = createSelector([getHasCmsHub, getPortalHasWebsitePagesNoBranding, getUserHasMarketplaceAssetProvider], (hasCmsHub, hasWebsitePagesNoBranding, hasMarketplaceAssetProvider) => hasCmsHub && hasWebsitePagesNoBranding && !hasMarketplaceAssetProvider);
export const getHasFreeLandingPages = createSelector([getPortalHasLandingPagesAccess, getPortalHasLandingPagesNoBranding], (hasLandingPagesAccess, hasLandingPagesNoBranding) => hasLandingPagesAccess && !hasLandingPagesNoBranding // Free portals don't have access to pages without branding, whereas starter portals do
);
export const getHasFreeSitePages = createSelector([getPortalHasSitePagesAccess, getPortalHasWebsitePagesNoBranding], (hasSitePagesAccess, hasWebsitePagesNoBranding) => hasSitePagesAccess && !hasWebsitePagesNoBranding // Free portals don't have access to pages with no branding, whereas paid portals do
);
export const getHasFreeBlog = createSelector([getPortalHasBlogAccess, getPortalHasBlogPagesNoBranding], (hasBlogAccess, hasBlogPagesNoBranding) => hasBlogAccess && !hasBlogPagesNoBranding // Free portals don't have access to pages with no branding, whereas paid portals do
);

// Multi-language pages only count against the site pages limit if
// the portal is a CMS Free or Starter portal and has the new bumped up
// site page limit. This preserves the behavior of counting mLang pages
// against the limit for gated portals.
export const getCanCreateMlangPagesIfSitePagesLimitReached = createSelector([getIsCmsStarterPortal, getHasFreeSitePages], (isCmsStarterPortal, hasFreeSitePages) => isCmsStarterPortal || hasFreeSitePages);
export const getShouldShowRemoveBrandingBanner = createSelector([getHasFreeSitePages, getHasFreeBlog, getHasFreeLandingPages, getTableViewContentType], (hasFreeSitePages, hasFreeBlog, hasFreeLandingPages, contentType) => contentType === LegacyContentTypes.SITE_PAGE && hasFreeSitePages || contentType === LegacyContentTypes.BLOG && hasFreeBlog || contentType === LegacyContentTypes.LANDING_PAGE && hasFreeLandingPages);

// Multi-language blog posts don't count against the blog post limit if
// the portal is a CMS Free or Starter portal and is ungated for the
// beta
export const getCanCreateMlangBlogPostsIfLimitReached = createSelector([getIsCmsStarterPortal, getHasFreeBlog], (isCmsStarterPortal, hasFreeBlog) => isCmsStarterPortal || hasFreeBlog);
export const getHasFreeVersionOfCurrentContentType = createSelector([getTableViewContentType, getHasFreeSitePages, getHasFreeLandingPages, getHasFreeBlog], (contentType, hasFreeSitePages, hasFreeLandingPages, hasFreeBlog) => contentType === LegacyContentTypes.SITE_PAGE && hasFreeSitePages || contentType === LegacyContentTypes.LANDING_PAGE && hasFreeLandingPages || contentType === LegacyContentTypes.BLOG && hasFreeBlog);
export const getHasAccessToCmsApprovals = contentType => createSelector([getUserScopes], scopes => {
  switch (contentType) {
    case ContentTypeIds.LANDING_PAGE:
      return scopes.includes('landingpages-approval-read');
    case ContentTypeIds.SITE_PAGE:
      return scopes.includes('pages-approval-read');
    case ContentTypeIds.BLOG_POST:
      return scopes.includes('blog-approval-read');
    default:
      return false;
  }
});
export const getUserHasSitePagesPasswordProtectionAccess = createSelector([getUserScopes], scopes => scopes.includes('sitepages-password-protection'));
export const getUserHasLandingPagesPasswordProtectionAccess = createSelector([getUserScopes], scopes => scopes.includes('landingpages-password-protection'));
const getHasLandingPagesManagePrivateContentScope = createSelector([getUserScopes], scopes => scopes.includes('landingpages-private-content-manage'));
export const getHasLandingPagesPublishScope = createSelector([getUserScopes], scopes => scopes.includes('landingpages-publish'));
const getIsUngatedForLandingPagesPrivateContentPermissions = createSelector([getGates], gates => gates.includes(gate('Permissions:LandingpagesPrivateContent')));
export const getUserHasLandingPagesManagePrivateContentPermissions = createSelector([getHasLandingPagesManagePrivateContentScope, getHasLandingPagesPublishScope, getIsUngatedForLandingPagesPrivateContentPermissions], (hasManagePrivateContentScope, hasPublishScope, isUngatedForManagePrivateContentPermissions) => isUngatedForManagePrivateContentPermissions ? hasManagePrivateContentScope : hasPublishScope);
const getHasSitePagesManagePrivateContentScope = createSelector([getUserScopes], scopes => scopes.includes('pages-private-content-manage'));
export const getHasSitePagesPublishScope = createSelector([getUserScopes], scopes => scopes.includes('pages-publish'));
const getIsUngatedForSitePagesPrivateContentPermissions = createSelector([getGates], gates => gates.includes(gate('Permissions:PagesPrivateContent')));
export const getUserHasSitePagesManagePrivateContentPermissions = createSelector([getHasSitePagesManagePrivateContentScope, getHasSitePagesPublishScope, getIsUngatedForSitePagesPrivateContentPermissions], (hasManagePrivateContentScope, hasPublishScope, isUngatedForManagePrivateContentPermissions) => isUngatedForManagePrivateContentPermissions ? hasManagePrivateContentScope : hasPublishScope);
const getHasBlogManagePrivateContentScope = createSelector([getUserScopes], scopes => scopes.includes('blog-private-content-manage'));
export const getHasBlogPublishScope = createSelector([getUserScopes], scopes => scopes.includes('blog-publish'));
const getIsUngatedForBlogPrivateContentPermissions = createSelector([getGates], gates => gates.includes(gate('Permissions:BlogPrivateContent')));
export const getUserHasBlogManagePrivateContentPermissions = createSelector([getHasBlogManagePrivateContentScope, getHasBlogPublishScope, getIsUngatedForBlogPrivateContentPermissions], (hasManagePrivateContentScope, hasPublishScope, isUngatedForManagePrivateContentPermissions) => isUngatedForManagePrivateContentPermissions ? hasManagePrivateContentScope : hasPublishScope);

/**
 * Fetches blog/blog post usage limits for:
 *  - Free portals
 *  - Marketing Hub Starter (blog has HS branding)
 *  - CMS Hub Starter (blog does not have HS branding)
 */
export const getShouldFetchBlogUsageLimits = createSelector([getHasBlogAccess, getPortalHasBlogPagesNoBranding, getUserHasAdvancedLPAccess], (hasBlogAccess, portalHasBlogPagesNoBranding, userHasAdvancedLPAccess) => hasBlogAccess && (!portalHasBlogPagesNoBranding || !userHasAdvancedLPAccess));

/**
 * Fetches site page usage limits for:
 *  - Free portals
 *  - CMS Hub Starter
 *  - Marketing Hub, all tiers
 */
export const getShouldFetchSitePageLimit = createSelector([getHasWebsiteAccess, getIsCmsStarterPortal, getHasFreeSitePages], (hasWebsiteAccess, isCmsStarterPortal, hasFreeSitePages) => hasWebsiteAccess && (isCmsStarterPortal || hasFreeSitePages));

/**
 * Fetches landing page usage limits for:
 *  - Free portals
 *  - CMS Hub Starter
 */
export const getShouldFetchLandingPageLimit = createSelector([getHasLandingPagesAccess, getHasFreeLandingPages], (hasLandingPagesAccess, hasFreeLandingPages) => hasLandingPagesAccess && hasFreeLandingPages);
export const getIsUngatedForUseSelectedBusinessUnitFromGlobalNav = createSelector([getGates, getHasBusinessUnitsAccess], (gates, hasBusinessUnitsAccess) => gates.includes('navigation:businessUnitPicker') && hasBusinessUnitsAccess);
export const getShouldFetchFromSharedPageLimitApi = createSelector([getShouldFetchSitePageLimit, getShouldFetchLandingPageLimit], (shouldFetchSitePageLimit, shouldFetchLandingPageLimit) => shouldFetchSitePageLimit || shouldFetchLandingPageLimit);
export const getHasCrmAccess = createSelector([getUserScopes], scopes => scopes.includes('crm-access') || scopes.includes('contacts-read'));
export const getIsUngatedForAiLandingPagesMvp = createSelector([getGates], gates => gates.includes('AILandingPages:MVP'));
export const getHasAiLandingPagesAccessScope = createSelector([getUserScopes], scopes => scopes.includes('ai-landing-pages-access'));
export const getIsUngatedForContentAgentBeta = createSelector([getGates], gates => gates.includes(gate('ContentAgent-INBOUND2024')));
export const getHasAiLandingPagesAccess = createSelector([getIsUngatedForAiLandingPagesMvp, getHasAiLandingPagesAccessScope], (isUngatedForAiLandingPagesMvp, hasAiLandingPagesAccessScope) => isUngatedForAiLandingPagesMvp && hasAiLandingPagesAccessScope);
export const getIsUngatedForBreezeAIRebranding = createSelector([getGates], gates => gates.includes(gate('BreezeAI:Rebranding')));
export const getIsUngatedForAiSettings = createSelector([getGates], gates => gates.includes('AIComponents:AISettings'));
export const getSemrushDataAccess = createSelector([getUserScopes], scopes => scopes.includes('semrush-data-access'));
export const getHasAIBlogPostGeneratorBasicAccess = createSelector([getUserScopes], scopes => scopes.includes('ai-blog-post-generator-basic-access'));
export const getHasAIBlogPostGeneratorCustomFeaturesAccess = createSelector([getUserScopes], scopes => scopes.includes('ai-blog-post-generator-custom-features'));
export const getIsUngatedForAIBPGRepackageRollout = createSelector([getGates], gates => gates.includes(gate('LFC:AIBPG:Repackage')));

// June 13, 2024 - time when click to edit flow was ungated to all
const clickToEditFlowUngatedToAllTime = 1718236800000;
export const getWasPortalCreatedBeforeClickToEditRollOut = createSelector([getPortalData], ({
  createdAt
}) => createdAt && createdAt < clickToEditFlowUngatedToAllTime);