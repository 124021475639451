'use es6';

import { List, OrderedMap, Set as ImmutableSet } from 'immutable';
import { createSelector } from 'reselect';
import I18n from 'I18n';
import { createSelectOptions } from '../../shared/utils/selectorHelpers';
import { getModalLangKey } from '../../shared/utils/modals';
import { TAGS } from '../../shared/constants/ManagementDataTypes';
const getTagsState = state => state.blog.tags;
export const getTagsData = createSelector([getTagsState], tags => tags.data);
export const getTagsStale = createSelector([getTagsState], tags => tags.stale);
export const getTagsStatus = createSelector([getTagsState], tags => tags.status);
export const getTagsTotal = createSelector([getTagsState], tags => tags.total);
const getTagsOptionsState = state => state.blog.tagsOptions;
export const getTagsOptionsData = createSelector([getTagsOptionsState], tags => tags.data);
export const getTagsOptionsStatus = createSelector([getTagsOptionsState], tags => tags.status);
export const getTagsOptionsTotal = (excludeTagIds = new ImmutableSet()) => createSelector([getTagsOptionsState], tags => tags.total - excludeTagIds.size);
export const getTagsAsSelectOptions = (includeAllOption, excludeTagIds = new ImmutableSet()) => createSelector([getTagsOptionsData], tags => {
  const tagsWithoutExcluded = tags.filterNot(tag => excludeTagIds.has(tag.id));
  const args = [tagsWithoutExcluded, 'id', 'name'];
  if (includeAllOption) {
    args.push('content-management-ui.header.filters.allTags');
  }
  return createSelectOptions(...args);
});
export const _getTranslatedTags = tags => tags.reduce((translatedTagsMap, tag) => tag.get('translations').reduce((acc, translation) => acc.set(translation.id, translation), translatedTagsMap), OrderedMap());
export const getTranslatedTags = createSelector(getTagsData, _getTranslatedTags);
const getCombineTagsOptionsState = state => state.blog.combineTagsOptions;
const getCombineTagsOptionsData = createSelector([getCombineTagsOptionsState], tags => tags.data);
export const getCombineTagsOptionsStatus = createSelector([getCombineTagsOptionsState], tags => tags.status);
export const getCombineTagsOptionsTotal = () => createSelector([getCombineTagsOptionsState], tags => tags.total);
export const getCombineTagsAsSelectOptions = (__, modalRelevantTags) => createSelector([getCombineTagsOptionsData], tags => {
  const isAnyGroupTagSelected = modalRelevantTags.some(tag => tag.hasTranslations());
  return tags.reduce((acc, tag) => {
    const {
      name,
      id: value,
      language
    } = tag;
    if (value) {
      return acc.push({
        value,
        text: `${name}${isAnyGroupTagSelected && tag.hasTranslations() && !tag.isTranslation() ? ` (${I18n.text(getModalLangKey('combine.mlangGroup', TAGS))})` : language ? ` (${I18n.text(`SharedI18nStrings.languageNames.${language}`)})` : ''}`,
        nameWithoutLanguage: name
      });
    }
    return acc;
  }, new List()).toArray();
});
const getAssignableTagOptions = state => state.blog.assignableTagOptions;
export const getAssignableTagOptionsData = createSelector([getAssignableTagOptions], assignableTagOptions => {
  return assignableTagOptions.get('assignableOptions');
});
export const getAssignableTagOptionsFetchStatuses = createSelector([getAssignableTagOptions], assignableTagOptions => assignableTagOptions.get('assignableOptionsFetchStatuses'));
export const getInitialAssignableTagOptionsFetchStatus = createSelector([getAssignableTagOptions], assignableTagOptions => assignableTagOptions.get('initialAssignableOptionsFetchStatus'));
export const getAssignableTagOptionsTotals = createSelector([getAssignableTagOptions], assignableTagOptions => assignableTagOptions.get('assignableOptionsTotals'));
const getSelectAssignableOptionsForTag = optionsForEntity => optionsForEntity.reduce((selectOptions, option) => {
  const languageText = option.language ? I18n.text(`SharedI18nStrings.languageNames.${option.language}`) : null;
  selectOptions.push({
    text: languageText ? `${option.name} (${languageText})` : option.name,
    value: option.id
  });
  return selectOptions;
}, []);
export const getAssignableTagOptionsForSelects = createSelector([getAssignableTagOptionsData], assignableTagOptionsData => {
  return assignableTagOptionsData.map(optionsForTag => getSelectAssignableOptionsForTag(optionsForTag));
});