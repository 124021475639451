'use es6';

import { fromJS, OrderedMap } from 'immutable';
import DSFieldRecord from 'customer-data-filters/filterQueryFormat/DSFieldRecord/DSFieldRecord';
import { ContentNameKeysToCrmPropertyNames } from '../constants/contentProperties';
export const isFilterAvailableForScopes = (property, user, contentType) => {
  switch (property) {
    case ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS:
      return user.hasMembershipsAccess();
    case ContentNameKeysToCrmPropertyNames.TEST_STATUS:
      return user.canCreateAbTest() || user.canCreateMABTest();
    case ContentNameKeysToCrmPropertyNames.VARIATION:
      return user.canCreateAbTest() || user.canCreateMABTest() || user.hasMultiLanguageAccess(contentType);
    case ContentNameKeysToCrmPropertyNames.CAMPAIGN:
      return user.hasCampaignsAccess();
    case ContentNameKeysToCrmPropertyNames.LANGUAGE:
      return user.hasMultiLanguageAccess(contentType);
    case ContentNameKeysToCrmPropertyNames.TEAM:
      return user.hasTeamsAccess(contentType);
    case ContentNameKeysToCrmPropertyNames.APPROVAL_STATUS:
      return user.hasAccessToCmsApprovals(contentType);
    case ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT:
      return user.hasBusinessUnitsAccess();
    default:
      return true;
  }
};
export const isFilterAvailableForGates = (property, gates) => {
  switch (property) {
    case ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT:
      return gates.includes('business-units:enforce-access-scope');
    default:
      return true;
  }
};
export const isFilterTagAvailableForGates = (property, gates) => {
  switch (property) {
    case ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT:
      return !gates.includes('navigation:businessUnitPicker');
    default:
      return true;
  }
};
export const getFilterPropertiesForScopes = (propertiesList, propertiesData, user, contentType) => propertiesList.reduce((acc, propertyName) => {
  if (isFilterAvailableForScopes(propertyName, user, contentType)) {
    return acc.set(propertyName, DSFieldRecord(fromJS(propertiesData.get(propertyName))));
  }
  return acc;
}, OrderedMap());