import { Set as ImmutableSet } from 'immutable';
export const PageStates = {
  PUBLISHED: 'PUBLISHED',
  PUBLISHED_OR_SCHEDULED: 'PUBLISHED_OR_SCHEDULED',
  DRAFT: 'DRAFT',
  SCHEDULED: 'SCHEDULED',
  PUBLISHED_AB: 'PUBLISHED_AB',
  PUBLISHED_AB_VARIANT: 'PUBLISHED_AB_VARIANT',
  LOSER_AB_VARIANT: 'LOSER_AB_VARIANT',
  DRAFT_AB: 'DRAFT_AB',
  DRAFT_AB_VARIANT: 'DRAFT_AB_VARIANT',
  SCHEDULED_AB: 'SCHEDULED_AB'
};
export const FilterablePageStates = {
  ALL: 'all',
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published',
  ARCHIVED: 'archived'
};
export const PUBLISHED_STATES = ImmutableSet([PageStates.PUBLISHED, PageStates.PUBLISHED_OR_SCHEDULED, PageStates.PUBLISHED_AB, PageStates.PUBLISHED_AB_VARIANT]);
export const DRAFT_STATES = ImmutableSet([PageStates.DRAFT, PageStates.DRAFT_AB, PageStates.DRAFT_AB_VARIANT, PageStates.LOSER_AB_VARIANT]);
export const SCHEDULED_STATES = ImmutableSet([PageStates.SCHEDULED, PageStates.SCHEDULED_AB, PageStates.PUBLISHED_OR_SCHEDULED]);
export const VARIANT_STATES = ImmutableSet([PageStates.PUBLISHED_AB_VARIANT, PageStates.DRAFT_AB_VARIANT, PageStates.LOSER_AB_VARIANT]);
export const FilterableStatesToPublishStates = {
  [FilterablePageStates.DRAFT]: DRAFT_STATES,
  [FilterablePageStates.SCHEDULED]: ImmutableSet([PageStates.SCHEDULED, PageStates.SCHEDULED_AB, PageStates.PUBLISHED_OR_SCHEDULED, PageStates.PUBLISHED_AB]),
  [FilterablePageStates.PUBLISHED]: PUBLISHED_STATES
};