'use es6';

import { createAction } from 'redux-actions';
import keyMirror from 'react-utils/keyMirror';
export const TagActionTypes = keyMirror({
  RECEIVED_TAGS: null,
  RECEIVED_TAGS_OPTIONS: null,
  RECEIVED_COMBINE_TAGS_OPTIONS: null,
  RESET_TAGS: null,
  REQUESTED_TAGS: null,
  REQUESTED_TAGS_OPTIONS: null,
  REQUESTED_COMBINE_TAGS_OPTIONS: null,
  REQUESTED_TAGS_FAILED: null,
  REQUESTED_TAGS_OPTIONS_FAILED: null,
  REQUESTED_COMBINE_TAGS_OPTIONS_FAILED: null,
  TAGS_FILTERS_CHANGED: null,
  TAGS_OPTIONS_FILTERS_CHANGED: null,
  COMBINE_TAGS_OPTIONS_FILTERS_CHANGED: null,
  TOGGLE_TAG_SELECT: null,
  TOGGLE_TAGS_SELECT_ALL: null,
  MARK_TAGS_STALE: null,
  TOGGLE_TAG_MLANG_GROUP_OPEN: null,
  // Tag actions
  REQUESTED_CREATE_TAG: null,
  REQUESTED_DELETE_TAGS: null,
  REQUESTED_RENAME_TAG: null,
  REQUESTED_COMBINE_TAGS: null,
  REQUESTED_REASSIGN_DELETE_TAGS: null,
  FETCH_ASSIGNABLE_TAG_OPTIONS: null,
  FETCH_INITIAL_ASSIGNABLE_TAG_OPTIONS: null,
  REFETCH_ASSIGNABLE_TAG_OPTIONS: null,
  REQUESTED_ASSIGNABLE_TAG_OPTIONS: null,
  REQUESTED_INITIAL_ASSIGNABLE_TAG_OPTIONS: null,
  RECEIVED_ASSIGNABLE_TAG_OPTIONS: null,
  RECEIVED_INITIAL_ASSIGNABLE_TAG_OPTIONS: null,
  REQUESTED_ASSIGNABLE_TAG_OPTIONS_FAILED: null,
  REQUESTED_INITIAL_ASSIGNABLE_TAG_OPTIONS_FAILED: null,
  CLEAR_ASSIGNABLE_TAG_OPTIONS: null,
  REQUESTED_CREATE_TAG_TRANSLATION: null,
  REQUESTED_SET_TAG_LANGUAGE: null,
  REQUESTED_CONVERT_TAG_TO_MASTER: null,
  REQUESTED_REMOVE_TAG_FROM_LANGUAGE_GROUP: null,
  REQUESTED_ADD_TAG_TO_LANGUAGE_GROUP: null,
  SWITCHED_LANGUAGE_MASTER_TAG: null,
  // filters
  UPDATE_BLOG_TAGS_FILTER_QUERY_STATE: null,
  TAG_MODAL_CONTAINER_UNMOUNTED: null,
  TAG_CONTAINER_UNMOUNTED: null
});
export const unmountTagContainer = createAction(TagActionTypes.TAG_CONTAINER_UNMOUNTED);
export const unmountTagModalsContainer = createAction(TagActionTypes.TAG_MODAL_CONTAINER_UNMOUNTED);
export const resetTags = createAction(TagActionTypes.RESET_TAGS);
export const requestTags = createAction(TagActionTypes.REQUESTED_TAGS);
export const receiveTags = createAction(TagActionTypes.RECEIVED_TAGS, ({
  tags,
  total
}) => ({
  tags,
  total
}));
export const receiveTagsFailure = createAction(TagActionTypes.REQUESTED_TAGS_FAILED, error => ({
  error
}));
export const tagsFiltersChanged = createAction(TagActionTypes.TAGS_FILTERS_CHANGED, ({
  query
}) => ({
  query
}));
export const toggleSelectTag = createAction(TagActionTypes.TOGGLE_TAG_SELECT, tagId => ({
  tagId
}));
export const toggleSelectAllTags = createAction(TagActionTypes.TOGGLE_TAGS_SELECT_ALL);
export const toggleTagNestedRowsOpen = createAction(TagActionTypes.TOGGLE_TAG_MLANG_GROUP_OPEN, tagId => ({
  tagId
}));
export const markTagsStale = createAction(TagActionTypes.MARK_TAGS_STALE);
export const tagsOptionsFiltersChanged = createAction(TagActionTypes.TAGS_OPTIONS_FILTERS_CHANGED, ({
  query
}) => ({
  query
}));
export const requestTagsOptions = createAction(TagActionTypes.REQUESTED_TAGS_OPTIONS);
export const receiveTagsOptions = createAction(TagActionTypes.RECEIVED_TAGS_OPTIONS, ({
  tags,
  total
}) => ({
  tags,
  total
}));
export const requestTagsOptionsFailure = createAction(TagActionTypes.REQUESTED_TAGS_OPTIONS_FAILED, error => ({
  error
}));
export const combineTagsOptionsFiltersChanged = createAction(TagActionTypes.COMBINE_TAGS_OPTIONS_FILTERS_CHANGED, ({
  query,
  id
}) => ({
  query,
  id
}));
export const requestCombineTagsOptions = createAction(TagActionTypes.REQUESTED_COMBINE_TAGS_OPTIONS);
export const receiveCombineTagsOptions = createAction(TagActionTypes.RECEIVED_COMBINE_TAGS_OPTIONS, ({
  tags,
  total
}) => ({
  tags,
  total
}));
export const requestCombineTagsOptionsFailure = createAction(TagActionTypes.REQUESTED_COMBINE_TAGS_OPTIONS_FAILED, error => ({
  error
}));

// Tag actions
export const createTag = createAction(TagActionTypes.REQUESTED_CREATE_TAG, (tagName, tagLanguage) => ({
  tagName,
  tagLanguage
}));
export const deleteTags = createAction(TagActionTypes.REQUESTED_DELETE_TAGS, tags => ({
  tags
}));
export const editTag = createAction(TagActionTypes.REQUESTED_RENAME_TAG, tag => ({
  tag
}));
export const reassignAndDeleteTags = createAction(TagActionTypes.REQUESTED_REASSIGN_DELETE_TAGS, (selectedAssignments, tagsToDelete) => ({
  selectedAssignments,
  tagsToDelete
}));
export const createTagTranslation = createAction(TagActionTypes.REQUESTED_CREATE_TAG_TRANSLATION, (tag, variantData) => ({
  tag,
  variantData
}));
export const setTagLanguage = createAction(TagActionTypes.REQUESTED_SET_TAG_LANGUAGE, (id, language, nextModal) => ({
  id,
  language,
  nextModal
}));
export const convertTagToMaster = createAction(TagActionTypes.REQUESTED_CONVERT_TAG_TO_MASTER, id => ({
  id
}));
export const removeTagFromLanguageGroup = createAction(TagActionTypes.REQUESTED_REMOVE_TAG_FROM_LANGUAGE_GROUP, ({
  id
}) => ({
  id
}));
export const addTagToLanguageGroup = createAction(TagActionTypes.REQUESTED_ADD_TAG_TO_LANGUAGE_GROUP, (id, language, translatedFromId, masterLanguage) => ({
  id,
  language,
  translatedFromId,
  masterLanguage
}));
export const switchedLanguageMasterTag = createAction(TagActionTypes.SWITCHED_LANGUAGE_MASTER_TAG, ({
  id
}) => ({
  id
}));
const _fetchAssignableTagOptions = createAction(TagActionTypes.FETCH_ASSIGNABLE_TAG_OPTIONS, (id, query) => ({
  id,
  query
}));
const refetchAssignableTagOptions = createAction(TagActionTypes.REFETCH_ASSIGNABLE_TAG_OPTIONS, (id, query) => ({
  id,
  query
}));
export const fetchAssignableTagOptions = (id, query) => query.search ? refetchAssignableTagOptions(id, query) : _fetchAssignableTagOptions(id, query);
export const requestAssignableTagOptions = createAction(TagActionTypes.REQUESTED_ASSIGNABLE_TAG_OPTIONS, id => ({
  id
}));
export const receiveAssignableTagOptions = createAction(TagActionTypes.RECEIVED_ASSIGNABLE_TAG_OPTIONS, ({
  id,
  tags,
  total
}) => ({
  id,
  tags,
  total
}));
export const receiveAssignableTagOptionsFailure = createAction(TagActionTypes.REQUESTED_ASSIGNABLE_TAG_OPTIONS_FAILED, (id, error) => ({
  id,
  error
}));
export const fetchInitialAssignableTagOptions = createAction(TagActionTypes.FETCH_INITIAL_ASSIGNABLE_TAG_OPTIONS, (ids, query) => ({
  ids,
  query
}));
export const requestInitialAssignableTagOptions = createAction(TagActionTypes.REQUESTED_INITIAL_ASSIGNABLE_TAG_OPTIONS, ids => ({
  ids
}));
export const receiveInitialAssignableTagOptions = createAction(TagActionTypes.RECEIVED_INITIAL_ASSIGNABLE_TAG_OPTIONS, responses => ({
  responses
}));
export const receiveInitialAssignableTagOptionsFailure = createAction(TagActionTypes.REQUESTED_INITIAL_ASSIGNABLE_TAG_OPTIONS_FAILED, (ids, error) => ({
  ids,
  error
}));
export const clearAssignableTagOptions = createAction(TagActionTypes.CLEAR_ASSIGNABLE_TAG_OPTIONS);
const _updateBlogTagsFilterQueryState = createAction(TagActionTypes.UPDATE_BLOG_TAGS_FILTER_QUERY_STATE, filterOptions => Object.assign({}, filterOptions));
export const updateBlogTagsFilterQueryState = filterOptions => dispatch => {
  dispatch(_updateBlogTagsFilterQueryState(filterOptions));
  dispatch(markTagsStale());
};