'use es6';

import memoize from 'react-utils/memoize';
import { locationContainsPath } from './urls';
import { BLOG_POSTS, COMMENTS, TAGS, AUTHORS, FOLDERS, LANDING_PAGES, SITE_PAGES, BLOG_LISTING_PAGE, PageManagementDataTypes, BlogManagementDataTypes } from '../constants/ManagementDataTypes';
import * as UrlPathParams from '../constants/UrlPathParams';
import { PAGES_MANAGEMENT_DATA_GROUP, BLOG_MANAGEMENT_DATA_GROUP } from '../constants/ManagementDataGroups';
const _getManagementDataType = memoize(location => {
  if (locationContainsPath(location, UrlPathParams.BLOG_POSTS)) {
    return BLOG_POSTS;
  } else if (locationContainsPath(location, UrlPathParams.BLOG_LISTING_PAGE)) {
    return BLOG_LISTING_PAGE;
  } else if (locationContainsPath(location, UrlPathParams.BLOG_COMMENTS)) {
    return COMMENTS;
  } else if (locationContainsPath(location, UrlPathParams.BLOG_TAGS)) {
    return TAGS;
  } else if (locationContainsPath(location, UrlPathParams.BLOG_AUTHORS)) {
    return AUTHORS;
  } else if (locationContainsPath(location, UrlPathParams.FOLDERS)) {
    return FOLDERS;
  } else if (locationContainsPath(location, UrlPathParams.LANDING_PAGES)) {
    return LANDING_PAGES;
  } else if (locationContainsPath(location, UrlPathParams.SITE_PAGES)) {
    return SITE_PAGES;
  }
  return null;
});
export const getManagementDataType = () => _getManagementDataType(window.location);
export const getManagementDataGroup = managementDataType => {
  if (PageManagementDataTypes.includes(managementDataType)) return PAGES_MANAGEMENT_DATA_GROUP;
  if (BlogManagementDataTypes.includes(managementDataType)) return BLOG_MANAGEMENT_DATA_GROUP;
  return undefined;
};