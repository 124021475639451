import memoize from 'react-utils/memoize';
// @ts-expect-error Untyped module
import { pathnameEndsWith } from './urls';
// @ts-expect-error Untyped module
import * as UrlPathParams from '../constants/UrlPathParams';
import AppDisplayModes from '../constants/AppDisplayModes';
const _getAppDisplayMode = memoize(pathname => {
  if (pathnameEndsWith(pathname, UrlPathParams.SITE_TREE)) {
    return AppDisplayModes.SITE_TREE;
  }
  return AppDisplayModes.TABLE;
});
export const getAppDisplayMode = () => _getAppDisplayMode(window.location.pathname);