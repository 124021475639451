import { Set as ImmutableSet } from 'immutable';
const BlogPostStates = {
  AUTOMATED_DRAFT: 'AUTOMATED_DRAFT',
  AUTOMATED_FOR_FORM_BUFFER: 'AUTOMATED_FOR_FORM_BUFFER',
  AUTOMATED_FOR_FORM_DRAFT: 'AUTOMATED_FOR_FORM_DRAFT',
  BLOG_EMAIL_DRAFT: 'BLOG_EMAIL_DRAFT',
  DISCARDABLE_STUB: 'DISCARDABLE_STUB',
  DRAFT: 'DRAFT',
  DRAFT_AB: 'DRAFT_AB',
  DRAFT_AB_VARIANT: 'DRAFT_AB_VARIANT',
  ERROR: 'ERROR',
  LOSER_AB_VARIANT: 'LOSER_AB_VARIANT',
  PAGE_STUB: 'PAGE_STUB',
  RSS_TO_EMAIL_DRAFT: 'RSS_TO_EMAIL_DRAFT',
  AUTOMATED: 'AUTOMATED',
  AUTOMATED_FOR_FORM: 'AUTOMATED_FOR_FORM',
  AUTOMATED_FOR_FORM_LEGACY: 'AUTOMATED_FOR_FORM_LEGACY',
  AUTOMATED_SENDING: 'AUTOMATED_SENDING',
  BLOG_EMAIL_PUBLISHED: 'BLOG_EMAIL_PUBLISHED',
  PRE_PROCESSING: 'PRE_PROCESSING',
  PROCESSING: 'PROCESSING',
  PUBLISHED: 'PUBLISHED',
  PUBLISHED_AB: 'PUBLISHED_AB',
  PUBLISHED_AB_VARIANT: 'PUBLISHED_AB_VARIANT',
  PUBLISHED_NO_CONTACTS: 'PUBLISHED_NO_CONTACTS',
  PUBLISHED_OR_SCHEDULED: 'PUBLISHED_OR_SCHEDULED',
  RSS_TO_EMAIL_PUBLISHED: 'RSS_TO_EMAIL_PUBLISHED',
  SCHEDULED: 'SCHEDULED',
  SCHEDULED_AB: 'SCHEDULED_AB'
};
const FilterableBlogPostStates = {
  ALL: 'all',
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published',
  ARCHIVED: 'archived'
};
export const PUBLISHED_STATES = ImmutableSet([BlogPostStates.AUTOMATED, BlogPostStates.AUTOMATED_FOR_FORM, BlogPostStates.AUTOMATED_FOR_FORM_LEGACY, BlogPostStates.AUTOMATED_SENDING, BlogPostStates.BLOG_EMAIL_PUBLISHED, BlogPostStates.PRE_PROCESSING, BlogPostStates.PROCESSING, BlogPostStates.PUBLISHED, BlogPostStates.PUBLISHED_AB, BlogPostStates.PUBLISHED_AB_VARIANT, BlogPostStates.PUBLISHED_NO_CONTACTS, BlogPostStates.PUBLISHED_OR_SCHEDULED, BlogPostStates.RSS_TO_EMAIL_PUBLISHED]);
export const DRAFT_STATES = ImmutableSet([BlogPostStates.AUTOMATED_DRAFT, BlogPostStates.AUTOMATED_FOR_FORM_BUFFER, BlogPostStates.AUTOMATED_FOR_FORM_DRAFT, BlogPostStates.BLOG_EMAIL_DRAFT, BlogPostStates.DISCARDABLE_STUB, BlogPostStates.DRAFT, BlogPostStates.DRAFT_AB, BlogPostStates.DRAFT_AB_VARIANT, BlogPostStates.ERROR, BlogPostStates.LOSER_AB_VARIANT, BlogPostStates.PAGE_STUB, BlogPostStates.RSS_TO_EMAIL_DRAFT]);
export const SCHEDULED_STATES = ImmutableSet([BlogPostStates.SCHEDULED, BlogPostStates.SCHEDULED_AB]);
export const FilterableStatesToPublishStates = {
  [FilterableBlogPostStates.DRAFT]: BlogPostStates.DRAFT,
  [FilterableBlogPostStates.SCHEDULED]: BlogPostStates.SCHEDULED,
  [FilterableBlogPostStates.PUBLISHED]: BlogPostStates.PUBLISHED
};