'use es6';

import { List, Set as ImmutableSet } from 'immutable';
import SiteTreeActionTypes from '../actions/SiteTreeActionTypes';
import RequestStatus from '../constants/RequestStatus';
import SiteTree from '../models/SiteTree';
import SiteTreeNode from '../models/SiteTreeNode';
import { removeNode, replaceNode } from '../utils/siteTreeReplaceRemoveNodeUtils';
import { openInitialTrees, mergeNodesIntoSiteTree } from '../utils/siteTreeUtils';
import { _getFlattenedSiteTreeContent } from '../selectors/siteTreeSelectors';
import { toggleSelectEntityAndExpandMultiLangGroup } from '../utils/displaySelectedHelpers';
import { convertFilterQueryToMap } from '../utils/filters';
export default ((state = new SiteTree(), action) => {
  switch (action.type) {
    case SiteTreeActionTypes.RECEIVED_INITIAL_SITE_TREE:
      return state.merge({
        treeData: SiteTreeNode.from(action.payload.siteTree),
        loadStatus: RequestStatus.SUCCEEDED
      }).mergeIn(['display'], {
        openTrees: openInitialTrees(action.payload.siteTree),
        selectedContent: new ImmutableSet()
      });
    case SiteTreeActionTypes.RECEIVED_LOAD_MORE_SITE_TREE_NODES:
      return state.merge({
        treeData: mergeNodesIntoSiteTree(state.get('treeData'), SiteTreeNode.from(action.payload.siteTree)),
        treesLoadingMore: state.get('treesLoadingMore').remove(action.payload.slug)
      });
    case SiteTreeActionTypes.REQUEST_LOAD_MORE_SITE_TREE_NODES:
      return state.set('treesLoadingMore', state.get('treesLoadingMore').add(action.payload.slug));
    case SiteTreeActionTypes.RECEIVED_LOAD_MORE_SITE_TREE_NODES_FAILURE:
      return state.set('treesLoadingMore', state.get('treesLoadingMore').remove(action.payload.slug));
    case SiteTreeActionTypes.REQUEST_INITIAL_SITE_TREE:
      return state.set('loadStatus', RequestStatus.PENDING);
    case SiteTreeActionTypes.RECEIVED_INITIAL_SITE_TREE_FAILURE:
      return state.set('loadStatus', RequestStatus.FAILED);
    case SiteTreeActionTypes.SET_NODES_TO_BE_REMOVED:
      return state.set('nodesToBeRemoved', action.payload.nodesToBeRemoved);
    case SiteTreeActionTypes.SET_NODES_TO_BE_MERGED:
      return state.set('nodesToBeMerged', action.payload.nodesToBeMerged);
    case SiteTreeActionTypes.MARK_STALE:
      {
        const nodesToBeMerged = state.get('nodesToBeMerged');
        const nodesToBeRemoved = state.get('nodesToBeRemoved');
        let updatedState = state;
        nodesToBeRemoved.forEach(page => {
          updatedState = updatedState.set('treeData', removeNode(updatedState.get('treeData'), page));
        });
        nodesToBeMerged.forEach(page => {
          updatedState = updatedState.set('treeData', replaceNode(updatedState.get('treeData'), page));
        });
        return updatedState.merge({
          nodesToBeMerged: new List(),
          nodesToBeRemoved: new List()
        }).setIn(['display', 'selectedContent'], new ImmutableSet());
      }

    // Site tree display logic
    case SiteTreeActionTypes.SET_ZOOM_LEVEL:
      return state.setIn(['display', 'zoomLevel'], action.payload.zoomLevel);
    case SiteTreeActionTypes.TOGGLE_TREE_OPEN:
      {
        const {
          payload: {
            nodeSlug
          }
        } = action;
        const openTrees = state.getIn(['display', 'openTrees']);
        return state.setIn(['display', 'openTrees'], openTrees.has(nodeSlug) ? openTrees.remove(nodeSlug) : openTrees.add(nodeSlug));
      }
    case SiteTreeActionTypes.TOGGLE_SELECTED_CONTENT:
      {
        const {
          payload: {
            contentId
          }
        } = action;
        const {
          primarySiteTreeContent,
          translatedSiteTreeContent
        } = _getFlattenedSiteTreeContent(state.get('treeData'));
        const {
          selectedEntities
        } = toggleSelectEntityAndExpandMultiLangGroup({
          id: contentId,
          primaryEntities: primarySiteTreeContent,
          translationEntities: translatedSiteTreeContent,
          currentlySelectedEntities: state.getIn(['display', 'selectedContent'])
        });
        return state.setIn(['display', 'selectedContent'], selectedEntities);
      }
    case SiteTreeActionTypes.TOGGLE_SHOULD_SHOW_ALL_URLS:
      return state.setIn(['display', 'shouldShowFullUrls'], !state.shouldShowFullUrls);
    case SiteTreeActionTypes.TOGGLE_SHOULD_SHOW_STATUSES:
      return state.setIn(['display', 'shouldShowStatuses'], !state.shouldShowStatuses);
    case SiteTreeActionTypes.UPDATE_SITE_TREE_SEARCH:
      return state.setIn(['display', 'search'], action.payload.search);
    case SiteTreeActionTypes.TOGGLE_SHOULD_SHOW_TRANSLATIONS:
      return state.setIn(['display', 'shouldShowTranslations'], !state.getIn(['display', 'shouldShowTranslations']));
    case SiteTreeActionTypes.UPDATE_SITE_TREE_FILTER_QUERY:
      return state.setIn(['display', 'filterQuery'], convertFilterQueryToMap(action.payload.query));
    case SiteTreeActionTypes.UPDATE_DEFAULT_RESOLVED_LANGUAGE:
      return state.setIn(['display', 'defaultResolvedLanguage'], action.payload.language);
    default:
      return state;
  }
});