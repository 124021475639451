'use es6';

import { OrderedMap, Record } from 'immutable';
import RequestStatus from '../../shared/constants/RequestStatus';
import TagsDisplay from './TagsDisplay';
const defaults = {
  data: new OrderedMap(),
  stale: false,
  status: RequestStatus.UNINITIALIZED,
  total: 0,
  display: new TagsDisplay()
};
export default class Tags extends Record(defaults) {}