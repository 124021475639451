'use es6';

import { Map as ImmutableMap } from 'immutable';
import { TagActionTypes } from '../actions/TagActions';
import RequestStatus from '../../shared/constants/RequestStatus';
const AssignableTagOptions = new ImmutableMap({
  assignableOptions: new ImmutableMap(),
  // Map of OrderedMap of Tag id -> Tag
  assignableOptionsFetchStatuses: new ImmutableMap(),
  // Map of Strings
  assignableOptionsTotals: new ImmutableMap(),
  // Map of Numbers
  initialAssignableOptionsFetchStatus: RequestStatus.UNINITIALIZED
});
const setAssignableOptionsForTagInState = (state, tagId, tags, total) => {
  const currentAssignableOptions = state.getIn(['assignableOptions', tagId]);
  return state.setIn(['assignableOptions', tagId], currentAssignableOptions ? currentAssignableOptions.merge(tags) : tags).setIn(['assignableOptionsFetchStatuses', tagId], RequestStatus.SUCCEEDED).setIn(['assignableOptionsTotals', tagId], total);
};

// TODO: refactor to share reducer logic with AssignableTagOptions
export default ((state = AssignableTagOptions, {
  payload,
  type
}) => {
  switch (type) {
    case TagActionTypes.REQUESTED_ASSIGNABLE_TAG_OPTIONS:
      return state.setIn(['assignableOptionsFetchStatuses', payload.id], RequestStatus.PENDING);
    case TagActionTypes.REQUESTED_INITIAL_ASSIGNABLE_TAG_OPTIONS:
      return state.set('initialAssignableOptionsFetchStatus', RequestStatus.PENDING);
    case TagActionTypes.RECEIVED_ASSIGNABLE_TAG_OPTIONS:
      return setAssignableOptionsForTagInState(state, payload.id, payload.tags, payload.total);
    case TagActionTypes.RECEIVED_INITIAL_ASSIGNABLE_TAG_OPTIONS:
      return payload.responses.reduce((updatedState, {
        id,
        tags,
        total
      }) => setAssignableOptionsForTagInState(updatedState, id, tags, total), state.set('initialAssignableOptionsFetchStatus', RequestStatus.SUCCEEDED));
    case TagActionTypes.REQUESTED_ASSIGNABLE_TAG_OPTIONS_FAILED:
      return state.setIn(['assignableOptionsFetchStatuses', payload.id], RequestStatus.FAILED);
    case TagActionTypes.REQUESTED_INITIAL_ASSIGNABLE_TAG_OPTIONS_FAILED:
      return state.set('initialAssignableOptionsFetchStatus', RequestStatus.FAILED);
    case TagActionTypes.CLEAR_ASSIGNABLE_TAG_OPTIONS:
      return AssignableTagOptions;
    default:
      return state;
  }
});