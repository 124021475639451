'use es6';

import ModalDataTypes from 'ContentComponents/constants/ModalDataTypes';
import { LANDING_PAGES, SITE_PAGES, FOLDERS } from '../constants/ManagementDataTypes';
export const getModalLangKey = (postFixKey, managementDataType) => {
  let prefix;
  if (!managementDataType) {
    prefix = 'shared';
  } else {
    if (managementDataType === LANDING_PAGES || managementDataType === SITE_PAGES || managementDataType === ModalDataTypes.PAGES) {
      prefix = 'pages';
    } else if (managementDataType === FOLDERS) {
      prefix = 'folders';
    } else {
      prefix = `blog.${managementDataType}`;
    }
  }
  return `content-management-ui.${prefix}.modals.${postFixKey}`;
};