'use es6';

import { OrderedMap, Record } from 'immutable';
import RequestStatus from '../../shared/constants/RequestStatus';
const defaults = {
  data: new OrderedMap(),
  status: RequestStatus.UNINITIALIZED,
  total: null,
  dataAsSelectOptions: []
};
export default class Domains extends Record(defaults) {}