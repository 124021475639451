'use es6';

import { List } from 'immutable';
import { createSelector } from 'reselect';
import RequestStatus from '../../shared/constants/RequestStatus';
const getFoldersState = state => state.pages.folders;
export const getFoldersData = createSelector([getFoldersState], folders => folders.rootFolder);
export const getFoldersStale = createSelector([getFoldersState], folders => folders.stale);
export const getFoldersStatus = createSelector([getFoldersState], folders => folders.status);
const findInNestedFolders = (currentFolder, folderId, breadcrumbTrail) => {
  const childFolders = currentFolder.get('childFolders').valueSeq();
  if (currentFolder.get('id') === folderId) {
    return currentFolder;
  }
  let foundFolder = null;
  childFolders.forEach(folder => {
    breadcrumbTrail.push(folder);
    foundFolder = findInNestedFolders(folder, folderId, breadcrumbTrail);
    if (!foundFolder) {
      breadcrumbTrail.pop();
      return true;
    }
    return false;
  });
  return foundFolder;
};
const _getFilteredChildFolders = (shouldSearchDeeply, currentFolder, search = '') => {
  if (!currentFolder) {
    return new List();
  }
  const lowercasedSearch = search;
  const currentChildFolders = currentFolder.get('childFolders');
  if (!search) {
    return currentChildFolders.toList();
  } else {
    return currentChildFolders.reduce((acc, folder) => {
      let updatedAcc;
      if (folder.get('name').toLowerCase().includes(lowercasedSearch)) {
        updatedAcc = acc.push(folder);
      } else {
        updatedAcc = acc;
      }
      if (shouldSearchDeeply) {
        return updatedAcc.concat(_getFilteredChildFolders(true, folder, search));
      }
      return updatedAcc;
    }, new List());
  }
};
export const getFilteredChildFolders = (currentFolder, folderId, search) => {
  const shouldSearchDeeply = folderId === 0;
  return _getFilteredChildFolders(shouldSearchDeeply, currentFolder, search).sortBy(folder => folder.get('name'));
};
export const getFolderDataById = (folderId = 0, search) => {
  return createSelector([getFoldersData, getFoldersStatus], (rootFolder, status) => {
    const isRootFolder = folderId === 0;
    if (isRootFolder || status !== RequestStatus.SUCCEEDED) {
      return {
        filteredChildFolders: getFilteredChildFolders(rootFolder, folderId, search),
        folder: rootFolder
      };
    }
    const breadcrumbTrail = [rootFolder];
    const folder = findInNestedFolders(rootFolder, parseInt(folderId, 10), breadcrumbTrail);
    return {
      breadcrumbTrail,
      filteredChildFolders: getFilteredChildFolders(folder, folderId, search),
      folder
    };
  });
};