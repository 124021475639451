'use es6';

import { createAction } from 'redux-actions';
import keyMirror from 'react-utils/keyMirror';
export const AuthorActionTypes = keyMirror({
  RESET_AUTHORS: null,
  REQUESTED_AUTHORS: null,
  RECEIVED_AUTHORS: null,
  REQUESTED_AUTHORS_FAILED: null,
  REQUESTED_AUTHORS_OPTIONS: null,
  RECEIVED_AUTHORS_OPTIONS: null,
  RECEIVED_AUTHORS_OPTIONS_FAILED: null,
  REQUESTED_AUTHORS_WITH_EMAIL_OPTIONS: null,
  RECEIVED_AUTHORS_WITH_EMAIL_OPTIONS: null,
  RECEIVED_AUTHORS_WITH_EMAIL_OPTIONS_FAILED: null,
  REQUESTED_COMBINE_AUTHORS_OPTIONS: null,
  RECEIVED_COMBINE_AUTHORS_OPTIONS: null,
  REQUESTED_COMBINE_AUTHORS_OPTIONS_FAILED: null,
  AUTHORS_FILTERS_CHANGED: null,
  AUTHORS_OPTIONS_FILTERS_CHANGED: null,
  COMBINE_AUTHORS_OPTIONS_FILTERS_CHANGED: null,
  CLEAR_AUTHORS_OPTIONS: null,
  TOGGLE_AUTHOR_SELECT: null,
  TOGGLE_AUTHORS_SELECT_ALL: null,
  TOGGLE_AUTHOR_MLANG_GROUP_OPEN: null,
  MARK_AUTHORS_STALE: null,
  // Author actions
  REQUESTED_CREATE_AUTHOR: null,
  REQUESTED_CREATE_AUTHOR_FAILED: null,
  REQUESTED_UPDATE_AUTHOR: null,
  REQUESTED_UPDATE_AUTHOR_FAILED: null,
  REQUESTED_DELETE_AUTHOR: null,
  REQUESTED_COMBINE_AUTHORS: null,
  FETCH_ASSIGNABLE_AUTHOR_OPTIONS: null,
  FETCH_INITIAL_ASSIGNABLE_AUTHOR_OPTIONS: null,
  REFETCH_ASSIGNABLE_AUTHOR_OPTIONS: null,
  REQUESTED_ASSIGNABLE_AUTHOR_OPTIONS: null,
  REQUESTED_INITIAL_ASSIGNABLE_AUTHOR_OPTIONS: null,
  RECEIVED_ASSIGNABLE_AUTHOR_OPTIONS: null,
  RECEIVED_INITIAL_ASSIGNABLE_AUTHOR_OPTIONS: null,
  REQUESTED_ASSIGNABLE_AUTHOR_OPTIONS_FAILED: null,
  REQUESTED_INITIAL_ASSIGNABLE_AUTHOR_OPTIONS_FAILED: null,
  CLEAR_ASSIGNABLE_AUTHOR_OPTIONS: null,
  REQUESTED_CREATE_AUTHOR_TRANSLATION: null,
  REQUESTED_CONVERT_AUTHOR_TO_MASTER: null,
  REQUESTED_SET_AUTHOR_LANGUAGE: null,
  REQUESTED_ADD_AUTHOR_TO_LANGUAGE_GROUP: null,
  REQUESTED_REMOVE_AUTHOR_FROM_LANGUAGE_GROUP: null,
  SWITCHED_LANGUAGE_MASTER_AUTHOR: null,
  // filters
  UPDATE_BLOG_AUTHORS_FILTER_QUERY_STATE: null,
  AUTHOR_MODAL_CONTAINER_UNMOUNTED: null,
  AUTHOR_CONTAINER_UNMOUNTED: null,
  AUTHOR_SELECT_UNMOUNTED: null
});
export const unmountAuthorContainer = createAction(AuthorActionTypes.AUTHOR_CONTAINER_UNMOUNTED);
export const unmountAuthorModalsContainer = createAction(AuthorActionTypes.AUTHOR_MODAL_CONTAINER_UNMOUNTED);
export const unmountAuthorSelect = createAction(AuthorActionTypes.AUTHOR_SELECT_UNMOUNTED);
export const resetAuthors = createAction(AuthorActionTypes.RESET_AUTHORS);
export const authorsFiltersChanged = createAction(AuthorActionTypes.AUTHORS_FILTERS_CHANGED, ({
  query
}) => ({
  query
}));
export const requestAuthors = createAction(AuthorActionTypes.REQUESTED_AUTHORS);
export const receiveAuthors = createAction(AuthorActionTypes.RECEIVED_AUTHORS, ({
  authors,
  total
}) => ({
  authors,
  total
}));
export const receiveAuthorsFailure = createAction(AuthorActionTypes.REQUESTED_AUTHORS_FAILED, error => ({
  error
}));
export const toggleSelectAuthor = createAction(AuthorActionTypes.TOGGLE_AUTHOR_SELECT, authorId => ({
  authorId
}));
export const toggleSelectAllAuthors = createAction(AuthorActionTypes.TOGGLE_AUTHORS_SELECT_ALL);
export const toggleAuthorNestedRowsOpen = createAction(AuthorActionTypes.TOGGLE_AUTHOR_MLANG_GROUP_OPEN, authorId => ({
  authorId
}));
export const authorsOptionsFiltersChanged = createAction(AuthorActionTypes.AUTHORS_OPTIONS_FILTERS_CHANGED, ({
  query
}) => ({
  query
}));
export const combineAuthorsOptionsFiltersChanged = createAction(AuthorActionTypes.COMBINE_AUTHORS_OPTIONS_FILTERS_CHANGED, ({
  query
}) => ({
  query
}));
const requestAllAuthorsOptions = createAction(AuthorActionTypes.REQUESTED_AUTHORS_OPTIONS);
const requestAuthorsWithEmailOptions = createAction(AuthorActionTypes.REQUESTED_AUTHORS_WITH_EMAIL_OPTIONS);
export const requestCombineAuthorsOptions = createAction(AuthorActionTypes.REQUESTED_COMBINE_AUTHORS_OPTIONS);
export const requestAuthorsOptions = requireEmail => {
  return requireEmail ? requestAuthorsWithEmailOptions : requestAllAuthorsOptions;
};
const receiveAllAuthorsOptions = createAction(AuthorActionTypes.RECEIVED_AUTHORS_OPTIONS, ({
  authors,
  total
}) => ({
  authors,
  total
}));
const receiveAuthorsWithEmailOptions = createAction(AuthorActionTypes.RECEIVED_AUTHORS_WITH_EMAIL_OPTIONS, ({
  authors,
  total
}) => ({
  authors,
  total
}));
export const receiveCombineAuthorsOptions = createAction(AuthorActionTypes.RECEIVED_COMBINE_AUTHORS_OPTIONS, ({
  authors,
  total
}) => ({
  authors,
  total
}));
export const receiveAuthorsOptions = requireEmail => {
  return requireEmail ? receiveAuthorsWithEmailOptions : receiveAllAuthorsOptions;
};
const receiveAllAuthorsOptionsFailure = createAction(AuthorActionTypes.RECEIVED_AUTHORS_OPTIONS_FAILED, error => ({
  error
}));
const receiveAuthorsWithEmailOptionsFailure = createAction(AuthorActionTypes.RECEIVED_AUTHORS_WITH_EMAIL_OPTIONS_FAILED, error => ({
  error
}));
export const receiveAuthorsOptionsFailure = requireEmail => {
  return requireEmail ? receiveAuthorsWithEmailOptionsFailure : receiveAllAuthorsOptionsFailure;
};
export const requestCombineAuthorsOptionsFailure = createAction(AuthorActionTypes.REQUESTED_COMBINE_AUTHORS_OPTIONS_FAILED, error => ({
  error
}));
export const clearAuthorsOptions = createAction(AuthorActionTypes.CLEAR_AUTHORS_OPTIONS);
export const markAuthorsStale = createAction(AuthorActionTypes.MARK_AUTHORS_STALE);

// Author actions
export const createAuthor = createAction(AuthorActionTypes.REQUESTED_CREATE_AUTHOR, author => ({
  author
}));
export const receiveCreateAuthorFailure = createAction(AuthorActionTypes.REQUESTED_CREATE_AUTHOR_FAILED, ({
  error
}) => ({
  error
}));
export const updateAuthor = createAction(AuthorActionTypes.REQUESTED_UPDATE_AUTHOR, (id, authorProperties) => ({
  id,
  authorProperties
}));
export const receiveUpdateAuthorFailure = createAction(AuthorActionTypes.REQUESTED_UPDATE_AUTHOR_FAILED, ({
  error
}) => ({
  error
}));
export const deleteAuthor = createAction(AuthorActionTypes.REQUESTED_DELETE_AUTHOR, (selectedAssignments, authorsToBeDeleted) => ({
  selectedAssignments,
  authorsToBeDeleted
}));
export const combineAuthors = createAction(AuthorActionTypes.REQUESTED_COMBINE_AUTHORS, (authors, groupedAuthorName) => ({
  groupedAuthorName,
  authors
}));
export const createAuthorTranslation = createAction(AuthorActionTypes.REQUESTED_CREATE_AUTHOR_TRANSLATION, (author, variantData) => ({
  primaryAuthor: author,
  variantData
}));
export const convertAuthorToMaster = createAction(AuthorActionTypes.REQUESTED_CONVERT_AUTHOR_TO_MASTER, id => ({
  id
}));
export const setAuthorLanguage = createAction(AuthorActionTypes.REQUESTED_SET_AUTHOR_LANGUAGE, (id, language, nextModal) => ({
  id,
  language,
  nextModal
}));
export const addAuthorToLanguageGroup = createAction(AuthorActionTypes.REQUESTED_ADD_AUTHOR_TO_LANGUAGE_GROUP, (id, language, translatedFromId, masterLanguage) => ({
  id,
  language,
  translatedFromId,
  masterLanguage
}));
export const removeAuthorFromLanguageGroup = createAction(AuthorActionTypes.REQUESTED_REMOVE_AUTHOR_FROM_LANGUAGE_GROUP, ({
  id
}) => ({
  id
}));
const _fetchAssignableAuthorOptions = createAction(AuthorActionTypes.FETCH_ASSIGNABLE_AUTHOR_OPTIONS, (id, query) => ({
  id,
  query
}));
const refetchAssignableAuthorOptions = createAction(AuthorActionTypes.REFETCH_ASSIGNABLE_AUTHOR_OPTIONS, (id, query) => ({
  id,
  query
}));
export const fetchAssignableAuthorOptions = (id, query) => query.search ? refetchAssignableAuthorOptions(id, query) : _fetchAssignableAuthorOptions(id, query);
export const requestAssignableAuthorOptions = createAction(AuthorActionTypes.REQUESTED_ASSIGNABLE_AUTHOR_OPTIONS, id => ({
  id
}));
export const receiveAssignableAuthorOptions = createAction(AuthorActionTypes.RECEIVED_ASSIGNABLE_AUTHOR_OPTIONS, ({
  id,
  authors,
  total
}) => ({
  id,
  authors,
  total
}));
export const receiveAssignableAuthorOptionsFailure = createAction(AuthorActionTypes.REQUESTED_ASSIGNABLE_AUTHOR_OPTIONS_FAILED, (id, error) => ({
  id,
  error
}));
export const switchedLanguageMasterAuthor = createAction(AuthorActionTypes.SWITCHED_LANGUAGE_MASTER_AUTHOR, ({
  id
}) => ({
  id
}));
export const fetchInitialAssignableAuthorOptions = createAction(AuthorActionTypes.FETCH_INITIAL_ASSIGNABLE_AUTHOR_OPTIONS, (ids, query) => ({
  ids,
  query
}));
export const requestInitialAssignableAuthorOptions = createAction(AuthorActionTypes.REQUESTED_INITIAL_ASSIGNABLE_AUTHOR_OPTIONS, ids => ({
  ids
}));
export const receiveInitialAssignableAuthorOptions = createAction(AuthorActionTypes.RECEIVED_INITIAL_ASSIGNABLE_AUTHOR_OPTIONS, responses => ({
  responses
}));
export const receiveInitialAssignableAuthorOptionsFailure = createAction(AuthorActionTypes.REQUESTED_INITIAL_ASSIGNABLE_AUTHOR_OPTIONS_FAILED, (ids, error) => ({
  ids,
  error
}));
export const clearAssignableAuthorOptions = createAction(AuthorActionTypes.CLEAR_ASSIGNABLE_AUTHOR_OPTIONS);
const _updateBlogAuthorssFilterQueryState = createAction(AuthorActionTypes.UPDATE_BLOG_AUTHORS_FILTER_QUERY_STATE, filterOptions => Object.assign({}, filterOptions));
export const updateBlogAuthorsFilterQueryState = filterOptions => dispatch => {
  dispatch(_updateBlogAuthorssFilterQueryState(filterOptions));
  dispatch(markAuthorsStale());
};