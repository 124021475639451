import { ContentNameKeysToCrmPropertyNames } from '../shared/constants/contentProperties';
import { ContentTypes } from '../shared/constants/ContentTypeConstants';
export const SELF_USER_IDENTIFIER = '__hs__ME';
export const PROPERTIES_WITH_SELF_USER_IDENTIFIER = [ContentNameKeysToCrmPropertyNames.UPDATED_BY, ContentNameKeysToCrmPropertyNames.CREATED_BY];
const DEFAULT_VIEW_IDS = {
  CREATED_BY_ME: '-1',
  ALL: '-2',
  RECENTLY_UPDATED: '-25',
  UPDATED_BY_ME: '-26',
  RECENTLY_PUBLISHED: '-27',
  DRAFTS: '-33',
  PUBLISHED: '-34',
  SCHEDULED: '-35'
};

/**
 * The label of each default view defined in PUMA
 */
const DEFAULT_VIEW_LABELS = {
  CREATED_BY_ME: 'my',
  ALL: 'all',
  RECENTLY_UPDATED: 'recently_updated',
  UPDATED_BY_ME: 'updated_by_me',
  RECENTLY_PUBLISHED: 'recently_published',
  DRAFTS: 'drafts',
  PUBLISHED: 'published',
  SCHEDULED: 'scheduled'
};

/**
 * The url of each default view
 */
const DEFAULT_VIEW_URLS = {
  CREATED_BY_ME: 'created-by-me',
  ALL: 'all',
  RECENTLY_UPDATED: 'recently-updated',
  UPDATED_BY_ME: 'updated-by-me',
  RECENTLY_PUBLISHED: 'recently-published',
  DRAFTS: 'all-drafts',
  PUBLISHED: 'all-published',
  SCHEDULED: 'all-scheduled'
};

/**
 * All valid view urls
 */

export const StandardViewsIdToUrl = {
  [DEFAULT_VIEW_IDS.CREATED_BY_ME]: DEFAULT_VIEW_URLS.CREATED_BY_ME,
  [DEFAULT_VIEW_LABELS.CREATED_BY_ME]: DEFAULT_VIEW_URLS.CREATED_BY_ME,
  [DEFAULT_VIEW_IDS.ALL]: DEFAULT_VIEW_URLS.ALL,
  [DEFAULT_VIEW_LABELS.ALL]: DEFAULT_VIEW_URLS.ALL,
  [DEFAULT_VIEW_IDS.RECENTLY_UPDATED]: DEFAULT_VIEW_URLS.RECENTLY_UPDATED,
  [DEFAULT_VIEW_LABELS.RECENTLY_UPDATED]: DEFAULT_VIEW_URLS.RECENTLY_UPDATED,
  [DEFAULT_VIEW_IDS.UPDATED_BY_ME]: DEFAULT_VIEW_URLS.UPDATED_BY_ME,
  [DEFAULT_VIEW_LABELS.UPDATED_BY_ME]: DEFAULT_VIEW_URLS.UPDATED_BY_ME,
  [DEFAULT_VIEW_IDS.RECENTLY_PUBLISHED]: DEFAULT_VIEW_URLS.RECENTLY_PUBLISHED,
  [DEFAULT_VIEW_LABELS.RECENTLY_PUBLISHED]: DEFAULT_VIEW_URLS.RECENTLY_PUBLISHED,
  [DEFAULT_VIEW_IDS.DRAFTS]: DEFAULT_VIEW_URLS.DRAFTS,
  [DEFAULT_VIEW_LABELS.DRAFTS]: DEFAULT_VIEW_URLS.DRAFTS,
  [DEFAULT_VIEW_IDS.PUBLISHED]: DEFAULT_VIEW_URLS.PUBLISHED,
  [DEFAULT_VIEW_LABELS.PUBLISHED]: DEFAULT_VIEW_URLS.PUBLISHED,
  [DEFAULT_VIEW_IDS.SCHEDULED]: DEFAULT_VIEW_URLS.SCHEDULED,
  [DEFAULT_VIEW_LABELS.SCHEDULED]: DEFAULT_VIEW_URLS.SCHEDULED
};
export const StandardViewsUrlToId = {
  [DEFAULT_VIEW_URLS.CREATED_BY_ME]: DEFAULT_VIEW_IDS.CREATED_BY_ME,
  [DEFAULT_VIEW_URLS.ALL]: DEFAULT_VIEW_IDS.ALL,
  [DEFAULT_VIEW_URLS.RECENTLY_UPDATED]: DEFAULT_VIEW_IDS.RECENTLY_UPDATED,
  [DEFAULT_VIEW_URLS.UPDATED_BY_ME]: DEFAULT_VIEW_IDS.UPDATED_BY_ME,
  [DEFAULT_VIEW_URLS.RECENTLY_PUBLISHED]: DEFAULT_VIEW_IDS.RECENTLY_PUBLISHED,
  [DEFAULT_VIEW_URLS.DRAFTS]: DEFAULT_VIEW_IDS.DRAFTS,
  [DEFAULT_VIEW_URLS.PUBLISHED]: DEFAULT_VIEW_IDS.PUBLISHED,
  [DEFAULT_VIEW_URLS.SCHEDULED]: DEFAULT_VIEW_IDS.SCHEDULED
};
export const NameSpaces = {
  [ContentTypes.LANDING_PAGE]: 'LANDING_PAGES',
  [ContentTypes.SITE_PAGE]: 'SITE_PAGES',
  [ContentTypes.BLOG_POST]: 'BLOG_POSTS',
  [ContentTypes.BLOG_LISTING_PAGE]: '',
  [ContentTypes.EXTERNAL]: ''
};