'use es6';

import { List } from 'immutable';
import I18n from 'I18n';
import { ALL_FILTER } from '../../shared/constants/DefaultFilterParams';
export const createSelectOptions = (data, idKey, nameKey, allLangKey) => {
  const result = data.reduce((acc, item) => {
    const value = item.get(idKey);
    if (value && value !== 0) {
      return acc.push({
        value,
        text: `${item.get(nameKey)}${item.get('language') ? ` (${I18n.text(`SharedI18nStrings.languageNames.${item.get('language')}`)})` : ''}`,
        nameWithoutLanguage: item.get(nameKey)
      });
    }
    return acc;
  }, new List()).toArray();
  if (allLangKey) {
    result.unshift({
      value: ALL_FILTER,
      text: I18n.text(allLangKey)
    });
  }
  return result;
};
export const createSelectOptionsFromArray = (data, idKey, nameKey, allLangKey) => {
  const result = data.reduce((acc, item) => {
    const value = item[idKey];
    if (value && value !== 0) {
      acc.push({
        value,
        text: `${item[nameKey]}${item.language ? ` (${I18n.text(`SharedI18nStrings.languageNames.${item.language}`)})` : ''}`,
        nameWithoutLanguage: item[nameKey]
      });
    }
    return acc;
  }, []);
  if (allLangKey) {
    result.unshift({
      value: ALL_FILTER,
      text: I18n.text(allLangKey)
    });
  }
  return result;
};