'use es6';

import { Record, Set as ImmutableSet } from 'immutable';
import { BlogAuthorsColumns, BlogAuthorsSortableColumnPreferredSortDirs } from '../constants/BlogTableColumns';
import { ALL_FILTER } from '../../shared/constants/DefaultFilterParams';
import { ITEMS_PER_PAGE } from '../../shared/constants/table';
const defaults = {
  authorsWithOpenNestedRows: new ImmutableSet(),
  selected: new ImmutableSet(),
  blogAuthorModalErrorType: null,
  // filters
  currentPage: 1,
  blogFilter: ALL_FILTER,
  authorStateFilter: ALL_FILTER,
  selectedSort: BlogAuthorsColumns.PUBLIC_NAME,
  selectedSortDir: BlogAuthorsSortableColumnPreferredSortDirs[BlogAuthorsColumns.PUBLIC_NAME],
  search: '',
  rowsPerPage: ITEMS_PER_PAGE
};
export default class AuthorsDisplay extends Record(defaults) {}