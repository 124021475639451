"use es6";

import { Subject } from '../Subject';
import { tryCatch } from '../util/tryCatch';
import { errorObject } from '../util/errorObject';
import { OuterSubscriber } from '../OuterSubscriber';
import { subscribeToResult } from '../util/subscribeToResult';
/**
 * Returns an Observable that mirrors the source Observable with the exception of an `error`. If the source Observable
 * calls `error`, this method will emit the Throwable that caused the error to the Observable returned from `notifier`.
 * If that Observable calls `complete` or `error` then this method will call `complete` or `error` on the child
 * subscription. Otherwise this method will resubscribe to the source Observable.
 *
 * <img src="./img/retryWhen.png" width="100%">
 *
 * @param {function(errors: Observable): Observable} notifier - Receives an Observable of notifications with which a
 * user can `complete` or `error`, aborting the retry.
 * @return {Observable} The source Observable modified with retry logic.
 * @method retryWhen
 * @owner Observable
 */
export function retryWhen(notifier) {
  return source => source.lift(new RetryWhenOperator(notifier, source));
}
class RetryWhenOperator {
  constructor(notifier, source) {
    this.notifier = notifier;
    this.source = source;
  }
  call(subscriber, source) {
    return source.subscribe(new RetryWhenSubscriber(subscriber, this.notifier, this.source));
  }
}
/**
 * We need this JSDoc comment for affecting ESDoc.
 * @ignore
 * @extends {Ignored}
 */
class RetryWhenSubscriber extends OuterSubscriber {
  constructor(destination, notifier, source) {
    super(destination);
    this.notifier = notifier;
    this.source = source;
  }
  error(err) {
    if (!this.isStopped) {
      let errors = this.errors;
      let retries = this.retries;
      let retriesSubscription = this.retriesSubscription;
      if (!retries) {
        errors = new Subject();
        retries = tryCatch(this.notifier)(errors);
        if (retries === errorObject) {
          return super.error(errorObject.e);
        }
        retriesSubscription = subscribeToResult(this, retries);
      } else {
        this.errors = null;
        this.retriesSubscription = null;
      }
      this._unsubscribeAndRecycle();
      this.errors = errors;
      this.retries = retries;
      this.retriesSubscription = retriesSubscription;
      errors.next(err);
    }
  }
  /** @deprecated internal use only */
  _unsubscribe() {
    const {
      errors,
      retriesSubscription
    } = this;
    if (errors) {
      errors.unsubscribe();
      this.errors = null;
    }
    if (retriesSubscription) {
      retriesSubscription.unsubscribe();
      this.retriesSubscription = null;
    }
    this.retries = null;
  }
  notifyNext(outerValue, innerValue, outerIndex, innerIndex, innerSub) {
    const {
      errors,
      retries,
      retriesSubscription
    } = this;
    this.errors = null;
    this.retries = null;
    this.retriesSubscription = null;
    this._unsubscribeAndRecycle();
    this.errors = errors;
    this.retries = retries;
    this.retriesSubscription = retriesSubscription;
    this.source.subscribe(this);
  }
}