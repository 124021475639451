'use es6';

import { SortDirTypes } from '../../shared/constants/table';
import { ColumnTypes } from '../../shared/constants/columns';
export const BlogCommentsColumns = {
  COMMENT: 'comment',
  COMMENTER: 'userName',
  POSTED: 'createdAt',
  POST: 'post',
  POST_AUTHOR: 'postAuthor'
};
export const BlogCommentsSortableColumnPreferredSortDirs = {
  [BlogCommentsColumns.COMMENT]: SortDirTypes.DESCENDING,
  [BlogCommentsColumns.COMMENTER]: SortDirTypes.DESCENDING,
  [BlogCommentsColumns.POSTED]: SortDirTypes.DESCENDING,
  [BlogCommentsColumns.POST]: SortDirTypes.DESCENDING,
  [BlogCommentsColumns.POST_AUTHOR]: SortDirTypes.DESCENDING
};
export const BlogAuthorsColumns = {
  PUBLIC_NAME: 'fullName',
  INTERNAL_NAME: 'name',
  EMAIL: 'email',
  LIVE_POSTS: 'livePosts',
  TOTAL_POSTS: 'totalPosts'
};
export const BlogAuthorsSortableColumnPreferredSortDirs = {
  [BlogAuthorsColumns.PUBLIC_NAME]: SortDirTypes.DESCENDING,
  [BlogAuthorsColumns.INTERNAL_NAME]: SortDirTypes.DESCENDING,
  [BlogAuthorsColumns.EMAIL]: SortDirTypes.DESCENDING,
  [BlogAuthorsColumns.LIVE_POSTS]: SortDirTypes.DESCENDING,
  [BlogAuthorsColumns.TOTAL_POSTS]: SortDirTypes.DESCENDING,
  [ColumnTypes.INTERNAL_NAME]: SortDirTypes.DESCENDING
};
export const BlogTagsColumns = {
  NAME: 'tagName',
  LIVE_POSTS: 'livePosts',
  TOTAL_POSTS: 'totalPosts',
  LAST_USED: 'lastUsed'
};
export const BlogTagsSortableColumnPreferredSortDirs = {
  [BlogTagsColumns.NAME]: SortDirTypes.ASCENDING,
  [BlogTagsColumns.LIVE_POSTS]: SortDirTypes.DESCENDING,
  [BlogTagsColumns.TOTAL_POSTS]: SortDirTypes.DESCENDING,
  [BlogTagsColumns.LAST_USED]: SortDirTypes.DESCENDING,
  [ColumnTypes.TAG_NAME]: SortDirTypes.ASCENDING
};