import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RequestStatuses from '../constants/RequestStatus';
import TeamsApi from '../api/Teams';
export const fetchAllTeams = createAsyncThunk('teams/fetchAllTeams', () => TeamsApi.fetchAllTeams());
export const fetchTeamsForUser = createAsyncThunk('teams/fetchTeamsForUser', () => TeamsApi.fetchTeamsForUser());
const initialState = {
  teams: [],
  allTeamsStatus: RequestStatuses.UNINITIALIZED,
  teamsForUsersRequestStatus: RequestStatuses.UNINITIALIZED
};
const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAllTeams.pending, state => {
      state.allTeamsStatus = RequestStatuses.PENDING;
    });
    builder.addCase(fetchAllTeams.fulfilled, (state, {
      payload
    }) => {
      state.allTeamsStatus = RequestStatuses.SUCCEEDED;
      state.teams = payload;
    });
    builder.addCase(fetchAllTeams.rejected, state => {
      state.allTeamsStatus = RequestStatuses.FAILED;
    });
    builder.addCase(fetchTeamsForUser.pending, state => {
      state.teamsForUsersRequestStatus = RequestStatuses.PENDING;
    });
    builder.addCase(fetchTeamsForUser.fulfilled, (state, {
      payload
    }) => {
      state.teamsForUsersRequestStatus = RequestStatuses.SUCCEEDED;
      state.teams = payload;
    });
    builder.addCase(fetchTeamsForUser.rejected, state => {
      state.teamsForUsersRequestStatus = RequestStatuses.FAILED;
    });
  }
});
export default teamsSlice.reducer;