import { ContentTypes } from './ContentTypeConstants';
export const FrameworkCustomColumnSettingKeys = {
  [ContentTypes.BLOG_POST]: 'blogPostsFrameworkCustomColumns',
  [ContentTypes.BLOG_LISTING_PAGE]: 'blogListingPagesFrameworkCustomColumns',
  [ContentTypes.LANDING_PAGE]: 'landingPagesFrameworkCustomColumns',
  [ContentTypes.SITE_PAGE]: 'sitePagesFrameworkCustomColumns'
};
export const OnboardingTourUserSettingKeys = {
  [ContentTypes.BLOG_POST]: 'hasSeenBpApprovalStatusColumnTour',
  [ContentTypes.LANDING_PAGE]: 'hasSeenLpApprovalStatusColumnTour',
  [ContentTypes.SITE_PAGE]: 'hasSeenWpApprovalStatusColumnTour'
};
export const UserSettingKeyToLocalStorageKey = {
  authorsCustomColumns: 'authors-custom-columns',
  tagsCustomColumns: 'tags-custom-columns',
  commentsCustomColumns: 'comments-custom-columns',
  blogPostsCustomColumns: 'blog-posts-custom-columns',
  landingPagesCustomColumns: 'landing-pages-custom-columns',
  sitePagesCustomColumns: 'site-pages-custom-columns',
  blogPostsFrameworkCustomColumns: 'blog-posts-framework-custom-columns',
  blogListingPagesFrameworkCustomColumns: 'blog-listing-pages-framework-custom-columns',
  landingPagesFrameworkCustomColumns: 'landing-pages-framework-custom-columns',
  sitePagesFrameworkCustomColumns: 'site-pages-framework-custom-columns'
};
export const HAS_DISMISSED_AI_BLOG_GENERATOR_SHEPHERD = 'HAS_DISMISSED_AI_BLOG_GENERATOR_SHEPHERD';
export const HAS_DISMISSED_AIBPG_REPACKAGE_ALERT = 'HAS_DISMISSED_AIBPG_REPACKAGE_ALERT';