'use es6';

import { OrderedMap } from 'immutable';
import { map } from 'rxjs/operators/map';
import httpRxjs from 'hub-http-rxjs/clients/apiClient';
import http from 'hub-http/clients/apiClient';
import I18n from 'I18n';
import BlogPost from '../models/BlogPost';
const URI = 'blogs/v3/blog-posts';
function getURI(id) {
  let result = URI;
  if (id) {
    result = `${result}/${id}`;
  }
  return result;
}
function parsePostsIntoOrderedMap(posts) {
  return posts.reduce((acc, p) => acc.set(p.id, BlogPost.from(p)), new OrderedMap());
}
export default class BlogPostsApi {
  static _fetchBlogPosts(query) {
    return http.get(getURI(null), {
      query
    });
  }
  static createBlogPost(blogId, language) {
    return httpRxjs.post(getURI(), {
      data: {
        contentGroupId: blogId,
        name: I18n.text('content-management-ui.blog.blogPosts.defaultPostTitle'),
        language,
        publishDate: new Date().getTime()
      }
    });
  }
  static fetchPostsForComments(comments) {
    const request = httpRxjs.get(URI, {
      query: {
        id__in: comments.reduce((acc, comment) => {
          acc.push(comment.contentId);
          return acc;
        }, []),
        limit: comments.size,
        property: ['id', 'name', 'language', 'parentBlog']
      }
    });
    return request.pipe(map(response => {
      return {
        posts: parsePostsIntoOrderedMap(response.objects)
      };
    }));
  }
}