import { useApprovalConfig } from 'ui-product-approvals/components';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ApprovalActions } from 'ui-product-approvals/types';
import { getTableViewContentTypeId } from '../utils/utils';
import { getHasAccessToCmsApprovals } from '../selectors/authSelectors';
import { ContentApprovalTypes } from '../constants/ContentApprovalTypes';
import usePrevious from 'react-utils/hooks/usePrevious';
const useApprovalConfigInfo = () => {
  const contentType = getTableViewContentTypeId();
  const approvalConfig = useApprovalConfig(ContentApprovalTypes[contentType], ApprovalActions.PUBLISH);
  const hasAccessToCmsApprovals = useSelector(getHasAccessToCmsApprovals(contentType));
  const prevContentType = usePrevious(contentType);
  useEffect(() => {
    if (hasAccessToCmsApprovals && (!approvalConfig.isLoading && approvalConfig.config.enabled === null || prevContentType !== contentType)) {
      approvalConfig.fetchConfig();
    }
  }, [approvalConfig, hasAccessToCmsApprovals, contentType, prevContentType]);
  const areApprovalsEnabled = useMemo(() => {
    return !!(hasAccessToCmsApprovals && !approvalConfig.isLoading && approvalConfig.config.enabled);
  }, [approvalConfig, hasAccessToCmsApprovals]);
  return areApprovalsEnabled;
};
export default useApprovalConfigInfo;