import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// @ts-expect-error Untyped module
import BlogsApi from '../api/Blogs';
import RequestStatuses from '../../shared/constants/RequestStatus';
import { getCreateDefaultBlogStatus } from '../selectors/blogZeroStateInitializerSelectors';
import { fetchBlogQuickCreateRequiredData } from 'ContentComponents/hooks/blogHooks';
import I18n from 'I18n';
const initialState = {
  createDefaultBlogStatus: RequestStatuses.UNINITIALIZED
};
const createDefaultBlog = createAsyncThunk('campaigns/createCampaign', async () => {
  const requiredData = await fetchBlogQuickCreateRequiredData();
  const defaultName = I18n.text('content-management-ui.blog.blogMagic.defaultBlogName');
  return BlogsApi.createBlog({
    name: defaultName,
    slug: defaultName,
    htmlTitle: defaultName,
    domain: '',
    language: requiredData.defaultLanguage,
    itemTemplatePath: requiredData.blogTemplate,
    listingTemplatePath: requiredData.blogListingTemplate
  });
}, {
  condition: (_, {
    getState
  }) => {
    const defaultBlogRequestStatus = getCreateDefaultBlogStatus(getState());
    return defaultBlogRequestStatus !== RequestStatuses.PENDING;
  }
});
const blogZeroStateInitializerSlice = createSlice({
  name: 'blogMagic',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createDefaultBlog.pending, state => {
      state.createDefaultBlogStatus = RequestStatuses.PENDING;
    }).addCase(createDefaultBlog.fulfilled, state => {
      state.createDefaultBlogStatus = RequestStatuses.SUCCEEDED;
    }).addCase(createDefaultBlog.rejected, state => {
      state.createDefaultBlogStatus = RequestStatuses.FAILED;
    });
  }
});
export const blogZeroStateInitializerActions = Object.assign({}, blogZeroStateInitializerSlice.actions, {
  createDefaultBlog
});
export default blogZeroStateInitializerSlice.reducer;