'use es6';

import { OrderedMap, Set as ImmutableSet } from 'immutable';
import { createSelector } from 'reselect';
import memoize from 'react-utils/memoize';
import { getAuthorsData, getTranslatedAuthors } from '../../blog/selectors/authorSelectors';
import BlogAuthorModalNames from '../../blog/constants/BlogAuthorModalNames';
import BlogCommentModalNames from '../../blog/constants/BlogCommentModalNames';
import BlogTagModalNames from '../../blog/constants/BlogTagModalNames';
import { getCommentsData } from '../../blog/selectors/commentSelectors';
import { getFolderDataById } from '../../pages/selectors/folderSelectors';
import PageModalNames from '../../pages/constants/PageModalNames';
import { getPagesSelector } from '../../pages/selectors/pagesSelectors';
import { getTagsData, getTranslatedTags } from '../../blog/selectors/tagSelectors';
import { getContentGroupLanguages } from '../../blog/utils/contentLanguageUtils';
export const getModalsDisplay = state => state.modalsDisplay;
export const getOpenModalName = createSelector([getModalsDisplay], modalsDisplay => modalsDisplay.openModalName);
export const getModalRelevantEntityIds = createSelector([getModalsDisplay], modalsDisplay => modalsDisplay.relevantEntityIds);
export const getModalRelevantEntities = modalNames => (openModalName, relevantEntityIds, entities, translations = OrderedMap()) => {
  if (modalNames[openModalName]) {
    const modalRelevantEntities = entities.filter((_, id) => relevantEntityIds.includes(id));
    if (modalRelevantEntities.size === 0) {
      const modalsWithIndividualEntities = modalRelevantEntities.concat(translations.filter((_, id) => relevantEntityIds.includes(id)));
      return modalsWithIndividualEntities;
    }
    return modalRelevantEntities;
  } else {
    return new OrderedMap();
  }
};
export const getModalRelevantBlogTags = createSelector([getOpenModalName, getModalRelevantEntityIds, getTagsData, getTranslatedTags], getModalRelevantEntities(BlogTagModalNames));
export const getTagGroupLanguagesForModals = createSelector([getTagsData, getModalRelevantBlogTags], (tags, modalRelevantTags) => modalRelevantTags.reduce((acc, tag) => {
  if (tag.isTranslation()) {
    return acc.union(getContentGroupLanguages(tags.get(tag.translatedFromId)));
  }
  return acc.union(getContentGroupLanguages(tag));
}, ImmutableSet()));
export const getModalRelevantBlogAuthors = createSelector([getOpenModalName, getModalRelevantEntityIds, getAuthorsData, getTranslatedAuthors], getModalRelevantEntities(BlogAuthorModalNames));
export const getModalRelevantBlogComments = createSelector([getOpenModalName, getModalRelevantEntityIds, getCommentsData], getModalRelevantEntities(BlogCommentModalNames));
export const getModalRelevantPages = memoize(contentType => {
  const getPagesData = getPagesSelector(contentType, 'getPagesData');
  const getTranslatedPages = getPagesSelector(contentType, 'getTranslatedPages');
  return createSelector([getOpenModalName, getModalRelevantEntityIds, getPagesData, getTranslatedPages], getModalRelevantEntities(PageModalNames));
});
export const getModalRelevantFolders = createSelector([getModalRelevantEntityIds], relevantEntityIds => {
  let folderId = 0;
  if (relevantEntityIds && relevantEntityIds.size > 0) {
    folderId = relevantEntityIds.first();
  }
  return getFolderDataById(folderId);
});
export const getSocialComposerParamsAsJS = createSelector([getModalsDisplay], modalsDisplay => modalsDisplay.socialComposerParams.toJS());
export const getIsSocialComposerPanelOpen = createSelector([getModalsDisplay], modalsDisplay => modalsDisplay.socialComposerOpen);
export const getIsContentPreviewPanelOpen = createSelector([getModalsDisplay], modalsDisplay => modalsDisplay.previewPanelOpen);
export const getParentCrmObjectCurrentModalContent = createSelector([getModalsDisplay], modalsDisplay => modalsDisplay.previewPanelParentCrmObject ? modalsDisplay.previewPanelParentCrmObject.toJS() : {});
export const getPreviewPanelCrmObject = createSelector([getModalsDisplay], modalsDisplay => modalsDisplay.previewPanelCrmObject ? modalsDisplay.previewPanelCrmObject.toJS() : {});
export const getPreviewPanelContentModel = createSelector([getModalsDisplay], modalsDisplay => modalsDisplay.previewPanelContentModel);
export const getModalExtraData = createSelector([getModalsDisplay], modalsDisplay => modalsDisplay.extraData);