'use es6';

import { Record } from 'immutable';
const FILTER_DEFAULTS = {
  filterType: null,
  isMultiSelect: true,
  needsDescriptorLabel: false,
  needsTranslatedLabel: true,
  propertyName: null,
  referencedObjectType: null,
  usageTrackerActionName: undefined
};
export default class Filter extends Record(FILTER_DEFAULTS) {}