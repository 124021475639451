'use es6';

import { ContentTypes as ContentActionModalContentTypes } from 'ContentComponents/constants/ContentActionModalContentTypes';
export const ContentTypes = {
  LANDING_PAGE: 'landing',
  SITE_PAGE: 'site',
  BLOG: 'blog',
  EXTERNAL_PAGE: 'external',
  BLOG_LISTING_PAGE: 'blogListingPage'
};
export const ContentTypeIds = {
  landing: 1,
  site: 4,
  blog: 3,
  external: 0,
  blogListingPage: 7
};
export const ContentTypeIdsToContentType = {
  [ContentTypeIds.landing]: ContentTypes.LANDING_PAGE,
  [ContentTypeIds.site]: ContentTypes.SITE_PAGE,
  [ContentTypeIds.blog]: ContentTypes.BLOG,
  [ContentTypeIds.blogListingPage]: ContentTypes.BLOG_LISTING_PAGE
};
export const ContentTypesToContentTypeIds = {
  [ContentTypes.LANDING_PAGE]: ContentTypeIds.landing,
  [ContentTypes.SITE_PAGE]: ContentTypeIds.site,
  [ContentTypes.BLOG]: ContentTypeIds.blog
};
export const ContentTypeWriteScopePrefixes = {
  BLOG_POST: 'blog'
};
export const ContentTypesToSocialShareTypes = {
  [ContentTypes.LANDING_PAGE]: 'landing-page',
  [ContentTypes.SITE_PAGE]: 'site-page',
  [ContentTypes.BLOG]: 'blog-post'
};
export const ContentTypesToContentActionModalContentTypes = {
  [ContentTypes.LANDING_PAGE]: ContentActionModalContentTypes.LANDING_PAGE,
  [ContentTypes.SITE_PAGE]: ContentActionModalContentTypes.SITE_PAGE,
  [ContentTypes.BLOG]: ContentActionModalContentTypes.BLOG_POST,
  [ContentTypes.EXTERNAL_PAGE]: ContentActionModalContentTypes.EXTERNAL_PAGE
};
export const SubcategoryTypes = {
  SITE_PAGE: 'site_page'
};