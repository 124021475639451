'use es6';

import { AuthorActionTypes } from '../actions/AuthorActions';
import Authors from '../models/Authors';
import RequestStatus from '../../shared/constants/RequestStatus';
export default ((state = new Authors(), action) => {
  switch (action.type) {
    case AuthorActionTypes.REQUESTED_COMBINE_AUTHORS_OPTIONS:
      return state.set('status', RequestStatus.PENDING);
    case AuthorActionTypes.RECEIVED_COMBINE_AUTHORS_OPTIONS:
      return state.merge({
        data: state.data.merge(action.payload.authors),
        status: RequestStatus.SUCCEEDED,
        total: action.payload.total
      });
    case AuthorActionTypes.REQUESTED_COMBINE_AUTHORS_OPTIONS_FAILED:
      return state.set('status', RequestStatus.FAILED);
    case AuthorActionTypes.CLEAR_AUTHORS_OPTIONS:
      return new Authors();
    default:
      return state;
  }
});