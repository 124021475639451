"use es6";

import { Observable } from '../Observable';
/**
 * We need this JSDoc comment for affecting ESDoc.
 * @extends {Ignored}
 * @hide true
 */
export class ScalarObservable extends Observable {
  constructor(value, scheduler) {
    super();
    this.value = value;
    this.scheduler = scheduler;
    this._isScalar = true;
    if (scheduler) {
      this._isScalar = false;
    }
  }
  static create(value, scheduler) {
    return new ScalarObservable(value, scheduler);
  }
  static dispatch(state) {
    const {
      done,
      value,
      subscriber
    } = state;
    if (done) {
      subscriber.complete();
      return;
    }
    subscriber.next(value);
    if (subscriber.closed) {
      return;
    }
    state.done = true;
    this.schedule(state);
  }
  /** @deprecated internal use only */
  _subscribe(subscriber) {
    const value = this.value;
    const scheduler = this.scheduler;
    if (scheduler) {
      return scheduler.schedule(ScalarObservable.dispatch, 0, {
        done: false,
        value,
        subscriber
      });
    } else {
      subscriber.next(value);
      if (!subscriber.closed) {
        subscriber.complete();
      }
    }
  }
}