'use es6';

import { List } from 'immutable';
import { ColumnConfigurations, DefaultPagesColumns, DefaultTagsColumns, RequiredColumns, DefaultAuthorsColumns, DefaultCommentsColumns } from '../../constants/columns';
import { createSelector } from 'reselect';
import { getUserSettingsData } from '../userSettingsSelectors';
export const getLandingPagesCustomColumns = createSelector([getUserSettingsData], ({
  landingPagesCustomColumns
}) => {
  const columns = landingPagesCustomColumns.length > 0 ?
  // TEMP: Remove businessUnit column from custom columns
  landingPagesCustomColumns.filter(column => column !== 'businessUnit') : null;
  if (!columns) {
    return DefaultPagesColumns;
  }
  return columns;
});
export const getSitePagesCustomColumns = createSelector([getUserSettingsData], ({
  sitePagesCustomColumns
}) => {
  const columns = sitePagesCustomColumns.length > 0 ? sitePagesCustomColumns : null;
  if (!columns) {
    return DefaultPagesColumns;
  }
  return columns;
});
export const getTagsCustomColumns = createSelector([getUserSettingsData], ({
  tagsCustomColumns
}) => {
  const columns = tagsCustomColumns.length > 0 ? tagsCustomColumns : null;
  if (!columns) {
    return DefaultTagsColumns;
  }
  return columns;
});
export const getAuthorsCustomColumns = createSelector([getUserSettingsData], ({
  authorsCustomColumns
}) => {
  const columns = authorsCustomColumns.length > 0 ? authorsCustomColumns : null;
  if (!columns) {
    return DefaultAuthorsColumns;
  }
  return columns;
});
export const getCommentsCustomColumns = createSelector([getUserSettingsData], ({
  commentsCustomColumns
}) => {
  const columns = commentsCustomColumns.length > 0 ? commentsCustomColumns : null;
  if (!columns) {
    return DefaultCommentsColumns;
  }
  return columns;
});
export const getColumnConfigurations = columns => columns.map(columnName => ColumnConfigurations.get(columnName));
export const getCustomColumnNames = (tableColumns, requiredColumnNames = RequiredColumns) => tableColumns.reduce((acc, columnConfig) => {
  if (requiredColumnNames.includes(columnConfig.columnName)) {
    return acc;
  }
  return acc.push(columnConfig.columnName);
}, List());