'use es6';

import { OrderedMap, Set as ImmutableSet } from 'immutable';
import { isAppViewSiteTree } from './urls';

/**
 * This util will open/close multilang group and reset selected rows of that group if any filters are applied
 */
export const toggleMultiLangGroupForEntityAndResetSelection = ({
  id,
  primaryEntities,
  translationEntities = new OrderedMap(),
  currentlySelectedEntities = new ImmutableSet(),
  currentlyOpenNestedRows = new ImmutableSet(),
  isFilteringOrSearching = false
}) => {
  const entity = primaryEntities.get(id) || translationEntities.get(id);
  const groupPrimaryId = entity.getGroupPrimaryId();
  const selectionsToBeReset = [];
  let openNestedRows = currentlyOpenNestedRows;
  if (isFilteringOrSearching) {
    if (currentlyOpenNestedRows.has(groupPrimaryId)) {
      selectionsToBeReset.push(groupPrimaryId, ...primaryEntities.get(groupPrimaryId).getTranslatedContentIds().toArray());
    } else {
      selectionsToBeReset.push(id, ...entity.getTranslatedContentIds().toArray());
    }

    /**
     * When filters are applied a group's expansion can be toggled
     * even if group rows are selected because we reset selections
     * in this view on group toggle
     */
    openNestedRows = currentlyOpenNestedRows.has(groupPrimaryId) ? currentlyOpenNestedRows.remove(groupPrimaryId) : currentlyOpenNestedRows.add(groupPrimaryId);
  } else {
    /**
     * When no filters are applied group's expansion can only be toggled
     * if the group is closed. If the group rows are selected, that
     * group cannot be closed and will always remain open
     */
    if (currentlySelectedEntities.has(groupPrimaryId) || !currentlyOpenNestedRows.has(groupPrimaryId)) {
      openNestedRows = currentlyOpenNestedRows.add(groupPrimaryId);
    } else {
      openNestedRows = currentlyOpenNestedRows.remove(groupPrimaryId);
    }
  }
  return {
    selectedEntities: selectionsToBeReset.length > 0 ? currentlySelectedEntities.subtract(selectionsToBeReset) : currentlySelectedEntities,
    openNestedRows
  };
};

/**
 * This util toggles all group expansions and resets selection when filters are applied
 */
export const toggleAllMultiLangGroupsAndResetSelectionsWhenFiltering = ({
  primaryEntities,
  currentlyOpenNestedRows = new ImmutableSet()
}) => {
  const allMlangGroupsIds = primaryEntities.reduce((acc, e) => e.isPartOfMultiLanguageGroup() ? acc.add(e.getGroupPrimaryId()) : acc, new ImmutableSet());
  const shouldBeOpened = currentlyOpenNestedRows.size < allMlangGroupsIds.size;
  return {
    selectedEntities: new ImmutableSet(),
    openNestedRows: shouldBeOpened ? allMlangGroupsIds : new ImmutableSet()
  };
};

/**
 * This util will select table row and then expand rows if it's a multi language group
 */
export const toggleSelectEntityAndExpandMultiLangGroup = ({
  id,
  primaryEntities,
  translationEntities = new OrderedMap(),
  currentlySelectedEntities = new ImmutableSet(),
  currentlyOpenNestedRows = new ImmutableSet(),
  isFilteringOrSearching = false
}) => {
  const entity = primaryEntities.get(id) || translationEntities.get(id);
  const isEntityPrimary = !entity.isTranslation() && entity.isPartOfMultiLanguageGroup();
  const isEntityPrimaryAsTranslation = isAppViewSiteTree() && entity.isPrimaryDisplayedAsTranslation();
  const shouldBeSelected = !currentlySelectedEntities.has(id);
  const primaryEntityId = isEntityPrimaryAsTranslation ? entity.siteTreeVisualParentId : entity.getGroupPrimaryId();
  const shouldBeOpened = isEntityPrimary && !isFilteringOrSearching && !currentlyOpenNestedRows.has(primaryEntityId);
  const selectedEntities = [id];
  if (entity.isPartOfMultiLanguageGroup() && (!isFilteringOrSearching || currentlyOpenNestedRows.has(primaryEntityId))) {
    /**
     * 2 use cases for selecting translation rows:
     * - when no filters are applied (no mlang search and filter)
     *   we will select primary + all variations in a group
     *
     * - when filters are applied (mlang search and filter)
     *   we will only select primary + all variations if
     *   the group was already open
     */
    if (isEntityPrimary && !isEntityPrimaryAsTranslation) {
      selectedEntities.push(...entity.getTranslatedContentIds().toArray());
    } else if (entity.isTranslation() || isEntityPrimaryAsTranslation) {
      selectedEntities.push(primaryEntityId, ...primaryEntities.get(primaryEntityId).getTranslatedContentIds().toArray());
    }
  }
  return {
    selectedEntities: shouldBeSelected ? currentlySelectedEntities.concat(selectedEntities) : currentlySelectedEntities.subtract(selectedEntities),
    openNestedRows: shouldBeOpened ? currentlyOpenNestedRows.add(id) : currentlyOpenNestedRows
  };
};

/**
 * This util will select all rows and translations(if not filtering or group is expanded)
 * and expand all mlang groups if no filters are applied
 */
export const toggleSelectAllEntitiesAndExpandMultiLangGroups = ({
  primaryEntities,
  translationEntities = new OrderedMap(),
  currentlySelectedEntities = new ImmutableSet(),
  currentlyOpenNestedRows = new ImmutableSet(),
  isFilteringOrSearching = false
}) => {
  let shouldBeSelected = true;
  let selectedEntities = new ImmutableSet();
  let openNestedRows = currentlyOpenNestedRows;
  if (isFilteringOrSearching) {
    // number of rows to be selected is different depending on expanded mlang groups when filtering
    shouldBeSelected = openNestedRows.isEmpty() ? currentlySelectedEntities.size < primaryEntities.size : currentlySelectedEntities.size < primaryEntities.reduce((acc, {
      id
    }) => {
      return acc + openNestedRows.has(id) ? primaryEntities.get(id).getTranslatedContentIds().size + 1 : 1;
    }, 0);
    if (shouldBeSelected) {
      selectedEntities = currentlySelectedEntities.concat(primaryEntities.keySeq());
      if (!openNestedRows.isEmpty()) {
        openNestedRows.forEach(id => {
          selectedEntities = selectedEntities.concat([id, ...primaryEntities.get(id).getTranslatedContentIds().toArray()]);
        });
      }
    }
  } else {
    // number of rows to be selected is always primary + translations when not filtering
    shouldBeSelected = currentlySelectedEntities.size < primaryEntities.size + translationEntities.size;
    if (shouldBeSelected) {
      selectedEntities = selectedEntities.concat(primaryEntities.keySeq(), translationEntities.keySeq());
      openNestedRows = openNestedRows.concat(primaryEntities.filter(e => !e.isTranslation() && e.isPartOfMultiLanguageGroup() && !openNestedRows.has(e.id)).keySeq());
    }
  }
  return {
    selectedEntities,
    openNestedRows
  };
};