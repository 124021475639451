'use es6';

import { fromJS, Set as ImmutableSet } from 'immutable';
const LAST_SLUG_IN_URL_REGEX = /\/\/.+(\/[^/]+?)$/;

// Constructs relative URL of current url by including last portion of parent's URL
export const getRelativeUrl = (url, parentUrl) => {
  if (!url) return '';
  if (!parentUrl) return url;
  const currentUrlParentIndex = url.indexOf(parentUrl);
  if (currentUrlParentIndex > -1) {
    const currentUrlSlug = url.slice(currentUrlParentIndex + parentUrl.length);
    const parentUrlLastSlugMatch = LAST_SLUG_IN_URL_REGEX.exec(parentUrl);
    if (parentUrlLastSlugMatch && parentUrlLastSlugMatch.length === 2) {
      return `…${parentUrlLastSlugMatch[1]}${currentUrlSlug}`;
    }
  }
  return url;
};

// (Map tree, String slug) -> Array|null
export const findPathToNodeWithSlugInSiteTree = (tree, slug) => {
  const pathToNodeWithSlug = [];
  let nodeFound = false;
  let currentNode = tree;
  while (currentNode) {
    if (currentNode.get('slug') === slug) {
      nodeFound = true;
      break;
    }
    const nextChildMatches = currentNode.get('children').filter(child => new RegExp(`^${child.get('slug')}`).test(slug));
    if (nextChildMatches.size === 0) {
      break;
    }
    // Choose best match in list of potential matching children
    const nextChildMatch = nextChildMatches.reduce((bestMatch, match) => {
      const matchesMatchSlug = slug.match(new RegExp(`^${match.get('slug')}`, 'gi'));
      const matchSlugMatchLength = matchesMatchSlug.length ? matchesMatchSlug[0].length : 0;
      const matchesBestMatchSlug = slug.match(new RegExp(`^${bestMatch.get('slug')}`, 'gi'));
      const bestMatchSlugMatchLength = matchesBestMatchSlug.length ? matchesBestMatchSlug[0].length : 0;
      if (matchSlugMatchLength > bestMatchSlugMatchLength) {
        return match;
      }
      return bestMatch;
    }, nextChildMatches.first());
    const nextChildIndex = currentNode.get('children').findIndex(child => child.get('slug') === nextChildMatch.get('slug'));
    pathToNodeWithSlug.push('children', nextChildIndex);
    currentNode = currentNode.getIn(['children', nextChildIndex]);
  }
  return nodeFound ? pathToNodeWithSlug : null;
};

// (Map currentTree, Object newTreeData) -> Map
export const mergeNodesIntoSiteTree = (currentTree, newTreeData) => {
  const slugForNodeToMergeInto = newTreeData.slug;
  const pathToNodeToMergeInto = findPathToNodeWithSlugInSiteTree(currentTree, slugForNodeToMergeInto);
  if (!pathToNodeToMergeInto) return currentTree;
  const nodeToMergeInto = currentTree.getIn(pathToNodeToMergeInto);
  return currentTree.setIn(pathToNodeToMergeInto, nodeToMergeInto.merge({
    children: nodeToMergeInto.get('children').concat(fromJS(newTreeData.children)),
    hasMore: newTreeData.hasMore
  }));
};
export const getLastTreeChildSlug = tree => {
  const children = tree.get('children');
  if (!children || children.size === 0) return undefined;
  return children.getIn([children.size - 1, 'slug']);
};
export const _openInitialTrees = (tree, currentLevel, openTrees) => {
  if (currentLevel >= 4 || !tree) {
    return openTrees;
  }
  return tree.children.reduce((acc, childTree) => {
    return _openInitialTrees(childTree, currentLevel + 1, acc);
  }, openTrees.add(tree.slug));
};

// Returns Set containing all node slugs that should be initially open in tree
export const openInitialTrees = tree => {
  return _openInitialTrees(tree, 1, new ImmutableSet());
};