'use es6';

import { Record } from 'immutable';
const COLUMN_DEFAULTS = {
  columnName: null,
  sortable: false,
  preferredSortDirection: null,
  width: 100,
  apiSortProperty: null
};
export default class Column extends Record(COLUMN_DEFAULTS) {}