'use es6';

import { catchError } from 'rxjs/operators/catchError';
import { map } from 'rxjs/operators/map';
import { mergeMap } from 'rxjs/operators/mergeMap';
import { of } from 'rxjs/observable/of';
import { startWith } from 'rxjs/operators/startWith';
import { getPagesAction, PagesActionTypes } from '../actions/PagesActions';
import FilterableDomains from '../api/FilterableDomains';
export default (action$ => action$.ofType(PagesActionTypes.FETCH_FILTERABLE_DOMAINS).pipe(mergeMap(({
  payload: {
    contentType,
    businessUnitId
  }
}) => {
  const requestDomains = getPagesAction(contentType, 'requestDomains');
  const receiveDomains = getPagesAction(contentType, 'receiveDomains');
  const receiveDomainsFailure = getPagesAction(contentType, 'receiveDomainsFailure');
  return FilterableDomains.fetchDomains(contentType, businessUnitId).pipe(map(receiveDomains), catchError(error => of(receiveDomainsFailure(error))), startWith(requestDomains(contentType)));
})));