'use es6';

import { List, Set as ImmutableSet } from 'immutable';
import { createSelector } from 'reselect';
import I18n from 'I18n';
import PropertyOptionRecord from 'customer-data-objects/property/PropertyOptionRecord';
import { ALL_FILTER } from '../../shared/constants/DefaultFilterParams';
import { getContentGroupLanguages } from '../utils/contentLanguageUtils';
import { getAppDisplayModeSyncedContentGroupIdFilterValue } from '../../shared/selectors/appDisplayModeSyncedFiltersSelectors';
const getBlogsState = state => state.blog.blogs;
export const getBlogsData = createSelector([getBlogsState], blogs => blogs.data);
export const getBlogsAsSelectOptions = ({
  includeBlogFilter
} = {}) => createSelector([getBlogsData], blogs => {
  let options = blogs.map(blog => {
    const option = {
      value: blog.id,
      text: blog.name
    };
    return option;
  });
  if (includeBlogFilter) {
    options = options.unshift({
      value: ALL_FILTER,
      text: I18n.text('content-management-ui.header.filters.allBlogs')
    });
  }
  return options.toArray();
});
export const getBlogsAsPropertyOptions = createSelector([getBlogsAsSelectOptions()], blogOptions => blogOptions.reduce((acc, blogOption) => acc.push(PropertyOptionRecord(Object.assign({}, blogOption, {
  label: blogOption.text
}))), List()));
export const getBlogsStatus = createSelector([getBlogsState], blogs => blogs.status);
export const getSelectedBlogId = createSelector([getBlogsState], ({
  selectedBlogId
}) => selectedBlogId ? parseInt(selectedBlogId, 10) : undefined);
export const getCurrentlySelectedBlogs = createSelector([getAppDisplayModeSyncedContentGroupIdFilterValue, getBlogsData], (contentGroupIdsFilter, blogs) => {
  if (contentGroupIdsFilter && Array.isArray(contentGroupIdsFilter)) {
    return blogs.reduce((acc, blog) => {
      if (contentGroupIdsFilter.includes(blog.id)) {
        acc.push(blog);
      }
      return acc;
    }, []);
  }
  return blogs;
});
export const findBlog = (blogs, blogId) => blogs.find(blog => Number(blog.id) === Number(blogId));
export const getSelectedBlogData = createSelector([getSelectedBlogId, getBlogsData], (selectedBlogId, blogs) => findBlog(blogs, selectedBlogId));
export const getBlogPropertySelector = createSelector([getBlogsData], blogs => (blogId, property) => {
  const blog = findBlog(blogs, blogId);
  if (blog) {
    return blog.get(property);
  }
  return undefined;
});
export const getIsOnlyBlogCreatedByHubspot = createSelector([getBlogsData], blogs => {
  if (blogs.size === 1) {
    return blogs.first().get('name') === 'Default HubSpot Blog';
  }
  return false;
});
export const getHasBlogsOtherThanDefaultHubspotBlog = createSelector([getBlogsData, getIsOnlyBlogCreatedByHubspot], (blogs, isOnlyBlogCreatedByHubspot) => blogs.size > 1 || blogs.size === 1 && !isOnlyBlogCreatedByHubspot);
export const getAllBlogLanguages = createSelector([getBlogsData], blogs => blogs.reduce((blogLanguages, blog) => blogLanguages.union(getContentGroupLanguages(blog)), ImmutableSet()));
export const getMappingOfPrimaryBlogIdsToVariationIds = createSelector([getBlogsData], blogs => blogs.reduce((acc, blog) => {
  const primaryContentId = blog.get('id');
  const blogVariationIds = blog.has('translations') ? blog.get('translations').reduce((translatedIds, translation) => {
    translatedIds.push(translation.get('id'));
    return translatedIds;
  }, []) : [];
  acc[primaryContentId] = blogVariationIds;
  return acc;
}, {}));
export const getFirstBlog = createSelector([getBlogsData], blogs => blogs.first());