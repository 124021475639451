'use es6';

import { Record } from 'immutable';
const defaults = {
  // From api call
  domain: '',
  id: 0,
  isInternalDomain: false,
  isResolving: false,
  isDnsCorrect: false
};
export default class FilterableDomain extends Record(defaults) {}