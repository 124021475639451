import { useAppSelector } from '../../rootReducer';
import { getUserData } from '../../shared/selectors/authSelectors';
// @ts-expect-error Untyped module
import { ContentTypes } from '../../shared/constants/ContentTypes';
import { useFetchContentAssistantSettings } from '../../shared/hooks/useFetchContentAssistantSettings';
export const useBlogPostGeneratorPermission = () => {
  const user = useAppSelector(getUserData);
  const hasBlogWritePermission = user.canWrite(ContentTypes.BLOG);
  const {
    isContentAssistanceOn,
    contentAssistantSettingsRequestStatus
  } = useFetchContentAssistantSettings();
  return {
    isContentAssistanceOn,
    hasBlogWritePermission,
    contentAssistantSettingsRequestStatus
  };
};