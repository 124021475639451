'use es6';

import { Record } from 'immutable';
const defaults = {
  domain: '',
  id: 0,
  isInternalDomain: false,
  isDnsCorrect: false,
  isResolving: false,
  primarySitePage: false,
  primaryLandingPage: false,
  primaryBlogPost: false,
  resolvedLanguage: 'en',
  // default to english,
  isUsedForAnyContentType: false,
  isUsedForLandingPage: false,
  isUsedForSitePage: false,
  isUsedForBlogPost: false,
  secondaryToDomain: ''
};
export default class Domain extends Record(defaults) {}