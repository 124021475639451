'use es6';

import { Set as ImmutableSet, Map as ImmutableMap, OrderedMap } from 'immutable';
const getMultiLangGroup = entity => {
  if (!entity.isPartOfMultiLanguageGroup() || !entity.isTranslation()) {
    return entity;
  }
  const primaryEntity = entity.translatedContent.find(translation => translation.get('id') === entity.translatedFromId);
  const translationsMap = {};
  entity.translatedContent.forEach(translation => {
    const translationLanguage = translation.get('language');
    if (translationLanguage !== primaryEntity.get('language')) {
      translationsMap[translationLanguage] = translation;
    }
  });
  translationsMap[entity.language] = entity;
  return primaryEntity.set('translatedContent', new ImmutableMap(translationsMap));
};
export const getEntitiesData = (entities, entitiesWithOpenNestedRows) => {
  if (entitiesWithOpenNestedRows.size > 0) {
    return entities.data.reduce((acc, entity) => {
      const primaryId = entity.getGroupPrimaryId();
      if (entitiesWithOpenNestedRows.has(primaryId)) {
        return acc.set(primaryId, getMultiLangGroup(entity));
      }
      return acc.set(entity.id, entity);
    }, new OrderedMap());
  }
  return entities.data;
};
export const getNumMultiLangGroups = entities => {
  const multiLangGroupIds = entities.reduce((acc, entity) => entity.isPartOfMultiLanguageGroup() ? acc.add(entity.getGroupPrimaryId()) : acc, new ImmutableSet());
  return multiLangGroupIds.size;
};
export const getTruePrimaryAndTranslationsForSiteTreeActions = (primarySiteTreeContent, translatedSiteTreeContent, selectedEntitiesOrModalRelevantEntities) => selectedEntitiesOrModalRelevantEntities.reduce((acc, id) => {
  const entity = primarySiteTreeContent.get(id) || translatedSiteTreeContent.get(id);
  if (entity.isPrimaryDisplayedAsTranslation()) {
    /**
     * In use cases where reversed mlang groups are displayed
     * in site tree we need to build correct mlang groups
     * and their translated content in true order of hierarchy.
     *
     * So we get the true primary and use visual primary's
     * translatedContent data to build translations for
     * true primary for bulk and modal actions.
     */
    const {
      siteTreeVisualParentId
    } = entity;
    const translationAsPrimary = primarySiteTreeContent.get(siteTreeVisualParentId);
    const truePrimaryWithTranslations = entity.merge({
      siteTreeVisualParentId: null,
      translatedContent: translationAsPrimary.translatedContent.delete(entity.language).set(translationAsPrimary.language, translationAsPrimary.set('translatedContent', ImmutableMap()))
    });
    return {
      truePrimaryEntities: acc.truePrimaryEntities.delete(siteTreeVisualParentId).set(id, truePrimaryWithTranslations),
      trueTranslationEntities: acc.trueTranslationEntities.delete(id).set(siteTreeVisualParentId, translationAsPrimary.set('translatedContent', ImmutableMap()))
    };
  }
  return acc;
}, {
  truePrimaryEntities: primarySiteTreeContent,
  trueTranslationEntities: translatedSiteTreeContent
});