'use es6';

import { createSelector } from 'reselect';
import { ContentTypes } from '../../shared/constants/ContentTypes';
import { getPagesDisplaySelector } from './pagesDisplaySelectors';
export const getFoldersFiltersData = createSelector([getPagesDisplaySelector(ContentTypes.LANDING_PAGE, 'getPagesDisplayState')], ({
  folderId,
  selectedSort,
  selectedSortDir,
  search,
  currentPage,
  rowsPerPage
}) => ({
  folderId,
  selectedSort,
  selectedSortDir,
  search,
  currentPage,
  rowsPerPage
}));
const getFolderFilterQueryFromState = createSelector([getFoldersFiltersData], ({
  folderId,
  selectedSort,
  selectedSortDir,
  search,
  currentPage,
  rowsPerPage
}) => ({
  folder: folderId,
  page: currentPage,
  search,
  sort: selectedSort,
  sortDir: selectedSortDir,
  rowsPerPage
}));
export const getFoldersCurrentlyAppliedFilters = createSelector([getPagesDisplaySelector(ContentTypes.LANDING_PAGE, 'getPagesCurrentlyAppliedFilters'), getFolderFilterQueryFromState], (pagesCurrentlyAppliedFilters, folderFiltersFromState) => Object.assign({}, pagesCurrentlyAppliedFilters, folderFiltersFromState));