'use es6';

import { Record, Set as ImmutableSet, Map as ImmutableMap, List } from 'immutable';
import { ALL_FILTER } from '../../shared/constants/DefaultFilterParams';
import { ITEMS_PER_PAGE } from '../../shared/constants/table';
import BulkUpdateTask from '../../shared/models/BulkUpdateTask';
const defaults = {
  pagesWithOpenNestedRows: new ImmutableSet(),
  selected: new ImmutableSet(),
  // filters
  currentPage: 1,
  contentStateFilter: ALL_FILTER,
  domainFilter: ALL_FILTER,
  selectedSort: null,
  selectedSortDir: null,
  search: '',
  folderId: 0,
  rowsPerPage: ITEMS_PER_PAGE,
  filterQuery: new ImmutableMap(),
  bulkUpdateTask: new BulkUpdateTask(),
  /** Use of CRM search apis introduce some lag between the action performed
   * and table refresh, which can lead to displaying inconsistent results in table.
   *
   * Following property handles certain actions that remove the content object from view
   * and need to be filtered out in api request to fetch results without these content objects.
   *
   * More info in GHI: https://git.hubteam.com/HubSpot/CMS-Publishing-Teams/issues/971
   */
  excludedPageIds: new List()
};
export default class PagesDisplay extends Record(defaults) {}