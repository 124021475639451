'use es6';

import { createStack } from 'hub-http';
import { withResponseHandlers, buildResponse, buildErrorResponse, withTracking, trackSuccess, trackFailureBasedOnErrorResponse, buildRequestError } from 'hub-http/adapters/adapterUtils';
import { responseError } from 'hub-http/helpers/response';
import { setMockAuth } from 'hub-http/middlewares/mockAuth';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { defer } from 'rxjs/observable/defer';
import { timer } from 'rxjs/observable/timer';
import { _throw } from 'rxjs/observable/throw';
import { mergeMap } from 'rxjs/operators/mergeMap';
import { retryWhen } from 'rxjs/operators/retryWhen';
import { tap } from 'rxjs/operators/tap';
import { delayWhen } from 'rxjs/operators/delayWhen';
import { map } from 'rxjs/operators/map';
import { scan } from 'rxjs/operators/scan';
import { catchError } from 'rxjs/operators/catchError';

// TODO promote this to hub-http and re-use in promiseClient and rxjsClient
const withOptions = options => {
  const xhr = new XMLHttpRequest();
  const promise = new Promise(resolve => {
    if (options.error) {
      resolve(withResponseHandlers(buildErrorResponse(xhr, options.error.message, 'OPTIONSERROR'), options));
      return;
    }

    // the http request was done by a separate client and is being piped back
    // into this one for response handling
    if (options.externalResponse) {
      const fromExternalResponse = options.externalResponse instanceof XMLHttpRequest ? buildResponse(options.externalResponse) : Object.assign(buildResponse(xhr), options.externalResponse);
      resolve(withResponseHandlers(fromExternalResponse, options));
      return;
    }
    xhr.open(options.method || 'GET', options.url, true);
    if (typeof options.timeout === 'number') {
      xhr.timeout = options.timeout;
    }
    xhr.withCredentials = options.withCredentials;
    if (options.responseType) {
      xhr.responseType = options.responseType;
    }
    if (typeof options.withXhr === 'function') {
      options.withXhr(xhr);
    }
    Object.keys(options.headers || {}).forEach(headerName => {
      if (options.headers[headerName] !== false) {
        xhr.setRequestHeader(headerName, options.headers[headerName]);
      }
    });
    xhr.addEventListener('load', () => resolve(withResponseHandlers(buildResponse(xhr), options)));
    xhr.addEventListener('error', () => resolve(withResponseHandlers(buildErrorResponse(xhr, 'Network request failed', 'NETWORKERROR'), options)));
    xhr.addEventListener('timeout', () => resolve(withResponseHandlers(buildErrorResponse(xhr, 'Request timeout', 'TIMEOUT'), options)));
    xhr.addEventListener('abort', () => resolve(withResponseHandlers(buildErrorResponse(xhr, 'Request aborted', 'ABORT'), options)));
    xhr.send(typeof options.data === 'undefined' ? null : options.data);
  });
  return {
    xhr,
    promise
  };
};
const handleRetry = error$ => {
  return error$.pipe(mergeMap(response => response.retry ? of(response) : _throw(response)), scan((acc, response) => {
    if (acc.attempts >= response.retry.maxRetries) {
      throw responseError(response, `Request for ${response.options.url} failed since max retries exceeded (${response.retry.maxRetries}). ${response.statusText || ''}`);
    }
    return {
      attempts: acc.attempts + 1,
      response
    };
  }, {
    attempts: 0
  }), delayWhen(({
    response: {
      retry
    }
  }) => timer(retry.delay)), tap(({
    attempts,
    response
  }) => {
    const {
      retry
    } = response;
    const reasonMessage = retry.reason ? ` Reason: ${retry.reason}` : '';
    trackFailureBasedOnErrorResponse(response, {
      willBeRetried: true,
      retryReason: reasonMessage,
      retryAttempt: attempts
    });
    console.log(`Retrying. Retry attempt ${attempts} of ${retry.maxRetries}.${reasonMessage}`);
  }));
};
export let _originalClientImplCalled = false;
let mockAuth = false;
const createClientImpl = optionMiddleware => {
  _originalClientImplCalled = true;
  const client = (url, options) => Observable.create(observer => {
    let currentXhr;
    const innerSub = defer(() => fromPromise(optionMiddleware(Object.assign({}, options, {
      url
    })))).pipe(map(withTracking), catchError(reason => _throw(buildRequestError(reason))), map(withOptions), mergeMap(({
      xhr,
      promise
    }) => {
      currentXhr = xhr;
      return promise;
    }), map(trackSuccess), retryWhen(handleRetry), catchError(response => _throw(trackFailureBasedOnErrorResponse(response)))).subscribe({
      next(response) {
        observer.next(response);
        observer.complete();
      },
      error(err) {
        observer.error(err);
      }
    });
    return () => {
      if (currentXhr && currentXhr.readyState !== 4) {
        currentXhr.abort();
      }

      // run through the event loop before unsubscribe so the tracking logic can run on the inner observable
      setTimeout(() => {
        innerSub.unsubscribe();
      });
    };
  });
  const responseWithMethod = method => (url, options) => client(url, Object.assign({}, options, {
    method
  }));
  const withMethod = method => (url, options) => responseWithMethod(method)(url, options).pipe(map(({
    data
  }) => data));
  return Object.assign(client, {
    get: withMethod('GET'),
    post: withMethod('POST'),
    put: withMethod('PUT'),
    patch: withMethod('PATCH'),
    delete: withMethod('DELETE'),
    options: withMethod('OPTIONS'),
    getWithResponse: responseWithMethod('GET'),
    postWithResponse: responseWithMethod('POST'),
    putWithResponse: responseWithMethod('PUT'),
    patchWithResponse: responseWithMethod('PATCH'),
    deleteWithResponse: responseWithMethod('DELETE'),
    optionsWithResponse: responseWithMethod('OPTIONS')
  });
};

/**
 * Enables a mechanism which will bypass any middleware which redirects based on the absence of the authentication cookie. Automatically enabled whenever <strong>hub-http-rxjs/disableAuthWithMock</strong> is imported. Should only be imported as part of build time tests.
 */
export const enableMockAuth = () => {
  mockAuth = true;
};
export default (optionMiddleware => createClientImpl(createStack(setMockAuth(mockAuth), optionMiddleware)));