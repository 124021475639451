'use es6';

import { createSelector } from 'reselect';
import { ContentTypes, ContentTypesToContentTypeIds } from '../../shared/constants/ContentTypes';
import { getPagesSelector } from '../selectors/pagesSelectors';
import { getHasAccessToCmsApprovals, getUserData } from '../../shared/selectors/authSelectors';
import { getPropertiesData } from '../../shared/selectors/propertiesSelectors';
import { buildDropdownFiltersDataOptions, buildFilterTagsData, correctFilterQueryForPagesRequest } from '../../shared/utils/filters';
import { getFilterPropertiesForScopes } from '../../shared/utils/filterAvailabilityProperties';
import { PagesDropdownFilters, PagesFilterableProperties } from '../../shared/constants/filters';
import { getLandingPagesCustomColumns, getSitePagesCustomColumns } from '../../shared/selectors/table/columnSelectors';
import { getDefaultColumnSortInfo } from '../../shared/utils/columnUtils';
import { getBusinessUnitsFilterValue } from '../../shared/selectors/appDisplayModeSyncedFiltersSelectors';
import { ContentNameKeysToCrmPropertyNames } from '../../shared/constants/contentProperties';
const makePageSelectors = contentType => {
  const getTranslatedPages = getPagesSelector(contentType, 'getTranslatedPages');
  const getPagesDisplayState = state => state.pages[contentType === ContentTypes.SITE_PAGE ? 'sitePages' : 'landingPages'].display;
  const getSelectedPages = createSelector([getPagesDisplayState], display => display.selected);
  const getPagesWithOpenNestedRows = createSelector([getPagesDisplayState], display => display.pagesWithOpenNestedRows);
  const getPagesBulkUpdateTask = createSelector([getPagesDisplayState], display => display.bulkUpdateTask);
  const getPagesBulkUpdateTaskId = createSelector([getPagesBulkUpdateTask], bulkUpdateTask => bulkUpdateTask.get('taskId'));
  const getPagesBulkUpdateActionName = createSelector([getPagesBulkUpdateTask], bulkUpdateTask => bulkUpdateTask.get('actionName'));
  const getPagesBulkUpdateTaskData = createSelector([getPagesBulkUpdateTask], bulkUpdateTask => bulkUpdateTask.get('data'));
  const getPagesFilterQuery = createSelector([getPagesDisplayState], display => display.filterQuery);
  const getPagesFilterQueryAsArray = createSelector([getPagesFilterQuery], filterQuery => filterQuery.valueSeq().toArray());
  const getIsFilteringPages = createSelector([getPagesFilterQuery], filterQuery => filterQuery.size > 0);
  const getPagesFilterProperties = createSelector([getPropertiesData, getUserData, getHasAccessToCmsApprovals(ContentTypesToContentTypeIds[contentType])], (propertiesData, user, hasAccessToCmsApprovals) => {
    const filterableProperties = [...PagesFilterableProperties, ContentNameKeysToCrmPropertyNames.STATE];
    if (hasAccessToCmsApprovals) {
      filterableProperties.push(ContentNameKeysToCrmPropertyNames.APPROVAL_STATUS);
    }
    return getFilterPropertiesForScopes(filterableProperties, propertiesData, user, contentType);
  });
  const getPagesFilterDataOptions = createSelector([getPagesFilterQuery, getPagesFilterProperties], (filterQuery, properties) => buildDropdownFiltersDataOptions(filterQuery, properties, [ContentNameKeysToCrmPropertyNames.DOMAIN, ContentNameKeysToCrmPropertyNames.STATE]));
  const getPagesSearchFilter = createSelector([getPagesDisplayState], ({
    search
  }) => search);
  const getPagesContentStateFilter = createSelector([getPagesDisplayState], ({
    contentStateFilter
  }) => contentStateFilter);
  const getIsFilteringOrSearchingPages = createSelector([getPagesSearchFilter, getIsFilteringPages], (search, isFiltering) => search !== '' || isFiltering);
  const getNumPagesFilterPanelOnlyFiltersApplied = createSelector([getPagesFilterQuery], filterQuery => filterQuery.keySeq().reduce((acc, key) => acc + (PagesDropdownFilters.includes(key) ? 0 : 1), 0));
  const getPagesFiltersData = createSelector([getPagesDisplayState, getLandingPagesCustomColumns, getSitePagesCustomColumns, getBusinessUnitsFilterValue], ({
    currentPage,
    contentStateFilter,
    domainFilter,
    selectedSort,
    selectedSortDir,
    search,
    rowsPerPage,
    excludedPageIds
  }, landingPagesCustomColumnNames, sitePagesCustomColumnNames, selectedBusinessUnit) => {
    const defaultPagesSortInfo = getDefaultColumnSortInfo(contentType === ContentTypes.LANDING_PAGE ? landingPagesCustomColumnNames : sitePagesCustomColumnNames);
    return {
      page: currentPage,
      state: contentStateFilter,
      domain: domainFilter,
      sort: selectedSort || defaultPagesSortInfo.selectedSort,
      sortDir: selectedSortDir || defaultPagesSortInfo.selectedSortDir,
      search,
      rowsPerPage,
      excludedPageIds,
      businessUnit: selectedBusinessUnit
    };
  });
  const getPagesFilterTagsData = createSelector([getPagesFilterQuery, getPagesFilterProperties], buildFilterTagsData);
  const getPagesCurrentlyAppliedFilters = createSelector([getPagesFiltersData, getPagesFilterQuery], (filtersData, filterQuery) => Object.assign({}, filtersData, {
    filters: correctFilterQueryForPagesRequest(filtersData, filterQuery)
  }));
  const getPagesTableDisplayProperties = createSelector([getPagesFiltersData, getPagesSelector(contentType, 'getPagesTotal')], ({
    sort,
    sortDir,
    page,
    rowsPerPage
  }, pagesTotal) => {
    return {
      sort,
      sortDir,
      rowsPerPage,
      page,
      totalTablePages: Math.ceil(pagesTotal / rowsPerPage) || 1
    };
  });
  return {
    getTranslatedPages,
    getPagesDisplayState,
    getPagesSearchFilter,
    getPagesContentStateFilter,
    getSelectedPages,
    getPagesWithOpenNestedRows,
    getPagesBulkUpdateTaskId,
    getPagesBulkUpdateActionName,
    getPagesBulkUpdateTaskData,
    getPagesCurrentlyAppliedFilters,
    getPagesFilterDataOptions,
    getPagesFilterTagsData,
    getPagesFilterQueryAsArray,
    getIsFilteringPages,
    getNumPagesFilterPanelOnlyFiltersApplied,
    getPagesFiltersData,
    getPagesFilterQuery,
    getIsFilteringOrSearchingPages,
    getPagesTableDisplayProperties,
    getPagesFilterProperties
  };
};
const landingPagesDisplaySelectors = makePageSelectors(ContentTypes.LANDING_PAGE);
const sitePagesDisplaySelectors = makePageSelectors(ContentTypes.SITE_PAGE);
export const getPagesDisplaySelector = (contentType, selectorName) => {
  if (contentType === ContentTypes.SITE_PAGE) {
    return sitePagesDisplaySelectors[selectorName];
  }
  // WARNING:
  // Site Tree is "using" Landing Page selectors due to it being the fallback
  return landingPagesDisplaySelectors[selectorName];
};