import userInfo from 'hub-http/userInfo';
import PortalApi from './shared/api/Portal';
import User from './shared/models/User';
import Portal from './shared/models/Portal';
class Auth {
  constructor() {
    this.initialized = false;
    this.data = {};
  }
  init() {
    if (this.initialized) {
      throw new Error('Auth Utils: Cannot be initialized twice. Use getInstance() if you ' + 'need to create multiple instances.');
    }
    this.initialized = true;
    return Promise.all([userInfo(), PortalApi.fetchPortalScopes()]).then(([authConfig, portalConfig]) => {
      const {
        user,
        gates,
        portal
      } = authConfig;
      const userRecord = new User(user, gates);
      const portalRecord = new Portal(Object.assign({}, portalConfig, {
        timezone: portal.timezone,
        createdAt: portal.created_at
      }));
      this.data = {
        user: userRecord,
        portal: portalRecord,
        gates
      };
      return this.data;
    });
  }
  get() {
    return this.data;
  }
}
export default new Auth();