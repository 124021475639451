"use es6";

import { Subscriber } from '../Subscriber';
import { Observable } from '../Observable';
import { OuterSubscriber } from '../OuterSubscriber';
import { subscribeToResult } from '../util/subscribeToResult';
/**
 * Delays the emission of items from the source Observable by a given time span
 * determined by the emissions of another Observable.
 *
 * <span class="informal">It's like {@link delay}, but the time span of the
 * delay duration is determined by a second Observable.</span>
 *
 * <img src="./img/delayWhen.png" width="100%">
 *
 * `delayWhen` time shifts each emitted value from the source Observable by a
 * time span determined by another Observable. When the source emits a value,
 * the `delayDurationSelector` function is called with the source value as
 * argument, and should return an Observable, called the "duration" Observable.
 * The source value is emitted on the output Observable only when the duration
 * Observable emits a value or completes.
 *
 * Optionally, `delayWhen` takes a second argument, `subscriptionDelay`, which
 * is an Observable. When `subscriptionDelay` emits its first value or
 * completes, the source Observable is subscribed to and starts behaving like
 * described in the previous paragraph. If `subscriptionDelay` is not provided,
 * `delayWhen` will subscribe to the source Observable as soon as the output
 * Observable is subscribed.
 *
 * @example <caption>Delay each click by a random amount of time, between 0 and 5 seconds</caption>
 * var clicks = Rx.Observable.fromEvent(document, 'click');
 * var delayedClicks = clicks.delayWhen(event =>
 *   Rx.Observable.interval(Math.random() * 5000)
 * );
 * delayedClicks.subscribe(x => console.log(x));
 *
 * @see {@link debounce}
 * @see {@link delay}
 *
 * @param {function(value: T): Observable} delayDurationSelector A function that
 * returns an Observable for each value emitted by the source Observable, which
 * is then used to delay the emission of that item on the output Observable
 * until the Observable returned from this function emits a value.
 * @param {Observable} subscriptionDelay An Observable that triggers the
 * subscription to the source Observable once it emits any value.
 * @return {Observable} An Observable that delays the emissions of the source
 * Observable by an amount of time specified by the Observable returned by
 * `delayDurationSelector`.
 * @method delayWhen
 * @owner Observable
 */
export function delayWhen(delayDurationSelector, subscriptionDelay) {
  if (subscriptionDelay) {
    return source => new SubscriptionDelayObservable(source, subscriptionDelay).lift(new DelayWhenOperator(delayDurationSelector));
  }
  return source => source.lift(new DelayWhenOperator(delayDurationSelector));
}
class DelayWhenOperator {
  constructor(delayDurationSelector) {
    this.delayDurationSelector = delayDurationSelector;
  }
  call(subscriber, source) {
    return source.subscribe(new DelayWhenSubscriber(subscriber, this.delayDurationSelector));
  }
}
/**
 * We need this JSDoc comment for affecting ESDoc.
 * @ignore
 * @extends {Ignored}
 */
class DelayWhenSubscriber extends OuterSubscriber {
  constructor(destination, delayDurationSelector) {
    super(destination);
    this.delayDurationSelector = delayDurationSelector;
    this.completed = false;
    this.delayNotifierSubscriptions = [];
    this.values = [];
  }
  notifyNext(outerValue, innerValue, outerIndex, innerIndex, innerSub) {
    this.destination.next(outerValue);
    this.removeSubscription(innerSub);
    this.tryComplete();
  }
  notifyError(error, innerSub) {
    this._error(error);
  }
  notifyComplete(innerSub) {
    const value = this.removeSubscription(innerSub);
    if (value) {
      this.destination.next(value);
    }
    this.tryComplete();
  }
  _next(value) {
    try {
      const delayNotifier = this.delayDurationSelector(value);
      if (delayNotifier) {
        this.tryDelay(delayNotifier, value);
      }
    } catch (err) {
      this.destination.error(err);
    }
  }
  _complete() {
    this.completed = true;
    this.tryComplete();
  }
  removeSubscription(subscription) {
    subscription.unsubscribe();
    const subscriptionIdx = this.delayNotifierSubscriptions.indexOf(subscription);
    let value = null;
    if (subscriptionIdx !== -1) {
      value = this.values[subscriptionIdx];
      this.delayNotifierSubscriptions.splice(subscriptionIdx, 1);
      this.values.splice(subscriptionIdx, 1);
    }
    return value;
  }
  tryDelay(delayNotifier, value) {
    const notifierSubscription = subscribeToResult(this, delayNotifier, value);
    if (notifierSubscription && !notifierSubscription.closed) {
      this.add(notifierSubscription);
      this.delayNotifierSubscriptions.push(notifierSubscription);
    }
    this.values.push(value);
  }
  tryComplete() {
    if (this.completed && this.delayNotifierSubscriptions.length === 0) {
      this.destination.complete();
    }
  }
}
/**
 * We need this JSDoc comment for affecting ESDoc.
 * @ignore
 * @extends {Ignored}
 */
class SubscriptionDelayObservable extends Observable {
  constructor( /** @deprecated internal use only */source, subscriptionDelay) {
    super();
    this.source = source;
    this.subscriptionDelay = subscriptionDelay;
  }
  /** @deprecated internal use only */
  _subscribe(subscriber) {
    this.subscriptionDelay.subscribe(new SubscriptionDelaySubscriber(subscriber, this.source));
  }
}
/**
 * We need this JSDoc comment for affecting ESDoc.
 * @ignore
 * @extends {Ignored}
 */
class SubscriptionDelaySubscriber extends Subscriber {
  constructor(parent, source) {
    super();
    this.parent = parent;
    this.source = source;
    this.sourceSubscribed = false;
  }
  _next(unused) {
    this.subscribeToSource();
  }
  _error(err) {
    this.unsubscribe();
    this.parent.error(err);
  }
  _complete() {
    this.subscribeToSource();
  }
  subscribeToSource() {
    if (!this.sourceSubscribed) {
      this.sourceSubscribed = true;
      this.unsubscribe();
      this.source.subscribe(this.parent);
    }
  }
}