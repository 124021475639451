import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
export const EXTERNAL_GENRATE = 'generate'; //used to just launch the wizard from an external link

export function useGenerateQueryParam() {
  const history = useHistory();
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [state, setState] = useState({
    generate: searchParams.get(EXTERNAL_GENRATE) === 'true'
  });
  function deleteGenerate() {
    if (!searchParams.get(EXTERNAL_GENRATE)) {
      return;
    }
    searchParams.delete(EXTERNAL_GENRATE);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString()
    });
    setState(prevState => Object.assign({}, prevState, {
      generate: false
    }));
  }
  return {
    deleteGenerate,
    generate: state.generate
  };
}