'use es6';

import { List, Map as ImmutableMap, Record, Set as ImmutableSet } from 'immutable';
import ContentRoutes from 'ContentUtils/Routes';
import { ApprovalStatus } from 'ui-product-approvals/types';
import ContentSecurityStates, { VIOLATION_STATES } from '../../shared/constants/ContentSecurityStates';
import { SIMPLE_LP_TEMPLATE_TYPE } from '../../shared/constants/TemplateTypes';
import { DRAFT_STATES, PUBLISHED_STATES, SCHEDULED_STATES } from '../constants/PageStates';
import { ContentTypes, ContentTypesToContentTypeIds, SubcategoryTypes } from '../../shared/constants/ContentTypes';
import { LANDING_PAGES, SITE_PAGES } from '../../shared/constants/ManagementDataTypes';
import { ApprovalValueToStatus } from '../../frameworkListing/utils/filterPropertyUtils';
const defaults = {
  contentType: '',
  // From api call
  ab: false,
  abTestId: '',
  absoluteUrl: '',
  archived: false,
  authorName: '',
  businessUnitId: -1,
  campaign: '',
  campaignName: '',
  categoryId: -1,
  clonedFrom: -1,
  contentTypeCategoryId: -1,
  created: -1,
  createdById: -1,
  currentState: 'DRAFT',
  domain: '',
  folderId: 0,
  htmlTitle: '',
  id: -1,
  isPublished: false,
  label: '',
  language: '',
  mab: false,
  mabExperimentId: -1,
  managementDataType: null,
  name: '',
  password: null,
  previewKey: null,
  audienceAccess: '',
  publicAccessRules: new List(),
  publicAccessRulesEnabled: false,
  publishDate: 0,
  resolvedDomain: '',
  scheduledUpdateDate: null,
  screenshotPreviewUrl: '',
  screenshotPreviewTakenAt: null,
  securityState: ContentSecurityStates.NONE,
  slug: 'whatever',
  state: 'DRAFT',
  subcategory: '',
  teamPerms: new List(),
  templateType: -1,
  templatePath: '',
  testStatus: '',
  title: null,
  translatedContent: new ImmutableMap(),
  translatedFromId: null,
  updated: -1,
  updatedById: -1,
  url: '',
  userPerms: new List(),
  siteTreeVisualParentId: null,
  // this is used to identify the visual parent of group, useful in identifying reversed mlang groups when filtering by language
  approvalStatus: null
};
class Page extends Record(defaults) {
  getName() {
    return this.label || this.name;
  }
  isPublished() {
    return PUBLISHED_STATES.has(this.currentState);
  }
  isPublishedWithScheduledUpdate() {
    return this.isPublished() && !!this.scheduledUpdateDate;
  }
  isScheduled() {
    return SCHEDULED_STATES.has(this.currentState);
  }
  isDraft() {
    return DRAFT_STATES.has(this.currentState);
  }
  isArchived() {
    return this.archived;
  }
  hasTranslations() {
    return !this.translatedContent.isEmpty();
  }
  isTranslation() {
    return !!this.translatedFromId;
  }
  isPublic() {
    return !this.publicAccessRulesEnabled && !this.password;
  }
  isPasswordProtected() {
    return this.password && this.password.length > 0;
  }
  requiresRegistration() {
    return this.publicAccessRulesEnabled && this.publicAccessRules.size > 0;
  }
  getPublicAccessType() {
    if (!(this.publicAccessRules && this.publicAccessRules.size)) {
      return null;
    }
    return this.publicAccessRules.first().type;
  }
  getTranslatedContent() {
    return this.translatedContent;
  }
  getMlangGroupSize() {
    return this.getTranslatedContent().size + 1;
  }
  isWebsitePage() {
    return this.contentType === ContentTypes.SITE_PAGE;
  }
  isInViolation() {
    return VIOLATION_STATES.includes(this.securityState);
  }
  getDomain() {
    return this.domain || this.resolvedDomain;
  }
  getEditUrl() {
    return ContentRoutes.edit(this.id, this.categoryId);
  }
  isTesting() {
    return this.ab || this.mab;
  }
  isPartOfMultiLanguageGroup() {
    return this.hasTranslations() || this.isTranslation() || this.isPrimaryDisplayedAsTranslation();
  }
  hasAdvancedLPTemplate() {
    return this.templateType !== SIMPLE_LP_TEMPLATE_TYPE;
  }
  getTranslatedContentIds() {
    return this.translatedContent.reduce((acc, translation) => acc.add(translation.get('id')), ImmutableSet());
  }
  getGroupPrimaryId() {
    return this.isTranslation() ? this.translatedFromId : this.id;
  }
  isPrimaryDisplayedAsTranslation() {
    // this is used to identify if the primary of group is displayed as a translation node
    return !this.isTranslation() && this.siteTreeVisualParentId;
  }
  needsApproval(user) {
    if (user.isSuperAdmin()) {
      return ApprovalValueToStatus[this.approvalStatus] === ApprovalStatus.APPROVAL_PENDING;
    }
    return ApprovalValueToStatus[this.approvalStatus] !== ApprovalStatus.APPROVED;
  }
  static from(json) {
    const subcategory = json.subcategory ? json.subcategory.toLowerCase() : undefined;
    const contentType = subcategory === SubcategoryTypes.SITE_PAGE ? ContentTypes.SITE_PAGE : ContentTypes.LANDING_PAGE;
    const managementDataType = subcategory === SubcategoryTypes.SITE_PAGE ? SITE_PAGES : LANDING_PAGES;
    const contentTypeCategoryId = ContentTypesToContentTypeIds[contentType];
    const receivedJson = Object.assign({}, json, {
      subcategory,
      contentType,
      managementDataType,
      contentTypeCategoryId
    });
    if (receivedJson.translatedContent) {
      const translatedContent = {};
      Object.keys(receivedJson.translatedContent).forEach(key => {
        translatedContent[key] = new Page(Object.assign({}, receivedJson.translatedContent[key], {
          categoryId: receivedJson.categoryId,
          contentTypeCategoryId: receivedJson.contentTypeCategoryId,
          subcategory: receivedJson.subcategory ? receivedJson.subcategory.toLowerCase() : undefined,
          publicAccessRules: new List(receivedJson.translatedContent[key].publicAccessRules),
          templateType: receivedJson.translatedContent[key].templateType,
          /**
           * To set the correct `translatedFromId`, we want to use
           * `masterId` that is part of the api response first.
           *
           * Since we have mostly eliminated use of `masterId` in all places
           * in this app, we can use passed value of `translatedFromId` for that
           * language translation.
           */
          translatedFromId: receivedJson.translatedContent[key].masterId || receivedJson.translatedContent[key].translatedFromId,
          /**
           * saves the `id` of visual parent of mlang group
           * which is not the same as true primary page of mlang group
           */
          siteTreeVisualParentId: receivedJson.id,
          contentType,
          managementDataType,
          teamPerms: new List(receivedJson.translatedContent[key].teamPerms),
          userPerms: new List(receivedJson.translatedContent[key].userPerms)
        }));
      });
      receivedJson.translatedContent = new ImmutableMap(translatedContent);
    }
    if (receivedJson.publicAccessRules) {
      receivedJson.publicAccessRules = new List(receivedJson.publicAccessRules);
    }
    receivedJson.teamPerms = new List(receivedJson.teamPerms);
    receivedJson.userPerms = new List(receivedJson.userPerms);
    return new Page(receivedJson);
  }
}
export default Page;